import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import './index.scss'

const allowRoles = ['OM', 'PP']

const ListStocks = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewStocks = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewStocks = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

export { ListStocks, ViewStocks, NewStocks }
