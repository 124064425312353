import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageCustomersSchema = yup.object().shape({
  code: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  // address: yup.string().required(requiredField).max(255, maxLength),
  // email: yup.string().email(invalidEmail).required(requiredField).max(255, maxLength),
  // phone_number: yup.string().required(requiredField).max(255, maxLength),
  country: yup.string().required(requiredField).max(255, maxLength),
  payment_method: yup.string().required(requiredField).max(255, maxLength),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
})

export default manageCustomersSchema
