import React from 'react'
import { Typography, Col, Button } from 'antd'
import {
  RollbackOutlined,
  FormOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { DocumentsTable } from 'Components'

import { formatNumber, roundUpDecimal } from 'Utils'

const { Title } = Typography

const format = 'DD-MM-YYYY'

export default React.memo((props) => {
  const { data, loading, viewId, totalCubic, presentCubic } = props
  const history = useHistory()

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/sale-orders`)
  }

  const handleProducing = () => {
    history.push(`/sale-orders/${viewId}/producing`)
  }

  const generateSuggestion = () => {
    if (!totalCubic || !presentCubic) {
      return ''
    } else if (totalCubic > presentCubic) {
      return (
        <span style={{ color: 'green' }}>
          {`Thiếu ${(totalCubic - presentCubic).toFixed(2)} m3`}
        </span>
      )
    } else if (totalCubic < presentCubic) {
      return (
        <span style={{ color: 'red' }}>{`Thừa ${(presentCubic - totalCubic).toFixed(2)} m3`}</span>
      )
    } else return ''
  }

  return (
    <div id="view_sale_order_producing_employee" className="table-container">
      <div className="inner-view">
        <div className="header-view">
          <Title className="page-title">Thông tin đơn hàng</Title>
        </div>

        <div className="view-wrapper">
          <div className="form-item">
            <Title className="field-title">Order No:</Title>
            <div className="content">{data.order_number}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Ngày:</Title>
            <div className="content">{moment(data.date, 'YYYY-MM-DD').format(format)}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Khách hàng:</Title>
            <div className="content">{`${data.customer.code}: ${data.customer.name}`}</div>
          </div>

          <div className="order-table">
            <Title className="order-table-title">Thông tin sản phẩm:</Title>
            <Col className="order-table-content">
              <div className="inner-content">
                <div className="scroll-wrap">
                  <div className="row-wrapper">
                    <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                      STT
                    </div>
                    <div className="row-item thread-row" style={{ minWidth: 250 }}>
                      Tên sản phẩm
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                      Tem sản phẩm
                    </div>
                    <div className="row-item thread-row">Packing</div>
                    {/* <div className="row-item thread-row" style={{ minWidth: 200 }}>
                      Giá mua sản phẩm chưa thành phẩm
                    </div>
                    <div className="row-item thread-row" style={{ minWidth: 200 }}>
                      Giá mua sản phẩm thành phẩm
                    </div> */}
                    <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                      Số lượng (thùng)
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                      Số lượng(đơn vị)
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 150 }}>
                      Cubic (m3)
                    </div>

                    <div className="row-item thread-row" style={{ minWidth: 240 }}>
                      Ghi chú mặt hàng
                    </div>
                    <div className="row-item thread-row" style={{ minWidth: 180 }}>
                      Số lượng đã sản xuất
                    </div>
                    <div className="last-child thread-row">Đã hoàn thành</div>
                  </div>

                  <div className="scroll-vertical-table">
                    {data.sale_order_items.map((item, index) => {
                      return (
                        <div key={index} className="row-wrapper">
                          <div className="row-item" style={{ maxWidth: 60 }}>
                            {index + 1}
                          </div>
                          <div
                            className="row-item"
                            style={{ minWidth: 250 }}
                          >{`${item.product.code}: ${item.product.name}`}</div>
                          <a
                            style={{ maxWidth: 180 }}
                            href={`/labels?product_id=${item.product_id}&customer_id=${data.customer.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="row-item hyperlink"
                          >
                            Chi tiết
                          </a>
                          <div className="row-item">{item.specification}</div>
                          {/* <div className="row-item" style={{ minWidth: 200 }}>
                          {item.product_type === 'semifinish'
                            ? item.first_price
                              ? formatNumber(item.first_price)
                              : '-'
                            : '-'}
                        </div>
                        <div className="row-item" style={{ minWidth: 200 }}>
                          {item.product_type === 'finish'
                            ? item.second_price
                              ? formatNumber(item.second_price)
                              : '-'
                            : '-'}
                        </div> */}
                          <div className="row-item" style={{ maxWidth: 180 }}>
                            {formatNumber(Number(item.box_quantity))}
                          </div>
                          <div className="row-item" style={{ maxWidth: 180 }}>
                            {formatNumber(Number(item.total_quantity))}
                          </div>
                          <div className="row-item" style={{ maxWidth: 150 }}>
                            {formatNumber(Number(item.total_size))}
                          </div>

                          <div className="row-item" style={{ minWidth: 240 }}>
                            {item.note}
                          </div>
                          <div className="row-item" style={{ minWidth: 180 }}>
                            {`${
                              item.produced_box_quantity
                                ? formatNumber(Number(item.produced_box_quantity))
                                : '-'
                            }`}
                            &nbsp;/&nbsp;{`${formatNumber(Number(item.box_quantity))}`}
                          </div>
                          <div className="last-child">
                            {item.completed ? (
                              <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} />
                            ) : (
                              <CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </Col>
          </div>

          <div className="form-item" style={{ flexDirection: 'column' }}>
            <div className="container-types">
              <Title className="order-table-title">Đóng hàng vào container</Title>
              <Col className="order-table-content">
                <div className="inner-content">
                  <div className="scroll-wrap">
                    <div className="row-wrapper">
                      <div className="row-item thread-row">Loại container</div>
                      <div className="row-item thread-row">Cubic (m3)</div>
                      <div className="last-child thread-row">Số lượng</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(1) Container 20'DC`}</div>
                      <div className="row-item">28.50 m3</div>
                      <div className="last-child">{data.container_type_1_amount}</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(2) Container 40'DC`}</div>
                      <div className="row-item">58.50 m3</div>
                      <div className="last-child">{data.container_type_2_amount}</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(3) Container 40'HC`}</div>
                      <div className="row-item">66.50 m3</div>
                      <div className="last-child">{data.container_type_3_amount}</div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>

          <div className="form-item container-information" style={{ marginBottom: 0 }}>
            <div className="item">
              <Title className="field-title">Tổng số khối:</Title>
              <span className="content">{totalCubic ? `${totalCubic} m3` : '- m3'}</span>
            </div>

            <div className="item">
              <Title className="field-title">Hiện tại:</Title>
              <span className="content">
                {presentCubic ? `${roundUpDecimal(presentCubic)} m3` : '- m3'}
              </span>
            </div>

            <div className="item">
              <Title className="field-title">Gợi ý:</Title>
              <span className="content">{generateSuggestion()}</span>
            </div>
          </div>

          <div className="form-item container-information">
            <div className="date-item">
              <Title className="field-title">Ngày dự kiến sản xuất:</Title>
              <div className="content">
                {moment(data.produce_date, 'YYYY-MM-DD').format(format)}
              </div>
            </div>

            <div className="date-item">
              <Title className="field-title">Ngày dự kiến hoàn thành:</Title>
              <div className="content">
                {moment(data.complete_date, 'YYYY-MM-DD').format(format)}
              </div>
            </div>

            <div className="date-item">
              <Title className="field-title">Ngày dự kiến xuất hàng:</Title>
              <div className="content">{moment(data.export_date, 'YYYY-MM-DD').format(format)}</div>
            </div>
          </div>

          {/* <DocumentsTable data={data} viewId={viewId} documentsFor="saleOrder" /> */}

          <DocumentsTable
            documentsFor="saleOrder"
            commodityExportId={data.commodity_export.id}
            saleOrderId={data.id}
            saleOrderStatus={data.status}
            orderNumber={data.order_number}
            exportNumber={data.commodity_export.export_number}
            customerCountry={data.customer.country}
          />

          <div className="button-container">
            <Button
              icon={<RollbackOutlined />}
              type="primary"
              loading={loading}
              className="cancel-button"
              onClick={handleBack}
            >
              Trở lại
            </Button>

            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={loading}
              className="pi-button"
              onClick={handleProducing}
            >
              Sửa thông tin sản xuất
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})
