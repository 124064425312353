import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['OM']

const ListPurchaseOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewPurchaseOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewPurchaseOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

const EditPurchaseOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export { ListPurchaseOrder, ViewPurchaseOrder, NewPurchaseOrder, EditPurchaseOrder }
