import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import FormView from './Form.view'
import { manageSuppliersSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  code: '',
  name: '',
  contact_name: '',
  phone_number: '',
  email: '',
  address: '',
  info: '',
  note: '',
  bank_name: '',
  bank_branch: '',
  bank_number: '',
  bank_account_name: '',
}

export default React.memo((props) => {
  const { path, editId } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    if (path === 'new') {
      createSupplier(values)
    } else if (path === 'edit') {
      updateSupplier(values)
    }
  }

  const createSupplier = async (params) => {
    const url = '/suppliers/'
    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/suppliers/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors.message)
      setSubmitting(false)
    }
  }

  const updateSupplier = async (params) => {
    const url = `/suppliers/${editId}/edit/`
    try {
      const resp = await axios.put(url, { ...params })
      setSubmitting(false)
      history.push(`/suppliers/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="supplier-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSubmitting={isSubmitting}
            initialValues={initialValues}
          />
        )}
        validationSchema={manageSuppliersSchema}
      />
    </div>
  )
})
