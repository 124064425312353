import React from 'react'
import { Typography, Modal, Card, Row, Col, InputNumber, Checkbox } from 'antd'

import { formatInputNumber, replaceItemAtIndex } from 'Utils'
import axiosExpand from 'Utils/axiosExpand'

const { Text } = Typography
const { Grid } = Card

const modalInitialValues = {
  sale_order_id: undefined,
  sale_order_materials: [
    {
      material_id: undefined,
      material_name: '',
      price: undefined,
      use_new_price: false,
    },
  ],
}

export default React.memo((props) => {
  const { data, loading, visible, setEditProductMaterial } = props

  const renderTitle = () => <Text className="modal-title">Cập nhật giá nguyên vật liệu</Text>

  const handlePressOK = async () => {
    const url = '/sale_orders/update-sale-order-materials/'
    setEditProductMaterial((prev) => ({ ...prev, loading: true }))
    try {
      await axiosExpand.put(url, { ...data })
      setEditProductMaterial({ visible: false, data: modalInitialValues })
    } catch (error) {}
  }

  const handleCancel = () =>
    setEditProductMaterial({ visible: false, loading: true, data: modalInitialValues })

  const handleNumberChange = (index, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      //   setFieldValue(`sale_order_items[${index}].box_quantity`, 1)
    } else {
      const price = Math.floor(value)
      const newVal = { ...data.sale_order_materials[index], price }

      const newArray = replaceItemAtIndex(data.sale_order_materials, index, newVal)

      return setEditProductMaterial((prev) => ({
        ...prev,
        data: { ...prev.data, sale_order_materials: newArray },
      }))
    }
  }

  const handleUseNewPrice = (event, index) => {
    const checked = event.target.checked
    const newVal = { ...data.sale_order_materials[index], use_new_price: checked }
    const newArray = replaceItemAtIndex(data.sale_order_materials, index, newVal)
    return setEditProductMaterial((prev) => ({
      ...prev,
      data: { ...prev.data, sale_order_materials: newArray },
    }))
  }

  return (
    <Modal
      title={renderTitle()}
      centered
      visible={visible}
      cancelButtonProps={{
        loading,
        style: {
          margin: 10,
        },
      }}
      okButtonProps={{
        loading,
        style: {
          margin: 10,
        },
      }}
      okText={'Cập nhật'}
      cancelText={'Huỷ'}
      onOk={handlePressOK}
      onCancel={handleCancel}
      className="edit-material-table-modal"
    >
      <Card className="view-wrapper">
        <Grid hoverable={false} className="view-item">
          <Row className="row">
            <Col className="content-wrapper" style={{ fontWeight: 'bold' }} span={8}>
              Tên
            </Col>
            <Col className="content-wrapper" style={{ fontWeight: 'bold' }} span={9}>
              Giá (VND)
            </Col>
            <Col
              className="content-wrapper"
              span={7}
              style={{ justifyContent: 'center', fontWeight: 'bold' }}
            >
              Áp dụng giá mới
            </Col>
          </Row>
        </Grid>
        {data.sale_order_materials.map((material, index) => {
          return (
            <Grid hoverable={false} className="view-item" key={index}>
              <Row className="row">
                <Col className="content-wrapper" span={8}>
                  {material.material_name}
                </Col>
                <Col className="content-wrapper" span={9}>
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Nhập giá"
                    disabled={loading}
                    value={material.price}
                    formatter={(value) => formatInputNumber(value)}
                    onChange={(value) => handleNumberChange(index, 10000000000, value)}
                    min={1}
                    max={10000000000}
                  />
                </Col>
                <Col
                  className="content-wrapper"
                  span={7}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Checkbox
                    disabled={loading}
                    checked={data.sale_order_materials[index].use_new_price}
                    onChange={(event) => handleUseNewPrice(event, index)}
                  />
                </Col>
              </Row>
            </Grid>
          )
        })}
      </Card>
    </Modal>
  )
})
