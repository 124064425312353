import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['OM', 'PP', 'DI']

const ListLabel = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
}

const ViewLabel = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
}

const NewLabel = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
}

const EditLabel = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
}

export { ListLabel, ViewLabel, NewLabel, EditLabel }
