import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageStocksSchema = yup.object().shape({
  transaction_number: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  transaction_type: yup.string().required(requiredField).max(255, maxLength),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(1000, maxLength1000),
  transaction_items: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(requiredField),
        specification: yup
          .string()
          .trim('Vui lòng không nhập khoảng trắng')
          .strict()
          .required(requiredField),
        unit: yup.string(),
        quantity_before: yup.number().required(requiredField).typeError(typeError),
        quantity: yup.number().required(requiredField).typeError(typeError),
        quantity_after: yup.number().required(requiredField).typeError(typeError),
        note: yup
          .string()
          .trim('Vui lòng không nhập khoảng trắng')
          .strict()
          .nullable(true)
          .max(255, maxLength),
      })
    )
    .required(requiredField) // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Cần tối thiểu 1 sản phẩm'),
  // sale_order_id: yup.string(),
  item_type: yup.string().required(requiredField),
})

export default manageStocksSchema
