import * as yup from 'yup'

const invalidEmail = 'Email không hợp lệ'
const requiredEmail = 'Vui lòng nhập email'

const requiredName = 'Vui lòng nhập tên nhân viên'
const minNameLength = 'Tên nhân viên cần ít nhất 3 ký tự'
const maxNameLength = 'Tên nhân viên tối đa 255 ký tự'
const requiredRoles = ' Vui lòng chọn ít nhất 1 vị trí/chức vụ'

const manageUsersSchema = yup.object().shape({
  name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .min(3, minNameLength)
    .max(255, maxNameLength)
    .required(requiredName),
  email: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .email(invalidEmail)
    .required(requiredEmail),
  roles: yup.array().required(requiredRoles),
})

export default manageUsersSchema
