import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import get from 'lodash/get'
import isArray from 'lodash/isArray'

import axios from 'Utils/axiosExpand'
import { convertObjectToQueryString, convertQueryStringToObject } from 'Utils'
import { formatToNumber } from 'Utils/formatNumber'

const useDebounceSearch = (value, delay, firstRender) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    if (firstRender) return setDebouncedValue(value)
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, value])
  return debouncedValue
}
const useDataApi = (filterKey, extendParams, extendsPagination) => {
  const [state, setState] = useState({
    data: [],
    count: 0,
    commodityExportCount: 0,
    loading: false,
    errors: null,
  })
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    order_by: '',
    order: '',
    ...extendsPagination,
  })
  const [params, setParams] = useState({
    page: 1,
    per_page: 5,
    order_by: '',
    order: '',
    ...extendParams,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [initSearchValue, setInitSearchValue] = useState('')
  const [firstRender, setFirstRender] = useState(true)

  const history = useHistory()
  const location = useLocation()

  const parseFilter = (value) => {
    if (value) {
      if (Array.isArray(value)) return value
      return value.split(',')
    }
    return null
  }

  const computeFilterKey = (value) => {
    if (value === 'null') return null
    return parseFilter(value)
  }

  useEffect(() => {
    let didCancel

    let search = location.search.substring(1)
    let formattedParams = { ...pagination, ...params }

    if (search) {
      let convetedString = convertQueryStringToObject(search)

      if (filterKey && convetedString[filterKey]) {
        const computedValue = computeFilterKey(convetedString[filterKey])
        convetedString = {
          ...convetedString,
          [filterKey]: computedValue,
        }
      }

      formattedParams = {
        ...formattedParams,
        ...convetedString,
        page: convetedString.page ? Number(convetedString.page) : 1,
        per_page: convetedString.per_page ? Number(convetedString.per_page) : 5,
        current: convetedString.page ? Number(convetedString.page) : 1,
        pageSize: convetedString.per_page ? Number(convetedString.per_page) : 5,
      }

      setSearchTerm(formattedParams.keyword ? formattedParams.keyword : '')
      setInitSearchValue(formattedParams.keyword ? formattedParams.keyword : '')

      setParams((prev) => ({
        ...prev,
        ...formattedParams,
        page: formattedParams.page,
        per_page: formattedParams.per_page,
        order_by: formattedParams.order_by,
        order: formattedParams.order,
      }))

      setPagination((prev) => ({
        ...prev,
        ...formattedParams,
        current: formattedParams.page,
        pageSize: formattedParams.per_page,
        order_by: formattedParams.order_by,
        order: formattedParams.order,
      }))
    }
    const fetchData = async () => {
      setState((prev) => ({ ...prev, loading: true, errors: null }))
      try {
        const result = await axios.get('/standing_orders/statistic/', {
          params: { ...formattedParams },
        })

        if (!didCancel) {
          let numb = 0
          const data = get(result, 'data.records', []) || []

          data.forEach((item) => {
            if (isArray(item.purchase_orders)) {
              item.purchase_orders.forEach((purchase_order) => {
                if (formatToNumber(purchase_order.commodity_export_items_count) > numb) {
                  numb = formatToNumber(purchase_order.commodity_export_items_count)
                }
              })
            }
          })

          setState({
            data,
            count: get(result, 'data.maximum_standing_order_records', 0),
            commodityExportCount: numb,
            loading: false,
          })

          setFirstRender(false)
          if (result.data.pagination) {
            setPagination((prev) => ({
              ...prev,
              ...params,
              current: result.data.pagination.page,
              pageSize: result.data.pagination.per_page,
              order: formattedParams.order,
              order_by: formattedParams.order_by,
              total: result.data.pagination.total_count,
            }))

            const queryString = {
              ...formattedParams,
              ...result.data.pagination,
            }

            const converted = convertObjectToQueryString(queryString)

            history.push({
              pathname: location.pathname,
              search: converted,
            })
          }
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors?.message,
          }))
          setFirstRender(false)
        }
      }
    }

    if (firstRender) fetchData()
    return () => {
      didCancel = true
      setFirstRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedSearchTerm = useDebounceSearch(searchTerm, 500, firstRender)

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      setState((prev) => ({ ...prev, loading: true, errors: null }))
      if (initSearchValue !== debouncedSearchTerm) params.page = 1
      try {
        const result = await axios.get('/standing_orders/statistic/', {
          params: {
            ...params,
            page: params.page,
            keyword: debouncedSearchTerm,
          },
        })
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            data: get(result, 'data.records', []),
            count: get(result, 'data.maximum_standing_order_records', 0),
            loading: false,
          }))

          setInitSearchValue(debouncedSearchTerm)
          if (result.data.pagination) {
            setPagination((prev) => ({
              ...prev,
              current: result.data.pagination.page,
              pageSize: result.data.pagination.per_page,
              order: params.order,
              order_by: params.order_by,
              total: result.data.pagination.total_count,
            }))

            const queryString = {
              ...params,
              ...result.data.pagination,
              keyword: debouncedSearchTerm,
            }

            const converted = convertObjectToQueryString(queryString)

            history.push({
              pathname: location.pathname,
              search: converted,
            })
          }
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors?.message,
          }))
        }
      }
    }

    if (!firstRender) fetchData()

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, debouncedSearchTerm])

  return {
    ...state,
    params,
    firstRender,
    searchTerm,
    initSearchValue,
    pagination,
    setPagination,
    doFetch: setParams,
    setSearchTerm,
  }
}
export default useDataApi
