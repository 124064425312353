import React, { useEffect } from 'react'

import ShippingScheduleForm from '../Form'
import ShippingScheduleTable from '../Table'
import useDataApi from '../hooks'
import '../index.scss'

export default React.memo(() => {
  const {
    firstRender,
    data,
    loading,
    searchTerm,
    pagination,
    errors,
    doFetch,
    setSearchTerm,
  } = useDataApi({
    url: '/sale_orders/shipping-schedule/',
    key: 'records',
  })
  useEffect(() => {
    handleFormSubmit()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleFormSubmit = (values) => {
    doFetch((prev) => ({ ...prev, ...values, page: 1, per_page: 10, order_by: '', order: '' }))
  }

  return (
    <div id="manage_shipping_schedule_page">
      <ShippingScheduleForm
        handleFormSubmit={handleFormSubmit}
        serverError={errors}
        loading={loading}
        firstRender={firstRender}
      />
      <ShippingScheduleTable
        data={data}
        loading={loading}
        firstRender={firstRender}
        pagination={pagination}
        doFetch={doFetch}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
    </div>
  )
})
