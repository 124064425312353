import React from 'react'

import { CheckPermission } from 'Components'
import Import from './Form'

import './index.scss'

const ImportTimeAttendance = (props) => <CheckPermission component={Import} {...props} />

export { ImportTimeAttendance }
