import { FormOutlined, PlusOutlined, RollbackOutlined, SearchOutlined } from '@ant-design/icons'
import { Alert, Button, Input, InputNumber, Select, Spin, Typography } from 'antd'
import { BlockNavigation, noData } from 'Components'
import { ErrorMessage, Field, Form } from 'formik'
import { useDebounce } from 'Hooks'
import { default as get, default as _get } from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  formatInputNumber,
  preventEnterSubmit,
  removeVietnameseTones,
  useScrollToErrors,
} from 'Utils'
import axios from 'Utils/axiosRexyAdmin'

const { Title } = Typography
const { Option } = Select

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const { TextArea } = Input

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    setFieldValue,
    serverError,
    editId,
    path,
    errors,
    touched,
    duplicate,
    initialValues,
  } = props

  const history = useHistory()
  const [isFetching, setIsFetching] = useState(false)
  const [units, setUnits] = useState([])
  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })
  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  useEffect(() => {
    let didCancel = false

    const fetchUnits = async () => {
      const url = '/materials/new/'
      const resp = await axios.get(url)
      setUnits(resp.data.material_units)

      // if page id edit, the server will return conversions
      // and unit, not need to setField
      if (path === 'edit') return

      const conversions = _get(resp, 'data.material_conversions', [])
      const defaultConversions = _map(conversions, (conversion) => ({
        conversion_type: _get(conversion, 'value'),
        content: _get(conversion, 'name'),
        value: 0,
      }))
      setFieldValue('conversions', defaultConversions)

      setFieldValue('unit', resp.data.material_units[0].value)
    }

    if (!didCancel) fetchUnits()

    return () => (didCancel = true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchSupplier = async () => {
      const url = '/materials/new/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
          },
        })

        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.suppliers,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (debouncedSearchTerm.length && searchTerm.keyword === debouncedSearchTerm) fetchSupplier()

    return () => (didCancel = true)
  }, [debouncedSearchTerm, units.length, searchTerm.keyword])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/materials/${editId}/edit`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)

        if (editId && !didCancel) {
          // if (!duplicate) onSearch(resp.data.supplier.code)
          const suppliers = get(resp.data, 'suppliers', [])
          setSearchTerm((prev) => ({ ...prev, result: suppliers }))
          const supplierIds = suppliers.map((ele) => ele.id)
          setValues({
            ...values,
            ...resp.data,
            temp_supplier_ids: supplierIds,

            code: duplicate ? undefined : resp.data.code,
            // supplier_name: duplicate
            //   ? undefined
            //   : `${resp.data.supplier.code}: ${resp.data.supplier.name}`,
            // supplier_id: duplicate ? undefined : resp.data.supplier.id,
          })

          const notSetFirstTimeFetchedValuesYet = _size(firstTimeFetchedValues) === 0

          if (notSetFirstTimeFetchedValuesYet)
            setFirstTimeFetchedValues({
              ...values,
              ...resp.data,
              code: duplicate ? undefined : resp.data.code,
            })

          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    if (editId) fetchData()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, setValues])

  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onSupplierSelect = (val) => {
    setFieldValue('temp_supplier_ids', val)
  }

  // const onUnitChange = (val) => {
  //   setFieldValue('unit', val)
  // }

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  const handlePriceChange = (fieldName, limit, value) => {
    if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(fieldName, undefined)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(fieldName, roundDown)
    }
  }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_materials_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <BlockNavigation
        values={values}
        initialValues={initialValues}
        path={path}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />
      <Title className="page-sub-title">Thông tin vật liệu</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Mã vật liệu <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="code"
              placeholder="Nhập mã vật liệu"
              disabled={loadingCondition}
              value={values.code}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="code" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Tên vật liệu <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="name"
              placeholder="Nhập tên vật liệu"
              disabled={loadingCondition}
              value={values.name}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="name" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Nhà cung cấp <span className="compulsory-field-symbol">*</span>
            </Title>
            {/* <SearchField
              mode="multiple"
              axios={axios}
              url="/suppliers/form/"
              value={values.suppliers}
              onChange={onSupplierSelect}
              disabled={loadingCondition}
              optionParams={{
                value: 'id',
                label: {
                  prefix: 'code',
                  suffix: 'name',
                },
              }}
              placeholder="Nhập mã hoặc tên nhà cung cấp để tìm kiếm"
              triggerKeywordSearch={triggerKeywordSearch.supplier}
              triggerOptions={triggerOptions.supplier}
            /> */}

            <Field name="supplier_ids" disabled={loadingCondition}>
              {(props) => {
                return (
                  <Select
                    mode="multiple"
                    name="temp_supplier_ids"
                    style={{ width: '100%' }}
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    notFoundContent={searchDropDownCondition}
                    placeholder="Nhập mã hoặc tên nhà cung cấp để tìm kiếm"
                    onSearch={onSearch}
                    value={values.temp_supplier_ids}
                    onChange={onSupplierSelect}
                    optionFilterProp="children"
                    loading={searchTerm.searching}
                    disabled={loadingCondition}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {searchTerm.result.map((item) => (
                      <Option key={item.id} value={item.id}>{`${item.code}: ${item.name}`}</Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="supplier_id" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Đơn giá (Kg) <span className="compulsory-field-symbol">*</span>
            </Title>
            <Input.Group compact>
              <Field
                as={InputNumber}
                name="price"
                disabled={loadingCondition}
                value={values.price}
                onChange={(value) => handlePriceChange('price', 1000000000000, value)}
                style={{ width: '78%' }}
                formatter={(value) => formatInputNumber(value)}
                placeholder="Nhập đơn giá"
                min={0}
                max={1000000000000}
              />
              <Input
                className="unit-disable-field"
                disabled={true}
                defaultValue="VND"
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Input.Group>

            <ErrorMessage component={(props) => <CustomError {...props} />} name="price" />
          </div>
          {/* 
          <div className="form-item">
            <Title className="field-title">
              Số lượng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={InputNumber}
              style={{ width: '100%' }}
              name="quantity"
              placeholder="Nhập số lượng"
              disabled={path === 'new' ? loadingCondition : true}
              value={values.quantity}
              onChange={(value) => {
                if (value > 1000000000) {
                  return
                } else {
                  setFieldValue('quantity', value)
                  setFieldValue('temporary_quantity', value)
                }
              }}
              step={0.1}
              precision={2}
              formatter={(value) => formatInputNumber(value)}
              min={0}
              max={1000000000}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="quantity" />
          </div>
          <div className="form-item">
            <Title className="field-title">
              Số lượng khả dụng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={InputNumber}
              style={{ width: '100%' }}
              name="temporary_quantity"
              placeholder="Nhập số lượng khả dụng"
              disabled={true}
              value={values.quantity}
              step={0.1}
              precision={2}
              onChange={(value) => {
                if (value > 1000000000) {
                  return
                } else {
                  setFieldValue('temporary_quantity', value)
                }
              }}
              formatter={(value) => formatInputNumber(value)}
              min={0}
              max={1000000000}
            />
          </div> */}

          <div className="form-item">
            <Title className="field-title">
              Quy cách <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="specification"
              placeholder="Nhập quy cách"
              disabled={loadingCondition}
              value={values.specification}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="specification" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Tiêu chuẩn chất lượng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="standard_quantity"
              placeholder="Nhập tiêu chuẩn chất lượng"
              disabled={loadingCondition}
              value={values.standard_quantity}
              onChange={handleChange}
            />
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="standard_quantity"
            />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Nơi lưu trữ/cách bảo quản <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="preserve_location"
              placeholder="Nhập nơi lưu trữ/cách bảo quản"
              disabled={loadingCondition}
              value={values.preserve_location}
              onChange={handleChange}
            />
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="preserve_location"
            />
          </div>

          <div className="form-item">
            <Title className="field-title">Ghi chú</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Nhập ghi chú"
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/materials')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Thêm vật liệu' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
