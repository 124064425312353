import { action } from 'easy-peasy'

export default {
  id: undefined,
  quantity_in_stock: undefined,
  action: 'use', // ['use', 'not-use', 'delete']
  toggleMaterialStatus: action((state, payload) => {
    state.id = payload.id
    state.quantity_in_stock = payload.quantity_in_stock
    state.action = payload.action
  }),
}
