import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import FormView from './Form.view'
import { manageMaterialsSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  material_type: 'packing',
  code: '',
  name: '',
  specification: '',
  standard_quantity: '',
  conversions: [
    {
      value: '',
      content: '',
    },
  ],
  note: '',
  preserve_location: '',
  unit: '',
  price: undefined,
  quantity: 0,
  temporary_quantity: 0,
  supplier_ids: [],
  supplier: { id: undefined, code: '', name: '' },
  suppliers: [],
  temp_supplier_ids:[]
}

export default React.memo((props) => {
  const { path, editId } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [formattedInitialValues, setFormattedInitialValues] = useState(initialValues)

  const handleFormSubmit = async (values) => {
    values.supplier_ids = values.temp_supplier_ids.join()

    setServerError(null)
    setSubmitting(true)
    if (path === 'new') {
      createMaterial(values)
    } else if (path === 'edit') {
      updateMaterial(values)
    }
  }

  const createMaterial = async (params) => {
    const url = '/materials/'
    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/packages/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors.message)
      setSubmitting(false)
    }
  }

  const updateMaterial = async (params) => {
    const url = `/materials/${editId}/edit/`
    try {
      const formattedParams = {
        ...params,
        conversions: _.map(params.conversions, (conversion) => ({
          ...conversion,
          value: Number(conversion.value) || 0,
        })),
      }
      const resp = await axios.put(url, formattedParams)
      setSubmitting(false)
      history.push(`/packages/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors.message)
      setSubmitting(false)
    }
  }

  // Need to call and format initial values to comparable
  // in the BlockNavigation
  useEffect(() => {
    let didCancel = false

    const fetchUnits = async () => {
      const url = '/materials/new/'
      const resp = await axios.get(url)
      // setUnits(resp.data.material_units)

      const conversions = _.get(resp, 'data.packing_conversions', [])
      const defaultConversions = _.map(conversions, (conversion) => ({
        conversion_type: _.get(conversion, 'value'),
        content: _.get(conversion, 'name'),
        value: 0,
      }))

      setFormattedInitialValues((prev) => ({
        ...prev,
        conversions: defaultConversions,
        unit: resp.data.packing_units[0].value,
      }))
    }

    if (!didCancel) fetchUnits()

    return () => (didCancel = true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="material-form">
      <Formik
        initialValues={formattedInitialValues}
        onSubmit={handleFormSubmit}
        children={(formProps) => (
          <FormView
            {...formProps}
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSubmitting={isSubmitting}
            initialValues={formattedInitialValues}
          />
        )}
        validationSchema={manageMaterialsSchema}
      />
    </div>
  )
})
