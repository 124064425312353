import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import { RollbackOutlined, FormOutlined, CopyOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
// import _ from 'lodash'
import { formatNumber } from 'Utils'
import axios from 'Utils/axiosRexyAdmin'

const { Title } = Typography

const INITIAL_PROFILE = {
  name: '',
  email: '',
  roles: [],
  is_super_admin: false,
  active: false,
  conversions: [],
  readable_unit: '',
  supplier: {
    name: '',
    code: '',
  },
}

export default React.memo((props) => {
  const history = useHistory()
  const id = props.match?.params.id

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/materials/${id}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            data: {
              ...resp.data,
              // supplier_name: resp.data.supplier.name,
            },
            loading: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [id])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/materials`)
  }

  const handleEdit = () => {
    history.push(`/materials/${id}/edit`)
  }

  const handleCreateDuplicate = () => {
    history.push(`/materials/new/${id}`)
  }

  return (
    <div id="view_material" className="table-container">
      <Title className="page-title">Thông tin nguyên vật liệu</Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">Thông tin chung</Title>
          <div className="part-field-wrapper">
            {/* <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.supplier.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.supplier.name}
                </Col>
              </Row>
            </Card.Grid> */}
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  <ul style={{ marginLeft: '-20px' }}>
                    {state.data.suppliers
                      ? state.data.suppliers.map((item) => (
                          <li key={item.id}>
                            {item.code}: {item.name}
                          </li>
                        ))
                      : ''}
                  </ul>
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã nguyên vật liệu
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.code}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên nguyên vật liệu
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.name}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Đơn giá (VND/Kg)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(state.data.price)}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số lượng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(state.data.quantity)}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số lượng khả dụng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(state.data.temporary_quantity)}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Quy cách
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.specification}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tiêu chuẩn chất lượng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.standard_quantity}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Nơi lưu trữ/cách bảo quản
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.preserve_location}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>

          <Button
            icon={<FormOutlined />}
            type="primary"
            loading={state.loading}
            className="process-button"
            onClick={handleEdit}
          >
            Chỉnh sửa
          </Button>

          <Button
            icon={<CopyOutlined />}
            type="primary"
            loading={state.loading}
            className="create-duplicate-button"
            onClick={handleCreateDuplicate}
          >
            Tạo bản sao
          </Button>
        </div>
      </div>
    </div>
  )
})
