import React, { useState } from 'react'
import { Typography, message } from 'antd'
import { Formik } from 'formik'
import _ from 'lodash'

import FormView from './Form.view'
import axiosExpand from 'Utils/axiosExpand'

const { Title } = Typography

const initialValues = {
  file: undefined,
}

export default React.memo((props) => {
  const id = props.match?.params.id
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [manualError, setManualError] = useState(false)

  const handleFormSubmit = async (values, action) => {
    const choseFile = _.get(values.file, 'fileList', []).length

    if (!choseFile) return setManualError(true)
    setManualError(false)
    setServerError(null)
    setSubmitting(true)

    const url = `/time_attendance/import`
    try {
      const file = values.file.fileList[0].originFileObj

      const formData = new FormData()
      formData.append('file', file)

      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      await axiosExpand.put(url, formData, options)
      setSubmitting(false)
      action.setFieldValue('file', undefined)
      message.success(`File uploaded successfully`)
    } catch (error) {
      setServerError('File không hợp lệ')
      setSubmitting(false)
    }
  }

  return (
    <div id="import_time_attendence_page">
      <Title className="page-title">Tải lên file chấm công</Title>

      <div className="time-attendence-form">
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          children={(props) => (
            <FormView
              {...props}
              manualError={manualError}
              setManualError={setManualError}
              serverError={serverError}
              editId={id}
              path={'edit'}
              isSubmitting={isSubmitting}
            />
          )}
          // validationSchema={manageImportTimeAttendenceSchema}
        />
      </div>
    </div>
  )
})
