import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import './index.scss'

class Editor extends React.Component {
  handleChange = (html) => {
    const pureContent = this.extractContent(html)
    this.props.handleChangeLabelContent(html, pureContent.trim())
  }

  extractContent = (s) => {
    let p = document.createElement('p')
    p.innerHTML = s
    let children = p.querySelectorAll('*')
    for (let i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += ' '
      else children[i].innerText += ' '
    }
    return [p.textContent || p.innerText].toString().replace(/ +/g, ' ')
  }

  render() {
    return (
      <ReactQuill
        theme="snow"
        onChange={this.handleChange}
        value={this.props.value}
        modules={Editor.modules}
        formats={Editor.formats}
        placeholder={this.props.placeholder}
      />
    )
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
]

Editor.propTypes = {
  placeholder: PropTypes.string,
}

export default Editor
