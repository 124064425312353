import React, { useState, useEffect, useCallback } from 'react'
import { Table, Input, Button, Upload, Modal } from 'antd'
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import axios from 'Utils/axiosRexyAdmin'
import { pageSizeConfig, formatNumber } from 'Utils'
import useDataApi from '../hooks'

export default React.memo(() => {
  const history = useHistory()

  const [clickedRow, setClickedRow] = useState({
    id: undefined,
    loading: false,
  })

  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/products/',
    key: 'records',
  })

  const [previewImage, setPreviewImage] = useState({
    file: '',
    visible: false,
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const productsState = useStoreState((state) => state.menuTabs['products'])

  useEffect(() => {
    let didCancel
    if (productsState && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'products', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsState])

  const columns = [
    {
      title: 'Hình ảnh minh họa',
      dataIndex: 'image_link',
      width: 180,
      render: (link, record) => renderProductImage(link, record),
    },
    {
      title: 'Datafood Code',
      dataIndex: 'code',
      sortOrder: pagination.order_by === 'code' ? `${pagination.order}` : null,
      width: 190,
      sorter: true,
    },
    {
      title: 'Tên tiếng Việt',
      dataIndex: 'name',
      sortOrder: pagination.order_by === 'name' ? `${pagination.order}` : null,
      width: 220,
      sorter: true,
    },
    {
      title: 'Tên tiếng Anh',
      dataIndex: 'name_on_proforma_invoice',
      sortOrder: pagination.order_by === 'name_on_proforma_invoice' ? `${pagination.order}` : null,
      width: 220,
      sorter: true,
    },

    {
      title: 'Packing',
      dataIndex: 'specification',
      sortOrder: pagination.order_by === 'specification' ? `${pagination.order}` : null,
      width: 190,
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'suppliers',
      width: 220,
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      width: 140,
      sortOrder: pagination.order_by === 'quantity' ? `${pagination.order}` : null,
      sorter: true,
      render: (record) => formatNumber(record),
    },
    {
      title: 'Số lượng khả dụng',
      dataIndex: 'temporary_quantity',
      width: 160,
      sortOrder: pagination.order_by === 'temporary_quantity' ? `${pagination.order}` : null,
      sorter: true,
      render: (record) => formatNumber(record),
    },
    {
      title: 'Customer Code',
      dataIndex: 'customer_code',
      sortOrder: pagination.order_by === 'customer_code' ? `${pagination.order}` : null,
      width: 190,
      sorter: true,
    },
    {
      title: 'Kích hoạt sản phẩm',
      dataIndex: 'active',
      sortOrder: pagination.order_by === 'active' ? `${pagination.order}` : null,
      width: 200,
      sorter: true,
      render: (record, row) => activeProduct(record, row),
    },
    {
      key: 'x',
      width: 220,
      render: (record) => actionColumn(record),
    },
  ]

  const getBase64 = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const renderProductImage = (link, record) => {
    return (
      <div onClick={(event) => event.stopPropagation()}>
        <Upload
          listType="picture-card"
          showUploadList={{ showRemoveIcon: false }}
          fileList={[
            {
              uid: `uid-${record.aws_object_key}`,
              status: 'done',
              url: link,
            },
          ]}
          onPreview={handlePreview}
          onRemove={false}
        />
      </div>
    )
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage({ file: file.url || file.preview, visible: true })
  }

  const handleCancel = () => {
    setPreviewImage((prevState) => ({ ...prevState, visible: false }))
  }

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}
        <Button className="action-item update" type="link" onClick={(e) => handleEdit(record, e)}>
          Sửa
        </Button>

        <Button
          className="action-item create-duplicate"
          type="link"
          onClick={(e) => handleCreateDuplicate(record, e)}
        >
          Tạo bản sao
        </Button>
      </div>
    )
  }

  const activeProduct = (active, row) => {
    const activeLoading = clickedRow.loading && row.id === clickedRow.id

    return (
      <div className="active-button-wrapper">
        {active ? (
          <Button
            className="action-item deactive"
            type="primary"
            loading={activeLoading}
            onClick={(e) => handleActive(row, e)}
          >
            Vô hiệu hoá
          </Button>
        ) : (
          <Button
            className="action-item active"
            type="primary"
            loading={activeLoading}
            onClick={(e) => handleActive(row, e)}
          >
            Khôi phục
          </Button>
        )}
      </div>
    )
  }

  const handCreate = () => {
    history.push('/products/new')
  }

  const handleCreateDuplicate = (record, e) => {
    e.stopPropagation()
    history.push(`/products/new/${record.id}`)
  }

  const handleView = (record) => {
    history.push(`/products/${record.id}/view`)
  }

  const handleEdit = (record, e) => {
    e.stopPropagation()
    history.push(`/products/${record.id}/edit`)
  }

  const handleActive = async (record, e) => {
    e.stopPropagation()
    setClickedRow({ id: record.id, loading: true })

    try {
      await axios.patch(`/products/${record.id}/`)
      doFetch({})
      setClickedRow({ id: undefined, loading: false })
    } catch (error) {
      setClickedRow({ id: undefined, loading: false })
    }
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Thêm sản phẩm
        </Button>
      </div>
    )
  }

  const dataSource = data.map(({ suppliers, ...rest }) => ({
    ...rest,
    suppliers: suppliers.map((ele) => ele.name).join(', '),
  }))

  return (
    <div id="manage_product_table">
      <Table
        title={renderTitle}
        className="users-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig, pageSizeOptions: [3, 5, 10, 20] }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 2050, y: 500 }}
        bordered
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
      <Modal
        visible={previewImage.visible}
        footer={null}
        onCancel={handleCancel}
        centered
        // closable={false}
        bodyStyle={{ padding: 5 }}
        width={'80%'}
        closeIcon={<CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />}
      >
        <img
          alt="preview"
          className="image-preview"
          style={{ width: '100%', height: 'auto' }}
          src={previewImage.file}
        />
      </Modal>
    </div>
  )
})
