import React from 'react'
import axiosExpand from 'Utils/axiosExpand'
import { message, Typography, Col, Button } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'

import _map from 'lodash/map'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _filter from 'lodash/filter'
const { Title } = Typography

const DocumentTable = React.memo((props) => {
  const { documents, action, setDocuments } = props
  const FileDownload = require('js-file-download')
  const download = async ({ url, fileName }) => {
    try {
      const result = await axiosExpand.get(url, { responseType: 'arraybuffer' })
      FileDownload(result.data, fileName)
    } catch (error) {
      message.error('Đã có lỗi xảy ra, xin vui lòng tải lại trang và thử lại.')
    }
  }

  const actionIsView = action === 'view'

  const handleDownloadDocument = (document) => {
    download({
      url: _get(document, 'originFileObj.url'),
      fileName: _get(document, 'originFileObj.name'),
    })
  }

  const handleRemoveDocument = (currentDocument) => {
    const newDocuments = _filter(documents, (document) => document.uid !== currentDocument.uid)
    setDocuments(newDocuments)
  }

  const shouldShowNoData = _size(documents) === 0 && actionIsView

  return (
    <div className="form-item" style={{ flexDirection: 'column', marginBottom: 12 }}>
      {/* <div className="container-types"> */}
      <Title className="field-title" style={{ marginBottom: 10 }}>
        Other Documents
      </Title>
      {shouldShowNoData ? (
        <div style={styles.showNoData}>No document.</div>
      ) : (
        <Col className="order-table-content">
          <div className="inner-content">
            <div className="scroll-wrap" style={{ minWidth: 'auto' }}>
              <div className="row-wrapper">
                <div className="row-item thread-row">Loại document</div>
                <div className="row-item thread-row" style={{ borderRightWidth: 0 }}>
                  {actionIsView ? 'Download' : 'Actions'}
                </div>
              </div>
              <Documents
                documents={documents}
                actionIsView={actionIsView}
                handleDownloadDocument={handleDownloadDocument}
                handleRemoveDocument={handleRemoveDocument}
              />
            </div>
          </div>
        </Col>
      )}
    </div>
  )
})

const Documents = ({ documents, actionIsView, handleDownloadDocument, handleRemoveDocument }) =>
  _map(documents, (document) => (
    <Document
      actionIsView={actionIsView}
      document={document}
      key={_get(document, 'uid')}
      handleDownloadDocument={handleDownloadDocument}
      handleRemoveDocument={handleRemoveDocument}
    />
  ))

const Document = ({ document, actionIsView, handleDownloadDocument, handleRemoveDocument }) => {
  return (
    <div className="row-wrapper">
      <div className="row-item">{_get(document, 'originFileObj.name')}</div>
      {actionIsView ? (
        <div className="row-item" style={{ borderRightWidth: 0 }}>
          <span className="downloadable-button" onClick={() => handleDownloadDocument(document)}>
            Tải về
          </span>
        </div>
      ) : (
        <div className="row-item" style={{ borderRightWidth: 0 }}>
          <Button
            type="link"
            danger
            icon={<MinusCircleOutlined />}
            onClick={() => handleRemoveDocument(document)}
          />
        </div>
      )}
    </div>
  )
}

const styles = {
  showNoData: {
    marginLeft: 10,
    fontStyle: 'italic',
  },
}

export default DocumentTable
