import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography, Select, InputNumber, DatePicker, message } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'
import {
  useScrollToErrors,
  formatNumber,
  formatInputNumber,
  preventEnterSubmit,
  removeVietnameseTones,
} from 'Utils'
import { CustomError } from './CustomComponent'

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
  } = props

  const history = useHistory()
  const [isFetching, setIsFetching] = useState(false)

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)
  const [staffList, setStaffList] = useState({
    result: [],
    searching: true,
  })

  useEffect(() => {
    let didCancel = false

    const fetchStaffs = async () => {
      setStaffList((prev) => ({
        ...prev,
        searching: true,
      }))

      const url = '/staffs/'
      try {
        const resp = await axios.get(url, {
          params: {
            page: 1,
            per_page: 100000,
          },
        })

        if (!didCancel) {
          setStaffList({
            result: resp.data.records,
            searching: false,
          })
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setStaffList((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    fetchStaffs()
    return () => (didCancel = true)
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/salary_calculation/${editId}/edit`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)

        if (editId && !didCancel) {
          const formatParams = { ...resp.data, staff_type: resp.data.staff.staff_type }
          setValues({ ...values, ...formatParams })
          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    if (editId) fetchData()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId])

  useEffect(() => {
    let didCancel = false

    const fetchWorkingHours = async () => {
      const url = '/salary_calculation/working-hours/'

      try {
        const result = await axiosExpand.get(url, {
          params: {
            staff_id: values.staff_id,
            start_date: values.start_date,
            end_date: values.end_date,
          },
        })
        if (!didCancel) {
          if (values.staff_type === 'office') {
            const totalWorkDays = Number(result.data.working_hours) / 8
            const roundDown = Number(totalWorkDays.toFixed(2))
            setFieldValue('total_hour', undefined)
            setFieldValue('total_day', roundDown)
            // salary: represent for 26 normal working days
            const DAYS_IN_A_MONTH = 26
            // -> total salary = salary / 26 * total_day
            const totalSalary = (values.salary / DAYS_IN_A_MONTH) * roundDown
            const roundDownSalary = Math.floor(totalSalary)
            setFieldValue('total_salary', Number(roundDownSalary))
          } else if (values.staff_type === 'factory') {
            const totalHours = Number(result.data.working_hours)
            // total working day = total working hours : 9
            const TOTAL_WORK_HOURS_A_DAY = 9
            // salary: in this case is salary for one working day of worker
            // total salary = salary * total working day
            const totalWorkingDays = totalHours / TOTAL_WORK_HOURS_A_DAY
            const totalSalary = values.salary * totalWorkingDays
            const roundDown = Math.floor(totalSalary)
            setFieldValue('total_hour', totalHours)
            setFieldValue('total_day', Number(totalWorkingDays.toFixed(2)))
            setFieldValue('total_salary', Number(roundDown))
          }
        }
      } catch (error) {}
    }

    if (values.staff_id) fetchWorkingHours()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.staff_id, values.start_date, values.end_date, values.staff_type])

  useEffect(() => {
    let bonus = values.bonus_amount ? values.bonus_amount : 0
    let responsibility = values.responsibility_amount ? values.responsibility_amount : 0
    const totalSalaryReceive = values.total_salary + bonus + responsibility
    setFieldValue('total', totalSalaryReceive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bonus_amount, values.total_salary, values.responsibility_amount])

  const handleNumberChange = (fieldName, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value && value !== 0) {
      setFieldValue(fieldName, undefined)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(fieldName, roundDown)
    }
  }

  const handleTotalDayChange = (fieldName, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value && value !== 0) {
      setFieldValue(fieldName, undefined)
    } else {
      const roundDown = Number(value.toFixed(2))
      setFieldValue(fieldName, roundDown)
    }
  }

  const onStaffSelect = (val, option) => {
    const { staff_type, salary } = option.attr

    setFieldValue('staff_id', val)
    setFieldValue('staff_type', staff_type)
    setFieldValue('salary', salary)
    setFieldValue('responsibility_amount', undefined)
    setFieldValue('bonus_amount', undefined)
    setFieldValue('total_day', undefined)
  }

  const onDateChange = (fieldName, val) => {
    setFieldValue(fieldName, val.format('YYYY-MM-DD'))

    if (fieldName === 'start_date' && val > moment(values.end_date, 'YYYY-MM-DD')) {
      setFieldValue('end_date', val)
    }
  }

  // function disabledDate(current) {
  //   return current && current < moment(values.start_date, 'YYYY-MM-DD').startOf('day')
  // }

  const loadingCondition = isSubmitting || isFetching || staffList.searching

  return (
    <Form
      id="manage_staffs_salary_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">Thông tin bảng lương</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Nhân viên <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name={'staff_id'} disabled={loadingCondition}>
              {() => {
                return (
                  <Select
                    name={'staff_id'}
                    style={{ width: '100%', maxWidth: 350 }}
                    showSearch
                    placeholder="Tìm kiếm nhân viên"
                    value={values.staff_id}
                    onChange={onStaffSelect}
                    optionFilterProp="children"
                    loading={staffList.searching}
                    disabled={loadingCondition || editId}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {staffList.result.map((item) => (
                      <Option
                        key={item.id}
                        value={item.id}
                        attr={item}
                      >{`${item.code}: ${item.name}`}</Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage
              component={(props) => <CustomError {...props} style={{ maxWidth: 350 }} />}
              name={'staff_id'}
            />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Từ ngày <span className="compulsory-field-symbol">&nbsp;*</span>
            </Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  format={'DD-MM-YYYY'}
                  value={moment(values.start_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('start_date', val)}
                  style={{ width: '100%', maxWidth: 350 }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="start_date" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Đến ngày <span className="compulsory-field-symbol">&nbsp;*</span>
            </Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  format={'DD-MM-YYYY'}
                  value={moment(values.end_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('end_date', val)}
                  style={{ width: '100%', maxWidth: 350 }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="end_date" />
          </div>

          <div className="form-item in-one-row">
            <Title className="field-title in-one-row-title">{`${
              values.staff_type === 'office' ? 'Lương tháng:' : 'Lương công nhật:'
            }`}</Title>
            <div>{values.salary ? `${formatNumber(values.salary)} VND` : '-'}</div>
          </div>
          {values.staff_type === 'office' ? (
            <div className="form-item in-one-row">
              <Title className="field-title in-one-row-title">Số ngày đi làm:</Title>
              {values.staff_type === 'office' ? (
                <div>
                  <Field
                    as={InputNumber}
                    name="total_day"
                    disabled={loadingCondition}
                    value={values.total_day}
                    onChange={(value) => handleTotalDayChange('total_day', 365, value)}
                    style={{ width: '100%', maxWidth: 210 }}
                    formatter={(value) => formatInputNumber(value)}
                    placeholder="Nhập số ngày đi làm"
                    min={0}
                    max={365}
                  />
                  <ErrorMessage
                    component={(props) => <CustomError {...props} style={{ maxWidth: 210 }} />}
                    name={'total_day'}
                  />
                </div>
              ) : (
                <div>{values.total_day ? `${values.total_day} ngày` : '-'}</div>
              )}
            </div>
          ) : (
            <>
              <div className="form-item in-one-row">
                <Title className="field-title in-one-row-title">Tổng giờ làm việc:</Title>
                <div>{values.total_hour ? `${values.total_hour} giờ` : '-'}</div>
              </div>

              <div className="form-item in-one-row">
                <Title className="field-title in-one-row-title">Số ngày công:</Title>
                <div>{values.total_day ? `${values.total_day} ngày` : '-'}</div>
              </div>
            </>
          )}
          <div className="form-item in-one-row">
            <Title className="field-title in-one-row-title">Lương:</Title>
            <div>{values.total_salary ? `${formatNumber(values.total_salary)} VND` : '-'}</div>
          </div>

          {values.staff_type === 'office' ? (
            <div className="form-item">
              <Title className="field-title">
                Trách nhiệm <span className="compulsory-field-symbol">*</span>
              </Title>
              <Input.Group compact style={{ maxWidth: 500 }}>
                <Field
                  as={InputNumber}
                  name="responsibility_amount"
                  disabled={loadingCondition}
                  value={values.responsibility_amount}
                  onChange={(value) =>
                    handleNumberChange('responsibility_amount', 100000000000, value)
                  }
                  style={{ width: '72%' }}
                  formatter={(value) => formatInputNumber(value)}
                  placeholder="Nhập mức thưởng trách nhiệm"
                  min={0}
                  max={100000000000}
                />
                <Input className="unit-disable-field" disabled={true} defaultValue="VND" />
              </Input.Group>
              <ErrorMessage
                component={(props) => <CustomError {...props} style={{ maxWidth: 500 }} />}
                name={'responsibility_amount'}
              />
            </div>
          ) : null}

          <div className="form-item">
            <Title className="field-title">
              Thưởng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Input.Group compact style={{ maxWidth: 500 }}>
              <Field
                as={InputNumber}
                name="bonus_amount"
                disabled={loadingCondition}
                value={values.bonus_amount}
                onChange={(value) => handleNumberChange('bonus_amount', 100000000000, value)}
                style={{ width: '72%' }}
                formatter={(value) => formatInputNumber(value)}
                placeholder="Nhập mức thưởng"
                min={0}
                max={100000000000}
              />
              <Input className="unit-disable-field" disabled={true} defaultValue="VND" />
            </Input.Group>
            <ErrorMessage
              component={(props) => <CustomError {...props} style={{ maxWidth: 500 }} />}
              name={'bonus_amount'}
            />
          </div>

          <div className="form-item in-one-row">
            <Title className="field-title in-one-row-title total-number">Tổng cộng:</Title>
            <div>{values.total ? `${formatNumber(values.total)} VND` : '-'}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Ghi chú</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Ghi chú..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/salary')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo bảng lương' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
