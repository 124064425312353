import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import FormView from './Form.view'
import { manageProductsSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  // 1.
  name: '',
  name_on_proforma_invoice: '',
  code: '',
  customer_code: '',
  // 2.
  aws_object_keys: [],
  specification: '',
  quantity_in_box: undefined,
  name_on_foreign_document: '',
  name_on_bill: '',
  name_on_declaration: '',
  brand_name: '',
  package_barcode: undefined,
  box_barcode: undefined,
  barcode_note: '', // not required
  box_size: '',
  net_weight: undefined,
  gross_weight: undefined,
  expire_date: '',
  quantity: 0,
  temporary_quantity: 0,
  // 3.
  quality_indicators: '',
  hs_code: '',
  co_note: '', // not required
  need_plan_quarantine: false,
  need_medical_quarantine: false,
  need_other_quarantines: '', // not required
  quality_note: '', // not required
  note: '', // not required
  // [finish semifinish]
  product_type: 'finish',
  first_price: undefined,
  production_cost: undefined,
  second_price: undefined,
  managament_cost: undefined, // not required
  fob_price: undefined, // not required
  // 4.
  suppliers: [],
  // 5.
  materials: [],
  typeMaterial: '',
}

export default React.memo((props) => {
  const { path, editId, duplicate } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)

    const awsObjectKeys = values.aws_object_keys.map((key) => key.originFileObj.aws_object_key)
    const suppliersIds = values.suppliers.map((id) => ({ supplier_id: id }))

    const formattedParams = {
      ...values,
      first_price: values.first_price ? values.first_price : 0,
      production_cost: values.production_cost ? values.production_cost : 0,
      aws_object_keys: awsObjectKeys,
      suppliers: suppliersIds,
    }

    if (path === 'new') {
      createProduct(formattedParams)
    } else if (path === 'edit') {
      updateProduct(formattedParams)
    }
  }

  const createProduct = async (params) => {
    const url = '/products/'
    try {
      const resp = await axios.post(url, {
        ...params,
      })

      setSubmitting(false)
      history.push(`/products/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updateProduct = async (params) => {
    const url = `/products/${editId}/edit/`
    try {
      const resp = await axios.put(url, {
        ...params,
      })

      setSubmitting(false)
      history.push(`/products/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }



  return (
    <div className="product-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            duplicate={duplicate}
            isSubmitting={isSubmitting}
            initialValues={initialValues}
          />
        )}
        validationSchema={manageProductsSchema}
      />
    </div>
  )
})
