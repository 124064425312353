import React from 'react'
import { Typography } from 'antd'

import MaterialForm from '../Form'

const { Title } = Typography

export default (props) => {
  const id = props.match?.params.id
  return (
    <div id="edit_material_page">
      <Title className="page-title">Cập nhật nguyên vật liệu</Title>

      <MaterialForm path={'edit'} editId={id} />
    </div>
  )
}