import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import FormView from './Form.view'
import { manageCommodityExportSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'

const initialValues = {
  // export_number: '',
  date: moment(),
  customer_id: undefined,
  note: '',
  to: '',
  vessel_name: '',
  bill_of_lading_no: '',
  invoice_number: '',
  customer: { id: '', code: '', name: '' },
  export_only_one_sale_order: false,
  sale_order_id: undefined,
  order_number: undefined,
  commodity_export_items: [
    {
      sale_order_id: undefined,
      sale_order_item_id: undefined,
      quantity: undefined,
      max_quantity: undefined,
      specification: '',
      box_size: undefined,
      sale_order_items: [],

      total_cubic: undefined,
    },
  ],
  sale_order_in_paper_id: undefined,
  container_type_1: { amount: 0, container_no: '', seal_no: '' },
  container_type_2: { amount: 0, container_no: '', seal_no: '' },
  container_type_3: { amount: 0, container_no: '', seal_no: '' },
  packing_date: moment(),
  depart_date: moment(),
  arrival_date: moment(),
}

export default React.memo((props) => {
  const { path, editId, dataFetch, isFetching } = props
  const history = useHistory()
  const [state, setState] = useState({ serverError: null, isSubmitting: false })

  const handleFormSubmit = async (values) => {
    setState({ serverError: null, isSubmitting: true })

    const commodity_export_items = values.commodity_export_items.map((item) => {
      if (item.purchase_orders && item.purchase_orders.length) {
        const ordersIds = item.purchase_orders.map((id) => ({ purchase_order_id: id }))
        return { ...item, adaptation_exported_orders_attributes: ordersIds }
      } else {
        return item
      }
    })

    const formatParams = {
      ...values,
      commodity_export_items,
      date: values.date.format('YYYY-MM-DD'),
      sale_order_id: values.sale_order_id || null,
      packing_date: values.packing_date.format('YYYY-MM-DD'),
      depart_date: values.depart_date.format('YYYY-MM-DD'),
      arrival_date: values.arrival_date.format('YYYY-MM-DD'),
      is_drafting: true,
    }

    if (path === 'new') {
      createCommodityExport(formatParams)
    } else if (path === 'edit') {
      updateCommodityExport(formatParams)
    }
  }

  const createCommodityExport = async (params) => {
    const url = '/commodity_exports/'

    try {
      const resp = await axios.post(url, params)
      setState((prev) => ({ ...prev, isSubmitting: false }))
      history.push(`/commodity-exports-draft/${resp.data.id}/view`)
    } catch (error) {
      setState({ serverError: error.response?.data.errors?.message, isSubmitting: false })
    }
  }

  const updateCommodityExport = async (params) => {
    const url = `/commodity_exports/${editId}/edit/`
    try {
      const resp = await axios.put(url, params)
      setState((prev) => ({ ...prev, isSubmitting: false }))
      history.push(`/commodity-exports-draft/${resp.data.id}/view`)
    } catch (error) {
      setState({ serverError: error.response?.data.errors?.message, isSubmitting: false })
    }
  }

  const handleConvert = async () => {
    const url = '/commodity_exports/convert/'
    setState({ serverError: null, isSubmitting: true })

    try {
      await axiosExpand.put(url, {
        id: Number(editId),
      })
      setState((prev) => ({ ...prev, isSubmitting: false }))
      history.push(`/commodity-exports/${editId}/view`)
    } catch (error) {
      setState({ serverError: error.response?.data.errors?.message, isSubmitting: false })
    }
  }

  return (
    <div className="commodity-export-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={state.serverError}
            editId={editId}
            path={path}
            dataFetch={dataFetch}
            isFetching={isFetching}
            isSubmitting={state.isSubmitting}
            validateOnChange={false}
            handleConvert={handleConvert}
          />
        )}
        validationSchema={manageCommodityExportSchema}
      />
    </div>
  )
})
