import {
  InvalidFormatError,
  InvalidNumberError,
  NotEnoughUnitError,
  ReadingConfig,
  doReadNumber,
} from 'read-vietnamese-number' // or CDN URL
import toString from 'lodash/toString'

const numToWord = (number, unit = 'đồng') => {
  // Config reading options
  const config = new ReadingConfig()
  config.unit = [unit]

  let text = ''

  try {
    const result = doReadNumber(config, toString(number))

    text = result
  } catch (err) {
    // Handle errors
    if (err instanceof InvalidFormatError) {
      text = 'Định dạng không hợp lệ'
    } else if (err instanceof InvalidNumberError) {
      text = 'Số không hợp lệ'
    } else if (err instanceof NotEnoughUnitError) {
      text = 'Không đủ đơn vị đọc số'
    }
  }

  return text
}

export default numToWord
