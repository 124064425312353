import { action } from 'easy-peasy'

export default {
  products: false,
  materials: false,
  packages: false,
  suppliers: false,
  customers: false,
  labels: false,
  notifications: false,
  'purchase-orders': false,
  'standing-orders': false,
  'sale-orders': false,
  'payment-transactions': false,
  'commodity-exports': false,
  'commodity-exports-draft': false,
  'manage-stocks': false,
  accounts: false,
  staffs: false,
  salary: false,
  settings: false,
  'products-history': false,
  'materials-history': false,
  'shipping-schedule': false,
  'customer-payment': false,
  'supplier-debt-invoice': false,
  'supplier-payment': false,

  shouldRefresh: action((state, payload) => {
    const { key, status } = payload
    state[key] = status
  }),
}
