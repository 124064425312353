import axios from 'axios'

let instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST_REXY_ADMIN}`,
  withCredentials: false,
})

instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('RexyAdmin-Authorization')
    let configHeaders = config.headers
    config.headers = {
      ...configHeaders,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      'RexyAdmin-Authorization': token,
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    /* eslint-disable no-console */
    console.log(error)
    /* eslint-disable no-console */
    if (error.response && error.response.status === 401) {
      window.location = '/login-step-one'
      localStorage.removeItem('RexyAdmin-Authorization')
      localStorage.removeItem('[EasyPeasyStore]@auth.email')
      localStorage.removeItem('[EasyPeasyStore]@auth.token')
      localStorage.removeItem('[EasyPeasyStore]@auth.isLoggedIn')
      console.clear()
    }
    if (error.response && error.response.status === 403) {
      window.location = '/forbidden'
    }
    return Promise.reject(error)
  }
)

export default instance
