import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'

import './index.scss'

const allowRoles = ['PP', 'MOD']

const ListNotification = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

export { ListNotification }
