import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography, Select, InputNumber, DatePicker } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import { formatInputNumber, useScrollToErrors, preventEnterSubmit } from 'Utils'
import { CustomError } from './CustomComponent'

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

const STAFF_TYPES = [
  { name: 'Nhân viên văn phòng', value: 'office' },
  { name: 'Nhân viên xưởng', value: 'factory' },
]
const SALARY_TYPES = [
  { name: 'Lương công nhật', value: 'day' },
  { name: 'Lương tháng', value: 'month' },
]

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
  } = props

  const history = useHistory()
  const [isFetching, setIsFetching] = useState(false)

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  const [jobTitleList, setJobTitleList] = useState([])

  useEffect(() => {
    let didCancel = false

    const fetchJobTitles = async () => {
      const url = '/staffs/new/'
      const resp = await axios.get(url)
      setJobTitleList(resp.data.job_titles)
    }

    if (!didCancel) {
      fetchJobTitles()
    }
    return () => (didCancel = true)
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/staffs/${editId}/edit`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)

        if (editId && !didCancel) {
          setValues({
            ...values,
            ...resp.data,
            start_date: moment(resp.data.start_date, 'YYYY-MM-DD'),
          })
          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    if (editId) {
      fetchData()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId])

  const handleStaffTypeSelect = (value) => {
    if (value === 'office') {
      setFieldValue('salary_type', 'month')
      setFieldValue('staff_type', value)
    } else {
      setFieldValue('staff_type', value)
      setFieldValue('salary_type', undefined)
    }
  }

  const handleSelectFieldChange = (fieldName, value) => setFieldValue(fieldName, value)

  const handleFieldChange = (fieldName, limit, value) => {
    if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(fieldName, undefined)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(fieldName, roundDown)
    }
  }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_staffs_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">Thông tin nhân viên</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Tên nhân viên <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="name"
              placeholder="Nhập tên nhân viên"
              disabled={loadingCondition}
              value={values.name}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="name" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Mã nhân viên <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="code"
              placeholder="Nhập mã nhân viên"
              disabled={loadingCondition}
              value={values.code}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="code" />
          </div>

          <div className="form-item">
            <Title className="field-title">Mã NV trên máy chấm công</Title>
            <Field
              as={Input}
              name="code_in_time_attendance_machine"
              placeholder="Nhập mã nhân viên trên máy chấm công"
              disabled={loadingCondition}
              value={values.code_in_time_attendance_machine}
              onChange={handleChange}
            />
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="code_in_time_attendance_machine"
            />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Phân loại nhân viên <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Select}
              name="staff_type"
              style={{ width: '100%' }}
              placeholder="Chọn loại nhân viên"
              disabled={loadingCondition}
              value={values.staff_type}
              onChange={(value) => handleStaffTypeSelect(value)}
            >
              {STAFF_TYPES.map((staffType) => (
                <Option key={staffType.value} value={staffType.value}>
                  {staffType.name}
                </Option>
              ))}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="staff_type" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Phân loại lương <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Select}
              name="salary_type"
              style={{ width: '100%' }}
              placeholder="Chọn loại lương"
              disabled={loadingCondition || values.staff_type === 'office'}
              value={values.salary_type}
              onChange={(value) => handleSelectFieldChange('salary_type', value)}
            >
              {SALARY_TYPES.map((salaryType) => (
                <Option key={salaryType.value} value={salaryType.value}>
                  {salaryType.name}
                </Option>
              ))}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="salary_type" />
          </div>

          <div className="form-item">
            <Title className="field-title">Vị trí công tác</Title>
            <Field
              allowClear
              as={Select}
              name="job_title"
              style={{ width: '100%' }}
              placeholder="Chọn vị trí công tác"
              disabled={loadingCondition}
              value={values.job_title}
              onChange={(value) => handleSelectFieldChange('job_title', value)}
            >
              {jobTitleList.map((jobTitle) => (
                <Option key={jobTitle.value} value={jobTitle.value}>
                  {jobTitle.name}
                </Option>
              ))}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="job_title" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Mức lương <span className="compulsory-field-symbol">*</span>
            </Title>

            <Input.Group compact>
              <Field
                as={InputNumber}
                name="salary"
                disabled={loadingCondition}
                value={values.salary}
                onChange={(value) => handleFieldChange('salary', 100000000000, value)}
                style={{ width: '62%' }}
                formatter={(value) => formatInputNumber(value)}
                placeholder={`Nhập mức lương ${
                  values.staff_type ? (values.staff_type === 'office' ? 'tháng' : 'ngày') : ''
                }`}
                min={0}
                max={100000000000}
              />
              <Input
                className="unit-disable-field"
                disabled={true}
                value={`VND${
                  values.staff_type ? (values.staff_type === 'office' ? '/Tháng' : '/Ngày') : ''
                }`}
              />
            </Input.Group>

            <ErrorMessage component={(props) => <CustomError {...props} />} name="salary" />
          </div>

          <div className="form-item" style={{ maxWidth: 300 }}>
            <Title className="field-title">
              Ngày bắt đầu <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="start_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.start_date}
                  onChange={(val) => setFieldValue('start_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày bắt đầu"
                  disabled={loadingCondition}
                  allowClear={false}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="start_date" />
          </div>

          <div className="form-item">
            <Title className="field-title">Ghi chú</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Ghi chú..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/staffs')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo nhân viên' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
