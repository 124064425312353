const roleOptions = [
  { id: 'MO', label: 'Quản lý đơn đặt hàng', value: 'MO' },
  { id: 'OM', label: 'Quản lý nguyên vật liệu', value: 'OM' },
  { id: 'PP', label: 'Sản xuất sản phẩm', value: 'PP' },
  { id: 'DI', label: 'Nhập liệu đầu vào', value: 'DI' },
  { id: 'R', label: 'Báo cáo', value: 'R' },
  { id: 'MOD', label: 'Manage Order Document', value: 'MOD' },
  { id: 'EPLD', label: 'Tải bảng giá thành', value: 'EPLD' },
  { id: 'ED', label: 'Tải chứng từ', value: 'ED' },
]

export default { roleOptions }
