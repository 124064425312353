import {
  nameColorGenerator,
  purchaseOrderStatusColorGenerator,
  saleOrderStatusColorGenerator,
} from './colorGenerator'
import generateRoleName from './generateRoleName'
import { scrollToTop, useScrollToErrors, scrollToBottomAdd } from './scrollTo'
import formatNumber, { roundUpDecimal, formatInputNumber, formatToNumber } from './formatNumber'
import getFieldName from './getFieldName'
import numToWord from './numToWord'
import replaceItemAtIndex from './replaceElementAtIndex'
import {
  convertObjectToQueryString,
  convertQueryStringToObject,
  removeVietnameseTones,
} from './convert'

const preventEnterSubmit = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

const pageSizeConfig = {
  showSizeChanger: true,
  pageSizeOptions: [5, 10, 20, 50],
}

export {
  scrollToBottomAdd,
  nameColorGenerator,
  purchaseOrderStatusColorGenerator,
  saleOrderStatusColorGenerator,
  generateRoleName,
  scrollToTop,
  useScrollToErrors,
  formatNumber,
  formatInputNumber,
  getFieldName,
  numToWord,
  replaceItemAtIndex,
  convertObjectToQueryString,
  convertQueryStringToObject,
  preventEnterSubmit,
  pageSizeConfig,
  roundUpDecimal,
  removeVietnameseTones,
  formatToNumber,
}
