import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useStoreState } from 'easy-peasy'
import map from 'lodash/map'

import axios from 'Utils/axiosRexyAdmin'
import { CheckPermission, Loading } from 'Components'
import { formatToNumber } from 'Utils'

import ViewDraftingAndPending from './ViewDraftingAndPending'
import ViewProducingAdmin from './ViewProducingAdmin'
import ViewProducedAdmin from './ViewProducedAdmin'
import ViewProducingEmployee from './ViewProducingEmployee'
import OtherView from './OtherView'

const INITIAL_PROFILE = {
  order_number: '',
  date: moment().format('YYYY-MM-DD'),
  customer_id: '',
  sale_order_items: [
    {
      product_id: undefined,
      product: { id: undefined, code: '', name: '' },
      specification: '',
      box_quantity: 1,
      first_price: 0,
      second_price: 0,
      product_type: '',
      quantity_in_box: 0,
      box_size: 0,
      note: '',
      total_quantity: 0,
      total_size: 0,
      old_fob: 0,
      new_fob: 0,
      use_new_prices: false,
      net_weight: 0,
      gross_weight: 0,
      total: 0,
      produced_box_quantity: 0,
      completed: false,
    },
  ],
  customer: { id: '', code: '', name: '' },
  container_type_1_amount: '',
  container_type_2_amount: '',
  container_type_3_amount: '',

  produce_date: moment().format('YYYY-MM-DD'),
  complete_date: moment().format('YYYY-MM-DD'),
  export_date: moment().format('YYYY-MM-DD'),

  status: 'drafting',
  sticker_fees: 0,
  usd_to_vnd_rate: 0,
}

export default React.memo((props) => {
  const viewId = props.match?.params.id
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: true,
    errors: null,
  })

  const [totalCubic, setTotalCubic] = useState(0)
  const [presentCubic, setPresentCubic] = useState(0)
  const [totalPI, setTotalPI] = useState(0)
  const [totalStickerFees, setTotalStickerFee] = useState(0)
  const [totalRow, setTotalRow] = useState({
    totalBoxQuantity: 0,
    totalQuantity: 0,
    totalSize: 0,
    totalFobProduct: 0,
    total_sticker_fees_quantity: 0,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/sale_orders/${viewId}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          computeData(resp.data)
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId])
  const calTotalCubic = (type1, type2, type3) => {
    const sum = 28.5 * type1 + 58.5 * type2 + 66.5 * type3
    const formatted = Number(Number(sum).toFixed(2))
    setTotalCubic(formatted)
  }

  const calPresentCubic = (orderItems = []) => {
    let sum = 0
    orderItems.forEach((item) => (sum += Number(item.total_size)))
    setPresentCubic(sum)
  }

  const calTotalRowAndPI = (orderItems = [], totalStickerFees) => {
    let totalBoxQuantity = 0
    let totalQuantity = 0
    let totalSize = 0
    let totalFobProduct = 0
    orderItems.forEach((item) => {
      totalBoxQuantity += item.box_quantity
      totalQuantity += item.total_quantity
      totalSize += item.total_size
      totalFobProduct += item.new_fob * item.box_quantity
    })

    let totalPI = Number((totalFobProduct + totalStickerFees).toFixed(2))
    setTotalPI(totalPI)
    setTotalRow({
      totalBoxQuantity,
      totalQuantity,
      totalSize,
      totalFobProduct,
    })
  }

  const computeData = (data) => {
    const type1 = data.container_type_1_amount
    const type2 = data.container_type_2_amount
    const type3 = data.container_type_3_amount
    const exchange_rate = Number(data.usd_to_vnd_rate)
    const totalStickerFees = Number((data.sticker_fees * data.total_label_quantity).toFixed(2))
    setTotalStickerFee(totalStickerFees)
    calTotalCubic(type1, type2, type3)
    calPresentCubic(data.sale_order_items)
    calTotalRowAndPI(data.sale_order_items, totalStickerFees)
    const saleOrderItems = data.sale_order_items.map((item) => ({
      ...item,
      new_fob:
        formatToNumber(item.new_fob) ??
        formatToNumber(
          ((item.second_price * (1 + item.managament_cost / 100)) / exchange_rate).toFixed(2)
        ),
    }))

    const formattedDocuments = map(data.documents, (document) => ({
      uid: `uid-${document.aws_object_key}`,
      originFileObj: {
        url: document.url,
        name: document.file_name,
        object_key: document.aws_object_key,
      },
    }))

    const formattedData = {
      ...state.data,
      ...data,
      // date: moment(data.date, 'YYYY-MM-DD').format(format),
      sale_order_items: saleOrderItems,
      documents: formattedDocuments,
    }
    setState((prev) => ({ ...prev, data: formattedData, loading: false }))
  }

  //const SALE_ORDER_STATUS = ['drafting' ,'pending_pi' ,'producing' ,'produced', 'exporting' ,'completed']

  // Role PP: see when status >= producing
  // Role MOD: see when status >= produced

  if (state.loading) return <Loading loading={state.loading} />

  if (
    state.data.status === 'drafting' ||
    state.data.status === 'pending_pi' ||
    state.data.status === 'approved_pi'
  )
    return (
      <CheckPermission
        rolesPermission={['MO']}
        component={ViewDraftingAndPending}
        {...state}
        totalCubic={totalCubic}
        presentCubic={presentCubic}
        viewId={viewId}
        isSuperAdmin={isSuperAdmin}
      />
    )

  if (state.data.status === 'producing' && isSuperAdmin)
    return (
      <CheckPermission
        rolesPermission={[]}
        component={ViewProducingAdmin}
        {...state}
        totalCubic={totalCubic}
        presentCubic={presentCubic}
        viewId={viewId}
        isSuperAdmin={isSuperAdmin}
        totalPI={totalPI}
        totalRow={totalRow}
        totalStickerFees={totalStickerFees}
      />
    )

  if (state.data.status === 'produced' && isSuperAdmin)
    return (
      <CheckPermission
        rolesPermission={[]}
        component={ViewProducedAdmin}
        {...state}
        totalCubic={totalCubic}
        presentCubic={presentCubic}
        viewId={viewId}
        isSuperAdmin={isSuperAdmin}
        totalPI={totalPI}
        totalRow={totalRow}
        totalStickerFees={totalStickerFees}
      />
    )

  if (state.data.status === 'producing' && !isSuperAdmin)
    return (
      <CheckPermission
        rolesPermission={['MO', 'PP']}
        component={ViewProducingEmployee}
        {...state}
        totalCubic={totalCubic}
        presentCubic={presentCubic}
        viewId={viewId}
      />
    )

  return (
    <CheckPermission
      rolesPermission={['MO', 'PP', 'MOD']}
      component={OtherView}
      {...state}
      totalCubic={totalCubic}
      presentCubic={presentCubic}
      viewId={viewId}
    />
  )
})
