import { loginStepOneAPI, loginStepTwoAPI, forgotPasswordAPI } from 'Services/api'
import { action, thunk } from 'easy-peasy'

export default {
  id: undefined,
  name: '',
  email: '',
  roles: [],
  is_super_admin: false,
  token: '',
  usd_to_vnd_rate: undefined,
  isLoggedIn: false,
  isCompletedStepOne: false,
  isSendToForgotPasswordEmail: false,

  saveUser: action((state, payload) => {
    state.token = payload.token
    state.isLoggedIn = true
  }),

  completedStepOne: action((state, payload) => {
    const { email } = payload
    state.isCompletedStepOne = true
    state.email = email
  }),

  saveUserInfor: action((state, payload) => {
    const { id, email, name, roles, is_super_admin, usd_to_vnd_rate } = payload
    return {
      ...state,
      id,
      email,
      name,
      roles,
      is_super_admin,
      usd_to_vnd_rate,
    }
  }),

  sendToForgotPasswordEmail: action((state, payload) => {
    state.isSendToForgotPasswordEmail = payload
  }),

  logOut: action((state, _) => {
    state.email = null
    state.token = ''
    state.isLoggedIn = false
    localStorage.removeItem('RexyAdmin-Authorization')
    localStorage.removeItem('[EasyPeasyStore]@auth.email')
    localStorage.removeItem('[EasyPeasyStore]@auth.token')
    localStorage.removeItem('[EasyPeasyStore]@auth.isLoggedIn')
  }),

  updateExchangeRate: action((state, payload) => {
    state.usd_to_vnd_rate = payload
  }),

  signinStepOne: thunk(async (actions, payload) => {
    const resp = await loginStepOneAPI(payload)

    if (resp.status === 200) {
      actions.completedStepOne(payload)
      return resp
    }
    return { errors: resp }
  }),

  signinStepTwo: thunk(async (actions, payload) => {
    const resp = await loginStepTwoAPI(payload)
    if (resp.status === 200) {
      actions.saveUser(resp.data)
      localStorage.setItem('RexyAdmin-Authorization', resp.data.token)
      return resp
    }
    return { errors: resp }
  }),

  forgotPassword: thunk(async (actions, payload) => {
    const resp = await forgotPasswordAPI(payload)
    if (resp.status === 200) {
      actions.sendToForgotPasswordEmail(true)
      return resp
    }

    return { errors: resp }
  }),
}
