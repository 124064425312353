import React from 'react'
import { Typography } from 'antd'

import LabelForm from '../Form'

const { Title } = Typography

export default (props) => {
  const id = props.match?.params.id

  return (
    <div id="new_label_page">
      <Title className="page-title">Tạo tem mới</Title>
      
      <LabelForm path={'new'} editId={id} duplicate={true} />
    </div>
  )
}
