import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import { formatNumber } from 'Utils'

const { Title } = Typography
const format = 'YYYY-MM-DD'

const INITIAL_PROFILE = {
  staff: { code: '', name: '', staff_type: 'office' },
  start_date: moment().format(format),
  end_date: moment().format(format),
  salary: 0,
  total_hour: 248,
  total_day: 31,
  total_salary: 0,
  responsibility_amount: undefined,
  bonus_amount: undefined,
  total: 0,
  note: '',
}

export default React.memo((props) => {
  const history = useHistory()
  const viewId = props.match?.params.id

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/salary_calculation/${viewId}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setState((prev) => ({ ...prev, data: { ...prev.data, ...resp.data }, loading: false }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [viewId])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/salary`)
  }

  const handleEdit = () => {
    history.push(`/salary/${viewId}/edit`)
  }

  return (
    <div id="view_staff_salary_calculation" className="table-container">
      <Title className="page-title">Thông tin nhân viên</Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">Thông tin chung</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã nhân viên
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.staff.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên nhân viên
                </Col>
                <Col className="content-wrapper" span={16}>
                  {`${state.data.staff.name}`}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Từ ngày
                </Col>
                <Col className="content-wrapper" span={16}>
                  {moment(state.data.start_date, format).format('DD-MM-YYYY')}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Đến ngày
                </Col>
                <Col className="content-wrapper" span={16}>
                  {moment(state.data.end_date, format).format('DD-MM-YYYY')}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  {state.data.staff.staff_type === 'office' ? 'Lương tháng:' : 'Lương công nhật:'}
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.salary ? formatNumber(state.data.salary) : '-'}
                </Col>
              </Row>
            </Card.Grid>

            {state.data.staff.staff_type === 'office' ? (
              <Card.Grid hoverable={false} className="view-item">
                <Row className="row">
                  <Col className="field-title-wrapper text-style" span={8}>
                    Số ngày đi làm
                  </Col>
                  <Col className="content-wrapper" span={16}>
                    {state.data.total_day ? state.data.total_day : '-'}
                  </Col>
                </Row>
              </Card.Grid>
            ) : (
              <>
                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Tổng giờ làm việc
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.total_hour ? state.data.total_hour : '-'}
                    </Col>
                  </Row>
                </Card.Grid>

                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Số ngày công
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.salary ? state.data.total_day : '-'}
                    </Col>
                  </Row>
                </Card.Grid>
              </>
            )}

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Lương
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.total_salary ? `${formatNumber(state.data.total_salary)} VND` : '-'}
                </Col>
              </Row>
            </Card.Grid>

            {state.data.staff.staff_type === 'office' ? (
              <Card.Grid hoverable={false} className="view-item">
                <Row className="row">
                  <Col className="field-title-wrapper text-style" span={8}>
                    Trách nhiệm
                  </Col>
                  <Col className="content-wrapper" span={16}>
                    {state.data.responsibility_amount
                      ? `${formatNumber(state.data.responsibility_amount)} VND`
                      : '-'}
                  </Col>
                </Row>
              </Card.Grid>
            ) : null}

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Thưởng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.bonus_amount ? `${formatNumber(state.data.bonus_amount)} VND` : '-'}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tổng cộng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.total ? `${formatNumber(state.data.total)} VND` : '-'}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>

          <Button
            icon={<FormOutlined />}
            type="primary"
            loading={state.loading}
            className="process-button"
            onClick={handleEdit}
          >
            Chỉnh sửa
          </Button>
        </div>
      </div>
    </div>
  )
})
