import React, { useState } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import FormView from './Form.view'
import { manageSaleReportSchema } from 'Validation'
import axiosExpand from 'Utils/axiosExpand'

const format = 'YYYY-MM-DD'

const initialValues = {
  start_date: moment().format(format),
  end_date: moment().format(format),
}

export default () => {
  const [serverError, setServerError] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [initialData, setInitialData] = useState({ isEdit: false, data: initialValues })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleFormSubmit = async (values, actions) => {
    setServerError(null)
    downloadSaleReport(values, actions)
  }

  const downloadSaleReport = async (params, actions) => { 
    setIsSubmitting(true)
    try {
      const FileDownload = require('js-file-download')
      const url = `/sale_orders/export/statistic-list?start_date=${params.start_date}&end_date=${params.end_date}`
      const result = await axiosExpand.get(url, { responseType: 'arraybuffer' })
      FileDownload(result.data, `bao-cao-doanh-so.xlsx`)

      setShouldFetch(true)
      setInitialData({ isEdit: false, data: params })
      setIsSubmitting(false)
    } catch (error) {
      setServerError(error.response?.data.errors.message)
      setIsSubmitting(false)
    }
  }

  return (
    <div className="sale_report-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            shouldFetch={shouldFetch}
            serverError={serverError}
            setShouldFetch={setShouldFetch}
            initialData={initialData}
            setInitialData={setInitialData}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={manageSaleReportSchema}
      />
    </div>
  )
}
