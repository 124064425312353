import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button, Upload } from 'antd'
import { RollbackOutlined, FormOutlined, CopyOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import findIndex from 'lodash/findIndex'

import axios from 'Utils/axiosRexyAdmin'
import { getFieldName, formatNumber } from 'Utils'
import { ImageSlide } from 'Components'

const { Title } = Typography

const INITIAL_PROFILE = {
  product_id: null,
  ingredient: '',
  label_info: '',
  label_size: '',
  label_guide: '',
  label_image: '',
  label_type: '',
  note: '',
  product: {
    id: null,
    code: '',
    name: '',
  },
  customer: {
    id: null,
    code: '',
    name: '',
  },
  supplier: {
    id: null,
    code: '',
    name: '',
  },
  quantity: undefined,
  addtional_hashes: [],
  suppliers: [],
}

export default React.memo((props) => {
  const history = useHistory()
  const viewId = props.match?.params.id

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  const [imageState, setImageState] = useState({ visible: false, currentIndex: 0 })

  const [labelTypes, setLabelTypes] = useState([])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/labels/${viewId}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          let addtionalHashes = []
          if (resp.data.addtional_hashes.length) {
            addtionalHashes = resp.data.addtional_hashes.map((item, index) => ({
              uid: `uid-${index}`,
              name: 'image.png',
              status: 'done',
              url: item.image_link,
              original: item.image_link,
              thumbnail: item.image_link,
            }))
          }
          const content = document.getElementById('label_parse_content')
          const inforrr = new DOMParser().parseFromString(resp.data.info, 'text/html')

          content.innerHTML = inforrr.body.innerHTML
          setState((prev) => ({
            ...prev,
            data: { ...resp.data, addtional_hashes: addtionalHashes },
            loading: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [viewId])

  useEffect(() => {
    let didCancel = false

    const fetchLabelTypes = async () => {
      const url = '/labels/new/'
      const resp = await axios.get(url)
      setLabelTypes(resp.data.label_types)
    }

    if (!didCancel) {
      fetchLabelTypes()
    }
    return () => (didCancel = true)
  }, [])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/labels`)
  }

  const handleEdit = () => {
    history.push(`/labels/${viewId}/edit`)
  }

  const handleCreateDuplicate = () => {
    history.push(`/labels/new/${viewId}`)
  }

  const handlePreview = (file) => {
    const index = findIndex(state.data.addtional_hashes, file)
    if (index > -1) {
      setImageState({ visible: true, currentIndex: index })
    } else {
      setImageState({ visible: true, currentIndex: 0 })
    }
  }

  return (
    <div id="view_label" className="table-container">
      <Title className="page-title">Thông tin tem</Title>

      <div className="body-container-wider">
        <Title className="part-title">Thông tin chung</Title>
        <div className="part-field-wrapper">
          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Mã sản phẩm
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.product.code}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Tên sản phẩm
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.product.name}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Mã khách hàng
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.customer?.code}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Tên khách hàng
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.customer?.name}
              </Col>
            </Row>
          </Card.Grid>
          {/* 
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={6}>
                  Mã nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.supplier?.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={6}>
                  Tên nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.supplier?.name}
                </Col>
              </Row>
            </Card.Grid> */}

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Danh sách NCC
              </Col>
              <Col className="content-wrapper supplier-wrapper" span={16}>
                {state.data.suppliers.map((supplier, index) => (
                  <Col
                    key={index}
                    className="supplier-item"
                  >{`${supplier.code}: ${supplier.name}`}</Col>
                ))}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Đơn giá (VND)
              </Col>
              <Col className="content-wrapper" span={16}>
                {formatNumber(state.data.price)}
              </Col>
            </Row>
          </Card.Grid>
          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Số lượng
              </Col>
              <Col className="content-wrapper" span={16}>
                {formatNumber(state.data.quantity)}
              </Col>
            </Row>
          </Card.Grid>
          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Số lượng khả dụng
              </Col>
              <Col className="content-wrapper" span={16}>
                {formatNumber(state.data.temporary_quantity)}
              </Col>
            </Row>
          </Card.Grid>
          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Thành phần
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.ingredient}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Nội dung tem
              </Col>
              <Col className="content-wrapper" span={16}>
                <div id="label_parse_content" />
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Kích thước tem
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.specification}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Cách dán tem
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.guide}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Hình ảnh minh hoạ
              </Col>
              <Col className="content-wrapper" span={16}>
                <Upload
                  listType="picture-card"
                  fileList={state.data.addtional_hashes}
                  onPreview={handlePreview}
                  onRemove={false}
                />
              </Col>
            </Row>
          </Card.Grid>
          <ImageSlide
            visible={imageState.visible}
            onCancel={() => setImageState({ visible: false, currentIndex: 0 })}
            images={state.data.addtional_hashes}
            currentIndex={imageState.currentIndex}
          />

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Loại tem
              </Col>
              <Col className="content-wrapper" span={16}>
                {getFieldName(state.data.label_type, labelTypes)}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={6}>
                Ghi chú
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.note}
              </Col>
            </Row>
          </Card.Grid>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>

          <Button
            icon={<FormOutlined />}
            type="primary"
            loading={state.loading}
            className="process-button"
            onClick={handleEdit}
          >
            Chỉnh sửa
          </Button>

          <Button
            icon={<CopyOutlined />}
            type="primary"
            loading={state.loading}
            className="create-duplicate-button"
            onClick={handleCreateDuplicate}
          >
            Tạo bản sao
          </Button>
        </div>
      </div>
    </div>
  )
})
