import { useStoreState } from 'easy-peasy'
import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import axios from 'Utils/axiosRexyAdmin'
import { getFieldName } from 'Utils'

const { Title } = Typography

const INITIAL_PROFILE = {
  code: '',
  name: '',
  address: '',
  email: '',
  phone_number: '',
  note: '',
  payment_method: '',
  country: '',
}

export default React.memo((props) => {
  const history = useHistory()
  const viewId = props.match?.params.id
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  const [countryList, setCountryList] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/customers/${viewId}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setState((prev) => ({ ...prev, data: resp.data, loading: false }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [viewId])

  useEffect(() => {
    let didCancel = false

    const fetchLabelTypes = async () => {
      const url = '/customers/new/'
      const resp = await axios.get(url)
      setCountryList(resp.data.countries)
      setPaymentMethods(resp.data.payment_methods)
    }

    if (!didCancel) {
      fetchLabelTypes()
    }
    return () => (didCancel = true)
  }, [])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/customers`)
  }

  const handleEdit = () => {
    history.push(`/customers/${viewId}/edit`)
  }

  return (
    <div id="view_customer" className="table-container">
      <Title className="page-title">Thông tin khách hàng</Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">Thông tin chung</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên khách hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {`${state.data.name}`}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã khách hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.code}
                </Col>
              </Row>
            </Card.Grid>
            {isSuperAdmin ? (
              <>
                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Địa chỉ
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.address}
                    </Col>
                  </Row>
                </Card.Grid>

                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Email
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.email}
                    </Col>
                  </Row>
                </Card.Grid>

                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Số Điện thoại/Fax
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.phone_number}
                    </Col>
                  </Row>
                </Card.Grid>
              </>
            ) : null}
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Quốc gia
                </Col>
                <Col className="content-wrapper" span={16}>
                  {getFieldName(state.data.country, countryList)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Hình thức thanh toán
                </Col>
                <Col className="content-wrapper" span={16}>
                  {getFieldName(state.data.payment_method, paymentMethods)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>

          <Button
            icon={<FormOutlined />}
            type="primary"
            loading={state.loading}
            className="process-button"
            onClick={handleEdit}
          >
            Chỉnh sửa
          </Button>
        </div>
      </div>
    </div>
  )
})
