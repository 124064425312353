import React from 'react'

import { CheckPermission } from 'Components'
import View from './MaterialHistory'

import './index.scss'
const allowRoles = ['MO', 'PP', 'MOD']

const MaterialHistory = (props) => (
  <CheckPermission {...props} rolesPermission={allowRoles} component={View} />
)

export default MaterialHistory
