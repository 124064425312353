export default (value) => {
  switch (value) {
    case 'MO':
      return 'Quản lý đơn đặt hàng'
    case 'OM':
      return 'Quản lý nguyên vật liệu'
    case 'PP':
      return 'Sản xuất sản phẩm'
    case 'DI':
      return 'Nhập liệu đầu vào'
    case 'R':
      return 'Báo cáo'
    case 'MOD':
      return 'Manage Order Document'
    case 'EPLD':
      return 'Tải bảng giá thành'
    case 'ED':
      return 'Tải chứng từ'
    default:
      return
  }
}
