import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography, DatePicker, Spin, message } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { useStoreActions } from 'easy-peasy'
import filter from 'lodash/filter'

import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'

import { useScrollToErrors, convertQueryStringToObject, preventEnterSubmit } from 'Utils'
import { CustomError, OrderItems, LabelItems, CustomRadios } from './CustomComponent'
import SupplierSelect from './SupplierSelect'
import OrderSelect from './OrderSelect'
// import { get } from 'lodash'
const { Title } = Typography
const { TextArea } = Input

const typeOptions = [
  { name: 'Nguyên vật liệu', value: 'material' },
  { name: 'Bao bì', value: 'packing' },
  { name: 'Tem', value: 'label' },
  { name: 'Thành phẩm', value: 'finish_product' },
]

const vatOptions = [
  { name: '0%', value: 0 },
  { name: '5%', value: 5 },
  { name: '8%', value: 8 },
]

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
    dataFetch,
    isFetching,
  } = props

  const history = useHistory()
  const location = useLocation()
  const [firstRender, setFirstRender] = useState(true)
  const [firstRender2, setFirstRender2] = useState(true)
  // const [labelOptions, setLabelOptions] = useState([])
  // const [materialOptions, setMaterialOptions] = useState([])
  const [itemOptions, setItemOptions] = useState([])
  const [itemOrder, setItemOrder] = useState([])
  const [onSeaching, setOnSearching] = useState({ orderOfSupplier: false, marterialOfOrder: false })
  const [orderSearchData, setOrderSearchData] = useState([])
  const [supplierSearch, setSupplierSearch] = useState({
    keyword: '',
    result: [],
    searching: false,
  })
  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  const toggleMaterialStatus = useStoreActions(
    (actions) => actions.purchaseOrder.toggleMaterialStatus
  )

  useEffect(() => {
    let search = location.search.substring(1)
    const queryObject = convertQueryStringToObject(search)

    let didCancel = false

    if (path === 'new' && firstRender && Object.keys(queryObject).length) {
      ;(async () => {
        setSupplierSearch((prev) => ({ ...prev, searching: true }))
        setOnSearching((prev) => ({ ...prev, orderOfSupplier: true }))
        try {
          const url = '/suppliers/form/'

          const supplierResult = await axios.get(url, {
            params: {
              keyword: queryObject.supplier_code,
              per_page: 20,
              order_type: queryObject.order_type,
            },
          })
          if (!didCancel) {
            setOrderSearchData([
              { id: Number(queryObject.sale_order_id), order_number: queryObject.order_number },
            ])

            const filteredSupplier = filter(supplierResult.data.records, {
              id: Number(queryObject.supplier_id),
              code: queryObject.supplier_code,
            })
            setSupplierSearch((prev) => ({
              ...prev,
              result: filteredSupplier,
              searching: false,
            }))
            if (queryObject.order_type === 'material' || queryObject.order_type === 'packing') {
              setItemOptions(filteredSupplier[0].materials)
            } else if (queryObject.order_type === 'label') {
              setItemOptions(filteredSupplier[0].labels)
            } else if (queryObject.order_type === 'finish_product') {
              setItemOptions(filteredSupplier[0].products)
            }
          }
          setOnSearching((prev) => ({ ...prev, orderOfSupplier: false }))
        } catch (error) {
          if (!didCancel) {
            message.error(error.response?.data.errors?.message)
            setOnSearching((prev) => ({ ...prev, orderOfSupplier: false }))
            setSupplierSearch((prev) => ({ ...prev, searching: false }))
          }
        }
      })()

      setValues({
        ...values,
        supplier_id: Number(queryObject.supplier_id),
        order_type: queryObject.order_type,
        sale_order_id: Number(queryObject.sale_order_id),
      })
      setFirstRender(false)
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let didCancel = false
    if (editId && !didCancel && Object.keys(dataFetch).length) {
      const fetchDataOrder = async () => {
        const typeOrder = dataFetch.order_type
        if (dataFetch.sale_order_id) {
          if (typeOrder === 'material' || typeOrder === 'packing') {
            await getDataOrder('/sale_orders/materials/', dataFetch)
          } else if (typeOrder === 'label') {
            await getDataOrder('/sale_orders/labels/', dataFetch)
          } else if (typeOrder === 'finish_product') {
            await getDataOrder('/sale_orders/finish-products/', dataFetch)
          }
        }
      }
      computeData(dataFetch)
      fetchDataOrder()
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, dataFetch, itemOrder.length])

  //Todos: call api page edit get max value purchase Order Input
  const getDataOrder = async (url, dataFetch) => {
    const typeOrder = dataFetch.order_type
    try {
      const result = await axiosExpand.get(url, {
        params: {
          purchase_order_id: editId,
          supplier_id: dataFetch.supplier_id,
          order_type: dataFetch.order_type,
          sale_order_id: dataFetch.sale_order_id,
        },
      })
      if (typeOrder === 'material' || typeOrder === 'packing') {
        setItemOrder(result.data.materials)
      } else if (typeOrder === 'label') {
        setItemOrder(result.data.labels)
      } else if (typeOrder === 'finish_product') {
        setItemOrder(result.data.products)
      }
    } catch (error) {
      setItemOrder([])
      message.error(error.response?.data.errors?.message)
    }
  }
  const computeData = (data) => {
    if (data.purchase_order_items.length) {
      let subtotal = 0
      let formattedMaterialsInStock = []
      let formattedMaterials = data.purchase_order_items.map((item) => {
        subtotal += Number(item.total)
        return {
          ...item,
          // label_id: 0,
          price: Number(item.price),
          quantity: Number(item.quantity),
          initQuantity: Number(item.quantity),
          total: Number(item.total),
        }
      })
      if (dataFetch.order_type === 'material' || dataFetch.order_type === 'packing') {
        const itemOptions = dataFetch.purchase_order_items.map((item) => item.material)

        setItemOptions(itemOptions)

        if (data.items_in_stock?.length) {
          formattedMaterialsInStock = data.items_in_stock.map((item) => ({
            ...item,
            quantity: Number(item.quantity_in_stock),
            usingQuantity: Number(item.quantity) || 0,
          }))
          // let filteredMaterialsInStock = formattedMaterialsInStock.filter(
          //   (item) => item.quantity_in_stock > 0
          // )

          for (const marterialInStock of formattedMaterialsInStock) {
            // eslint-disable-next-line no-loop-func
            formattedMaterials.forEach((item) => {
              item.total = Number(item.quantity) * Number(item.price)
              subtotal += Number(item.quantity) * Number(item.price)
              if (marterialInStock.id === item.material_id) {
                const indexOfMaterial = formattedMaterialsInStock.findIndex(
                  (element) => element.id === marterialInStock.id
                )
                if (marterialInStock.action === 'use') {
                  formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                    ...marterialInStock,
                    quantity: marterialInStock.quantity - marterialInStock.usingQuantity,
                  })
                  item.initQuantity = marterialInStock.usingQuantity + item.quantity
                } else {
                  if (marterialInStock.quantity_in_stock <= item.quantity) {
                    formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                      ...marterialInStock,
                      usingQuantity: Number(marterialInStock.quantity),
                    })
                  } else {
                    formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                      ...marterialInStock,
                      usingQuantity: Number(item.quantity),
                    })
                  }
                }
              }
            })
          }
        }
      }
      if (dataFetch.order_type === 'label') {
        const itemOptions = dataFetch.purchase_order_items.map((item) => ({
          ...item,
          code: item.product.code,
          name: item.product.name,
        }))
        setItemOptions(itemOptions)

        if (data.items_in_stock?.length) {
          formattedMaterialsInStock = data.items_in_stock.map((item) => ({
            ...item,
            quantity: Number(item.quantity_in_stock),
            usingQuantity: Number(item.quantity) || 0,
          }))

          // let filteredMaterialsInStock = formattedMaterialsInStock.filter(
          //   (item) => item.quantity_in_stock > 0
          // )

          for (const marterialInStock of formattedMaterialsInStock) {
            // eslint-disable-next-line no-loop-func
            formattedMaterials.forEach((item) => {
              item.total = Number(item.quantity) * Number(item.price)
              subtotal += Number(item.quantity) * Number(item.price)
              if (marterialInStock.id === item.label_id) {
                const indexOfMaterial = formattedMaterialsInStock.findIndex(
                  (element) => element.id === marterialInStock.id
                )

                if (marterialInStock.action === 'use') {
                  formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                    ...marterialInStock,
                    quantity: marterialInStock.quantity - marterialInStock.usingQuantity,
                  })
                  item.initQuantity = marterialInStock.usingQuantity + item.quantity
                } else {
                  if (marterialInStock.quantity_in_stock <= item.quantity) {
                    formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                      ...marterialInStock,
                      usingQuantity: Number(marterialInStock.quantity),
                    })
                  } else {
                    formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                      ...marterialInStock,
                      usingQuantity: Number(item.quantity),
                    })
                  }
                }
              }
            })
          }
        }
      }
      if (dataFetch.order_type === 'finish_product') {
        const itemOptions = dataFetch.purchase_order_items.map((item) => item.product)
        setItemOptions(itemOptions)

        if (data.items_in_stock?.length) {
          formattedMaterialsInStock = data.items_in_stock.map((item) => ({
            ...item,
            quantity: Number(item.quantity_in_stock),
            usingQuantity: Number(item.quantity) || 0,
          }))

          // let filteredMaterialsInStock = formattedMaterialsInStock.filter(
          //   (item) => item.quantity_in_stock > 0
          // )
          for (const marterialInStock of formattedMaterialsInStock) {
            // eslint-disable-next-line no-loop-func
            formattedMaterials.forEach((item) => {
              item.total = Number(item.quantity) * Number(item.price)
              subtotal += Number(item.quantity) * Number(item.price)
              if (marterialInStock.id === item.product_id) {
                const indexOfMaterial = formattedMaterialsInStock.findIndex(
                  (element) => element.id === marterialInStock.id
                )
                if (marterialInStock.action === 'use') {
                  formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                    ...marterialInStock,
                    quantity: marterialInStock.quantity - marterialInStock.usingQuantity,
                  })

                  item.initQuantity = marterialInStock.usingQuantity + item.quantity
                } else {
                  if (marterialInStock.quantity_in_stock <= item.quantity) {
                    formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                      ...marterialInStock,
                      usingQuantity: Number(marterialInStock.quantity),
                    })
                  } else {
                    formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                      ...marterialInStock,
                      usingQuantity: Number(item.quantity),
                    })
                  }
                }
              }
            })
          }
        }
      }
      setValues({
        ...values,
        ...data,
        date: moment(dataFetch.date, 'YYYY-MM-DD'),
        receive_date: moment(dataFetch.receive_date, 'YYYY-MM-DD'),
        sub_total: Math.floor(subtotal),
        vat: Math.floor(subtotal * 0.1),
        total: Math.floor(subtotal * 1.1),
        purchase_order_items: formattedMaterials,
        items_in_stock: formattedMaterialsInStock,
      })
    }
  }
  const onTypeChange = (event) => {
    setValues({
      ...values,
      // order_number: '',
      // date: moment(),
      // receive_date: moment(),
      order_type: 'material',
      supplier_id: undefined,
      sale_order_id: undefined,
      vat_percentage: 0,
      purchase_order_items: [
        {
          label_id: 0,
          product_id: 0,
          material_id: 0,
          product: {},
          price: 0,
          unit: '',
          code: '',
          name: '',
          readable_unit: '',
          specification: '',
          quantity: 1,
          initQuantity: 0,
          total: 0,
          note: '',
          info: '',
          label_type: '',
        },
      ],
      items_in_stock: [],
      sub_total: 0,
      vat: 0,
      total: 0,
      production_requirement: '',
      note: '',
    })
    setFieldValue('order_type', event.target.value)
    setOrderSearchData([])
    setSupplierSearch((prev) => ({ ...prev, result: [], keyword: '' }))
    toggleMaterialStatus({ id: undefined, quantity_in_stock: undefined, action: 'use' })
    setItemOptions([])
  }

  const onVatChange = (event) => {
    setFieldValue('vat_percentage', event.target.value)
  }

  const onVatOther = (event) => {
    setFieldValue('vat_percentage', event)
  }

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current < moment().startOf('day')
  // }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_purchase_orders_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">Thông tin đơn đặt hàng</Title>
      {serverError && (
        <Alert
          message={serverError}
          type="error"
          className="server-error"
          showIcon
          style={{ maxWidth: 400 }}
        />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              No <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="order_number"
              placeholder="Nhập mã đặt hàng"
              disabled={loadingCondition}
              value={values.order_number}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="order_number" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Ngày <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.date}
                  onChange={(val) => setFieldValue('date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="date" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Ngày dự kiến giao hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="receive_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.receive_date}
                  onChange={(val) => setFieldValue('receive_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="receive_date" />
          </div>

          <div className="form-item order-type" style={{ maxWidth: 600 }}>
            <Title className="field-title">
              Loại hàng <span className="compulsory-field-symbol">*</span>
            </Title>

            <CustomRadios
              values={values}
              name="order_type"
              loading={loadingCondition}
              editId={editId}
              options={typeOptions}
              onToggleChange={onTypeChange}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="order_type" />
          </div>

          <SupplierSelect
            {...props}
            loadingCondition={loadingCondition}
            setItemOptions={setItemOptions}
            onSeaching={onSeaching}
            setOnSearching={setOnSearching}
            setOrderSearchData={setOrderSearchData}
            searchTerm={supplierSearch}
            setSearchTerm={setSupplierSearch}
            firstRender={firstRender}
          />

          {/* Only show when user choose supplier */}
          {values.supplier_id ? (
            <OrderSelect
              {...props}
              loadingCondition={loadingCondition}
              onSeaching={onSeaching}
              setOnSearching={setOnSearching}
              orderSearchData={orderSearchData}
              setItemOptions={setItemOptions}
              firstRender2={firstRender2}
              setFirstRender2={setFirstRender2}
            />
          ) : null}

          <div className="form-item order-type" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Tỷ lệ VAT <span className="compulsory-field-symbol">*</span>
            </Title>

            <CustomRadios
              values={values}
              name="vat_percentage"
              loading={loadingCondition}
              options={vatOptions}
              otherOption={true}
              onToggleChange={onVatChange}
              onVatOther={onVatOther}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="order_type" />
          </div>

          <div className="form-item scrollToBottomAdd">
            <Title className="field-title">
              {values.order_type === 'material'
                ? 'Thông tin nguyên vật liệu'
                : values.order_type === 'packing'
                ? 'Thông tin bao bì'
                : values.order_type === 'label'
                ? 'Thông tin tem'
                : 'Thông tin thành phẩm'}
              <span className="compulsory-field-symbol">*</span>
            </Title>

            {onSeaching.marterialOfOrder || onSeaching.orderOfSupplier ? (
              <div className="searching-material-loading">
                <Spin spinning={onSeaching.marterialOfOrder || onSeaching.orderOfSupplier} />
              </div>
            ) : null}

            {values.order_type === 'material' ||
            values.order_type === 'packing' ||
            values.order_type === 'finish_product' ? (
              <OrderItems
                name="purchase_order_items"
                values={values}
                loadingCondition={loadingCondition}
                setFieldValue={setFieldValue}
                editId={editId}
                path={path}
                itemOrder={itemOrder}
                itemOptions={itemOptions}
              />
            ) : (
              <LabelItems
                name="purchase_order_items"
                values={values}
                loadingCondition={loadingCondition}
                setFieldValue={setFieldValue}
                editId={editId}
                path={path}
                itemOrder={itemOrder}
                itemOptions={itemOptions}
              />
            )}
          </div>

          <div className="form-item" style={{ maxWidth: 500 }}>
            <Title className="field-title">Điều kiện sản xuất lô hàng</Title>
            <Field
              as={TextArea}
              name="production_requirement"
              placeholder="Điều kiện..."
              disabled={loadingCondition}
              value={values.production_requirement}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="production_requirement"
            />
          </div>
          <div className="form-item" style={{ maxWidth: 500 }}>
            <Title className="field-title">Ghi chú</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Ghi chú..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/purchase-orders')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo đơn đặt hàng' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
