import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Formik } from 'formik'
import { Redirect, useHistory } from 'react-router-dom'

import { loginStepTwoSchema } from 'Validation'
import LoginView from './Login.view'

export default () => {
  const history = useHistory()
  const email = useStoreState((state) => state.auth.email)

  const initialValues = {
    signinToken: '',
  }

  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const { signinStepTwo } = useStoreActions((actions) => actions.auth)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    const params = {
      ...values,
      email,
    }
    const resp = await signinStepTwo(params)
    if (resp.errors) {
      setServerError(resp.errors)
      setSubmitting(false)
      return
    }

    setSubmitting(false)
    history.push('/')
  }

  if (!email) {
    return <Redirect to="/login-step-one" />
  }

  return (
    <div className="login-page-step-two">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={loginStepTwoSchema}
        children={(props) => (
          <LoginView
            email={email}
            {...props}
            serverError={serverError}
            setServerError={setServerError}
            isSubmitting={isSubmitting}
          />
        )}
      />
    </div>
  )
}
