import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'

const manageMaterialsHistorySchema = yup.object().shape({
  //material_id: yup.number().required(requiredField).max(30, maxLength),
  material_id: yup.number().required(requiredField),
  option_content: yup.string().required(requiredField).max(255, maxLength),
  start_date: yup.date().required(requiredField),
  end_date: yup.date().required(requiredField),
})

export default manageMaterialsHistorySchema
