import React from 'react'
import { Typography, DatePicker } from 'antd'
import moment from 'moment'

const { Title } = Typography

export default React.memo((props) => {
  const { dates, setDates } = props

  const onDateChange = (fieldName, val) => {
    const formattedDate = val.format('YYYY-MM-DD')

    if (fieldName === 'startDate' && val > moment(dates.endDate, 'YYYY-MM-DD')) {
      return setDates((dates) => ({
        startDate: dates.endDate,
        endDate: formattedDate,
      }))
    }

    if (fieldName === 'endDate' && val < moment(dates.startDate, 'YYYY-MM-DD')) {
      return setDates((dates) => ({
        endDate: dates.startDate,
        startDate: formattedDate,
      }))
    }

    setDates((dates) => ({
      ...dates,
      [fieldName]: formattedDate,
    }))
  }

  return (
    <div id="time_attendence_form">
      <div className="time-attendence-form">
        <div id="manage_time_attendence_form" className="form-wrapper">
          {/* {serverError && (
            <Alert message={serverError} type="error" className="server-error" showIcon />
          )} */}
          <div className="general-information">
            <div className="part-field-wrapper">
              <div className="form-item">
                <Title className="field-title">
                  Từ ngày <span className="compulsory-field-symbol">&nbsp;*</span>
                </Title>
                <DatePicker
                  format={'DD-MM-YYYY'}
                  value={moment(dates.startDate, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('startDate', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  allowClear={false}
                />
              </div>

              <div className="form-item">
                <Title className="field-title">
                  Đến ngày <span className="compulsory-field-symbol">&nbsp;*</span>
                </Title>
                <DatePicker
                  format={'DD-MM-YYYY'}
                  value={moment(dates.endDate, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('endDate', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  allowClear={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
