import React from 'react'
import { Spin } from 'antd'

import './index.scss'

const Loading = (props) => {
  const { loading } = props

  return (
    <div className="loading-contain">
      <Spin spinning={loading} size="large" />
    </div>
  )
}

export default Loading
