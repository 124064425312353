import React from 'react'
import { Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import ImageGallery from 'react-image-gallery'

const ImageModalPreview = React.memo((props) => {
  const { visible, onCancel, images } = props
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onCancel}
      style={{ top: 35, maxWidth: 1200, maxHeight: 766 }}
      bodyStyle={{ padding: 5 }}
      width={'90%'}
      closeIcon={<CloseCircleOutlined style={{ color: 'red', fontSize: 20 }} />}
    >
      <ImageGallery items={images} lazyLoad thumbnailPosition="left" showPlayButton={false} />
    </Modal>
  )
})

export default ImageModalPreview
