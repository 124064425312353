import React from 'react'
import { Typography, Modal, Card, Row, Col, InputNumber, message } from 'antd'
import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import { formatInputNumber } from 'Utils'

const { Text } = Typography
const { Grid } = Card

const modalInitialValues = {
  id: undefined,
  updated_at: '',
  staff_id: undefined,
  date: moment().format('YYYY-MM-DD'),
  office_hours: '',
  overtime_hours: '',
  staff: {
    id: undefined,
    code: '',
    name: '',
    staff_type: '',
    readable_staff_type: '',
  },
}

export default React.memo((props) => {
  const { data, loading, visible, setEditModal, doFetch } = props

  const renderTitle = () => <Text className="modal-title">Cập nhật chấm công</Text>

  const handlePressOK = async () => {
    const url = 'time_attendance'
    setEditModal((prev) => ({ ...prev, loading: true }))
    const params = {
      time_attendances: [
        {
          ...data,
          office_hours: Number((data.office_hours / 60).toFixed(2)),
          overtime_hours: Number((data.overtime_hours / 60).toFixed(2)),
          date: data.date,
        },
      ],
    }
    try {
      await axios.put(url, params)

      setEditModal({
        id: undefined,
        data: modalInitialValues,
        visible: false,
        loading: false,
      })
      doFetch((prev) => ({ ...prev }))
    } catch (error) {
      setEditModal((prev) => ({
        ...prev,
        loading: false,
      }))
      message.error(error.response?.data.errors?.message)
    }
  }

  const handleCancel = () =>
    setEditModal({
      id: undefined,
      data: modalInitialValues,
      visible: false,
    })

  const handleNumberChange = (fieldName, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      //   setFieldValue(`sale_order_items[${index}].box_quantity`, 1)
    } else {
      const price = Number(value.toFixed(2))

      return setEditModal((prev) => ({
        ...prev,
        data: { ...prev.data, [fieldName]: price },
      }))
    }
  }

  return (
    <Modal
      title={renderTitle()}
      centered
      visible={visible}
      cancelButtonProps={{
        loading,
        style: { margin: 10 },
      }}
      okButtonProps={{
        loading,
        style: { margin: 10 },
      }}
      okText={'Cập nhật'}
      cancelText={'Hủy'}
      onOk={handlePressOK}
      onCancel={loading ? null : handleCancel}
      className="edit-time-attendence-modal"
      style={{ maxWidth: 650 }}
    >
      <Card className="view-wrapper">
        <Grid hoverable={false} className="view-item">
          <Row className="row">
            <Col className="content-wrapper" span={8}>
              Mã nhân viên
            </Col>
            <Col className="content-wrapper" span={9}>
              {data.staff.code}
            </Col>
          </Row>
        </Grid>
        <Grid hoverable={false} className="view-item">
          <Row className="row">
            <Col className="content-wrapper" span={8}>
              Tên nhân viên
            </Col>
            <Col className="content-wrapper" span={9}>
              {data.staff.name}
            </Col>
          </Row>
        </Grid>
        <Grid hoverable={false} className="view-item">
          <Row className="row">
            <Col className="content-wrapper" span={8}>
              Ngày
            </Col>
            <Col className="content-wrapper" span={9}>
              {moment(data.date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
            </Col>
          </Row>
        </Grid>
        <Grid hoverable={false} className="view-item">
          <Row className="row">
            <Col className="content-wrapper" span={8}>
              Tổng giờ làm (phút)
            </Col>
            <Col className="content-wrapper" span={9}>
              <InputNumber
                style={{ width: '100%' }}
                disabled={loading}
                value={data.office_hours}
                formatter={(value) => formatInputNumber(value)}
                onChange={(value) => handleNumberChange('office_hours', 1440, value)}
                min={0}
                max={1440}
              />
            </Col>
          </Row>
        </Grid>
        <Grid hoverable={false} className="view-item">
          <Row className="row">
            <Col className="content-wrapper" span={8}>
              Tổng giờ tăng ca (phút)
            </Col>
            <Col className="content-wrapper" span={9}>
              <InputNumber
                style={{ width: '100%' }}
                disabled={loading}
                value={data.overtime_hours}
                formatter={(value) => formatInputNumber(value)}
                onChange={(value) => handleNumberChange('overtime_hours', 1440, value)}
                min={0}
                max={1440}
              />
            </Col>
          </Row>
        </Grid>
        {/* {data.sale_order_materials.map((material, index) => {
          return (
            <Grid hoverable={false} className="view-item" key={index}>
              <Row className="row">
                <Col className="content-wrapper" span={8}>
                  {material.material_name}
                </Col>
                <Col className="content-wrapper" span={8}>
                  {material.price ? formatNumber(material.price) : '-'}
                </Col>
              </Row>
            </Grid>
          )
        })} */}
      </Card>
    </Modal>
  )
})
