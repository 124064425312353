import axios from 'Utils/axiosExpand'

const loginStepOneAPI = async (values) => {
  const { email, password } = values

  try {
    const resp = await axios.post('/users/login-step-one/', {
      email,
      password,
    })
    return resp
  } catch (error) {
    return error.response?.data.errors?.message
  }
}

const loginStepTwoAPI = async (values) => {
  const { email, signinToken } = values

  try {
    const resp = await axios.post('/users/login-step-two/', {
      email,
      sign_in_token: signinToken,
    })
    return resp
  } catch (error) {
    return error.response?.data.errors?.message
  }
}

const forgotPasswordAPI = async (values) => {
  const { email } = values

  try {
    const resp = await axios.post('/users/send-forgot-password-mail/', {
      email,
    })
    return resp
  } catch (error) {
    return error.response?.data.errors?.message
  }
}

export { loginStepOneAPI, loginStepTwoAPI, forgotPasswordAPI }
