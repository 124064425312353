import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

const managePaymentTransactionSchema = yup.object().shape({
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
  date: yup.date().required(requiredField),
  payment_method: yup.string().required(requiredField).max(255, maxLength),
  customer_id: yup.number().required(requiredField),
  sale_order_id: yup.number().required(requiredField),
  amount: yup.number().required(requiredField).typeError(typeError),
})

export default managePaymentTransactionSchema
