import React, { useState, useEffect } from 'react'
import { Table, Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'

import axios from 'Utils/axiosRexyAdmin'
import { useDataApi } from 'Hooks'
import { getFieldName, formatNumber, pageSizeConfig } from 'Utils'
import DeleteModal from './DeleteModal'

export default React.memo(() => {
  const history = useHistory()

  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/staffs/',
    key: 'records',
  })

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    name: '',
    visible: false,
  })

  const [jobTitleList, setJobTitleList] = useState([])

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const staffsState = useStoreState((state) => state.menuTabs['staffs'])

  useEffect(() => {
    let didCancel
    if (staffsState && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'staffs', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffsState])

  useEffect(() => {
    let didCancel = false

    const fetchJobTitles = async () => {
      const url = '/staffs/new/'
      const resp = await axios.get(url)
      setJobTitleList(resp.data.job_titles)
    }

    if (!didCancel) {
      fetchJobTitles()
    }
    return () => (didCancel = true)
  }, [])

  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'code',
      sortOrder: pagination.order_by === 'code' ? `${pagination.order}` : null,
      width: 120,
      sorter: true,
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'name',
      sortOrder: pagination.order_by === 'name' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
    },
    {
      title: 'Vị trí công tác',
      dataIndex: 'job_title',
      sortOrder: pagination.order_by === 'job_title' ? `${pagination.order}` : null,
      width: 160,
      sorter: true,
      render: (jobTitle) => getFieldName(jobTitle, jobTitleList),
    },
    {
      title: 'Mức lương',
      dataIndex: 'salary',
      sortOrder: pagination.order_by === 'salary' ? `${pagination.order}` : null,
      width: 140,
      sorter: true,
      render: (salary) => formatNumber(Number(salary)),
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'start_date',
      sortOrder: pagination.order_by === 'start_date' ? `${pagination.order}` : null,
      width: 140,
      sorter: true,
      render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    },
    {
      key: 'x',
      width: 150,
      render: (record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}

        <Button
          className="action-item update"
          type="link"
          onClick={(event) => handleEdit(record, event)}
        >
          Sửa
        </Button>

        <Button
          className="action-item delete"
          type="link"
          onClick={(event) => handleDelete(record, event)}
        >
          Xoá
        </Button>
      </div>
    )
  }

  const handleView = (record) => {
    history.push(`/staffs/${record.id}/view`)
  }

  const handleEdit = (record, event) => {
    event.stopPropagation()
    history.push(`/staffs/${record.id}/edit`)
  }

  const handleDelete = (record, event) => {
    event.stopPropagation()
    setDeleteModal({ id: record.id, name: record.name, visible: true })
  }

  const handCreate = () => history.push('/staffs/new')

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Thêm nhân viên
        </Button>
      </div>
    )
  }

  return (
    <div id="manage_staff_table">
      <Table
        title={renderTitle}
        className="staffs-table"
        dataSource={data}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 940, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />

      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
})
