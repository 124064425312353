import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

const allowRoles = ['OM', 'R']

const ListSupplierDebtInvoice = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewSupplierDebtInvoice = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewSupplierDebtInvoice = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

const EditSupplierDebtInvoice = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export {
  ListSupplierDebtInvoice,
  ViewSupplierDebtInvoice,
  NewSupplierDebtInvoice,
  EditSupplierDebtInvoice,
}
