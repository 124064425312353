import React, { useState, useRef } from 'react'
import { Layout, Button, Avatar, Card } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

import { useClickOutside } from 'Hooks'
import { nameColorGenerator } from 'Utils'

const { Header } = Layout

// const menu = (
//   <Menu>
//     <Menu.Item key="1" icon={<UserOutlined />}>
//       1st menu item
//     </Menu.Item>
//     <Menu.Item key="2" icon={<UserOutlined />}>
//       2nd menu item
//     </Menu.Item>
//     <Menu.Item key="3" icon={<UserOutlined />}>
//       3rd item
//     </Menu.Item>
//   </Menu>
// )

const AvatarDropDownCard = React.memo((props) => {
  const { name, email, sortName, avaRef, logOut, color } = props

  const history = useHistory()

  return (
    <div ref={avaRef}>
      <Card
        hoverable={false}
        className="avatar-card-dropdown-wrapper"
        cover={
          <Avatar
            shape={'circle'}
            size={54}
            className="card-cover-avatar"
            style={{ background: color }}
          >
            {sortName}
          </Avatar>
        }
      >
        <div className="inner-card">
          <strong className="user-name">{name}</strong>
          <span className="user-email">{email}</span>
          <div className="button-wrapper">
            <Button
              icon={<SettingOutlined />}
              onClick={() => history.push('/settings-account')}
              className="settings-button"
            >
              Cài đặt
            </Button>

            <Button icon={<LogoutOutlined />} onClick={logOut} className="logout-button">
              Đăng xuất
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
})

const CardInfo = (props) => {
  const { bpCollapsed, toggleMenu } = props
  const userInfors = useStoreState((state) => state.auth)

  const _name = userInfors.name || 'Datafood User'
  const _nameArr = _name.split(' ')
  const length = _nameArr.length
  const sortName = length === 1 ? _nameArr[0][0] : _nameArr[0][0] + _nameArr[length - 1][0]

  const color = nameColorGenerator(_name)

  const avaRef = useRef()
  const btnRef = useRef()

  const [cardVisible, setCardVisible] = useState(false)
  const logOut = useStoreActions((actions) => actions.auth.logOut)

  useClickOutside(avaRef, btnRef, () => setCardVisible(false))

  return (
    <Header className="site-layout-header">
      {bpCollapsed.collapsedButton ? (
        bpCollapsed.isCollapsed ? (
          <MenuUnfoldOutlined onClick={toggleMenu} className="trigger" />
        ) : (
          <MenuFoldOutlined onClick={toggleMenu} className="trigger" />
        )
      ) : (
        <div />
      )}

      <div className="right-header">
        {/* <div className="notification-container">
          <span className="notification-badge">7</span>
          <Dropdown
            trigger={['click']}
            overlay={menu}
            placement="bottomCenter"
            className="notification-icon"
          >
            <Button icon={<BellOutlined />} />
          </Dropdown>
        </div> */}

        <div
          className="user-infor"
          ref={btnRef}
          onClick={() => {
            setCardVisible(!cardVisible)
          }}
        >
          <Avatar className="user-avatar" style={{ background: color }}>
            {sortName}
          </Avatar>
          <span className="user-name">{_name}</span>
          {cardVisible ? (
            <AvatarDropDownCard
              sortName={sortName}
              name={_name}
              avaRef={avaRef}
              logOut={logOut}
              color={color}
              {...userInfors}
            />
          ) : null}
        </div>
      </div>
    </Header>
  )
}

export default CardInfo
