import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import {
  Input,
  InputNumber,
  Button,
  Alert,
  Typography,
  Select,
  Spin,
  DatePicker,
  message,
  Switch,
} from 'antd'
import {
  RollbackOutlined,
  PlusOutlined,
  FormOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
import {
  formatNumber,
  numToWord,
  useScrollToErrors,
  preventEnterSubmit,
  removeVietnameseTones,
  formatInputNumber,
} from 'Utils'
import { useDebounce } from 'Hooks'
import { noData } from 'Components'
import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const getSuppliers = async (supplierCode) => {
  const supplierResult = await axios.get('/suppliers/form/', {
    params: {
      keyword: supplierCode,
      per_page: 20,
    },
  })

  return _.get(supplierResult, 'data.records', [])
}
const getPurchaseOrders = async (orderNumber, supplierId) => {
  const purchaseOrderResult = await axiosExpand.get('/purchase_orders/form-in-standing-order/', {
    params: {
      keyword: orderNumber,
      supplier_id: Number(supplierId),
      per_page: 20,
    },
  })
  return _.get(purchaseOrderResult, 'data.records', [])
}
const getPurchaseInvoice = async (id) => {
  const response = await axiosExpand.get(`/purchase_orders/${id}/purchase-order-invoices`)
  return get(response, 'data.purchase_orders_invoices', [])
}

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
    isSuperAdmin,
  } = props

  const history = useHistory()
  const [firstRender, setFirstRender] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [purchaseInvoice, setPurchaseInvoice] = useState({ loading: false, data: [] })
  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })
  const [purchaseOrderId, setPurchaseOrderId] = useState([])
  const [approved, setApproved] = useState([])
  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)
  const [purchaseOrderSearchTerm, setPurchaseOrderSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })
  const purchaseOrderDebouncedSearchTerm = useDebounce(purchaseOrderSearchTerm.keyword, 500)

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  useEffect(() => {
    let didCancel = false

    const fetchLabelTypes = async () => {
      const url = '/customers/new/'
      const resp = await axios.get(url)
      setPaymentMethods(resp.data.payment_methods)
    }

    if (!didCancel) {
      fetchLabelTypes()
    }
    return () => (didCancel = true)
  }, [])

  const setLoading = (loading) => {
    setSearchTerm((prev) => ({
      ...prev,
      searching: loading,
    }))
    setPurchaseOrderSearchTerm((prev) => ({
      ...prev,
      searching: loading,
    }))
    setPurchaseInvoice((prev) => ({
      ...prev,
      searching: loading,
    }))
  }

  useEffect(() => {
    const url = new URL(window.location.href)
    const supplierId = url.searchParams.get('supplier_id')
    const supplierCode = url.searchParams.get('supplier_code')
    const purchaseOrderId = url.searchParams.get('purchase_order_id')
    const orderNumber = url.searchParams.get('order_number')
    const purchaseOrderInvoiceId = url.searchParams.get('purchase_order_invoice_id')
    const purchaseOrderInvoiceNumber = url.searchParams.get('purchase_order_invoice_number')

    let didCancel = false
    const fetchOrderList = async () => {
      setLoading(true)
      try {
        const suppliers = await getSuppliers(supplierCode)
        setSearchTerm((prev) => ({
          ...prev,
          result: suppliers,
          searching: false,
        }))
        const supplier = _.find(suppliers, ['id', Number(supplierId)])

        const purchaseOrders = await getPurchaseOrders(orderNumber, supplierId)
        setPurchaseOrderSearchTerm((prev) => ({
          ...prev,
          result: purchaseOrders,
          searching: false,
        }))

        const purchaseOrderInvoices = await getPurchaseInvoice(purchaseOrderId)
        const purchaseOrderInvoice = _.find(purchaseOrderInvoices, [
          'id',
          Number(purchaseOrderInvoiceId),
        ])

        setPurchaseInvoice((prev) => ({
          ...prev,
          data: purchaseOrderInvoices,
          searching: false,
        }))

        setValues({
          ...values,
          supplier_id: Number(supplierId),
          bank_name: supplier.bank_name,
          bank_account_name: supplier.bank_account_name,
          bank_branch: supplier.bank_branch,
          bank_number: supplier.bank_number,
          purchase_order_id: Number(purchaseOrderId),
          purchase_order_invoice_id: Number(purchaseOrderInvoiceId),
          total_amount: Number(purchaseOrderInvoice.total),
          paid_amount: Number(purchaseOrderInvoice.paid_amount),
          not_paid_amount: Number(purchaseOrderInvoice.not_paid_amount),
        })
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setLoading(false)
        }
      }
    }

    if (
      path === 'new' &&
      firstRender &&
      purchaseOrderId &&
      purchaseOrderId !== 'undefined' &&
      orderNumber &&
      orderNumber !== 'undefined' &&
      supplierCode &&
      supplierCode !== 'undefined' &&
      supplierId &&
      supplierId !== 'undefined' &&
      purchaseOrderInvoiceId &&
      purchaseOrderInvoiceId !== 'undefined' &&
      purchaseOrderInvoiceNumber &&
      purchaseOrderInvoiceNumber !== 'undefined'
    ) {
      fetchOrderList()

      setFirstRender(false)
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let didCancel = false

    if (editId) {
      ;(async () => {
        const url = `/standing_orders/${editId}/edit`
        setIsFetching(true)
        try {
          const resp = await axios.get(url)
          if (editId && !didCancel) {
            const purchaseOrder = get(resp.data, 'purchase_order', {})
            const purchaseOrderInvoice = get(resp.data, 'purchase_order_invoice', {})
            setApproved(resp.data.approved)
            onSearch(resp.data.supplier.code)
            const result = await getPurchaseInvoice(purchaseOrder?.id)
            setPurchaseInvoice({ data: result, loading: false })

            const url = '/purchase_orders/form-in-standing-order/'

            const resp_purchase = await axiosExpand.get(url, {
              params: {
                keyword: resp.data.purchase_order.order_number,
                supplier_id: resp.data.supplier_id,
                per_page: 20,
              },
            })
            // const formatedInvoice =
            //   resp.data.purchase_order_invoice === null
            //     ? {
            //         // ...resp_purchase.data.records[0],
            //         total_amount: resp_purchase.data.records[0]?.total_amount,
            //         paid_amount: resp_purchase.data.records[0]?.paid_amount,
            //         not_paid_amount: resp_purchase.data.records[0]?.not_paid_amount,
            //       }
            //     : {
            //         ...purchaseOrderInvoice,
            //         total: purchaseOrderInvoice?.total_amount,
            //         paid_amount: purchaseOrderInvoice?.paid_amount,
            //         not_paid_amount: purchaseOrderInvoice?.not_paid_amount,
            //       }

            const record =
              resp.data.purchase_order_invoice === null
                ? resp_purchase.data.records[0]
                : purchaseOrderInvoice

            let total_amount = record.total_amount
            let paid_amount = record.paid_amount - resp.data.amount
            let not_paid_amount = total_amount - paid_amount

            // if (not_paid_amount > total_amount || not_paid_amount < 0) not_paid_amount = 0

            const formatedInvoice = {
              ...record,
              total_amount: total_amount,
              paid_amount: paid_amount,
              not_paid_amount: not_paid_amount,
            }

            onPurchaseOrderSearch(purchaseOrder.order_number)

            setValues({
              ...values,
              ...formatedInvoice,
              ...resp.data,
              purchase_order_invoice_id:
                resp.data.purchase_order_invoice_id === null
                  ? undefined
                  : resp.data.purchase_order_invoice_id,
              date: moment(resp.data.date, 'YYYY-MM-DD'),
            })
            setIsFetching(false)
          }
        } catch (error) {
          if (!didCancel) {
            setIsFetching(false)
          }
        }
      })()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId])

  useEffect(() => {
    if (values.not_paid_amount <= values.total_amount && values.not_paid_amount >= 0) return

    setFieldValue('not_paid_amount', 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.not_paid_amount, values.total_amount])

  useEffect(() => {
    let didCancel = false

    const fetchSuppliers = async () => {
      const url = '/suppliers/form/'
      // setIsFetching(true)
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
          },
        })

        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setSearchTerm((prev) => ({ ...prev, searching: false }))
          setIsFetching(false)
        }
      }
    }

    if (debouncedSearchTerm.length && searchTerm.keyword === debouncedSearchTerm) {
      fetchSuppliers()
    }
    return () => (didCancel = true)
  }, [debouncedSearchTerm, searchTerm.keyword])

  useEffect(() => {
    let didCancel = false
    const fetchPurchaseOrders = async () => {
      const url = '/purchase_orders/form-in-standing-order/'

      try {
        const resp = await axiosExpand.get(url, {
          params: {
            keyword: purchaseOrderDebouncedSearchTerm,
            supplier_id: values.supplier_id,
            per_page: 20,
          },
        })

        if (!didCancel) {
          setPurchaseOrderSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setPurchaseOrderSearchTerm((prev) => ({ ...prev, searching: false }))
          setIsFetching(false)
        }
      }
    }

    if (
      purchaseOrderDebouncedSearchTerm.length &&
      purchaseOrderSearchTerm.keyword === purchaseOrderDebouncedSearchTerm
    ) {
      fetchPurchaseOrders()
    }
    return () => (didCancel = true)
  }, [purchaseOrderDebouncedSearchTerm, purchaseOrderSearchTerm.keyword, values.supplier_id])

  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onResultSelect = (value, option) => {
    setValues({
      ...values,
      supplier_id: value,
      bank_name: option.item.bank_name,
      bank_account_name: option.item.bank_account_name,
      bank_branch: option.item.bank_branch,
      bank_number: option.item.bank_number,
      purchase_order_id: undefined,
      purchase_order_invoice_id: undefined,
      total_amount: 0,
      paid_amount: 0,
      // not_paid_amount: 0,
    })

    if (purchaseOrderSearchTerm.result.length || purchaseOrderSearchTerm.keyword.length) {
      setPurchaseOrderSearchTerm((prev) => ({ ...prev, keyword: '', result: [] }))
    }
  }

  const onPurchaseOrderSearch = (val) => {
    if (!val) {
      setPurchaseOrderSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setPurchaseOrderSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onPurchaseOrderSelect = async (value, option) => {
    setPurchaseOrderId(option)
    setPurchaseInvoice((prev) => ({ ...prev, loading: true }))
    setValues({
      ...values,
      purchase_order_id: value,
      purchase_order_invoice_id: undefined,
      total_amount: Number(option.item.total_amount),
      paid_amount: Number(option.item.paid_amount),
      not_paid_amount: Number(option.item.not_paid_amount),
    })

    const result = await getPurchaseInvoice(value)

    setPurchaseInvoice({ data: result, loading: false })
  }

  const onSelectInvoice = async (value, option) => {
    if (!value) {
      if (path === 'edit') {
        const dataPurchaseOrders = await getPurchaseOrders(
          values.purchase_order.order_number,
          values.supplier_id
        )
        return setValues({
          ...values,
          purchase_order_invoice_id: undefined,
          total_amount: Number(dataPurchaseOrders[0].total_amount),
          paid_amount: Number(dataPurchaseOrders[0].paid_amount),
          not_paid_amount: Number(dataPurchaseOrders[0].not_paid_amount),
          approved: false,
        })
      } else {
        return setValues({
          ...values,
          purchase_order_invoice_id: undefined,
          total_amount: Number(purchaseOrderId.item.total_amount),
          paid_amount: Number(purchaseOrderId.item.paid_amount),
          not_paid_amount: Number(purchaseOrderId.item.not_paid_amount),
          approved: false,
        })
      }
    }

    setValues({
      ...values,
      purchase_order_invoice_id: value,
      total_amount: Number(option.item.total),
      paid_amount: Number(option.item.paid_amount),
      not_paid_amount: Number(option.item.not_paid_amount),
      approved: false,
    })
  }

  const handleChangePaymentMethod = (value) => {
    setFieldValue('payment_method', value)
  }

  const handleNumberChange = (name, limit, value) => {
    if (typeof value !== 'number') {
      return
    }
    // else if (value > limit) {
    //   return
    // }
    else if (!value) {
      setFieldValue(name, 0)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(name, roundDown)
    }
  }

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  const purchaseOrderSearchDropDownCondition = purchaseOrderSearchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : purchaseOrderSearchTerm.keyword.length && !purchaseOrderSearchTerm.result.length ? (
    noData()
  ) : null

  const loadingCondition = isSubmitting || isFetching
  const isApproved = values.approved

  const onApprove = (checked) => {
    setFieldValue('approved', checked)
  }

  const showApproved = () => {
    if (editId) return values.show_approved
    return values.amount > values.not_paid_amount
  }

  return (
    <Form
      id="manage_standing_orders_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">Thông tin uỷ nhiệm chi</Title>
      {serverError && (
        <Alert
          message={serverError}
          type="error"
          className="server-error"
          showIcon
          style={{ marginLeft: 10 }}
        />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">Ngày</Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.date}
                  onChange={(val) => setFieldValue('date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition || isApproved}
                  allowClear={false}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="date" />
          </div>
          <div className="form-item">
            <Title className="field-title">
              Nhà cung cấp <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="supplier_id" disabled={loadingCondition}>
              {() => {
                return (
                  <Select
                    name="supplier_id"
                    style={{ width: '100%' }}
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    notFoundContent={searchDropDownCondition}
                    placeholder="Nhập mã hoặc tên nhà cung cấp để tìm kiếm"
                    onSearch={onSearch}
                    value={searchTerm.result.length === 0 ? undefined : values.supplier_id}
                    onChange={onResultSelect}
                    optionFilterProp="children"
                    loading={searchTerm.searching}
                    disabled={loadingCondition || isApproved}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {searchTerm.result.map((item) => (
                      <Option
                        key={item.id}
                        item={item}
                        value={item.id}
                      >{`${item.code}: ${item.name}`}</Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="supplier_id" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Đơn đặt hàng NCC <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="purchase_order_id" disabled={loadingCondition}>
              {() => {
                return (
                  <Select
                    name="purchase_order_id"
                    style={{ width: '100%' }}
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    notFoundContent={purchaseOrderSearchDropDownCondition}
                    placeholder="Nhập mã đơn đặt hàng NCC để tìm kiếm"
                    onSearch={onPurchaseOrderSearch}
                    value={
                      purchaseOrderSearchTerm.result.length === 0
                        ? undefined
                        : values.purchase_order_id
                    }
                    onChange={onPurchaseOrderSelect}
                    optionFilterProp="children"
                    loading={purchaseOrderSearchTerm.searching || purchaseInvoice.loading}
                    disabled={
                      loadingCondition ||
                      !values.supplier_id ||
                      purchaseInvoice.loading ||
                      values.purchase_order_invoice_id
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {purchaseOrderSearchTerm.result.map((item) => (
                      <Option key={item.id} item={item} value={item.id}>
                        {item.order_number}
                      </Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="purchase_order_id"
            />
          </div>

          <div className="form-item">
            <Title className="field-title">Hoá đơn TT công nợ NCC</Title>
            <Field
              as={Select}
              loading={purchaseInvoice.loading}
              allowClear
              name="purchase_order_invoice_id"
              style={{ width: '100%' }}
              placeholder="Chọn hóa đơn thanh toán"
              disabled={
                loadingCondition ||
                !values.purchase_order_id ||
                purchaseInvoice.loading ||
                isApproved
              }
              value={values.purchase_order_invoice_id}
              onChange={onSelectInvoice}
            >
              {purchaseInvoice.data.map((invoice) => (
                <Option key={invoice.id} value={invoice.id} item={invoice}>
                  {invoice.invoice_number}
                </Option>
              ))}
            </Field>
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="purchase_order_invoice_id"
            />
          </div>

          {values.purchase_order_id ? (
            <>
              <div className="form-item hint-field">
                <Title className="field-title hint-title">
                  {values.purchase_order_invoice_id === undefined
                    ? 'Tổng số tiền đơn đặt hàng:'
                    : 'Tổng số tiền hoá đơn:'}
                </Title>
                <span className="to-word-money">
                  {values.total_amount
                    ? `${formatNumber(Math.round(values.total_amount))} VND`
                    : ' - VND'}
                </span>
              </div>

              <div className="form-item hint-field">
                <Title className="field-title hint-title">Số tiền đã trả:</Title>
                <span className="to-word-money">
                  {values.paid_amount
                    ? `${formatNumber(Math.round(values.paid_amount))} VND`
                    : ' - VND'}
                </span>
              </div>

              <div className="form-item hint-field">
                <Title className="field-title hint-title">Số tiền còn phải trả:</Title>
                <span className="to-word-money">
                  {values.not_paid_amount
                    ? `${formatNumber(Math.round(values.not_paid_amount))} VND`
                    : ' - VND'}
                </span>
              </div>
            </>
          ) : null}

          <div className="form-item">
            <Title className="field-title">
              Hình thức thanh toán <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Select}
              name="payment_method"
              style={{ width: '100%' }}
              placeholder="Chọn hình thức thanh toán"
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={loadingCondition || isApproved}
              value={values.payment_method}
              onChange={handleChangePaymentMethod}
            >
              {paymentMethods.map((method) => (
                <Option key={method.value} value={method.value}>
                  {method.name}
                </Option>
              ))}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="payment_method" />
          </div>

          {values.payment_method === 'atm' && (
            <>
              <div className="form-item">
                <Title className="field-title">
                  Tên ngân hàng thụ hưởng<span className="compulsory-field-symbol"> *</span>
                </Title>
                <Field
                  as={Input}
                  name="bank_name"
                  placeholder="Nhập tên ngân hàng"
                  disabled={loadingCondition || isApproved}
                  value={values.bank_name}
                  onChange={handleChange}
                />
                <ErrorMessage component={(props) => <CustomError {...props} />} name="bank_name" />
              </div>

              <div className="form-item">
                <Title className="field-title">
                  Chi nhánh ngân hàng<span className="compulsory-field-symbol"> *</span>
                </Title>
                <Field
                  as={Input}
                  name="bank_branch"
                  placeholder="Nhập chi nhánh ngân hàng"
                  disabled={loadingCondition || isApproved}
                  value={values.bank_branch}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="bank_branch"
                />
              </div>

              <div className="form-item">
                <Title className="field-title">
                  Số tài khoản<span className="compulsory-field-symbol"> *</span>
                </Title>
                <Field
                  as={Input}
                  name="bank_number"
                  placeholder="Nhập số tài khoản"
                  disabled={loadingCondition || isApproved}
                  value={values.bank_number}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="bank_number"
                />
              </div>

              <div className="form-item">
                <Title className="field-title">
                  Đơn vị thụ hưởng<span className="compulsory-field-symbol"> *</span>
                </Title>
                <Field
                  as={Input}
                  name="bank_account_name"
                  placeholder="Nhập đơn vị thụ hưởng"
                  disabled={loadingCondition || isApproved}
                  value={values.bank_account_name}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="bank_account_name"
                />
              </div>
            </>
          )}

          <div className="form-item">
            <Title className="field-title">
              Số tiền bằng số (VND) <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={InputNumber}
              name="amount"
              placeholder="Nhập số tiền"
              // disabled={loadingCondition || !values.purchase_order_invoice_id}
              disabled={loadingCondition || isApproved}
              value={values.amount}
              onChange={(value) => handleNumberChange('amount', values.not_paid_amount, value)}
              style={{ width: '100%' }}
              formatter={(value) => formatInputNumber(value)}
              min={0}
              max={
                values.purchase_order_invoice_id !== undefined ? values.not_paid_amount : undefined
              }
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="amount" />
          </div>

          <div className="form-item hint-field">
            <Title className="field-title hint-title">Số tiền bằng chữ:</Title>
            <span className="to-word-money">
              {!loadingCondition && values.amount ? numToWord(values.amount) : '-'}
            </span>
          </div>

          <div className="form-item">
            <Title className="field-title">
              Nội dung<span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Nội dung..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
          {!values.purchase_order_invoice_id && showApproved() ? (
            <div className="form-item switch-field">
              <Title className="field-title">Duyệt uỷ nhiệm chi:</Title>

              <Switch
                className="switcher"
                checked={values.approved}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={onApprove}
                disabled={!isSuperAdmin ? true : approved === true ? true : false}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/standing-orders')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo uỷ nhiệm chi' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
