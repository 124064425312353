import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import FormView from './Form.view'
import { manageLabelsSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  product_id: undefined,
  customer_id: undefined,
  // supplier_id: [],
  price: undefined,
  quantity: 0,
  temporary_quantity: 0,
  suppliers: [],
  ingredient: '',
  info: '',
  pure_content: '',
  specification: '',
  guide: '',
  aws_object_keys: [],
  label_type: '',
  note: '',
}

export default React.memo((props) => {
  const { path, editId, duplicate } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    const successImages = values.aws_object_keys.filter(
      (image) => !!image.originFileObj.aws_object_key
    )
    const awsObjectKeys = successImages.map((key) => key.originFileObj.aws_object_key)
    const suppliersIds = values.suppliers.map((id) => ({ supplier_id: id }))

    const formattedParams = {
      ...values,
      aws_object_keys: awsObjectKeys,
      suppliers: suppliersIds,
    }

    if (path === 'new') {
      await createLabel(formattedParams)
    } else if (path === 'edit') {
      await updateLabel(formattedParams)
    }
  }

  const createLabel = async (params) => {
    const url = '/labels/'
    try {
      const resp = await axios.post(url, { ...params, action_type: 'user' })
      setSubmitting(false)
      history.push(`/labels/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updateLabel = async (params) => {
    const url = `/labels/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params, action_type: 'user' })

      setSubmitting(false)
      history.push(`/labels/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="label-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            duplicate={duplicate}
            isSubmitting={isSubmitting}
            initialValues={initialValues}
          />
        )}
        validationSchema={manageLabelsSchema}
      />
    </div>
  )
})
