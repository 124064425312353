import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import FormView from './Form.view'
import { manageSalaryCalculation } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const format = 'YYYY-MM-DD'
const initialValues = {
  staff_id: undefined,
  start_date: moment().subtract(1, 'months').startOf('month').format(format),
  end_date: moment().subtract(1, 'months').endOf('month').format(format),
  staff_type: '',
  salary: 0,
  total_hour: undefined,
  total_day: undefined,
  total_salary: 0,
  responsibility_amount: undefined,
  bonus_amount: undefined,
  total: 0,
  note: '',
}

export default React.memo((props) => {
  const { path, editId } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    const formattedParams = {
      ...values,
      responsibility_amount: values.responsibility_amount ? values.responsibility_amount : 0,
      total_hour: values.total_hour ? values.total_hour : 0,
    }

    if (path === 'new') {
      createSalary(formattedParams)
    } else if (path === 'edit') {
      updateSalary(formattedParams)
    }
  }

  const createSalary = async (params) => {
    const url = '/salary_calculation/'
    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/salary/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updateSalary = async (params) => {
    const url = `/salary_calculation/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setSubmitting(false)
      history.push(`/salary/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="salary-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={manageSalaryCalculation}
      />
    </div>
  )
})
