import React from 'react'
import { Typography } from 'antd'

import SalaryForm from '../Form'

const { Title } = Typography

export default () => {
  return (
    <div id="new_staffs_salary_page">
      <Title className="page-title">Tạo bảng lương mới</Title>
      <SalaryForm path={'new'} />
    </div>
  )
}
