import { useStoreState } from 'easy-peasy'
import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import FormView from './Form.view'
import { manageCustomersIfIsSuperAdmin, manageCustomersIfIsNotSuperAdmin } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  code: '',
  name: '',
  address: '',
  email: '',
  phone_number: '',
  country: undefined,
  payment_method: undefined,
  note: '',
}

export default React.memo((props) => {
  const { path, editId } = props
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    if (path === 'new') {
      createCustomer(values)
    } else if (path === 'edit') {
      updateCustomer(values)
    }
  }

  const createCustomer = async (params) => {
    const url = '/customers/'
    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/customers/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updateCustomer = async (params) => {
    const url = `/customers/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setSubmitting(false)
      history.push(`/customers/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const validationSchema = isSuperAdmin
    ? manageCustomersIfIsSuperAdmin
    : manageCustomersIfIsNotSuperAdmin

  return (
    <div className="customer-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSubmitting={isSubmitting}
            isSuperAdmin={isSuperAdmin}
            initialValues={initialValues}
          />
        )}
        validationSchema={validationSchema}
      />
    </div>
  )
})
