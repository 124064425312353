import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'
import PI from './ProformalInvoice'
import Producing from './Producing'
import Produced from './Produced'

import './index.scss'

const allowRoles = ['MO', 'PP', 'MOD']

const ListSaleOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewSaleOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewSaleOrder = (props) => (
  <CheckPermission rolesPermission={['MO']} component={New} {...props} />
)

const EditSaleOrder = (props) => (
  <CheckPermission rolesPermission={['MO']} component={Edit} {...props} />
)

const ProducingSaleOrder = (props) => (
  <CheckPermission rolesPermission={['MO', 'PP']} component={Producing} {...props} />
)
const ProducedSaleOrder = (props) => (
  <CheckPermission rolesPermission={['MO', 'PP']} component={Produced} {...props} />
)
// only Admin
const ProformalInvoice = (props) => <CheckPermission component={PI} {...props} />

export {
  ListSaleOrder,
  ViewSaleOrder,
  NewSaleOrder,
  EditSaleOrder,
  ProformalInvoice,
  ProducingSaleOrder,
  ProducedSaleOrder,
}
