const nameColorGenerator = (name) => {
  const colors = ['#364954', '#008957', '#364954', '#950000', '#002D53']

  const _name = name || 'Datafood User'
  const _nameArr = _name.split(' ')
  const nameLetter = _nameArr.length === 1 ? _nameArr[0][0] : _nameArr[_nameArr.length - 1][0]

  const numb = nameLetter.charCodeAt()
  return colors[numb % 5]
}

const purchaseOrderStatusColorGenerator = (status) => {
  const colors = ['orange', 'red', 'geekblue', 'green', 'volcano']

  //  Options : pending (Đang xác nhận) - submitted (Đã gửi NCC) - in stock (Đã nhập hàng) - completed (Hoàn thành)

  switch (status) {
    case 'pending':
      return colors[0]
    case 'submitted':
      return colors[1]
    case 'in_stock':
      return colors[2]
    case 'completed':
      return colors[3]
    default:
      return colors[4]
  }
}

//const SALE_ORDER_STATUS = ['drafting' 'pending_pi' 'producing' 'produced' 'exporting' 'completed']
const saleOrderStatusColorGenerator = (status) => {
  const colors = ['red', 'orange', 'geekblue', 'cyan', 'volcano', 'green']

  switch (status) {
    case 'drafting':
      return colors[0]
    case 'pending_pi':
      return colors[1]
    case 'producing':
      return colors[2]
    case 'produced':
      return colors[3]
    case 'exporting':
      return colors[4]
    case 'completed':
      return colors[5]
    default:
      return colors[5]
  }
}

export { nameColorGenerator, purchaseOrderStatusColorGenerator, saleOrderStatusColorGenerator }
