import React, { useEffect } from 'react'
import { Select, Spin, Alert } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { isObject, isString, get } from 'lodash'

import { useDebounce } from 'Hooks'
import { noData } from 'Components'
import { removeVietnameseTones } from 'Utils'

const { Option } = Select

const renderOptionLabel = (item, params) => {
  if (!params) return null
  if (isString(params)) return item[params]

  if (isObject(params) && isString(params.prefix) && isString(params.suffix)) {
    const prefix = get(item, params.prefix, 'N/A')
    const suffix = get(item, params.suffix, 'N/A')
    return `${prefix}: ${suffix}`
  }

  return null
}

export const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

export const SearchField = React.memo((props) => {
  const {
    axios,
    extraParams = {},
    perPage,
    onChange,
    value,
    disabled,
    placeholder,
    url,
    optionParams = {},
    style = {},
    className,
    searchTerm,
    setSearchTerm,
    onSearch,
    ...rest
  } = props

  const debounce = useDebounce(searchTerm.keyword, 500)

  useEffect(() => {
    let didCancel = false

    if (!url) return

    if (debounce.length && searchTerm.keyword === debounce) {
      ;(async () => {
        try {
          const resp = await axios.get(url, {
            params: {
              ...extraParams,
              keyword: debounce,
              per_page: perPage || 20,
            },
          })
          const result = get(resp, 'data.records', [])
          if (!didCancel) {
            setSearchTerm((prev) => ({
              ...prev,
              result,
              searching: false,
            }))
          }
        } catch (error) {
          if (!didCancel) {
            setSearchTerm((prev) => ({ ...prev, searching: false }))
          }
        }
      })()
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, searchTerm.keyword])

  const onSelect = (value, option) => {
    onChange(value, option)
  }

  const dropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  return (
    <Select
      style={{ width: '100%', ...style }}
      className={className}
      showSearch={true}
      suffixIcon={<SearchOutlined />}
      notFoundContent={dropDownCondition}
      placeholder={placeholder}
      onSearch={onSearch}
      value={value}
      onChange={onSelect}
      optionFilterProp="children"
      loading={searchTerm.searching}
      disabled={disabled}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterOption={(input, option) => {
        const dataInput = removeVietnameseTones(input)
        const dataOption = removeVietnameseTones(option.children)
        return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
      }}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ maxWidth: 650 }}
      {...rest}
    >
      {searchTerm.result.map((item, index) => (
        <Option
          key={index}
          value={optionParams.value ? item[optionParams.value] : null}
          item={item}
        >
          {renderOptionLabel(item, optionParams.label)}
        </Option>
      ))}
    </Select>
  )
})
