import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'

const manageTimeAttendenceSchema = yup.object().shape({
  staff_id: yup.number().required(requiredField).max(255, maxLength),
  date: yup.date().required(requiredField),
})

export default manageTimeAttendenceSchema
