import React from 'react'

import ProductsHistoryForm from './Form'
import ProductsHistoryTable from './Table'
import useDataApi from './hooks'
import './index.scss'

const ProductsHistory = React.memo(() => {
  const { firstRender, data, loading, searchTerm, pagination, errors, doFetch, setSearchTerm } =
    useDataApi({
      url: '/sale_orders/get-sale-order-items-price-history/',
      key: 'records',
    })

  const handleFormSubmit = (values) => {
    doFetch((prev) => ({
      ...prev,
      ...values,
      page: 1,
      per_page: 10,
      order_by: '',
      order: '',
    }))
  }

  return (
    <div id="products_history_page">
      <ProductsHistoryForm
        handleFormSubmit={handleFormSubmit}
        serverError={errors}
        loading={loading}
        firstRender={firstRender}
      />
      <ProductsHistoryTable
        data={data}
        loading={loading}
        pagination={pagination}
        doFetch={doFetch}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        firstRender={firstRender}
      />
    </div>
  )
})

export default ProductsHistory
