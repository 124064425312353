import React from "react";

import List from "./List";
import View from "./View";
import New from "./New";
import Edit from "./Edit";
import { CheckPermission } from "Components";

import "./index.scss";

// Only superadmin
const ListUser = (props) => {
  return <CheckPermission component={List} {...props} />;
};

const ViewUser = (props) => {
  return <CheckPermission component={View} {...props} />;
};
const NewUser = (props) => {
  return <CheckPermission component={New} {...props} />;
};
const EditUser = (props) => {
  return <CheckPermission component={Edit} {...props} />;
};

export { ListUser, ViewUser, NewUser, EditUser };
