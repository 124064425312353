import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'

import axiosExpand from 'Utils/axiosExpand'
import { formatNumber, roundUpDecimal, formatToNumber } from 'Utils'
import axios from 'Utils/axiosRexyAdmin'
import { DocumentsTable } from 'Components'

const { Title } = Typography

const INITIAL_PROFILE = {
  export_number: '',
  date: '',
  customer_id: undefined,
  note: '',
  customer: { id: '', code: '', name: '' },
  export_only_one_sale_order: false,
  sale_order_number_in_paper: '',
  commodity_export_items: [
    {
      sale_order_id: undefined,
      sale_order_item_id: undefined,
      quantity: undefined,
      specification: '',
      box_size: undefined,
      order_number: '',
      product: {
        id: undefined,
        code: '',
        name: '',
      },
    },
  ],
  container_type_1: { amount: 0, container_no: '', seal_no: '' },
  container_type_2: { amount: 0, container_no: '', seal_no: '' },
  container_type_3: { amount: 0, container_no: '', seal_no: '' },
  to: '',
  packing_date: '',
  depart_date: '',
  arrival_date: '',
}

export default React.memo((props) => {
  const history = useHistory()
  const id = props.match?.params.id
  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })
  const [totalContainerCubic, setTotalContainerCubic] = useState(0)
  const [presentCubic, setPresentCubic] = useState(0)
  const [totalData, setTotalData] = useState({
    total_quantity: 0,
    total_size: 0,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/commodity_exports/${id}/edit?is_drafting=false`

      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          const dataComputed = await computeData(resp.data)

          const calTotal = () => {
            let total_quantity = 0
            let total_size = 0

            dataComputed.commodity_export_items.forEach((item) => {
              total_quantity += formatToNumber(item.quantity)
              total_size += formatToNumber(item.box_size) * formatToNumber(item.quantity)
            })

            setTotalData({ total_quantity, total_size })
          }

          calTotal()

          setState((prev) => ({
            ...prev,
            data: {
              ...dataComputed,
              date: moment(resp.data.date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
              packing_date: moment(resp.data.packing_date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
              depart_date: moment(resp.data.depart_date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
              arrival_date: moment(resp.data.arrival_date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
            },
            loading: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    if (id) fetchData()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const computeData = async (response) => {
    const url = `/sale_orders/form-in-commodity-export/?customer_id=${response.customer_id}&current_sale_order_id=${response.sale_order_in_paper_id}&current_commodity_export_id=${id}&is_drafting=false`

    let result
    try {
      result = await axiosExpand.get(url)
    } catch (error) {}

    let cloneData = cloneDeep(response)

    const formatedExportItems = cloneData.commodity_export_items.map((item) => {
      const filteredOrder = result.data.records.filter((record) => record.id === item.sale_order_id)

      let filteredOrderItem = [
        { order_number: undefined, specification: '', box_size: 0, product: {} },
      ]
      if (filteredOrder.length) {
        filteredOrderItem = filteredOrder[0].sale_order_items.filter(
          (record) => item.sale_order_item_id === record.id
        )
      }

      // TODO: HERE
      let purchase_orders = []

      if (item?.purchase_orders && isArray(item?.purchase_orders)) {
        purchase_orders = item.purchase_orders
          .map((purchase) => purchase?.order_number)
          .filter((purchase) => !!purchase)
      }

      return {
        ...item,
        purchase_orders: purchase_orders.join(', '),
        order_number: filteredOrder[0]?.order_number,
        specification: filteredOrderItem[0].specification,
        box_size: Number(filteredOrderItem[0].box_size),
        product: filteredOrderItem[0].product,
      }
    })

    let filteredOrderNumberInPaper = ''

    if (response.export_only_one_sale_order) {
      const filtered = result.data.records.filter((record) => record.id === response.sale_order_id)
      filteredOrderNumberInPaper = filtered[0].order_number
    } else {
      const filtered = result.data.records.filter(
        (record) => record.id === response.sale_order_in_paper_id
      )

      filteredOrderNumberInPaper = filtered[0].order_number
    }

    let presentCubicCalculation = 0

    formatedExportItems.forEach((item) => {
      return (presentCubicCalculation += Number(Number(item.box_size) * item.quantity))
    })

    setPresentCubic(Number(presentCubicCalculation))

    const sum =
      28.5 * cloneData.container_type_1.amount +
      58.5 * cloneData.container_type_2.amount +
      66.5 * cloneData.container_type_3.amount
    const formatted = Number(Number(sum).toFixed(2))
    setTotalContainerCubic(formatted)

    cloneData = {
      ...cloneData,
      commodity_export_items: formatedExportItems,
      sale_order_number_in_paper: filteredOrderNumberInPaper,
    }
    return cloneData
  }

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/commodity-exports`)
  }

  const handleEdit = () => {
    history.push(`/commodity-exports/${id}/edit`)
  }

  const generateSuggestion = () => {
    if (!totalContainerCubic || !presentCubic) {
      return ''
    } else if (totalContainerCubic > presentCubic) {
      return (
        <span style={{ color: 'green' }}>
          {`Thiếu ${(totalContainerCubic - presentCubic).toFixed(2)} m3`}
        </span>
      )
    } else if (totalContainerCubic < presentCubic) {
      return (
        <span style={{ color: 'red' }}>{`Thừa ${(presentCubic - totalContainerCubic).toFixed(
          2
        )} m3`}</span>
      )
    } else return ''
  }

  return (
    <div id="view_commodity_export" className="table-container">
      <Title className="page-title">Thông tin đơn xuất hàng</Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">Thông tin chung</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã xuất hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.export_number}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã khách hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.customer.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên khách hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.customer.name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ngày xuất hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.date}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ngày dự kiến đóng hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.packing_date}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ngày dự kiến tàu chạy
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.depart_date}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ngày dự kiến tàu đến
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.arrival_date}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  To
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.to}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Vessel name
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.vessel_name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Bill of lading no
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.bill_of_lading_no}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Invoice number
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.invoice_number}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú giao hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Order No trên chứng từ
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.sale_order_number_in_paper}
                </Col>
              </Row>
            </Card.Grid>

            <div className="commodity-export-table">
              <Title className="commodity-export-table-title">Thông tin xuất hàng</Title>
              <Col className="commodity-export-table-content">
                <div className="inner-content">
                  <div className="scroll-wrap">
                    <div className="row-wrapper">
                      <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                        STT
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 200 }}>
                        Order No
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 185 }}>
                        Mã sản phẩm
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 300 }}>
                        Tên sản phẩm
                      </div>
                      <div className="row-item thread-row">Đơn đặt hàng NCC</div>
                      <div className="row-item thread-row" style={{ maxWidth: 185 }}>
                        Packing
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 185 }}>
                        Cubic (m3/ctn)
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 185 }}>
                        Số lượng(thùng)
                      </div>
                      <div className="last-child thread-row" style={{ maxWidth: 185 }}>
                        Total Cubic (m3)
                      </div>
                    </div>

                    <div className="scroll-vertical-table">
                      {state.data.commodity_export_items.map((item, index) => {
                        return (
                          <div key={index} className="row-wrapper">
                            <div className="row-item" style={{ maxWidth: 60 }}>
                              {index + 1}
                            </div>
                            <div className="row-item" style={{ maxWidth: 200 }}>
                              {item.order_number}
                            </div>
                            <div className="row-item" style={{ maxWidth: 185 }}>
                              {item.product.code}
                            </div>
                            <div className="row-item" style={{ maxWidth: 300 }}>
                              {item.product.name}
                            </div>
                            <div className="row-item ">{item.purchase_orders}</div>
                            <div className="row-item" style={{ maxWidth: 185 }}>
                              {item.specification}
                            </div>
                            <div className="row-item" style={{ maxWidth: 185 }}>
                              {item.box_size ? formatNumber(item.box_size) : '-'}
                            </div>
                            <div className="row-item" style={{ maxWidth: 185 }}>
                              {item.quantity ? formatNumber(item.quantity) : '-'}
                            </div>
                            <div className="last-child" style={{ maxWidth: 185 }}>
                              {item.box_size
                                ? roundUpDecimal(Number(item.box_size) * Number(item.quantity))
                                : '-'}
                            </div>
                          </div>
                        )
                      })}
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item thread-row" style={{ maxWidth: 60 }} />
                      <div className="row-item thread-row" style={{ maxWidth: 200 }} />
                      <div className="row-item thread-row" style={{ maxWidth: 185 }} />
                      <div className="row-item thread-row" style={{ maxWidth: 300 }} />
                      <div className="row-item thread-row" />
                      <div className="row-item thread-row" style={{ maxWidth: 185 }} />
                      <div className="row-item thread-row" style={{ maxWidth: 185 }} />
                      <div className="row-item thread-row" style={{ maxWidth: 185 }}>
                        {totalData.total_quantity ? formatNumber(totalData.total_quantity) : '-'}
                      </div>
                      <div className="last-child thread-row" style={{ maxWidth: 185 }}>
                        {totalData.total_size ? formatNumber(totalData.total_size) : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>

            <div className="container-wrapper" style={{ flexDirection: 'column' }}>
              <div className="container-types">
                <Title className="order-table-title">Đóng hàng vào container</Title>
                <Col className="order-table-content">
                  <div className="inner-content">
                    <div className="scroll-wrap">
                      <div className="row-wrapper">
                        <div className="row-item thread-row">Loại container</div>
                        <div className="row-item thread-row">Cubic (m3)</div>
                        <div className="row-item thread-row">Seal No.</div>
                        <div className="row-item thread-row">Container No.</div>
                        <div className="last-child thread-row">Số lượng</div>
                      </div>

                      <div className="row-wrapper">
                        <div className="row-item">{`(1) Container 20'DC`}</div>
                        <div className="row-item">28.50 m3</div>
                        <div className="row-item">
                          {state.data.container_type_1.seal_no
                            ? state.data.container_type_1.seal_no
                            : '-'}
                        </div>
                        <div className="row-item">
                          {state.data.container_type_1.container_no
                            ? state.data.container_type_1.container_no
                            : '-'}
                        </div>
                        <div className="last-child">
                          {state.data.container_type_1.amount
                            ? formatNumber(state.data.container_type_1.amount)
                            : '-'}
                        </div>
                      </div>

                      <div className="row-wrapper">
                        <div className="row-item">{`(2) Container 40'DC`}</div>
                        <div className="row-item">58.50 m3</div>
                        <div className="row-item">
                          {state.data.container_type_2.seal_no
                            ? state.data.container_type_2.seal_no
                            : '-'}
                        </div>
                        <div className="row-item">
                          {state.data.container_type_2.container_no
                            ? state.data.container_type_2.container_no
                            : '-'}
                        </div>
                        <div className="last-child">
                          {state.data.container_type_2.amount
                            ? formatNumber(state.data.container_type_2.amount)
                            : '-'}
                        </div>
                      </div>

                      <div className="row-wrapper">
                        <div className="row-item">{`(3) Container 40'HC`}</div>
                        <div className="row-item">66.50 m3</div>
                        <div className="row-item">
                          {state.data.container_type_3.seal_no
                            ? state.data.container_type_3.seal_no
                            : '-'}
                        </div>
                        <div className="row-item">
                          {state.data.container_type_3.container_no
                            ? state.data.container_type_3.container_no
                            : '-'}
                        </div>
                        <div className="last-child">
                          {state.data.container_type_3.amount
                            ? formatNumber(state.data.container_type_3.amount)
                            : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>

            <div className="container-information">
              <div className="item">
                <Title className="field-title">Tổng số khối:</Title>
                <span className="content">
                  {totalContainerCubic ? `${totalContainerCubic} m3` : '- m3'}
                </span>
              </div>

              <div className="item">
                <Title className="field-title">Hiện tại:</Title>
                <span className="content">
                  {presentCubic ? `${roundUpDecimal(presentCubic)} m3` : '- m3'}
                </span>
              </div>

              <div className="item">
                <Title className="field-title">Gợi ý:</Title>
                <span className="content">{generateSuggestion()}</span>
              </div>
            </div>

            <div className="container-wrapper" style={{ maxWidth: '600' }}>
              <div className="container-types">
                {/* <DocumentsTable
                  data={state.data}
                  viewId={state.data.sale_order_in_paper_id}
                  documentsFor="commodityExport"
                /> */}
                <DocumentsTable
                  documentsFor="commodityExport"
                  commodityExportId={state.data.id}
                  exportNumber={state.data.export_number}
                  customerCountry={state.data.customer.country}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>

          <Button
            icon={<FormOutlined />}
            type="primary"
            loading={state.loading}
            className="process-button"
            onClick={handleEdit}
          >
            Chỉnh sửa
          </Button>
        </div>
      </div>
    </div>
  )
})
