import { MinusCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Input, InputNumber, Radio, Select, Typography } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { ErrorMessage, Field, FieldArray } from 'formik'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import {
  formatNumber,
  formatInputNumber,
  numToWord,
  removeVietnameseTones,
  scrollToBottomAdd,
} from 'Utils'
import './custom.scss'

const { TextArea } = Input
const { Option } = Select
const { Title } = Typography

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const OrderItems = React.memo((props) => {
  const { values, loadingCondition, setFieldValue } = props
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)
  const [focusingRow, setFocus] = useState(undefined)

  useEffect(() => {
    let didCancel = false
    let subtotal = values.sub_total || 0
    let vat = values.vat || 0

    const calTotal = () => {
      if (typeof subtotal !== 'number' || typeof vat !== 'number') {
        return
      } else {
        const total = Number(subtotal) + Number(vat)
        const formatted = Number(total.toFixed(2))
        setFieldValue('total', Math.round(formatted))
      }
    }

    if (!didCancel && !first) calTotal()

    return () => {
      didCancel = true
      setFirst(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, values.sub_total, values.vat_percentage, values.vat])

  useEffect(() => {
    let didCancel = false

    const calSubtotal = () => {
      let subtotal = 0
      const vatPercentage = values.vat_percentage / 100
      values.purchase_order_items.forEach((item) => {
        subtotal += item.total
      })
      setFieldValue('sub_total', Math.floor(subtotal))
      setFieldValue('vat', Number((subtotal * vatPercentage).toFixed(2)))
    }

    if (!didCancel && !second) calSubtotal()

    return () => {
      didCancel = true
      setSecond(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.purchase_order_items.length,
    values.purchase_order_items,
    second,
    values.vat_percentage,
  ])

  return (
    <FieldArray
      name="purchase_order_items"
      render={(arrayHelpers) => (
        <div>
          <Col className="container order-table-content">
            <div className="inner-content">
              <div className="row-wrapper fixed-scroll-header">
                <div style={{ maxWidth: 50 }} className="row-item thread-row">
                  STT
                </div>
                <div className="row-item thread-row">Tên hàng</div>
                <div style={{ maxWidth: 140 }} className="row-item thread-row">
                  Quy cách
                </div>
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  Đơn vị
                </div>
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  Đơn giá
                </div>
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  Số lượng {values.order_type === 'finish_product' && `(thùng)`}
                </div>
                <div style={{ maxWidth: 160 }} className="row-item thread-row">
                  Thành tiền
                </div>
                {values.sale_order_id ? (
                  <div style={{ maxWidth: 160 }} className="row-item thread-row">
                    Ghi chú
                  </div>
                ) : (
                  <>
                    <div style={{ maxWidth: 160 }} className="row-item thread-row">
                      Ghi chú
                    </div>
                    <div style={{ maxWidth: 100 }} className="row-item thread-row">
                      Actions
                    </div>
                  </>
                )}
              </div>

              {/* Order Items render */}
              {values.purchase_order_items.map((item, index) => (
                <OrderItem
                  {...arrayHelpers}
                  {...props}
                  key={index}
                  {...item}
                  index={index}
                  focusingRow={focusingRow}
                  setFocus={setFocus}
                />
              ))}

              <div className="row-wrapper">
                <div style={{ maxWidth: 50 }} className="row-item thread-row" />
                <div className="row-item thread-row" />
                <div style={{ maxWidth: 140 }} className="row-item thread-row" />
                <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                <div style={{ maxWidth: 130 }} className="row-item thread-row" />
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  Tổng cộng
                </div>
                <div style={{ maxWidth: 160 }} className="row-item thread-row">
                  {values.sub_total ? formatNumber(values.sub_total) : '-'}
                </div>
                {values.sale_order_id ? (
                  <div style={{ maxWidth: 160 }} className="row-item thread-row" />
                ) : (
                  <>
                    <div style={{ maxWidth: 160 }} className="row-item thread-row" />
                    <div style={{ maxWidth: 100 }} className="row-item thread-row" />
                  </>
                )}
              </div>

              <div className="row-wrapper">
                <div style={{ maxWidth: 50 }} className="row-item thread-row" />
                <div className="row-item thread-row" />
                <div style={{ maxWidth: 140 }} className="row-item thread-row" />
                <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                <div style={{ maxWidth: 130 }} className="row-item thread-row" />
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  VAT
                </div>
                <div style={{ maxWidth: 160 }} className="row-item thread-row">
                  {values.vat ? formatNumber(values.vat) : '-'}
                </div>
                {values.sale_order_id ? (
                  <div style={{ maxWidth: 160 }} className="row-item thread-row" />
                ) : (
                  <>
                    <div style={{ maxWidth: 160 }} className="row-item thread-row" />
                    <div style={{ maxWidth: 100 }} className="row-item thread-row" />
                  </>
                )}
              </div>
            </div>
          </Col>

          {values.sale_order_id ? null : (
            <div className="button-wrapper">
              <Button
                icon={<PlusOutlined />}
                type="primary"
                className="add-more-button"
                disabled={loadingCondition}
                onClick={() => {
                  arrayHelpers.push({
                    code: '',
                    name: '',
                    label_id: undefined,
                    product_id: undefined,
                    material_id: undefined,
                    unit: '',
                    readable_unit: '',
                    material: {},
                    product: {},
                    price: 0,
                    specification: '',
                    quantity: 1,
                    initQuantity: 0,
                    total: 0,
                    note: '',
                    info: '',
                  })
                  scrollToBottomAdd(
                    `scroll-to-bottom-add${focusingRow === undefined ? 1 : focusingRow + 1}`,
                    'container'
                  )
                  setFocus(focusingRow === undefined ? 1 : focusingRow + 1)
                }}
              />
            </div>
          )}

          <div className="total-wrapper">
            <div className="total-number">
              <Title className="total-number-title">Thành tiền:</Title>
              <span className="total-number-content">
                {values.total ? formatNumber(Math.round(values.total)) : '-'}
              </span>
            </div>
            <div className="total-number">
              <Title className="total-number-title">Bằng chữ:</Title>
              <span className="total-number-content">
                {values.total ? numToWord(Math.round(values.total)) : '-'}
              </span>
            </div>
          </div>
        </div>
      )}
    />
  )
})

const OrderItem = React.memo((props) => {
  const { values } = props
  return values.sale_order_id ? (
    <OrderItemWithOrderId {...props} />
  ) : (
    <OrderItemWithNoOrderId {...props} />
  )
})

const OrderItemWithNoOrderId = React.memo((props) => {
  const {
    // editId,
    values,
    loadingCondition,
    setFieldValue,
    remove,
    form,
    index,
    itemOptions,
    focusingRow,
    setFocus,
  } = props
  const onResultSelect = (index, val, option) => {
    if (values.order_type === 'material' || values.order_type === 'packing') {
      setFieldValue(`purchase_order_items[${index}].material_id`, val)
    } else if (values.order_type === 'finish_product') {
      setFieldValue(`purchase_order_items[${index}].product_id`, val)
    }
    setFieldValue(`purchase_order_items[${index}].code`, option.attr.code)
    setFieldValue(`purchase_order_items[${index}].name`, option.attr.name)
    setFieldValue(`purchase_order_items[${index}].price`, Number(option.attr.price))
    setFieldValue(`purchase_order_items[${index}].unit`, option.attr.unit)
    setFieldValue(`purchase_order_items[${index}].readable_unit`, option.attr.readable_unit)
    setFieldValue(`purchase_order_items[${index}].specification`, option.attr.specification)
    setFieldValue(`purchase_order_items[${index}].label_type`, option.attr.label_type)
    const itemTotal = Number(
      Math.floor(Number(option.attr.price) * values.purchase_order_items[index].quantity)
    )
    setFieldValue(`purchase_order_items[${index}].total`, itemTotal)
  }

  const handleNumberChange = (index, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(`purchase_order_items[${index}].quantity`, 1)
    } else {
      const roundDown = value
      setFieldValue(`purchase_order_items[${index}].quantity`, roundDown)
      const itemTotal = Math.floor(Number(values.purchase_order_items[index].price * roundDown))
      setFieldValue(`purchase_order_items[${index}].total`, itemTotal)
    }
  }

  const handleRowColor = () => {
    if (focusingRow === index) return
    setFocus(index)
  }

  return (
    <div
      className={`row-wrapper  scroll-to-bottom-add${index} ${
        focusingRow === index ? 'is-focusing' : ''
      }`}
    >
      <div style={{ maxWidth: 50 }} className="row-item">
        {index + 1}
      </div>
      <div style={{ minWidth: 230 }} className="row-item">
        <Field
          name={
            values.order_type === 'material' || values.order_type === 'packing'
              ? `purchase_order_items[${index}].material_id`
              : `purchase_order_items[${index}].product_id`
          }
          disabled={loadingCondition}
        >
          {() => (
            <Select
              name={
                values.order_type === 'material' || values.order_type === 'packing'
                  ? `purchase_order_items[${index}].material_id`
                  : `purchase_order_items[${index}].product_id`
              }
              style={{ width: '100%' }}
              showSearch={true}
              suffixIcon={<SearchOutlined />}
              placeholder={`Chọn  ${
                values.order_type === 'material'
                  ? 'nguyên vật liệu'
                  : values.order_type === 'packing'
                  ? 'bao bì'
                  : 'thành phẩm'
              }`}
              // onSearch={onSearch}
              value={`${
                values.purchase_order_items[index].code &&
                `${values.purchase_order_items[index].code + `:`}`
              } ${values.purchase_order_items[index].name}`}
              onChange={(val, option) => onResultSelect(index, val, option)}
              optionFilterProp="children"
              // notFoundContent={searchDropDownCondition}
              //   loading={searchTerm.searching}

              disabled={loadingCondition}
              filterOption={(input, option) => {
                const dataInput = removeVietnameseTones(input)
                const dataOption = removeVietnameseTones(option.children)
                return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
              }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              onFocus={handleRowColor}
            >
              {itemOptions.map((item) => (
                <Option key={item.id} value={item.id} attr={item}>
                  {`${item.code && item.code}: ${item.name && item.name}`}
                </Option>
              ))}
            </Select>
          )}
        </Field>
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`purchase_order_items[${index}].material_id`}
        />
      </div>

      <div style={{ maxWidth: 140 }} className="row-item">
        {values.purchase_order_items[index].specification
          ? values.purchase_order_items[index].specification
          : '-'}
      </div>

      <div style={{ maxWidth: 120 }} className="row-item">
        {values.purchase_order_items[index].readable_unit
          ? values.purchase_order_items[index].readable_unit
          : '-'}
      </div>

      <div style={{ maxWidth: 130 }} className="row-item">
        {values.purchase_order_items[index].price
          ? formatNumber(Number(values.purchase_order_items[index].price))
          : '-'}
      </div>

      <div style={{ maxWidth: 130 }} className="row-item">
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`purchase_order_items[${index}].quantity`}
          placeholder="Nhập số lượng"
          disabled={loadingCondition}
          value={values.purchase_order_items[index].quantity}
          onChange={(value) => handleNumberChange(index, 100000000, value)}
          formatter={(value) => formatInputNumber(value)}
          min={1}
          max={100000000}
          onFocus={handleRowColor}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`purchase_order_items[${index}].quantity`}
        />
      </div>

      <div style={{ maxWidth: 160 }} className="row-item">
        {values.purchase_order_items[index].total
          ? formatNumber(values.purchase_order_items[index].total)
          : '-'}
      </div>

      <div style={{ maxWidth: 160 }} className="row-item">
        <Field
          as={TextArea}
          name={`purchase_order_items[${index}].note`}
          placeholder="Ghi chú..."
          disabled={loadingCondition}
          value={values.purchase_order_items[index].note}
          onChange={form.handleChange}
          autoSize={{ minRows: 1, maxRows: 3 }}
          onFocus={handleRowColor}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`purchase_order_items[${index}].note`}
        />
      </div>

      <div style={{ maxWidth: 100 }} className="row-item">
        {values.purchase_order_items.length > 1 ? (
          <Button type="link" danger icon={<MinusCircleOutlined />} onClick={() => remove(index)} />
        ) : null}
      </div>
    </div>
  )
})

const OrderItemWithOrderId = React.memo((props) => {
  const { values, index, setFieldValue, path, itemOrder } = props
  const { id, quantity_in_stock, action } = useStoreState((state) => state.purchaseOrder)
  const [quanityMax, setQuanityMax] = useState(0)
  const {
    product_id,
    material_id,
    material,
    price,
    specification,
    readable_unit,
    quantity,
    initQuantity,
    total,
    name,
    code,
    // product,
  } = values.purchase_order_items[index]

  const quantity_in_stock_new = get(values, `items_in_stock[${index}].quantity_in_stock`)
  const action_state = get(values, `items_in_stock[${index}].action`)
  const quantity_in_stock_default = get(itemOrder, `[${index}].quantity`)

  useEffect(() => {
    let didCancel = false
    const calculateQuantity = () => {
      let calQuantity = 0
      //Set values not-use or use
      if (quantity_in_stock <= initQuantity) {
        calQuantity = action === 'use' ? initQuantity - quantity_in_stock : initQuantity
      } else {
        calQuantity = action === 'use' ? 0 : initQuantity
      }
      const roundDown = calQuantity
      setFieldValue(`purchase_order_items[${index}].quantity`, roundDown)
      const itemTotal = Math.floor(Number(price * roundDown))
      setFieldValue(`purchase_order_items[${index}].total`, itemTotal)
    }
    if (!didCancel && material_id === id) {
      calculateQuantity()
    }
    if (!didCancel && product_id === id) {
      calculateQuantity()
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, product_id, material_id, quantity_in_stock, action])
  useEffect(() => {
    // let didCancel = false
    const calculateQuantityMax = () => {
      const quanity = initQuantity - quantity_in_stock_new
      let calQuantity = 0
      if (path === 'new') {
        calQuantity =
          action === 'use'
            ? quantity === 0 && quanity < 0
              ? quantity
              : initQuantity - quantity_in_stock_new
            : initQuantity
      } else {
        if (action_state === 'not-use') {
          calQuantity = quantity_in_stock_default
        } else {
          calQuantity =
            quantity === 0 && quanity < 0 ? quantity : initQuantity - quantity_in_stock_new
        }
      }

      setQuanityMax(calQuantity)
    }
    calculateQuantityMax()

    // return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quanityMax, quantity])

  // round item total when load data
  useEffect(() => {
    setFieldValue(`purchase_order_items[${index}].total`, Math.round(total))
  }, [total, index, setFieldValue])

  const handleNumberChange = (index, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit || value <= 0) {
      setFieldValue(`purchase_order_items[${index}].quantity`, 0)
      setFieldValue(`purchase_order_items[${index}].total`, 0)
      return
    } else if (!value) {
      setFieldValue(`purchase_order_items[${index}].quantity`, 0)
      return
    } else {
      setFieldValue(`purchase_order_items[${index}].quantity`, value)
      const itemTotal = Math.floor(Number(values.purchase_order_items[index].price * value))
      setFieldValue(`purchase_order_items[${index}].total`, itemTotal)
    }
  }

  const handleNoteChange = (e) => {
    setFieldValue(`purchase_order_items[${index}].note`, e.target.value)
  }

  return (
    <div className="row-wrapper">
      <div style={{ maxWidth: 50 }} className="row-item">
        {index + 1}
      </div>
      <div style={{ minWidth: 230 }} className="row-item" attr={material_id}>
        {values.order_type === 'finish_product'
          ? `${code}: ${name}`
          : `${material?.code ? `${material?.code + ':'}` : '-'} ${
              material?.name ? material?.name : ''
            }`}
      </div>

      <div style={{ maxWidth: 140 }} className="row-item">
        {specification ? specification : '-'}
      </div>

      <div style={{ maxWidth: 120 }} className="row-item">
        {readable_unit ? readable_unit : '-'}
      </div>

      <div style={{ maxWidth: 130 }} className="row-item">
        {price ? formatNumber(Number(price)) : '-'}
      </div>

      <div style={{ maxWidth: 130 }} className="row-item">
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`purchase_order_items[${index}].quantity`}
          placeholder="Nhập số lượng"
          // disabled={loadingCondition}
          value={values.purchase_order_items[index].quantity}
          onChange={(value) => handleNumberChange(index, quanityMax, value)}
          formatter={(value) => formatInputNumber(value)}
          min={0}
          max={Number(quanityMax)}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`purchase_order_items[${index}].quantity`}
        />
      </div>

      <div style={{ maxWidth: 160 }} className="row-item">
        {total ? formatNumber(total) : '-'}
      </div>

      <div style={{ maxWidth: 160 }} className="row-item">
        <Field
          as={TextArea}
          name={`purchase_order_items[${index}].note`}
          placeholder="Ghi chú..."
          value={values.purchase_order_items[index].note}
          onChange={handleNoteChange}
          autoSize={{ minRows: 1, maxRows: 3 }}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`purchase_order_items[${index}].note`}
        />
      </div>
    </div>
  )
})

const MaterialInStock = React.memo((props) => {
  const { itemsInStock } = props

  return (
    <FieldArray
      name="items_in_stock"
      render={(arrayHelpers) => (
        <Col className="container-material material-in-stock-table-content">
          <div className="inner-material-content">
            <div className="row-wrapper">
              <div style={{ maxWidth: 120 }} className="row-item thread-row">
                Mã hàng
              </div>
              <div style={{ maxWidth: 160 }} className="row-item thread-row">
                Tên hàng
              </div>
              <div style={{ maxWidth: 150 }} className="row-item thread-row">
                Tồn kho
              </div>
              <div className="row-item thread-row" />
            </div>
            <div className="scroll-vertical-table">
              {itemsInStock.map((item, index) => {
                return (
                  <MaterialInStockItem
                    key={index}
                    {...arrayHelpers}
                    {...props}
                    {...item}
                    index={index}
                  />
                )
              })}
            </div>
          </div>
        </Col>
      )}
    />
  )
})

const MaterialInStockItem = React.memo((props) => {
  const {
    id,
    quantity,
    action,
    usingQuantity,
    code,
    name,
    index,
    setFieldValue,
    loadingCondition,
    loading,
    orderType,
    product,
  } = props

  if (!quantity && action === 'not-use') return null

  const toggleMaterialStatus = useStoreActions(
    (actions) => actions.purchaseOrder.toggleMaterialStatus
  )

  const [initStatus, setInitStatus] = useState(action)

  const statusColorGenerate = (action) => {
    switch (action) {
      case 'use':
        return '#148d1a'
      case 'not-use':
        return '#097b9e'
      // case 'delete':
      //   return '#ec2020'

      default:
        return
    }
  }

  const handleActions = (event) => {
    setInitStatus(event.target.value)

    // action === 'use' ? quantity - quantity_in_stock : quantity + quantity_in_stock
    setFieldValue(`items_in_stock[${index}].action`, event.target.value)
    let quantityToSet = 0

    if (initStatus === 'use') {
      if (event.target.value === 'not-use') {
        setFieldValue(`items_in_stock[${index}].quantity`, quantity + usingQuantity)
        quantityToSet = usingQuantity
      }
    }

    if (initStatus === 'not-use') {
      if (event.target.value === 'use') {
        setFieldValue(`items_in_stock[${index}].quantity`, quantity - usingQuantity)
        quantityToSet = quantity
      }
    }

    // if (initStatus === 'delete') {
    //   if (event.target.value === 'use') {
    //     setFieldValue(`items_in_stock[${index}].quantity`, quantity - usingQuantity)
    //     quantityToSet = quantity
    //   }
    //   if (event.target.value === 'not-use') quantityToSet = 0
    // }

    toggleMaterialStatus({
      id,
      quantity_in_stock: quantityToSet,
      action: event.target.value,
    })
  }

  const orderTypes = ['material', 'packing', 'finish_product']

  return (
    <div className="row-wrapper">
      <div style={{ maxWidth: 120 }} className="row-item">
        {orderTypes.includes(orderType) ? (code ? code : '-') : ''}
        {orderType === 'label' ? (product.code ? product.code : '-') : ''}
      </div>
      <div style={{ maxWidth: 160 }} className="row-item">
        {orderTypes.includes(orderType) ? (name ? name : '-') : ''}
        {orderType === 'label' ? (product.name ? product.name : '-') : ''}
      </div>
      <div style={{ maxWidth: 150 }} className="row-item">
        {quantity ? formatNumber(Number(quantity)) : '-'}
      </div>
      <div className="row-item">
        <Radio.Group
          buttonStyle="solid"
          value={action}
          disabled={loadingCondition || loading}
          onChange={handleActions}
          name={`items_in_stock[${index}].action`}
        >
          <Radio.Button
            value={'use'}
            style={{
              backgroundColor: action === 'use' && statusColorGenerate('use'),
              borderColor: action === 'use' && statusColorGenerate('use'),
            }}
          >
            Dùng
          </Radio.Button>
          <Radio.Button
            value={'not-use'}
            style={{
              backgroundColor: action === 'not-use' && statusColorGenerate('not-use'),
              borderColor: action === 'not-use' && statusColorGenerate('not-use'),
            }}
          >
            Không dùng
          </Radio.Button>
        </Radio.Group>
      </div>
    </div>
  )
})

const CustomRadios = React.memo((props) => {
  const { loading, values, onToggleChange, editId, options, name, onVatOther, otherOption } = props

  return (
    <Field name="order_type" disabled={loading}>
      {() => {
        return (
          <Radio.Group
            buttonStyle="solid"
            className="labels-wrapper"
            value={values[name]}
            disabled={loading || editId}
            onChange={onToggleChange}
          >
            {options.map((type) => {
              return (
                <Radio.Button key={type.value} className="label-item" value={type.value}>
                  {type.name}
                </Radio.Button>
              )
            })}
            {otherOption ? (
              <InputNumber
                className="label-item"
                style={{ width: 80, textAlign: 'center', marginLeft: 20, paddingLeft: 5 }}
                placeholder="khác"
                onChange={(e) => onVatOther(e)}
                formatter={(value) => `${value}%`}
                value={values.vat_percentage}
                min={0}
              />
            ) : null}
          </Radio.Group>
        )
      }}
    </Field>
  )
})

const LabelItems = React.memo((props) => {
  const { values, loadingCondition, setFieldValue } = props
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)
  const [focusingRow, setFocus] = useState(undefined)

  useEffect(() => {
    let didCancel = false
    let subtotal = values.sub_total || 0
    let vat = values.vat || 0

    const calTotal = () => {
      if (typeof subtotal !== 'number' || typeof vat !== 'number') {
        return
      } else {
        const total = Number(subtotal) + Number(vat)
        const formatted = Number(total.toFixed(2))
        setFieldValue('total', Math.round(formatted))
      }
    }

    if (!didCancel && !first) calTotal()

    return () => {
      didCancel = true
      setFirst(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, values.sub_total, values.vat_percentage, values.vat])

  useEffect(() => {
    let didCancel = false

    const calSubtotal = () => {
      let subtotal = 0
      const vatPercentage = values.vat_percentage / 100
      values.purchase_order_items.forEach((item) => {
        subtotal += item.total
      })

      setFieldValue('sub_total', Math.floor(subtotal))
      setFieldValue('vat', Number((subtotal * vatPercentage).toFixed(2)))
    }

    if (!didCancel && !second) calSubtotal()

    return () => {
      didCancel = true
      setSecond(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.purchase_order_items.length,
    values.purchase_order_items,
    second,
    values.vat_percentage,
  ])
  return (
    <FieldArray
      name="purchase_order_items"
      render={(arrayHelpers) => (
        <div>
          <Col className="container order-table-content">
            <div className="inner-content-label inner-content">
              <div className="row-wrapper fixed-scroll-header">
                <div style={{ maxWidth: 50 }} className="row-item thread-row">
                  STT
                </div>
                <div style={{ maxWidth: 230 }} className="row-item thread-row">
                  Tên hàng
                </div>
                <div style={{ maxWidth: 250 }} className="row-item thread-row">
                  Nội dung
                </div>
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  Quy cách
                </div>
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  Đơn giá
                </div>
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  Số lượng
                </div>
                <div style={{ maxWidth: 160 }} className="row-item thread-row">
                  Thành tiền
                </div>

                {/* <div className="row-item thread-row">Ghi chú</div> */}
                <div className="row-item thread-row">Ghi chú</div>
                <div className="row-item thread-row">Action</div>
              </div>

              {/* Order Items render */}
              {values.purchase_order_items.map((item, index) => {
                return (
                  <LabelItem
                    {...arrayHelpers}
                    {...props}
                    key={index}
                    {...item}
                    index={index}
                    focusingRow={focusingRow}
                    setFocus={setFocus}
                  />
                )
              })}

              <div className="row-wrapper">
                <div style={{ maxWidth: 50 }} className="row-item thread-row" />

                <div style={{ maxWidth: 230 }} className="row-item thread-row" />
                <div style={{ maxWidth: 250 }} className="row-item thread-row" />
                <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                <div style={{ maxWidth: 130 }} className="row-item thread-row" />
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  Tổng cộng
                </div>
                <div style={{ maxWidth: 160 }} className="row-item thread-row">
                  {values.sub_total ? formatNumber(Math.round(values.sub_total)) : '-'}
                </div>

                <div className="row-item thread-row" />
                <div className="row-item thread-row" />
              </div>

              <div className="row-wrapper">
                <div style={{ maxWidth: 50 }} className="row-item thread-row" />
                <div style={{ maxWidth: 230 }} className="row-item thread-row" />
                <div style={{ maxWidth: 250 }} className="row-item thread-row" />
                <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                <div style={{ maxWidth: 130 }} className="row-item thread-row" />
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  VAT
                </div>
                <div style={{ maxWidth: 160 }} className="row-item thread-row">
                  {values.vat ? formatNumber(Math.round(values.vat)) : '-'}
                </div>
                <div className="row-item thread-row" />
                <div className="row-item thread-row" />
              </div>
            </div>
          </Col>
          {values.sale_order_id ? null : (
            <div className="button-wrapper">
              <Button
                icon={<PlusOutlined />}
                type="primary"
                className="add-more-button"
                disabled={loadingCondition}
                onClick={() => {
                  arrayHelpers.push({
                    code: '',
                    name: '',
                    material_id: undefined,
                    unit: '',
                    readable_unit: '',
                    material: {},
                    product: {},
                    price: 0,
                    specification: '',
                    quantity: 1,
                    initQuantity: 0,
                    total: 0,
                    note: '',
                    info: '',
                  })
                  scrollToBottomAdd(
                    `scroll-to-bottom-add${focusingRow === undefined ? 1 : focusingRow + 1}`,
                    'container'
                  )
                  setFocus(focusingRow === undefined ? 1 : focusingRow + 1)
                }}
              />
            </div>
          )}
          <div className="total-wrapper">
            <div className="total-number">
              <Title className="total-number-title">Thành tiền:</Title>
              <span className="total-number-content">
                {values.total ? formatNumber(values.total) : '-'}
              </span>
            </div>
            <div className="total-number">
              <Title className="total-number-title">Bằng chữ:</Title>
              <span className="total-number-content">
                {values.total ? numToWord(values.total) : '-'}
              </span>
            </div>
          </div>
        </div>
      )}
    />
  )
})

const LabelItem = React.memo((props) => {
  const { values } = props
  return values.sale_order_id ? (
    <LabelItemWithLabelId {...props} />
  ) : (
    <LabelItemWithNoLabelId {...props} />
  )
})

const LabelItemWithNoLabelId = React.memo((props) => {
  const {
    // editId,
    values,
    loadingCondition,
    setFieldValue,
    remove,
    form,
    index,
    itemOptions,
    focusingRow,
    setFocus,
  } = props

  useEffect(() => {
    const content = document.getElementById(`label_parse_content${index}`)
    if (values.purchase_order_items[index].info && content) {
      const inforrr = new DOMParser().parseFromString(
        values.purchase_order_items[index].info,
        'text/html'
      )

      content.innerHTML = inforrr.body.innerHTML
    }
  }, [index, values.purchase_order_items])

  const onResultSelect = (index, val, option) => {
    setFieldValue(`purchase_order_items[${index}].label_id`, val)
    setFieldValue(`purchase_order_items[${index}].price`, Number(option.attr.price))
    setFieldValue(`purchase_order_items[${index}].unit`, option.attr.unit)
    setFieldValue(`purchase_order_items[${index}].readable_unit`, option.attr.readable_unit)
    setFieldValue(`purchase_order_items[${index}].specification`, option.attr.specification)
    setFieldValue(`purchase_order_items[${index}].info`, option.attr.info)
    setFieldValue(`purchase_order_items[${index}].label_type`, option.attr.label_type)
    setFieldValue(`purchase_order_items[${index}].code`, option.attr.product.code)
    setFieldValue(`purchase_order_items[${index}].name`, option.attr.product.name)
    const itemTotal = Number(
      Math.floor(Number(option.attr.price) * values.purchase_order_items[index].quantity)
    )
    setFieldValue(`purchase_order_items[${index}].total`, itemTotal)
  }
  const handleNumberChange = (index, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(`purchase_order_items[${index}].quantity`, 1)
    } else {
      const roundDown = value
      setFieldValue(`purchase_order_items[${index}].quantity`, roundDown)
      const itemTotal = Math.floor(Number(values.purchase_order_items[index].price * roundDown))
      setFieldValue(`purchase_order_items[${index}].total`, itemTotal)
    }
  }
  const handleRowColor = () => {
    if (focusingRow === index) return
    setFocus(index)
  }

  return (
    <div className="row-wrapper">
      <div
        className={`row-wrapper  scroll-to-bottom-add${index} ${
          focusingRow === index ? 'is-focusing' : ''
        }`}
      >
        <div style={{ maxWidth: 50 }} className="row-item">
          {index + 1}
        </div>
        <div style={{ maxWidth: 230 }} className="row-item">
          <Field name={`purchase_order_items[${index}].label_id`} disabled={loadingCondition}>
            {() => (
              <Select
                name={`purchase_order_items[${index}].label_id`}
                showSearch={true}
                suffixIcon={<SearchOutlined />}
                style={{ width: '100%' }}
                placeholder="Chọn Tem"
                value={`${
                  values.purchase_order_items[index].code &&
                  `${values.purchase_order_items[index].code + ':'}`
                } ${values.purchase_order_items[index].name}`}
                onChange={(val, option) => onResultSelect(index, val, option)}
                optionFilterProp="children"
                disabled={loadingCondition}
                filterOption={(input, option) => {
                  const dataInput = removeVietnameseTones(input)
                  const dataOption = removeVietnameseTones(option.children)
                  return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
                onFocus={handleRowColor}
              >
                {itemOptions.map((item) => {
                  return (
                    <Option key={item.id} value={item.id} attr={item}>
                      {`${item.product.code}: ${item.product.name}`}
                    </Option>
                  )
                })}
              </Select>
            )}
          </Field>
          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name={`purchase_order_items[${index}].material_id`}
          />
        </div>

        <div style={{ maxWidth: 250 }} className="row-item">
          {values.purchase_order_items[index]?.info ? (
            <div id={`label_parse_content${index}`} className="label_parse_content" />
          ) : (
            '-'
          )}
        </div>

        <div style={{ maxWidth: 120 }} className="row-item">
          {values.purchase_order_items[index].specification
            ? values.purchase_order_items[index].specification
            : '-'}
        </div>
        <div style={{ maxWidth: 130 }} className="row-item">
          {values.purchase_order_items[index].price
            ? formatNumber(Number(values.purchase_order_items[index].price))
            : '-'}
        </div>
        <div style={{ maxWidth: 130 }} className="row-item">
          <Field
            as={InputNumber}
            style={{ width: '100%' }}
            name={`purchase_order_items[${index}].quantity`}
            placeholder="Nhập số lượng"
            disabled={loadingCondition}
            value={values.purchase_order_items[index].quantity}
            onChange={(value) => handleNumberChange(index, 100000000, value)}
            formatter={(value) => formatInputNumber(value)}
            min={1}
            max={100000000}
            onFocus={handleRowColor}
          />
          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name={`purchase_order_items[${index}].quantity`}
          />
        </div>
        <div style={{ maxWidth: 160 }} className="row-item">
          {values.purchase_order_items[index].total
            ? formatNumber(values.purchase_order_items[index].total)
            : '-'}
        </div>
        <div className="row-item">
          <Field
            as={TextArea}
            name={`purchase_order_items[${index}].note`}
            placeholder="Ghi chú..."
            disabled={loadingCondition}
            value={values.purchase_order_items[index].note}
            onChange={form.handleChange}
            autoSize={{ minRows: 1, maxRows: 3 }}
            onFocus={handleRowColor}
          />
          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name={`purchase_order_items[${index}].note`}
          />
        </div>
        <div className="row-item">
          {values.purchase_order_items.length > 1 ? (
            <Button
              type="link"
              danger
              icon={<MinusCircleOutlined />}
              onClick={() => remove(index)}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
})

const LabelItemWithLabelId = React.memo((props) => {
  const { values, index, setFieldValue } = props
  const { id, quantity_in_stock, action } = useStoreState((state) => state.purchaseOrder)
  const {
    // product_id,
    product,
    price,
    initQuantity,
    total,
    // guide,
    label_id,
    // name,
    // code,
    info,
    specification,
  } = values.purchase_order_items[index]
  // const quantity_in_stock_new = get(values, `items_in_stock[${index}].quantity_in_stock`)
  // const action_state = get(values, `items_in_stock[${index}].action`)
  // const quantity_in_stock_default = get(itemOrder, `[${index}].quantity`)

  useEffect(() => {
    const content = document.getElementById(`label_parse_content${index}`)
    if (info && content) {
      const inforrr = new DOMParser().parseFromString(info, 'text/html')

      content.innerHTML = inforrr.body.innerHTML
    }
  }, [index, info])

  useEffect(() => {
    let didCancel = false
    const calculateQuantity = () => {
      let calQuantity = 0
      if (quantity_in_stock <= initQuantity) {
        calQuantity = action === 'use' ? initQuantity - quantity_in_stock : initQuantity
      } else {
        calQuantity = action === 'use' ? 0 : initQuantity
      }
      const roundDown = calQuantity
      setFieldValue(`purchase_order_items[${index}].quantity`, roundDown)
      const itemTotal = Math.floor(Number(price * roundDown))
      setFieldValue(`purchase_order_items[${index}].total`, itemTotal)
    }
    if (!didCancel && label_id === id) {
      calculateQuantity()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, values.purchase_order_items[index].id, quantity_in_stock, action])

  const handleNumberChange = (index, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit || value <= 0) {
      setFieldValue(`purchase_order_items[${index}].quantity`, 0)
      setFieldValue(`purchase_order_items[${index}].total`, 0)
      return
    } else if (!value) {
      setFieldValue(`purchase_order_items[${index}].quantity`, 0)
      return
    } else {
      setFieldValue(`purchase_order_items[${index}].quantity`, value)
      const itemTotal = Math.floor(Number(values.purchase_order_items[index].price * value))
      setFieldValue(`purchase_order_items[${index}].total`, itemTotal)
    }
  }

  const handleNoteChange = (e) => {
    setFieldValue(`purchase_order_items[${index}].note`, e.target.value)
  }

  return (
    <div className="row-wrapper">
      <div style={{ maxWidth: 50 }} className="row-item">
        {index + 1}
      </div>
      <div style={{ maxWidth: 230 }} className="row-item" attr={values.purchase_order_items[index]}>
        {product.code && product.name ? `${product?.code}: ${product?.name}` : '-'}
      </div>
      <div style={{ maxWidth: 250 }} className="row-item">
        {values.purchase_order_items[index]?.info ? (
          <div id={`label_parse_content${index}`} className="label_parse_content" />
        ) : (
          '-'
        )}
      </div>
      <div style={{ maxWidth: 120 }} className="row-item">
        {specification ? specification : '-'}
      </div>
      <div style={{ maxWidth: 130 }} className="row-item">
        {price ? formatNumber(Number(price)) : '-'}
      </div>
      <div style={{ maxWidth: 130 }} className="row-item">
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`purchase_order_items[${index}].quantity`}
          placeholder="Nhập số lượng"
          // disabled={loadingCondition}
          value={values.purchase_order_items[index].quantity}
          onChange={(value) => handleNumberChange(index, 500000, value)}
          formatter={(value) => formatInputNumber(value)}
          min={0}
          max={Number(500000)}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`purchase_order_items[${index}].quantity`}
        />
      </div>
      <div style={{ maxWidth: 160 }} className="row-item">
        {total ? formatNumber(total) : '-'}
      </div>

      <div className="row-item">
        <Field
          as={TextArea}
          name={`purchase_order_items[${index}].note`}
          placeholder="Ghi chú..."
          // disabled={loadingCondition}
          value={values.purchase_order_items[index].note}
          onChange={handleNoteChange}
          autoSize={{ minRows: 1, maxRows: 3 }}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`purchase_order_items[${index}].note`}
        />
      </div>

      <div className="row-item"></div>
    </div>
  )
})

export { CustomError, OrderItems, MaterialInStock, CustomRadios, LabelItems }
