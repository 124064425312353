import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const maxLength1000 = 'Tối đa 1000 ký tự'
const typeError = 'Vui lòng nhập số'
const managePurchaseOrderSchema = yup.object().shape({
  order_number: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  date: yup.date().required(requiredField),
  supplier_id: yup.number().required(requiredField),
  // sale_order_id: yup.number().when('order_type', {
  //   is: (value) => value === 'label',
  //   then: yup.number().required(requiredField),
  //   otherwise: yup.number().nullable(true).notRequired(),
  // }),
  production_requirement: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .max(1000, maxLength1000),
  note: yup.string().trim('Vui lòng không nhập khoảng trắng').strict().max(1000, maxLength1000),
  purchase_order_items: yup
    .array()
    .of(
      yup.object().shape({
        // material_id: yup.string().required(requiredField),
        material_id: yup.number().test('oneOfRequired', requiredField, function (item) {
          return this.parent.material_id || this.parent.label_id || this.parent.product_id
        }),
        label_id: yup.number().test('oneOfRequired', requiredField, function (item) {
          return this.parent.material_id || this.parent.label_id || this.parent.product_id
        }),
        product_id: yup.number().test('oneOfRequired', requiredField, function (item) {
          return this.parent.material_id || this.parent.label_id || this.parent.product_id
        }),
        quantity: yup.number().required(requiredField).typeError(typeError), // these constraints take precedence
        note: yup
          .string()
          .trim('Vui lòng không nhập khoảng trắng')
          .strict()
          .nullable(true)
          .max(255, maxLength),
      })
    )
    .required(requiredField) // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Cần tối thiểu 1 vật liệu'),
})

export default managePurchaseOrderSchema
