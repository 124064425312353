import React, { useState } from 'react'
import { Table, Input, Button } from 'antd'
import moment from 'moment'

import { formatNumber } from 'Utils'
import EditModal from './EditModal'
// const STAFF_TYPE = [
//   { name: 'Nhân viên văn phòng', value: 'office' },
//   { name: 'Nhân viên xưởng', value: 'factory' },
// ]

const modalInitialValues = {
  id: undefined,
  updated_at: '',
  staff_id: undefined,
  date: moment().format('YYYY-MM-DD'),
  office_hours: '',
  overtime_hours: '',
  staff: {
    id: undefined,
    code: '',
    name: '',
    staff_type: '',
    readable_staff_type: '',
  },
}

export default React.memo((props) => {
  const { data, loading, pagination, doFetch, setSearchTerm, searchTerm } = props

  const [editModal, setEditModal] = useState({
    id: undefined,
    data: modalInitialValues,
    visible: false,
    loading: false,
  })

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      width: 70,
    },
    // {
    //   title: 'Phân loại nhân viên',
    //   dataIndex: 'staff.staff_type',
    //   width: 200,
    //   render: (type) => getFieldName(type, STAFF_TYPE),
    // },
    {
      title: 'Ngày',
      dataIndex: 'date',
      width: 170,
      sorter: true,
      render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    },
    {
      title: 'Tổng giờ làm (phút)',
      dataIndex: 'office_hours',
      width: 180,
      sorter: true,
      render: (val) => formatNumber(Number((val * 60).toFixed(2))),
    },
    {
      title: 'Tổng giờ tăng ca (phút)',
      dataIndex: 'overtime_hours',
      width: 200,
      sorter: true,
      render: (val) => formatNumber(Number((val * 60).toFixed(2))),
    },
    {
      title: 'Lần cập nhật cuối',
      dataIndex: 'updated_at',
      width: 200,
      sorter: true,
      render: (date) => moment(date).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      key: 'x',
      width: 140,
      // fixed: 'right',
      render: (record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        <Button className="action-item update" type="link" onClick={() => handleEdit(record)}>
          Sửa
        </Button>
      </div>
    )
  }

  const handleEdit = (record) =>
    setEditModal({
      id: record.id,
      data: {
        ...record,
        office_hours: Number((record.office_hours * 60).toFixed(2)),
        overtime_hours: Number((record.overtime_hours * 60).toFixed(2)),
      },
      visible: true,
      loading: false,
    })

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch((prev) => ({
      ...prev,
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
    }))
  }

  const renderTitle = () => {
    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    )
  }

  const dataSource = data.map((item, index) => ({
    ...item,
    index: index + 1,
    'staff.staff_type': item.staff.staff_type,
  }))

  return (
    <div id="time_attendence_table">
      <Table
        title={renderTitle}
        className="time-attendence-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, showSizeChanger: true }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1100, y: 680 }}
        bordered
      />

      <EditModal {...editModal} setEditModal={setEditModal} doFetch={doFetch} />
    </div>
  )
})
