import React from 'react'
import {
  DeliveredProcedureOutlined,
  SafetyCertificateOutlined,
  SnippetsOutlined,
  AreaChartOutlined,
} from '@ant-design/icons'
import { Row, Col, Typography } from 'antd'

import '../index.scss'

// allowRoles: ["MO", "OM", "PP", "MOD", "DI", "R"]
// Manage Order: MO
// Order Material: OM
// Produce Product: PP
// Manage Order Document: MOD
// Data Input: DI
// Report: R

const { Title } = Typography

const generateMenu = (count) => {
  return [
    {
      id: 'input-data',
      name: 'Dữ liệu đầu vào',
      icon: DeliveredProcedureOutlined,
      onlySuperadmin: false,
      allowRoles: ['MO', 'OM', 'PP', 'DI'],
      isHasSubMenu: true,
      subMenus: [
        {
          id: 'packages',
          name: 'Bao bì',
          route: '/packages',
          onlySuperadmin: false,
          allowRoles: ['OM', 'PP', 'DI'],
        },
        {
          id: 'materials',
          name: 'Nguyên vật liệu',
          route: '/materials',
          onlySuperadmin: false,
          allowRoles: ['OM', 'PP', 'DI'],
        },
        {
          id: 'labels',
          name: 'Tem Sticker',
          route: '/labels',
          onlySuperadmin: false,
          allowRoles: ['OM', 'PP', 'DI'],
        },
        {
          id: 'products',
          name: 'Sản phẩm',
          route: '/products',
          onlySuperadmin: false,
          allowRoles: ['MO', 'OM', 'PP', 'DI'],
        },
        {
          id: 'customers',
          name: 'Khách hàng',
          route: '/customers',
          onlySuperadmin: false,
          allowRoles: ['MO', 'DI'],
        },
        {
          id: 'suppliers',
          name: 'Nhà cung cấp',
          route: '/suppliers',
          onlySuperadmin: false,
          allowRoles: ['OM', 'DI'],
        },
      ],
    },
    {
      id: 'manage-orders',
      name: 'Quản lý đơn hàng',
      icon: SnippetsOutlined,
      onlySuperadmin: false,
      allowRoles: ['MO', 'OM', 'PP', 'MOD', 'R'],
      isHasSubMenu: true,
      subMenus: [
        {
          id: 'sale-orders',
          name: 'ORDER',
          route: '/sale-orders',
          onlySuperadmin: false,
          allowRoles: ['MO', 'PP', 'MOD'],
        },
        {
          id: 'purchase-orders',
          name: 'Đơn đặt hàng NCC',
          route: '/purchase-orders',
          onlySuperadmin: false,
          allowRoles: ['OM'],
        },
        {
          id: 'commodity-exports',
          name: 'Đơn xuất hàng',
          route: '/commodity-exports',
          onlySuperadmin: false,
          allowRoles: ['PP'],
        },
        {
          id: 'commodity-exports-draft',
          name: 'Đơn xuất hàng nháp',
          route: '/commodity-exports-draft',
          onlySuperadmin: false,
          allowRoles: ['PP'],
        },
        {
          id: 'manage-stocks',
          name: 'Xuất/nhập tồn',
          route: '/manage-stocks/?transaction_type=in%2Cout',
          onlySuperadmin: false,
          allowRoles: ['OM', 'PP'],
        },
        {
          id: 'supplier-debt-invoice',
          name: 'Hoá đơn TT công nợ NCC',
          route: '/supplier-debt-invoice',
          onlySuperadmin: false,
          allowRoles: ['OM', 'R'],
        },
        {
          id: 'standing-orders',
          name: 'Thanh toán NCC',
          route: '/standing-orders',
          onlySuperadmin: false,
          allowRoles: ['OM', 'R'],
        },
        {
          id: 'supplier-payment',
          name: 'Công nợ thanh toán NCC',
          route: '/supplier-payment',
          onlySuperadmin: false,
          allowRoles: ['OM', 'R'],
        },
        {
          id: 'payment-transactions',
          name: 'Khách hàng thanh toán',
          route: '/payment-transactions',
          onlySuperadmin: false,
          allowRoles: ['MO', 'R'],
        },
        {
          id: 'customer-payment',
          name: 'Công nợ khách hàng TT',
          route: '/customer-payment',
          onlySuperadmin: false,
          allowRoles: ['MO', 'R'],
        },
        {
          id: 'notifications',
          name: (
            <Row justify="space-between" align="middle">
              <Col>Thông báo</Col>
              <Col className="notificationsCount">
                <Title level={5} className="numberCount">
                  {count}
                </Title>
              </Col>
            </Row>
          ),

          route: '/notifications?order_by=dateline&order=ascend',
          onlySuperadmin: false,
          allowRoles: ['PP', 'MOD'],
        },
        {
          id: 'shipping-schedule',
          name: 'Báo cáo shipping schedule',
          route: '/shipping-schedule',
          onlySuperadmin: false,
          allowRoles: ['R'],
        },
      ],
    },
    {
      id: 'statistical',
      name: 'Thống kê',
      icon: AreaChartOutlined,
      onlySuperadmin: false,
      allowRoles: ['MO', 'PP', 'MOD', 'R', 'OM'],
      isHasSubMenu: true,
      subMenus: [
        {
          id: 'products-history',
          name: 'Lịch sử giá sản phẩm',
          route: '/products-history',
          onlySuperadmin: false,
          allowRoles: ['MO', 'PP', 'MOD'],
        },
        {
          id: 'materials-history',
          name: 'Lịch sử giá nguyên vật liệu',
          route: '/materials-history',
          onlySuperadmin: false,
          allowRoles: ['MO', 'PP', 'MOD'],
        },
        {
          id: 'stocks-report',
          name: 'Báo cáo xuất/nhập tồn',
          route: '/stocks-report',
          onlySuperadmin: false,
          allowRoles: ['R', 'OM'],
        },
        {
          id: 'sales-report',
          name: 'Báo cáo doanh số',
          route: '/sales-report',
          onlySuperadmin: true,
          allowRoles: [],
        },
      ],
    },
    {
      id: 'admin',
      name: 'Admin',
      icon: SafetyCertificateOutlined,
      onlySuperadmin: true,
      isHasSubMenu: true,
      allowRoles: [],
      subMenus: [
        {
          id: 'accounts',
          name: 'Tài khoản',
          isHasSubMenu: false,
          route: '/accounts',
          onlySuperadmin: true,
          allowRoles: [],
        },
        {
          id: 'staffs',
          name: 'Nhân viên',
          isHasSubMenu: false,
          route: '/staffs',
          onlySuperadmin: true,
          allowRoles: [],
        },
        {
          id: 'salary',
          name: 'Bảng tính lương',
          isHasSubMenu: false,
          route: '/salary',
          onlySuperadmin: true,
          allowRoles: [],
        },
        {
          id: 'time-attendance',
          name: 'Chấm công',
          isHasSubMenu: false,
          route: '/time-attendance',
          onlySuperadmin: true,
          allowRoles: [],
        },
        {
          id: 'import-time-attendance',
          name: 'Tải lên file chấm công',
          isHasSubMenu: false,
          route: '/import-time-attendance',
          onlySuperadmin: true,
          allowRoles: [],
        },
        {
          id: 'settings',
          name: 'Thiết lập',
          isHasSubMenu: false,
          route: '/settings',
          onlySuperadmin: true,
          allowRoles: [],
        },
      ],
    },
  ]
}

export { generateMenu }
