import React, { useState, useEffect, useCallback } from 'react'
import { Table, Input, Button, Upload, Modal } from 'antd'
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { useDataApi } from 'Hooks'
import { pageSizeConfig, formatNumber } from 'Utils'
import DeleteModal from './DeleteModal'

export default React.memo(() => {
  const history = useHistory()

  const [previewImage, setPreviewImage] = useState({
    file: '',
    visible: false,
  })

  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi(
    {
      url: '/labels/',
      key: 'records',
    },
    undefined,
    { per_page: 3 },
    { pageSize: 3 }
  )

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    image_link: '',
    visible: false,
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const labelsState = useStoreState((state) => state.menuTabs['labels'])

  useEffect(() => {
    let didCancel
    if (labelsState && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'labels', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsState])

  const columns = [
    {
      title: 'Hình ảnh minh họa',
      dataIndex: 'image_link',
      width: 180,
      render: (link, record) => renderLabelImage(link, record),
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'product.code',
      sortOrder: pagination.order_by === 'product.code' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
    },
    {
      title: 'Kích thước tem',
      dataIndex: 'specification',
      sortOrder: pagination.order_by === 'specification' ? `${pagination.order}` : null,
      width: 160,
      sorter: true,
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer.code',
      sortOrder: pagination.order_by === 'customer.code' ? `${pagination.order}` : null,
      width: 210,
      sorter: true,
    },
    {
      title: 'Ngày update',
      dataIndex: 'updated_at',
      sortOrder: pagination.order_by === 'updated_at' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      render: (date) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: 'Loại tem',
      dataIndex: 'label_type',
      sortOrder: pagination.order_by === 'label_type' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      width: 140,
      sortOrder: pagination.order_by === 'quantity' ? `${pagination.order}` : null,
      sorter: true,
      render: (record) => formatNumber(record),
    },
    {
      title: 'Số lượng khả dụng',
      dataIndex: 'temporary_quantity',
      sortOrder: pagination.order_by === 'temporary_quantity' ? `${pagination.order}` : null,
      sorter: true,
      width: 160,
      render: (record) => formatNumber(record),
    },
    {
      key: 'x',
      width: 280,
      //   fixed: 'right',
      render: (record) => actionColumn(record),
    },
  ]

  const renderLabelImage = (link, record) => {
    return (
      <div onClick={(event) => event.stopPropagation()}>
        <Upload
          listType="picture-card"
          fileList={[
            {
              uid: `uid-${record.aws_object_key}`,
              status: 'done',
              url: link,
            },
          ]}
          onPreview={handlePreview}
          onRemove={false}
        />
      </div>
    )
  }

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}

        <Button className="action-item update" type="link" onClick={(e) => handleEdit(record, e)}>
          Sửa
        </Button>

        <Button
          className="action-item create-duplicate"
          type="link"
          onClick={(e) => handleCreateDuplicate(record, e)}
        >
          Tạo bản sao
        </Button>

        <Button
          className="action-item delete"
          type="link"
          danger
          onClick={(e) => handleDelete(record, e)}
        >
          Xoá
        </Button>
      </div>
    )
  }

  const handleView = (record) => {
    history.push(`/labels/${record.id}/view`)
  }

  const handleEdit = (record, e) => {
    e.stopPropagation()
    history.push(`/labels/${record.id}/edit`)
  }

  const handleCreateDuplicate = (record, e) => {
    e.stopPropagation()
    history.push(`/labels/new/${record.id}`)
  }

  const handleDelete = (record, e) => {
    e.stopPropagation()
    setDeleteModal({ id: record.id, image_link: record.image_link, visible: true })
  }

  const handleCancel = () => {
    setPreviewImage((prevState) => ({ ...prevState, visible: false }))
  }

  const handCreate = () => history.push('/labels/new')

  const getBase64 = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage({ file: file.url || file.preview, visible: true })
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Thêm tem
        </Button>
      </div>
    )
  }

  const dataSource = data.map((label) => ({
    ...label,
    'product.code': `${label.product.code} : ${label.product.name}`,
    'customer.code': `${label.customer.code} : ${label.customer.name}`,
  }))

  return (
    <div id="manage_label_table">
      <Table
        title={renderTitle}
        className="users-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig, pageSizeOptions: [3, 5, 10, 20] }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1620, y: 500 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />

      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
      <Modal
        visible={previewImage.visible}
        footer={null}
        onCancel={handleCancel}
        centered
        // closable={false}
        bodyStyle={{ padding: 5 }}
        width={'80%'}
        closeIcon={<CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />}
      >
        <img
          alt="preview"
          className="image-preview"
          style={{ width: '100%', height: 'auto' }}
          src={previewImage.file}
        />
      </Modal>
    </div>
  )
})
