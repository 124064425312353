import React from 'react'
import { Result } from 'antd'
import { SmileOutlined } from '@ant-design/icons'

import '../index.scss'

export default () => {
  return (
    <Result className="home-page" icon={<SmileOutlined />} title="Great, Welcome to Datafood!" />
  )
}
