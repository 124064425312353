import { useStoreState } from 'easy-peasy'
import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import FormView from './Form.View'
import { manageSupplierDebtInvoiceSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'
import './index.scss'

const initialValues = {
  supplier_id: undefined,
  purchase_order_id: undefined,
  total_amount: 0,
  paid_amount: 0,
  not_paid_amount: 0,
  invoice_number: '',
  date: moment(),
  total: undefined,
  note: '',
  approved: false,
}

export default React.memo((props) => {
  const { path, editId } = props
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)

    if (values.total < values.not_paid_amount) {
      values.approved = true
    }

    const formatted = { ...values, date: moment(values.date).format('YYYY-MM-DD') }

    if (path === 'new') {
      createInvoice(formatted)
    } else if (path === 'edit') {
      updateInvoice(formatted)
    }
  }

  const createInvoice = async (params) => {
    const url = '/purchase_order_invoices/'
    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/supplier-debt-invoice/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updateInvoice = async (params) => {
    const url = `/purchase_order_invoices/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setSubmitting(false)
      history.push(`/supplier-debt-invoice/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="supplier-debt-invoice-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSubmitting={isSubmitting}
            isSuperAdmin={isSuperAdmin}
            initialValues={initialValues}
          />
        )}
        validationSchema={manageSupplierDebtInvoiceSchema}
      />
    </div>
  )
})
