import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageCommodityExportSchema = yup.object().shape({
  // export_number: yup.string().required(requiredField).max(255, maxLength),
  date: yup.date().required(requiredField),
  packing_date: yup.date().required(requiredField),
  depart_date: yup.date().required(requiredField),
  arrival_date: yup.date().required(requiredField),
  customer_id: yup.number().required(requiredField),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
  sale_order_id: yup.number().when('export_only_one_sale_order', {
    is: (value) => value === true,
    then: yup.number().required(requiredField).typeError(requiredField),
    otherwise: yup.number().nullable(true),
  }),
  commodity_export_items: yup
    .array()
    .when('export_only_one_sale_order', {
      is: (value) => value === true,
      then: yup.array().notRequired(),
      otherwise: yup.array().of(
        yup.object().shape({
          sale_order_id: yup.number().required(requiredField).typeError(typeError),
          sale_order_item_id: yup.number().required(requiredField).typeError(typeError),
          quantity: yup.number().required(requiredField).typeError(typeError),
        })
      ),
    })
    .required(requiredField)
    .min(1, 'Cần tối thiểu 1 sản phẩm'),

  sale_order_in_paper_id: yup.number().when('export_only_one_sale_order', {
    is: (value) => value === true,
    then: yup.number().notRequired(),
    otherwise: yup.number().required(requiredField).typeError(typeError),
  }),

  container_type_1: yup
    .object()
    .shape(
      {
        container_no: yup.string().when('amount', {
          is: (value) => value > 0,
          then: yup.string().trim('Vui lòng không nhập khoảng trắng').strict(),
          // .required(requiredField),
          otherwise: yup.string().notRequired(),
        }),
        seal_no: yup.string().when('amount', {
          is: (value) => value > 0,
          then: yup.string().trim('Vui lòng không nhập khoảng trắng').strict(),
          // .required(requiredField),
          otherwise: yup.string().notRequired(),
        }),
      },
      ['amount', 'container_no', 'seal_no']
    )
    .test('oneOfRequired', { amount: 'Cần chọn ít nhất 1 container' }, function (item) {
      return (
        this.parent.container_type_1.amount ||
        this.parent.container_type_2.amount ||
        this.parent.container_type_3.amount
      )
    }),

  container_type_2: yup
    .object()
    .shape(
      {
        container_no: yup.string().when('amount', {
          is: (value) => value !== 0,
          then: yup.string().trim('Vui lòng không nhập khoảng trắng').strict(),
          // .required(requiredField),
          otherwise: yup.string().notRequired(),
        }),
        seal_no: yup.string().when('amount', {
          is: (value) => value !== 0,
          then: yup.string().trim('Vui lòng không nhập khoảng trắng').strict(),
          // .required(requiredField),
          otherwise: yup.string().notRequired(),
        }),
      },
      ['amount', 'container_no', 'seal_no']
    )
    .test('oneOfRequired', { amount: 'Cần chọn ít nhất 1 container' }, function (item) {
      return (
        this.parent.container_type_1.amount ||
        this.parent.container_type_2.amount ||
        this.parent.container_type_3.amount
      )
    }),

  container_type_3: yup
    .object()
    .shape(
      {
        container_no: yup.string().when('amount', {
          is: (value) => value !== 0,
          then: yup.string().trim('Vui lòng không nhập khoảng trắng').strict(),
          // .required(requiredField),
          otherwise: yup.string().notRequired(),
        }),
        seal_no: yup.string().when('amount', {
          is: (value) => value !== 0,
          then: yup.string().trim('Vui lòng không nhập khoảng trắng').strict(),
          // .required(requiredField),
          otherwise: yup.string().notRequired(),
        }),
      },
      ['amount', 'container_no', 'seal_no']
    )
    .test('oneOfRequired', { amount: 'Cần chọn ít nhất 1 container' }, function (item) {
      return (
        this.parent.container_type_1.amount ||
        this.parent.container_type_2.amount ||
        this.parent.container_type_3.amount
      )
    }),
})

export default manageCommodityExportSchema
