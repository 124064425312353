import React, { useEffect, useState } from 'react'
import { Alert, Col, Button, InputNumber, Select } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Field, FieldArray, ErrorMessage } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import _ from 'lodash'

import {
  formatNumber,
  formatToNumber,
  formatInputNumber,
  removeVietnameseTones,
  roundUpDecimal,
} from 'Utils'
import { noData, SearchField } from 'Components'
import axios from 'Utils/axiosRexyAdmin'

const { Option } = Select
// const { Title } = Typography

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const ExportItems = React.memo((props) => {
  const { values, loadingCondition, maxItems } = props
  const [focusingRow, setFocus] = useState(undefined)
  const [totalData, setTotalData] = useState({
    total_quantity: 0,
    total_size: 0,
  })

  useEffect(() => {
    const calTotal = () => {
      let total_quantity = 0
      let total_size = 0

      values.commodity_export_items.forEach((item) => {
        total_quantity += formatToNumber(item.quantity)
        total_size += formatToNumber(item.total_cubic)
      })

      setTotalData({ total_quantity, total_size })
    }

    calTotal()
  }, [values.commodity_export_items])

  return (
    <FieldArray
      name="commodity_export_items"
      render={(arrayHelpers) => (
        <div style={{ paddingLeft: 10, marginBottom: 15 }}>
          <Col style={styles.container} className="commodity-export-content">
            <div style={styles.innerContent}>
              <div style={styles.rowWrapper}>
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 60 }}>STT</div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, minWidth: 180 }}>
                  Order No
                </div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, minWidth: 300 }}>
                  Tên sản phẩm
                </div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, minWidth: 300 }}>
                  Đơn đặt hàng NCC
                </div>
                <div style={{ ...styles.rowItem, ...styles.threadRow }}>Packing</div>
                <div style={{ ...styles.rowItem, ...styles.threadRow }}>Cubic (m3/ctn)</div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
                  Số lượng (thùng)
                </div>
                <div style={{ ...styles.rowItem, ...styles.threadRow }}>Total Cubic (m3)</div>

                <div style={{ ...styles.lastChild, ...styles.threadRow, maxWidth: 160 }} />
              </div>

              <div className="scroll-vertical-table">
                {values.commodity_export_items.map((item, index) => (
                  <ExportItem
                    {...props}
                    {...arrayHelpers}
                    {...item}
                    key={index}
                    index={index}
                    focusingRow={focusingRow}
                    setFocus={setFocus}
                  />
                ))}
              </div>

              <div style={styles.rowWrapper}>
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 60 }} />
                <div style={{ ...styles.rowItem, ...styles.threadRow, minWidth: 180 }} />
                <div style={{ ...styles.rowItem, ...styles.threadRow, minWidth: 300 }} />
                <div style={{ ...styles.rowItem, ...styles.threadRow, minWidth: 300 }} />
                <div style={{ ...styles.rowItem, ...styles.threadRow }} />
                <div style={{ ...styles.rowItem, ...styles.threadRow }} />
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
                  {totalData.total_quantity ? formatNumber(totalData.total_quantity) : '-'}
                </div>
                <div style={{ ...styles.rowItem, ...styles.threadRow }}>
                  {totalData.total_size ? formatNumber(totalData.total_size) : '-'}
                </div>

                <div style={{ ...styles.lastChild, ...styles.threadRow, maxWidth: 160 }} />
              </div>
            </div>
          </Col>

          {values.commodity_export_items.length < maxItems ? (
            <div style={styles.buttonWrapper}>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                style={styles.addMoreButton}
                disabled={loadingCondition}
                onClick={() =>
                  arrayHelpers.push({
                    sale_order_id: undefined,
                    sale_order_item_id: undefined,
                    quantity: undefined,
                    max_quantity: undefined,
                    sale_order_items: [],
                    specification: '',
                    box_size: undefined,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      )}
    />
  )
})

const ExportItem = React.memo((props) => {
  const {
    values,
    loadingCondition,
    orderOptions,
    setFieldValue,
    remove,
    index,
    editId,
    dataFetch,
    selectedSaleOrderItems,
    setSelectedSaleOrderItems,
    selectedSaleOrder,
    setSelectedSaleOrder,
    focusingRow,
    setFocus,
  } = props

  // TODO: HERE
  const [triggerKeywordSearch] = useState({ purchase_orders: '' })

  useEffect(() => {
    let didCancel = false
    if (editId && !didCancel && Object.keys(dataFetch).length) {
      const saleOrderItemOptions = dataFetch.order_items.filter((record) => {
        if (index < dataFetch.commodity_export_items.length) {
          return record.id === dataFetch.commodity_export_items[index].sale_order_id
        } else return false
      })

      if (saleOrderItemOptions.length) {
        setFieldValue(
          `commodity_export_items[${index}].sale_order_items`,
          saleOrderItemOptions[0].sale_order_items
        )
      }
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, dataFetch])

  useEffect(() => {
    const selectedExportItem = _.map(values.commodity_export_items, (item) => ({
      id: item.sale_order_id,
      order_number: item.sale_order_order_number || item.order_number,
    }))

    const uniqIdSaleOrder = _.uniqBy(selectedExportItem, 'id')
    const filtered = _.filter(uniqIdSaleOrder, (item) => item.id !== undefined)
    setSelectedSaleOrder(filtered)
    // const needResetValue = !_.some(filtered, { id: values.sale_order_in_paper_id })
    // if (needResetValue) setValues({ ...values, sale_order_in_paper_id: undefined })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.commodity_export_items])

  const onSelectOrder = (index, val, option) => {
    setFieldValue(`commodity_export_items[${index}]`, {
      ...values.commodity_export_items[index],
      sale_order_id: val,
      sale_order_order_number: option.attr.order_number,
      sale_order_items: option.attr.sale_order_items,
      sale_order_item_id: undefined,
      quantity: undefined,
      max_quantity: undefined,
      specification: '',
      box_size: undefined,
    })

    let cloneSelectedSaleOrderItems = cloneDeep(selectedSaleOrderItems)
    const removeSaleOrderItemsId = values.commodity_export_items[index].sale_order_item_id
    const removeSaleOrderItemsIdIndex = cloneSelectedSaleOrderItems.indexOf(removeSaleOrderItemsId)

    if (removeSaleOrderItemsIdIndex > -1) {
      cloneSelectedSaleOrderItems.splice(removeSaleOrderItemsIdIndex, 1)
      setSelectedSaleOrderItems(cloneSelectedSaleOrderItems)
    }
  }

  const onSelectOrderItem = (index, val, option) => {
    let cloneSelected = cloneDeep(selectedSaleOrderItems)

    const totalCubic = roundUpDecimal(Number(option.attr.box_size) * option.attr.box_quantity)

    if (cloneSelected.includes(values.commodity_export_items[index].sale_order_item_id)) {
      const valueIndex = cloneSelected.indexOf(
        values.commodity_export_items[index].sale_order_item_id
      )
      // replaces element at valueIndex
      cloneSelected.splice(valueIndex, 1, val)
      setSelectedSaleOrderItems(cloneSelected)
    } else setSelectedSaleOrderItems((prev) => [...prev, val])

    setFieldValue(`commodity_export_items[${index}]`, {
      ...values.commodity_export_items[index],
      sale_order_item_id: val,
      quantity: option.attr.box_quantity,
      max_quantity: option.attr.box_quantity,
      specification: option.attr.specification,
      box_size: Number(option.attr.box_size),
      total_cubic: totalCubic,
    })
  }

  const onRemoveItem = (index) => {
    let cloneSelectedSaleOrder = cloneDeep(selectedSaleOrder)
    const removeSaleOrderId = values.commodity_export_items[index].sale_order_id
    const removeSaleOrderIdIndex = cloneSelectedSaleOrder.findIndex(
      (ele) => ele.id === removeSaleOrderId
    )

    let cloneSelectedSaleOrderItems = cloneDeep(selectedSaleOrderItems)
    const removeSaleOrderItemsId = values.commodity_export_items[index].sale_order_item_id
    const removeSaleOrderItemsIdIndex = cloneSelectedSaleOrderItems.indexOf(removeSaleOrderItemsId)

    if (removeSaleOrderItemsIdIndex > -1) {
      cloneSelectedSaleOrderItems.splice(removeSaleOrderItemsIdIndex, 1)
      setSelectedSaleOrderItems(cloneSelectedSaleOrderItems)
    }

    if (removeSaleOrderIdIndex > -1) {
      if (cloneSelectedSaleOrder[removeSaleOrderIdIndex].id === removeSaleOrderId)
        setFieldValue('sale_order_in_paper_id', undefined)
      // cloneSelectedSaleOrder.splice(removeSaleOrderIdIndex, 1)
      // setSelectedSaleOrder(cloneSelectedSaleOrder)
    }

    remove(index)
  }

  const handleNumberChange = (index, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(`commodity_export_items[${index}].quantity`, 0)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(`commodity_export_items[${index}].quantity`, roundDown)

      const totalCubic = roundUpDecimal(
        Number(values.commodity_export_items[index].box_size) * Number(value)
      )
      setFieldValue(`commodity_export_items[${index}].total_cubic`, totalCubic)
    }
  }

  const limitNumber = values.commodity_export_items[index].max_quantity
    ? values.commodity_export_items[index].max_quantity
    : 1000000

  const filteredProductOptions = values.commodity_export_items[index].sale_order_items.filter(
    (element) => {
      let cloneSelected = cloneDeep(selectedSaleOrderItems)
      if (cloneSelected.length) {
        // Remove itselt from selected sale order items array
        if (values.commodity_export_items[index].sale_order_item_id) {
          cloneSelected.splice(
            cloneSelected.indexOf(values.commodity_export_items[index].sale_order_item_id),
            1
          )
        }
        // ==========

        // remove all options except itself and not selected options
        return !cloneSelected.some((item) => item === element.id)
        // ==========
      } else {
        return true
      }
    }
  )

  const handleRowColor = () => {
    if (focusingRow === index) return
    setFocus(index)
  }

  return (
    <div style={{ ...styles.rowWrapper, ...(focusingRow === index ? styles.isFocusing : {}) }}>
      <div style={{ ...styles.rowItem, maxWidth: 60 }}>{index + 1}</div>
      <div style={{ ...styles.rowItem, minWidth: 180 }}>
        <Field name={`commodity_export_items[${index}].sale_order_id`} disabled={loadingCondition}>
          {() => {
            return (
              <Select
                name={`commodity_export_items[${index}].sale_order_id`}
                style={{ width: '100%' }}
                showSearch
                placeholder="Chọn order"
                value={values.commodity_export_items[index].sale_order_id}
                onChange={(val, option) => onSelectOrder(index, val, option)}
                optionFilterProp="children"
                disabled={loadingCondition}
                filterOption={(input, option) => {
                  const dataInput = removeVietnameseTones(input)
                  const dataOption = removeVietnameseTones(option.children)
                  return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                }}
                onFocus={handleRowColor}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {orderOptions.map((item) => (
                  <Option key={item.id} value={item.id} attr={item}>
                    {item.order_number}
                  </Option>
                ))}
              </Select>
            )
          }}
        </Field>

        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`commodity_export_items[${index}].sale_order_id`}
        />
      </div>

      <div style={{ ...styles.rowItem, minWidth: 300 }}>
        <Field
          name={`commodity_export_items[${index}].sale_order_item_id`}
          disabled={loadingCondition}
        >
          {() => {
            return (
              <Select
                showSearch
                name={`commodity_export_items[${index}].sale_order_item_id`}
                style={{ width: '100%' }}
                placeholder="Chọn sản phẩm"
                value={values.commodity_export_items[index].sale_order_item_id}
                onChange={(val, option) => onSelectOrderItem(index, val, option)}
                disabled={loadingCondition}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  const dataInput = removeVietnameseTones(input)
                  const dataOption = removeVietnameseTones(option.children)
                  return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                }}
                onFocus={handleRowColor}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {filteredProductOptions.map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    attr={item}
                  >{`${item.product?.code}: ${item.product?.name}`}</Option>
                ))}
              </Select>
            )
          }}
        </Field>
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`commodity_export_items[${index}].sale_order_item_id`}
        />
      </div>

      {/* TODO: HERE */}
      <div style={{ ...styles.rowItem, minWidth: 300 }}>
        <SearchField
          mode="multiple"
          axios={axios}
          url="/purchase_orders/"
          value={values.commodity_export_items[index].purchase_orders}
          onChange={(ids) => setFieldValue(`commodity_export_items[${index}].purchase_orders`, ids)}
          disabled={loadingCondition}
          optionParams={{ value: 'id', label: 'order_number' }}
          placeholder="Nhập mã đơn đặt hàng NCC"
          triggerKeywordSearch={triggerKeywordSearch.purchase_orders}
          getPopupContainer={undefined}
          triggerOptions={values.commodity_export_items[index].purchaseOrderOptions}
        />
      </div>

      <div style={{ ...styles.rowItem }}>
        {values.commodity_export_items[index].specification
          ? values.commodity_export_items[index].specification
          : '-'}
      </div>

      <div style={{ ...styles.rowItem }}>
        {values.commodity_export_items[index].box_size
          ? formatNumber(values.commodity_export_items[index].box_size)
          : '-'}
      </div>

      <div style={{ ...styles.rowItem, maxWidth: 160 }}>
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`commodity_export_items[${index}].quantity`}
          placeholder="Nhập số lượng"
          disabled={loadingCondition}
          onFocus={handleRowColor}
          value={values.commodity_export_items[index].quantity}
          onChange={(value) => handleNumberChange(index, limitNumber, value)}
          formatter={(value) => formatInputNumber(value)}
          min={0}
          max={limitNumber}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`commodity_export_items[${index}].quantity`}
        />
      </div>
      {/* <div style={{ ...styles.rowItem }}>
        {values.commodity_export_items[index].box_size
          ? roundUpDecimal(
              Number(values.commodity_export_items[index].box_size) *
                Number(values.commodity_export_items[index].quantity)
            )
          : '-'}
      </div> */}

      <div style={{ ...styles.rowItem }}>
        {values.commodity_export_items[index].total_cubic
          ? formatNumber(values.commodity_export_items[index].total_cubic)
          : '-'}
      </div>

      <div style={{ ...styles.lastChild, maxWidth: 160 }}>
        {values.commodity_export_items.length > 1 ? (
          <Button
            type="link"
            danger
            icon={<MinusCircleOutlined />}
            onClick={() => onRemoveItem(index)}
          />
        ) : null}
      </div>
    </div>
  )
})

const ExportAllProductOfOneOrder = React.memo((props) => {
  const { values, loadingCondition, setFieldValue } = props

  const [triggerKeywordSearch] = useState({ purchase_orders: '' })

  const [totalData, setTotalData] = useState({
    total_quantity: 0,
    total_size: 0,
  })

  useEffect(() => {
    const calTotal = () => {
      let total_quantity = 0
      let total_size = 0

      values.commodity_export_items.forEach((item) => {
        total_quantity += formatToNumber(item.quantity)
        total_size += formatToNumber(item.box_size) * formatToNumber(item.quantity)
      })

      setTotalData({ total_quantity, total_size })
    }

    calTotal()
  }, [values.commodity_export_items])

  return (
    <div style={{ paddingLeft: 10 }}>
      <Col style={styles.container} className="commodity-export-content">
        <div style={{ ...styles.innerContent, minWidth: 1300, maxWidth: 1400 }}>
          <div style={styles.rowWrapper}>
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 60 }}>STT</div>
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 300 }}>
              Tên sản phẩm
            </div>
            <div style={{ ...styles.rowItem, ...styles.threadRow, minWidth: 300 }}>
              Đơn đặt hàng NCC
            </div>
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 190 }}>Packing</div>
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 180 }}>
              Cubic (m3/ctn)
            </div>
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
              Số lượng (thùng)
            </div>
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
              Total Cubic (m3)
            </div>
          </div>

          <div className="scroll-vertical-table">
            <div style={{ ...styles.rowWrapper, flexDirection: 'column' }}>
              {values.export_only_one_sale_order && values.sale_order_id
                ? values.commodity_export_items.length
                  ? values.commodity_export_items.map((item, index) => (
                      <div style={{ display: 'flex', width: '100%', height: '100%' }} key={index}>
                        <div style={{ ...styles.rowItem, maxWidth: 60 }}>{index + 1}</div>
                        <div style={{ ...styles.rowItem, maxWidth: 300 }}>
                          {item.product?.code && item.product?.name
                            ? `${item.product?.code}: ${item.product?.name}`
                            : '-'}
                        </div>
                        <div style={{ ...styles.rowItem, minWidth: 300 }}>
                          <SearchField
                            mode="multiple"
                            axios={axios}
                            url="/purchase_orders/"
                            value={values.commodity_export_items[index].purchase_orders}
                            onChange={(ids) =>
                              setFieldValue(`commodity_export_items[${index}].purchase_orders`, ids)
                            }
                            disabled={loadingCondition}
                            optionParams={{ value: 'id', label: 'order_number' }}
                            placeholder="Nhập mã đơn đặt hàng NCC"
                            triggerKeywordSearch={triggerKeywordSearch.purchase_orders}
                            getPopupContainer={undefined}
                            triggerOptions={
                              values.commodity_export_items[index].purchaseOrderOptions
                            }
                          />
                        </div>
                        <div style={{ ...styles.rowItem, maxWidth: 190 }}>
                          {item.specification ? item.specification : '-'}
                        </div>
                        <div style={{ ...styles.rowItem, maxWidth: 180 }}>
                          {item.box_size ? formatNumber(item.box_size) : '-'}
                        </div>
                        <div style={{ ...styles.rowItem, maxWidth: 160 }}>
                          {item.quantity ? formatNumber(item.quantity) : '-'}
                        </div>
                        <div style={{ ...styles.lastChild, maxWidth: 160 }}>
                          {item.box_size
                            ? roundUpDecimal(Number(item.box_size) * Number(item.quantity))
                            : '-'}
                        </div>
                      </div>
                    ))
                  : noData({
                      paddingTop: 15,
                      paddingBottom: 15,
                      width: '100%',
                      borderBottom: '1px solid #CCCCCC',
                    })
                : noData({
                    paddingTop: 15,
                    paddingBottom: 15,
                    width: '100%',
                    borderBottom: '1px solid #CCCCCC',
                  })}
            </div>
          </div>

          <div style={styles.rowWrapper}>
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 60 }} />
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 300 }} />
            <div style={{ ...styles.rowItem, ...styles.threadRow, minWidth: 300 }} />
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 190 }} />
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 180 }} />
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
              {totalData.total_quantity ? formatNumber(totalData.total_quantity) : '-'}
            </div>
            <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
              {totalData.total_size ? formatNumber(totalData.total_size) : '-'}
            </div>
          </div>
        </div>
      </Col>
    </div>
  )
})

export { CustomError, ExportItems, ExportAllProductOfOneOrder }

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'none',
    width: '100%',
    overflowX: 'scroll',
  },
  innerContent: {
    minWidth: 1500,
    border: '1px solid #CCCCCC',
    borderBottom: 'none',
    overflowX: 'scroll',
  },
  cardWrapper: {
    width: '100%',
    padding: 0,
    boxShadow: 'none',
  },
  rowWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 0,
  },
  rowItem: {
    flex: 1,
    borderRight: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
    padding: 10,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  threadRow: {
    backgroundColor: '#FAFAFA',
    fontWeight: 500,
  },
  lastChild: {
    flex: 1,
    maxWidth: 80,
    padding: 10,
    borderBottom: '1px solid #CCCCCC',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  addMoreButton: {
    border: 'none',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: 'none',
    backgroundColor: '#0097c4',
  },
  totalWrapper: {
    marginTop: 20,
  },
  totalNumber: {
    width: '100%',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  totalNumberTitle: {
    fontSize: 14,
    color: '#474747',
    marginBottom: 10,
    marginRight: 20,
    width: 80,
  },
  totalNumberContent: {
    fontSize: 14,
    color: '#474747',
  },
  isFocusing: {
    backgroundColor: '#daf1f8d7',
  },
}
