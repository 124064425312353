import React from 'react'

import MaterialHistoryForm from './Form'
import MaterialHistoryTable from './Table'
import useDataApi from './hooks'
import './index.scss'

const MaterialHistory = React.memo(() => {
  const {
    firstRender,
    data,
    loading,
    searchTerm,
    pagination,
    errors,
    doFetch,
    setSearchTerm,
  } = useDataApi({
    url: '/sale_orders/get-sale-order-materials-price-history/',
    key: 'records',
  })

  const handleFormSubmit = (values) => {
    doFetch((prev) => ({ ...prev, ...values, page: 1, per_page: 10, order_by: '', order: '' }))
  }

  return (
    <div id="materials_history_page">
      <MaterialHistoryForm
        handleFormSubmit={handleFormSubmit}
        serverError={errors}
        loading={loading}
        firstRender={firstRender}
      />
      <MaterialHistoryTable
        data={data}
        loading={loading}
        pagination={pagination}
        doFetch={doFetch}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        firstRender={firstRender}
      />
    </div>
  )
})

export default MaterialHistory
