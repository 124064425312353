import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, InputNumber, Button, Alert, Typography, Select, Spin, DatePicker } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import get from 'lodash/get'

import {
  formatNumber,
  numToWord,
  formatInputNumber,
  preventEnterSubmit,
  removeVietnameseTones,
} from 'Utils'
import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'
import { noData } from 'Components'
import { useDebounce } from 'Hooks'
import { useScrollToErrors } from 'Utils'

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
  } = props
  const history = useHistory()
  const [isFetching, setIsFetching] = useState(false)
  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })
  const [saleOrderSearchTerm, setSaleOrderSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })
  const saleOrderDebouncedSearchTerm = useDebounce(saleOrderSearchTerm.keyword, 500)

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)
  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  useEffect(() => {
    let didCancel = false

    if (editId) {
      ;(async () => {
        const url = `/payment_transactions/${editId}/edit`
        setIsFetching(true)
        try {
          const resp = await axios.get(url)

          if (editId && !didCancel) {
            const saleOrder = get(resp.data, 'sale_order', {})
            const formated = {
              ...saleOrder,
              paid_amount: saleOrder.paid_amount - resp.data.amount,
              not_paid_amount: saleOrder.not_paid_amount + resp.data.amount,
            }
            setValues({
              ...values,
              ...resp.data,
              ...formated,
              date: moment(resp.data.date, 'YYYY-MM-DD'),
            })
            onSearch(resp.data.customer.code)
            onSaleOrderSearch(saleOrder.order_number)
            // setIsFetching(false)
          }
        } catch (error) {
          if (!didCancel) {
            setIsFetching(false)
          }
        }
      })()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, setValues])

  useEffect(() => {
    let didCancel = false

    const fetchCustomers = async () => {
      const url = '/customers/form/'
      // setIsFetching(true)
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
          },
        })
        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setSearchTerm((prev) => ({ ...prev, searching: false }))
          setIsFetching(false)
        }
      }
    }

    if (debouncedSearchTerm.length && debouncedSearchTerm === searchTerm.keyword) fetchCustomers()

    return () => (didCancel = true)
  }, [debouncedSearchTerm, searchTerm.keyword])

  useEffect(() => {
    let didCancel = false
    const fetchSaleOrders = async () => {
      const url = '/sale_orders/form-in-payment-transaction/'

      try {
        const resp = await axiosExpand.get(url, {
          params: {
            keyword: saleOrderDebouncedSearchTerm,
            customer_id: values.customer_id,
            per_page: 20,
          },
        })

        if (!didCancel) {
          setSaleOrderSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setSaleOrderSearchTerm((prev) => ({ ...prev, searching: false }))
          setIsFetching(false)
        }
      }
    }

    if (
      saleOrderDebouncedSearchTerm.length &&
      saleOrderSearchTerm.keyword === saleOrderDebouncedSearchTerm
    ) {
      fetchSaleOrders()
    }
    return () => (didCancel = true)
  }, [saleOrderDebouncedSearchTerm, saleOrderSearchTerm.keyword, values.customer_id])

  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onSaleOrderSearch = (val) => {
    if (!val) {
      setSaleOrderSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSaleOrderSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onResultSelect = async (val, option) => {
    if (!val) {
      setValues({
        ...values,
        date: moment(),
        customer_id: undefined,
        sale_order_id: undefined,
        payment_method: undefined,
        amount: undefined,
        note: '',
        total_amount: 0,
        paid_amount: 0,
        not_paid_amount: 0,
      })
    } else {
      setValues({
        ...values,
        date: moment(),
        customer_id: undefined,
        sale_order_id: undefined,
        payment_method: undefined,
        amount: undefined,
        note: '',
        total_amount: 0,
        paid_amount: 0,
        not_paid_amount: 0,
      })
      setFieldValue('customer_id', option.item.id)
      setFieldValue('sale_order_id', undefined)
      setSaleOrderSearchTerm((prev) => ({ ...prev, result: [] }))
    }
    // try {
    //   const url = '/sale_orders/form-in-payment-transaction/'
    //   const orderResult = await axiosExpand.get(url, { params: { customer_id: val } })
    //   setSaleOrderSearchTerm((prev) => ({ ...prev, result: orderResult.data.records, searching: false }))

    // } catch (error) {
    //   message.error(error.response?.data.errors?.message)
    //   setSaleOrderSearchTerm((prev) => ({ ...prev, searching: false }))
    // }
  }
  const [paymentMethods, setPaymentMethods] = useState([])

  useEffect(() => {
    let didCancel = false

    const fetchLabelTypes = async () => {
      const url = '/customers/new/'
      const resp = await axios.get(url)
      setPaymentMethods(resp.data.payment_methods)
    }

    if (!didCancel) {
      fetchLabelTypes()
    }
    return () => (didCancel = true)
  }, [])

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  const saleOrderSearchDropDownCondition = saleOrderSearchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : saleOrderSearchTerm.keyword.length && !saleOrderSearchTerm.result.length ? (
    noData()
  ) : null

  const handleChangePaymentMethod = (value) => {
    setFieldValue('payment_method', value)
  }

  const onSaleOrderSelect = (value, option) => {
    setValues({
      ...values,
      sale_order_id: value,
      total_amount: Number(option.item.total_amount),
      paid_amount: Number(option.item.paid_amount),
      not_paid_amount: Number(option.item.not_paid_amount),
    })
  }

  const handleNumberChange = (name, limit, value) => {
    if (typeof value !== 'number') {
      return
      // } else if (value > limit) {
      //   return
    } else if (!value) {
      setFieldValue(name, 0)
    } else {
      setFieldValue(name, value)
    }
  }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_payment_transactions_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">Thông tin thanh toán order</Title>
      {serverError && (
        <Alert
          message={serverError}
          type="error"
          className="server-error"
          showIcon
          style={{ marginLeft: 10 }}
        />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Ngày <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.date}
                  onChange={(val) => setFieldValue('date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="date" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Khách hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="customer_id" disabled={loadingCondition}>
              {() => {
                return (
                  <Select
                    name="customer_id"
                    style={{ width: '100%' }}
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    notFoundContent={searchDropDownCondition}
                    placeholder="Nhập mã hoặc tên khách hàng để tìm kiếm"
                    allowClear
                    onSearch={onSearch}
                    value={searchTerm.result.length === 0 ? undefined : values.customer_id}
                    // onChange={onResultSelect}
                    onChange={(val, option) => onResultSelect(val, option)}
                    optionFilterProp="children"
                    loading={searchTerm.searching}
                    disabled={loadingCondition}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {searchTerm.result.map((item) => (
                      <Option
                        key={item.id}
                        item={item}
                        value={item.id}
                      >{`${item.code}: ${item.name}`}</Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="customer_id" />
          </div>
          {/* {values.customer_id ? ( */}
          <div className="form-item">
            <Title className="field-title">
              Order <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="sale_order_id" disabled={loadingCondition}>
              {() => {
                return (
                  <Select
                    name="sale_order_id"
                    style={{ width: '100%' }}
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    notFoundContent={saleOrderSearchDropDownCondition}
                    placeholder="Nhập mã Order để tìm kiếm"
                    onSearch={onSaleOrderSearch}
                    value={
                      saleOrderSearchTerm.result.length === 0 ? undefined : values.sale_order_id
                    }
                    onChange={onSaleOrderSelect}
                    optionFilterProp="children"
                    loading={saleOrderSearchTerm.searching}
                    disabled={loadingCondition || !values.customer_id}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {saleOrderSearchTerm.result.map((item) => (
                      <Option key={item.id} item={item} value={item.id}>
                        {item.order_number}
                      </Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="sale_order_id" />
          </div>

          {values.sale_order_id ? (
            <>
              <div className="form-item hint-field">
                <Title className="field-title hint-title">Tổng giá trị Order:</Title>
                <span className="to-word-money">
                  {values.total_amount ? `${formatNumber(values.total_amount)} USD` : '- USD'}
                </span>
              </div>

              <div className="form-item hint-field">
                <Title className="field-title hint-title">Số tiền đã trả:</Title>
                <span className="to-word-money">
                  {values.paid_amount ? `${formatNumber(values.paid_amount)} USD` : '- USD'}
                </span>
              </div>

              <div className="form-item hint-field">
                <Title className="field-title hint-title">Số tiền còn phải trả:</Title>
                <span className="to-word-money">
                  {values.not_paid_amount ? `${formatNumber(values.not_paid_amount)} USD` : '- USD'}
                </span>
              </div>
            </>
          ) : null}

          <div className="form-item">
            <Title className="field-title">
              Hình thức thanh toán <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Select}
              name="payment_method"
              style={{ width: '100%' }}
              placeholder="Chọn hình thức thanh toán"
              disabled={loadingCondition}
              value={values.payment_method}
              onChange={handleChangePaymentMethod}
            >
              {paymentMethods.map((method) => (
                <Option key={method.value} value={method.value}>
                  {method.name}
                </Option>
              ))}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="payment_method" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Số tiền bằng số (USD)<span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={InputNumber}
              name="amount"
              placeholder="Nhập số tiền"
              disabled={loadingCondition || !values.sale_order_id}
              value={values.amount}
              onChange={(value) => handleNumberChange('amount', values.not_paid_amount, value)}
              style={{ width: '100%' }}
              formatter={(value) => formatInputNumber(value)}
              min={values.not_paid_amount === 0 ? 0 : 0.01}
              // max={values.not_paid_amount}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="amount" />
          </div>

          <div className="form-item hint-field">
            <Title className="field-title hint-title">Số tiền bằng chữ:</Title>
            <span className="to-word-money">
              {!loadingCondition && values.amount ? numToWord(values.amount, 'đô') : '-'}
            </span>
          </div>

          <div className="form-item">
            <Title className="field-title">Nội dung</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Nội dung..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/payment-transactions')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo thanh toán order' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
