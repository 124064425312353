import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field, FieldArray } from 'formik'
import { Input, Button, Alert, Typography, Checkbox, Row, Col, Radio } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

import axios from 'Utils/axiosRexyAdmin'
import { useScrollToErrors } from 'Utils'

const { Title } = Typography

const isSuperAdmin = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const CustomCheckboxGroup = React.memo((props) => {
  const { roleOptions, values, push, remove, loadingCondition, setFieldValue } = props

  useEffect(() => {
    if (values.is_super_admin) {
      roleOptions.forEach((role) => {
        push(role.value)
      })
    } else {
      if (values.roles.length) {
        setFieldValue('roles', [])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.is_super_admin])

  return (
    <Row className="roles-checkbox-container">
      {roleOptions.map((role) => (
        <Col className="checkbox-item" key={role.id} span={12}>
          <Checkbox
            checked={values.roles.includes(role.value)}
            disabled={loadingCondition || values.is_super_admin}
            onChange={(e) => {
              if (e.target.checked) {
                push(role.value)
              } else {
                const idx = values.roles.indexOf(role.value)
                remove(idx)
              }
            }}
            value={role.value}
          >
            {role.label}
          </Checkbox>
        </Col>
      ))}
    </Row>
  )
})

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    setFieldValue,
    serverError,
    editId,
    path,
    errors,
    touched,
  } = props

  const history = useHistory()
  const roleOptions = useStoreState((state) => state.roles.roleOptions)
  const [isFetching, setIsFetching] = useState(false)

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/users/${editId}/edit`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)

        if (editId && !didCancel) {
          setValues({ ...values, ...resp.data })
          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    if (editId) {
      fetchData()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, setValues])

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form id="manage_users_form" className="form-wrapper" onSubmit={handleSubmit}>
      <Title className="page-sub-title">Thông tin tài khoản</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Tên <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="name"
              placeholder="Nhập tên nhân viên"
              disabled={loadingCondition}
              value={values.name}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="name" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Email <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="email"
              placeholder="Nhập email"
              disabled={loadingCondition}
              value={values.email}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="email" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Quản trị viên? (Admin) <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Radio.Group}
              name="is_super_admin"
              disabled={loadingCondition}
              value={values.is_super_admin}
              onChange={handleChange}
              options={isSuperAdmin}
              //   component={(props) => <CustomRadioGroup values={values} {...props} />}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="is_super_admin" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Vai trò nhân viên <span className="compulsory-field-symbol">*</span>
            </Title>
            <FieldArray
              name="roles"
              render={(arrayHelpers) => (
                <CustomCheckboxGroup
                  {...arrayHelpers}
                  values={values}
                  loadingCondition={loadingCondition}
                  roleOptions={roleOptions}
                  setFieldValue={setFieldValue}
                />
              )}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="roles" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/accounts')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo tài khoản' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
