import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button, Tag, Upload, message } from 'antd'
import {
  RollbackOutlined,
  FormOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import findIndex from 'lodash/findIndex'

import axios from 'Utils/axiosRexyAdmin'
import { formatNumber, getFieldName } from 'Utils'
import { ImageSlide } from 'Components'

const { Title } = Typography

const INITIAL_PROFILE = {
  id: undefined,
  code: '',
  name: '',
  name_on_proforma_invoice: '',
  name_on_foreign_document: '',
  name_on_bill: '',
  name_on_declaration: '',
  brand_name: '',
  specification: '',
  package_barcode: '',
  box_barcode: '',
  box_size: '',
  quality_indicators: '',
  hs_code: '',
  expire_date: '',
  barcode_note: '',
  co_note: '',
  note: '',
  quality_note: '',
  need_plan_quarantine: false,
  need_medical_quarantine: false,
  need_other_quarantines: '',
  customer_id: undefined,
  net_weight: undefined,
  gross_weight: undefined,
  quantity_in_box: undefined,
  quantity: undefined,
  product_type: '',
  fob_price: undefined,
  production_cost: undefined,
  managament_cost: undefined,
  deleted_at: undefined,
  active: false,
  customer_code: '',
  addtional_hashes: [],
  suppliers: [],
  materials: [],
}

const PRODUCT_TYPES = [
  { value: 'finish', name: 'Thành phẩm' },
  { value: 'semifinish', name: 'Bán thành phẩm' },
]

export default React.memo((props) => {
  const history = useHistory()
  const viewId = props.match?.params.id

  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })
  const [imageState, setImageState] = useState({ visible: false, currentIndex: 0 })

  const [unitList, setUnitList] = useState({ data: [], loading: true })

  useEffect(() => {
    let didCancel = false

    setUnitList((prev) => ({ ...prev, loading: true }))

    const fetchUnitsList = async () => {
      const url = '/materials/new/'
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setUnitList({ data: resp.data.units, loading: false })
        }
      } catch (error) {
        if (!didCancel) {
          setUnitList((prev) => ({ ...prev, loading: false }))
          message.error(error.response?.data.errors?.message)
        }
      }
    }

    fetchUnitsList()
    return () => (didCancel = true)
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/products/${viewId}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          let addtionalHashes = []
          if (resp.data.addtional_hashes.length) {
            addtionalHashes = resp.data.addtional_hashes.map((item, index) => ({
              uid: `uid-${index}`,
              name: 'image.png',
              status: 'done',
              url: item.image_link,
              original: item.image_link,
              thumbnail: item.image_link,
            }))
          }

          setState((prev) => ({
            ...prev,
            data: { ...resp.data, addtional_hashes: addtionalHashes },
            loading: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors?.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [viewId])

  const handlePreview = (file) => {
    const index = findIndex(state.data.addtional_hashes, file)
    if (index > -1) {
      setImageState({ visible: true, currentIndex: index })
    } else {
      setImageState({ visible: true, currentIndex: 0 })
    }
  }

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/products`)
  }

  const handleEdit = () => {
    history.push(`/products/${viewId}/edit`)
  }

  const handleCreateDuplicate = () => {
    history.push(`/products/new/${viewId}`)
  }
  const {
    code,
    name,
    name_on_proforma_invoice,
    name_on_foreign_document,
    name_on_bill,
    name_on_declaration,
    brand_name,
    specification,
    package_barcode,
    box_barcode,
    box_size,
    quality_indicators,
    hs_code,
    expire_date,
    barcode_note,
    co_note,
    note,
    quality_note,
    need_plan_quarantine,
    need_medical_quarantine,
    need_other_quarantines,
    net_weight,
    gross_weight,
    quantity_in_box,
    quantity,
    product_type,
    first_price,
    production_cost,
    second_price,
    managament_cost,
    fob_price,
    active,
    customer_code,
    addtional_hashes,
    suppliers,
    materials,
  } = state.data

  return (
    <div id="view_product" className="table-container">
      <Title className="page-title">Thông tin sản phẩm:</Title>

      <div className="inner-content">
        <Card className="view-wrapper">
          <div className="general-information">
            <Title className="part-title">1. Thông tin chung</Title>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên sản phẩm
                </Col>
                <Col className="content-wrapper" span={16}>
                  {name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên tiếng Anh (Proforma Invoice)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {name_on_proforma_invoice}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Datafood Code
                </Col>
                <Col className="content-wrapper" span={16}>
                  {code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Trạng thái sản phẩm
                </Col>
                <Col className="content-wrapper" span={16}>
                  {active ? (
                    <Tag icon={<CheckCircleOutlined />} color="success" key={true}>
                      Đang kích hoạt
                    </Tag>
                  ) : (
                    <Tag icon={<CloseCircleOutlined />} color="error" key={false}>
                      Vô hiệu hoá
                    </Tag>
                  )}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Customer Code
                </Col>
                <Col className="content-wrapper" span={16}>
                  {customer_code}
                </Col>
              </Row>
            </Card.Grid>
          </div>

          <div className="product-details">
            <Title className="part-title">2. Chi tiết sản phẩm</Title>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Hình ảnh sản phẩm
                </Col>
                <Col className="content-wrapper" span={16}>
                  <Upload
                    listType="picture-card"
                    fileList={addtional_hashes}
                    onPreview={handlePreview}
                    onRemove={false}
                  />
                </Col>
              </Row>
            </Card.Grid>
            <ImageSlide
              visible={imageState.visible}
              onCancel={() => setImageState({ visible: false, currentIndex: 0 })}
              images={addtional_hashes}
              currentIndex={imageState.currentIndex}
            />

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Danh sách NCC
                </Col>
                <Col className="content-wrapper supplier-wrapper" span={16}>
                  {suppliers.map((supplier, index) => (
                    <Col
                      key={index}
                      className="supplier-item"
                    >{`${supplier.code}: ${supplier.name}`}</Col>
                  ))}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Loại sản phẩm
                </Col>
                <Col className="content-wrapper" span={16}>
                  {getFieldName(product_type, PRODUCT_TYPES)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Packing
                </Col>
                <Col className="content-wrapper" span={16}>
                  {specification}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số sản phẩm / thùng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(quantity_in_box)}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số lượng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(quantity)}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số lượng khả dụng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(state.data.temporary_quantity)}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên tiếng Anh (trên chứng từ ngoại)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {name_on_foreign_document}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên rút gọn (trên bill)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {name_on_bill}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên sản phẩm trên tờ khai
                </Col>
                <Col className="content-wrapper" span={16}>
                  {name_on_declaration}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên thương hiệu trên tờ khai
                </Col>
                <Col className="content-wrapper" span={16}>
                  {brand_name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Barcode gói
                </Col>
                <Col className="content-wrapper" span={16}>
                  {package_barcode}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Barcode thùng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {box_barcode}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú barcode
                </Col>
                <Col className="content-wrapper" span={16}>
                  {barcode_note}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Kích thước thùng (D x R x C) m3
                </Col>
                <Col className="content-wrapper" span={16}>
                  {box_size}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Net Weight (kgs)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(net_weight)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Gross weight (kgs)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(gross_weight)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Hạn sử dụng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {expire_date}
                </Col>
              </Row>
            </Card.Grid>
          </div>

          <div className="product-qualitifcations">
            <Title className="part-title">3. Chỉ tiêu sản phẩm</Title>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Chỉ tiêu chất lượng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {quality_indicators}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  HS code
                </Col>
                <Col className="content-wrapper" span={16}>
                  {hs_code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú mặt hàng không xin CO
                </Col>
                <Col className="content-wrapper" span={16}>
                  {co_note}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Kiểm dịch thực vật
                </Col>
                <Col className="content-wrapper" span={16}>
                  {need_plan_quarantine ? (
                    <CheckCircleOutlined style={{ fontSize: 20, color: 'green' }} />
                  ) : (
                    <CloseCircleOutlined style={{ fontSize: 20, color: 'red' }} />
                  )}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Kiểm dịch y tế
                </Col>
                <Col className="content-wrapper" span={16}>
                  {need_medical_quarantine ? (
                    <CheckCircleOutlined style={{ fontSize: 20, color: 'green' }} />
                  ) : (
                    <CloseCircleOutlined style={{ fontSize: 20, color: 'red' }} />
                  )}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Kiểm khác
                </Col>
                <Col className="content-wrapper" span={16}>
                  {need_other_quarantines}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú chất lượng sản phẩm
                </Col>
                <Col className="content-wrapper" span={16}>
                  {quality_note}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú khác
                </Col>
                <Col className="content-wrapper" span={16}>
                  {note}
                </Col>
              </Row>
            </Card.Grid>
          </div>

          <div className="product-expenses">
            <Title className="part-title">4. Giá thành sản phẩm</Title>

            {product_type === 'semifinish' && (
              <>
                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Giá mua sản phẩm chưa thành phẩm (VND/Thùng)
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {formatNumber(first_price)}
                    </Col>
                  </Row>
                </Card.Grid>
                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Chi phí sản xuất (VND/Thùng)
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {formatNumber(production_cost)}
                    </Col>
                  </Row>
                </Card.Grid>
              </>
            )}
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Giá mua thành phẩm (VND/Thùng)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(second_price)}
                </Col>
              </Row>
            </Card.Grid>

            {isSuperAdmin ? (
              <>
                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Chi phí quản lý (%)
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {formatNumber(managament_cost)}
                    </Col>
                  </Row>
                </Card.Grid>

                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Giá FOB (USD/CTN)
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {formatNumber(fob_price)}
                    </Col>
                  </Row>
                </Card.Grid>
              </>
            ) : null}
          </div>

          {state.data.product_type === 'semifinish' && (
            <div className="product-materials">
              <Title className="part-title">
                5. Thông tin vật liệu (tạo nên 1 thùng thành phẩm)
              </Title>

              <Col className="marterial-table-content">
                <div className="inner-marterial-content">
                  <div className="scroll-wrap">
                    <div className="row-wrapper">
                      <div
                        className="row-item thread-row"
                        style={{ maxWidth: 260, lineBreak: 'anywhere' }}
                      >
                        Loại
                      </div>
                      <div
                        className="row-item thread-row"
                        style={{ maxWidth: 260, lineBreak: 'anywhere' }}
                      >
                        Tên vật liệu
                      </div>
                      <div
                        className="row-item thread-row"
                        style={{ maxWidth: 260, lineBreak: 'anywhere' }}
                      >
                        Số lượng
                      </div>
                      <div className="last-child thread-row" style={{ maxWidth: 180 }}>
                        Đơn vị
                      </div>
                    </div>
                    <div className="scroll-vertical-table">
                      {materials.map((item, index) => {
                        return (
                          <div key={index} className="row-wrapper">
                            <div
                              className="row-item"
                              style={{ maxWidth: 260, lineBreak: 'anywhere' }}
                            >{`${
                              item.material_type === 'packing' ? 'Bao bì' : 'Nguyên liệu'
                            }`}</div>
                            <div
                              className="row-item"
                              style={{ maxWidth: 260, lineBreak: 'anywhere' }}
                            >{`${item.material_code}: ${item.material_name}`}</div>
                            <div
                              className="row-item"
                              style={{ maxWidth: 260, lineBreak: 'anywhere' }}
                            >
                              {formatNumber(Number(item.material_quantity))}
                            </div>
                            <div className="last-child" style={{ maxWidth: 180 }}>
                              {item.material_unit
                                ? getFieldName(item.material_unit, unitList.data)
                                : '-'}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          )}

          <div className="button-container">
            <Button
              icon={<RollbackOutlined />}
              type="primary"
              loading={state.loading}
              className="cancel-button"
              onClick={handleBack}
            >
              Trở lại
            </Button>

            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={state.loading}
              className="process-button"
              onClick={handleEdit}
            >
              Chỉnh sửa
            </Button>

            <Button
              icon={<CopyOutlined />}
              type="primary"
              loading={state.loading}
              className="create-duplicate-button"
              onClick={handleCreateDuplicate}
            >
              Tạo bản sao
            </Button>
          </div>
        </Card>
      </div>
    </div>
  )
})
