import React, { useState, useEffect } from 'react'
import { Typography, message } from 'antd'
import { Redirect } from 'react-router-dom'

import axios from 'Utils/axiosRexyAdmin'
import PurchaseOrderForm from '../Form'
import { Loading } from 'Components'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  const [fetchEditData, setFetchEditData] = useState({ dataFetch: {}, isFetching: true })
  const [statusPurchaseOrder, setStatusPurchaseOrder] = useState('pending')

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/purchase_orders/${id}/edit`
      setFetchEditData((prev) => ({ ...prev, isFetching: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          const purchaseOrderItems = resp.data.purchase_order_items
          const data = resp.data.order_type

          const customerDataProduct =
            data === 'label' || data === 'finish_product'
              ? resp.data.purchase_order_items.map((item) => ({
                  ...item,
                  code: item.product.code,
                  name: item.product.name,
                }))
              : purchaseOrderItems.map((item) => ({
                  ...item,
                  code: item.material.code,
                  name: item.material.name,
                }))
          // const customerDataMaterial = resp.data.purchase_order_items.map((item) => ({
          //   ...item,
          //   code: item.material.code,
          //   name: item.material.name,
          // }))
          // console.log('data', data)
          // const formateData = {
          //   ...resp.data,
          //   purchase_order_items: customerData,
          // }

          setStatusPurchaseOrder(resp.data.status)
          setFetchEditData({
            dataFetch: {
              ...resp.data,
              purchase_order_items: customerDataProduct,
            },
            isFetching: false,
          })
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setFetchEditData((prev) => ({ ...prev, isFetching: false }))
        }
      }
    }
    if (id) {
      fetchData()
    }

    return () => (didCancel = true)
  }, [id])

  if (fetchEditData.isFetching) return <Loading loading={fetchEditData.isFetching} />

  return statusPurchaseOrder !== 'pending' ? (
    <Redirect to={`/purchase-orders/${id}/view`} />
  ) : (
    <div id="edit_purchase_order_page">
      <Title className="page-title">Cập nhật đơn đặt hàng</Title>
      <PurchaseOrderForm path={'edit'} {...fetchEditData} editId={id} />
    </div>
  )
})
