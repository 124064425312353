import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography, DatePicker, InputNumber, Switch } from 'antd'
import {
  RollbackOutlined,
  PlusOutlined,
  FormOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'
import get from 'lodash/get'
import moment from 'moment'

import axiosExpand from 'Utils/axiosExpand'
import axios from 'Utils/axiosRexyAdmin'
import { useScrollToErrors, formatInputNumber, formatNumber, numToWord } from 'Utils'
import { BlockNavigation } from 'Components'
import { SearchField, CustomError } from './CustomComponent'

const { Title } = Typography
const { TextArea } = Input

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
    isSuperAdmin,
    initialValues,
  } = props

  const history = useHistory()
  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  const [isFetching, setIsFetching] = useState(false)
  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})
  const [approved, setApproved] = useState()
  const [supllierSearchTerm, setSupplierSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })
  const [purchaseSearchTerm, setPurchaseSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })

  useEffect(() => {
    let didCancel = false

    if (editId) {
      ;(async () => {
        const url = `/purchase_order_invoices/${editId}/edit`
        setIsFetching(true)
        try {
          const resp = await axios.get(url)
          if (editId && !didCancel) {
            setApproved(resp.data.approved)
            const supplierCode = get(resp.data, 'supplier.code', '')
            onSupllierSearch(supplierCode)

            const purchaseOrder = get(resp.data, 'purchase_order', {})
            onPurchaseSearch(purchaseOrder.order_number)

            setValues({
              ...values,
              ...resp.data,
              ...purchaseOrder,
              date: moment(resp.data.date, 'YYYY-MM-DD'),
              total: Number(resp.data.total),
            })
            setIsFetching(false)
            const notSetFirstTimeFetchedValuesYet = size(firstTimeFetchedValues) === 0

            if (notSetFirstTimeFetchedValuesYet)
              setFirstTimeFetchedValues({ ...values, ...resp.data })
          }
        } catch (error) {
          if (!didCancel) {
            setIsFetching(false)
          }
        }
      })()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId])

  const onSupllierSearch = (val) => {
    if (!val) {
      setSupplierSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
    } else {
      setSupplierSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
    }
  }

  const onSupplierSelect = (value) => {
    // setFieldValue('supplier_id', value)
    // setFieldValue('purchase_order_id', undefined)
    // setFieldValue('total_purchase_order', 0)
    setValues({
      ...values,
      purchase_order_id: undefined,
      total_amount: 0,
      paid_amount: 0,
      not_paid_amount: 0,
      supplier_id: value,
    })
    if (purchaseSearchTerm.result.length || purchaseSearchTerm.keyword.length) {
      setPurchaseSearchTerm((prev) => ({ ...prev, keyword: '', result: [] }))
    }
  }

  const onPurchaseSearch = (val) => {
    if (!val) {
      setPurchaseSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
    } else {
      setPurchaseSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
    }
  }

  const onPurchaseOrderSelect = (value, option) => {
    // setFieldValue('purchase_order_id', value)
    // setFieldValue('total_purchase_order', option.item.total)
    setValues({
      ...values,
      purchase_order_id: value,
      total_amount: Number(option.item.total_amount),
      paid_amount: Number(option.item.paid_amount),
      not_paid_amount: Number(option.item.not_paid_amount),
    })
  }

  const onApprove = (checked) => {
    setFieldValue('approved', checked)
  }

  const handleNumberChange = (name, limit, value) => {
    if (typeof value !== 'number') {
      return
    }
    // else if (value > limit) {
    //   return
    // }
    else if (!value) {
      setFieldValue(name, 0.01)
    } else {
      const roundDown = Number(value.toFixed(2))
      setFieldValue(name, roundDown)
    }
  }

  const loadingCondition = isSubmitting || isFetching
  const isApproved = values.approved
  const showApproved = () => {
    if (editId) return values.show_approved
    return values.total > values.not_paid_amount
  }

  return (
    <Form id="manage_supplier_debt_invoice_form" className="form-wrapper" onSubmit={handleSubmit}>
      <BlockNavigation
        values={values}
        initialValues={initialValues}
        path={path}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />
      <Title className="page-sub-title">Thông tin hoá đơn</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">
          Nhà cung cấp <span className="compulsory-field-symbol">*</span>
        </Title>
        <SearchField
          axios={axios}
          url="/suppliers/form/"
          value={values.supplier_id}
          onChange={onSupplierSelect}
          disabled={loadingCondition || isApproved}
          placeholder="Nhập mã hoặc tên nhà cung cấp để tìm kiếm"
          optionParams={{
            value: 'id',
            label: {
              prefix: 'code',
              suffix: 'name',
            },
          }}
          searchTerm={supllierSearchTerm}
          setSearchTerm={setSupplierSearchTerm}
          onSearch={onSupllierSearch}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="supplier_id" />
      </div>
      <div className="form-item">
        <Title className="field-title">
          Đơn đặt hàng NCC <span className="compulsory-field-symbol">*</span>
        </Title>
        <SearchField
          axios={axiosExpand}
          url="/purchase_orders/form-in-purchase-order-invoice/"
          extraParams={{ supplier_id: values.supplier_id }}
          value={values.purchase_order_id}
          onChange={onPurchaseOrderSelect}
          disabled={loadingCondition || !values.supplier_id || isApproved}
          placeholder="Nhập mã đơn đặt hàng NCC để tìm kiếm"
          optionParams={{ value: 'id', label: 'order_number' }}
          searchTerm={purchaseSearchTerm}
          setSearchTerm={setPurchaseSearchTerm}
          onSearch={onPurchaseSearch}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="purchase_order_id" />
      </div>

      {values.purchase_order_id ? (
        <>
          <div className="form-item switch-field direction-class">
            <Title className="field-title hint-title">Tổng giá trị đơn đặt hàng:</Title>
            <span className="to-word-money">
              {values.total_amount ? `${formatNumber(values.total_amount)} VND` : '- VND'}
            </span>
          </div>

          <div className="form-item switch-field direction-class">
            {/* <Title className="field-title hint-title">Số tiền đã trả:</Title> */}
            <Title className="field-title hint-title">Tổng số tiền các hoá đơn:</Title>
            <span className="to-word-money">
              {values.paid_amount ? `${formatNumber(values.paid_amount)} VND` : '- VND'}
            </span>
          </div>

          <div className="form-item switch-field direction-class">
            {/* <Title className="field-title hint-title">Số tiền còn phải trả:</Title> */}
            <Title className="field-title hint-title">Số tiền còn lại:</Title>
            <span className="to-word-money">
              {values.not_paid_amount ? `${formatNumber(values.not_paid_amount)} VND` : '- VND'}
            </span>
          </div>
        </>
      ) : null}

      <div className="form-item">
        <Title className="field-title">
          Số hoá đơn <span className="compulsory-field-symbol"> *</span>
        </Title>
        <Field
          as={Input}
          name="invoice_number"
          placeholder="Nhập số hoá đơn"
          disabled={loadingCondition || isApproved}
          value={values.invoice_number}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="invoice_number" />
      </div>

      <div className="form-item">
        <Title className="field-title">
          Ngày hoá đơn <span className="compulsory-field-symbol">*</span>
        </Title>
        <DatePicker
          inputReadOnly
          format={'DD-MM-YYYY'}
          value={values.date}
          onChange={(val) => setFieldValue('date', val)}
          style={{ width: '100%' }}
          placeholder="Chọn ngày hoá đơn"
          disabled={loadingCondition || isApproved}
          allowClear={false}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="date" />
      </div>

      <div className="form-item">
        <Title className="field-title">
          Số tiền hoá đơn (VND) <span className="compulsory-field-symbol">*</span>
        </Title>
        <Field
          as={InputNumber}
          name="total"
          placeholder="Nhập số tiền"
          disabled={loadingCondition || isApproved}
          value={values.total}
          onChange={(value) => handleNumberChange('total', 100000000000, value)}
          style={{ width: '100%' }}
          formatter={(value) => formatInputNumber(value)}
          min={0.01}
          max={100000000000}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="total" />
      </div>

      <div className="form-item switch-field direction-class">
        <Title className="field-title numb-to-word-title">Số tiền bằng chữ:</Title>
        <span className="to-word-money">{values.total ? numToWord(values.total) : '-'}</span>
      </div>

      <div className="form-item">
        <Title className="field-title">
          Ghi chú <span className="compulsory-field-symbol">*</span>
        </Title>
        <Field
          as={TextArea}
          name="note"
          placeholder="Ghi chú..."
          disabled={loadingCondition}
          value={values.note}
          onChange={handleChange}
          autoSize={{ minRows: 2, maxRows: 5 }}
        />

        <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
      </div>

      {/* {values.total > values.not_paid_amount ? ( */}
      {showApproved() ? (
        <div className="form-item switch-field">
          <Title className="field-title">Duyệt hoá đơn:</Title>
          <Switch
            className="switcher"
            checked={values.approved}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={onApprove}
            // disabled={loadingCondition}
            disabled={isSuperAdmin === false ? true : approved === true ? true : false}
          />
        </div>
      ) : (
        <></>
      )}

      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/supplier-debt-invoice')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo hoá đơn' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
