import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import {
  RollbackOutlined,
  FormOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useStoreState } from 'easy-peasy'

import { formatNumber } from 'Utils'
import axios from 'Utils/axiosRexyAdmin'

import './index.scss'

const { Title } = Typography

const INITIAL_PROFILE = {
  purchase_order: {},
  supplier: {},
  invoice_number: '',
  date: '',
  total: '',
  note: '',
  approved: false,
}

export default React.memo((props) => {
  const history = useHistory()
  const id = props.match?.params.id
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/purchase_order_invoices/${id}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setState((prev) => ({ ...prev, data: { ...resp.data }, loading: false }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [id])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/supplier-debt-invoice`)
  }

  const handleEdit = () => {
    history.push(`/supplier-debt-invoice/${id}/edit`)
  }

  return (
    <div id="view_supplier_debt_invoice" className="table-container">
      <Title className="page-title">Thông tin hoá đơn TT công nợ NCC</Title>

      <div className="body-container">
        <Title className="part-title">Thông tin chung</Title>
        <div className="part-field-wrapper">
          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={8}>
                Mã đơn đặt hàng NCC
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.purchase_order.order_number}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={8}>
                Số Hoá đơn
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.invoice_number}
              </Col>
            </Row>
          </Card.Grid>
          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={8}>
                Ngày hoá đơn
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.date ? moment(state.data.date).format('DD-MM-YYYY') : '-'}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={8}>
                Số tiền hoá đơn (VND)
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.total ? formatNumber(Number(state.data.total)) : '-'}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={8}>
                Mã nhà cung cấp
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.supplier.code}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={8}>
                Tên nhà cung cấp
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.supplier.name}
              </Col>
            </Row>
          </Card.Grid>

          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={8}>
                Đã duyệt
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.approved ? (
                  <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} />
                ) : (
                  <CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />
                )}
              </Col>
            </Row>
          </Card.Grid>
          <Card.Grid hoverable={false} className="view-item">
            <Row className="row">
              <Col className="field-title-wrapper text-style" span={8}>
                 Ghi chú
              </Col>
              <Col className="content-wrapper" span={16}>
                {state.data.note}
              </Col>
            </Row>
          </Card.Grid>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>
          {state.data.approved === false && isSuperAdmin === false && (
            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={state.loading}
              className="process-button"
              onClick={handleEdit}
            >
              Chỉnh sửa
            </Button>
          )}

          {isSuperAdmin === true && (
            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={state.loading}
              className="process-button"
              onClick={handleEdit}
            >
              Chỉnh sửa
            </Button>
          )}
        </div>
      </div>
    </div>
  )
})
