import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Button, Alert, Typography, InputNumber, Input } from 'antd'
import { FormOutlined, RollbackOutlined } from '@ant-design/icons'

import axios from 'Utils/axiosRexyAdmin'
import { formatInputNumber, formatNumber, preventEnterSubmit } from 'Utils'

const { Title } = Typography

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

export default React.memo((props) => {
  const {
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    setFieldValue,
    serverError,
    shouldFetch,
    setShouldFetch,
    initialData,
    setInitialData,
  } = props

  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    setShouldFetch(false)
    let didCancel = false
    const fetchData = async () => {
      const url = `/settings/new`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setValues({ ...values, ...resp.data.record })
          setIsFetching(false)
          setInitialData((prev) => ({ ...prev, data: resp.data.record }))
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    fetchData()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, shouldFetch])

  const handleCancel = () => {
    setInitialData((prev) => ({ ...prev, isEdit: false }))
    setFieldValue('usd_to_vnd_rate', initialData.data.usd_to_vnd_rate)
    setFieldValue('sticker_fees', initialData.data.sticker_fees)
  }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_settings_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">Thiết lập</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Tỉ giá USD<span className="compulsory-field-symbol"> *</span>
            </Title>
            {initialData.isEdit ? (
              <Input.Group compact>
                <Field
                  as={InputNumber}
                  style={{ width: '78%' }}
                  name="usd_to_vnd_rate"
                  placeholder="Nhập tỉ giá VND"
                  disabled={loadingCondition}
                  value={values.usd_to_vnd_rate}
                  onChange={(value) => {
                    if (value > 200000) {
                      return
                    } else if (!value) {
                      setFieldValue('usd_to_vnd_rate', undefined)
                    } else {
                      setFieldValue('usd_to_vnd_rate', Number(Number(value).toFixed(2)))
                    }
                  }}
                  formatter={(value) => formatInputNumber(value)}
                  min={0}
                  max={200000}
                />
                <Input
                  className="unit-disable-field"
                  style={{ textAlign: 'center' }}
                  disabled={true}
                  defaultValue="VND"
                />
              </Input.Group>
            ) : (
              <Input.Group compact>
                <Input
                  className="unit-disable-field"
                  style={{ width: '78%', backgroundColor: '#FAFAFA' }}
                  disabled={true}
                  value={formatNumber(initialData.data.usd_to_vnd_rate)}
                />
                <Input
                  className="unit-disable-field"
                  style={{ textAlign: 'center' }}
                  disabled={true}
                  defaultValue="VND"
                />
              </Input.Group>
            )}
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="usd_to_vnd_rate"
            />
          </div>
          <div className="form-item">
            <Title className="field-title">
              Sticker Fees<span className="compulsory-field-symbol"> *</span>
            </Title>
            {initialData.isEdit ? (
              <Input.Group compact>
                <Field
                  as={InputNumber}
                  style={{ width: '100%' }}
                  name="sticker_fees"
                  placeholder="Nhập sticker fees USD"
                  disabled={loadingCondition}
                  value={values.sticker_fees}
                  onChange={(value) => {
                    // console.log('Value ====>', value)
                    // if (!value) {
                    //   setFieldValue('sticker_fees', undefined)
                    // } else {
                    //   // setFieldValue('sticker_fees', Number(Number(value).toFixed(4)))
                    //   setFieldValue('sticker_fees', Number(value))
                    // }
                    setFieldValue('sticker_fees', Number(value))
                  }}
                  formatter={(value) => formatInputNumber(value)}
                  min={0}
                  max={10000}
                />
              </Input.Group>
            ) : (
              <Input.Group compact>
                <Input
                  className="unit-disable-field"
                  style={{ width: '100%', backgroundColor: '#FAFAFA' }}
                  disabled={true}
                  value={formatNumber(initialData.data.sticker_fees)}
                />
              </Input.Group>
            )}
            <ErrorMessage component={(props) => <CustomError {...props} />} name="sticker_fees" />
          </div>
        </div>
      </div>

      <div className="form-button-contain">
        {initialData.isEdit ? (
          <>
            <Button
              type="primary"
              className="cancel-button"
              icon={<RollbackOutlined />}
              loading={loadingCondition}
              onClick={handleCancel}
            >
              Huỷ
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              icon={<FormOutlined />}
              loading={loadingCondition}
            >
              Cập nhật
            </Button>
          </>
        ) : (
          <Button
            type="primary"
            className="submit-button"
            icon={<FormOutlined />}
            loading={loadingCondition}
            onClick={() => setInitialData((prev) => ({ ...prev, isEdit: true }))}
          >
            Chỉnh sửa
          </Button>
        )}
      </div>
    </Form>
  )
})
