import React, { useEffect } from 'react'
import { Table, Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { useDataApi } from 'Hooks'
import { pageSizeConfig } from 'Utils'

export default React.memo(() => {
  const history = useHistory()
  const { params, data, loading, pagination, doFetch, setSearchTerm } = useDataApi(
    {
      url: '/transactions/',
      key: 'records',
    },
    'transaction_type'
  )

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const manageStocks = useStoreState((state) => state.menuTabs['manage-stocks'])

  useEffect(() => {
    let didCancel
    if (manageStocks && !didCancel) {
      doFetch({
        order_by: '',
        order: '',
      })
      shouldRefresh({ key: 'manage-stocks', status: false })
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageStocks])
  const renderFiltered = (value) => {
    // if (value === undefined) return ['in', 'out']
    if (value) {
      if (Array.isArray(value)) return value
      return value.split(',')
    }
    return null
  }
  const columns = [
    {
      title: 'Mã phiếu',
      dataIndex: 'transaction_number',
      sortOrder: pagination.order_by === 'transaction_number' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
    },
    {
      title: 'Loại hàng',
      dataIndex: 'item_type',
      sortOrder: pagination.order_by === 'item_type' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      filters: [
        {
          text: 'Tem',
          value: 'label',
        },
        {
          text: 'Thành phẩm',
          value: 'finish_product',
        },
        {
          text: 'Bao bì',
          value: 'packing',
        },
        {
          text: 'Nguyên vật liệu',
          value: 'material',
        },
        {
          text: 'Bán thành phẩm',
          value: 'semi_finish_product',
        },
      ],
      onFilter: (value, record) => record.item_type === value,
      filteredValue: renderFiltered(params.item_type),
      render: (type) =>
        type === 'packing'
          ? 'Bao bì '
          : type === 'material'
          ? 'Nguyên vật liệu '
          : type === 'label'
          ? 'Tem '
          : type === 'semi_finish_product'
          ? 'Bán thành phẩm '
          : type === 'finish_product'
          ? 'Thành phẩm '
          : '-',
    },
    {
      title: 'Loại phiếu',
      dataIndex: 'transaction_type',
      sortOrder: pagination.order_by === 'transaction_type' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      filters: [
        {
          text: 'Phiếu xuất kho',
          value: 'out',
        },
        {
          text: 'Phiếu nhập kho',
          value: 'in',
        },
        {
          text: 'Phiếu deposit nhập',
          value: 'deposit_in',
        },
        {
          text: 'Phiếu deposit xuất',
          value: 'deposit_out',
        },
      ],
      render: (value, row, index) =>
        row.transaction_type === 'out'
          ? 'Phiếu xuất kho'
          : row.transaction_type === 'in'
          ? 'Phiếu nhập kho'
          : row.transaction_type === 'deposit_in'
          ? 'Phiếu deposit nhập'
          : 'Phiếu deposit xuất',
      onFilter: (value, record) => record.transaction_type === value,
      filteredValue: renderFiltered(params.transaction_type),
    },
    {
      title: 'Tạo bởi',
      dataIndex: 'created_by',
      sortOrder: pagination.order_by === 'created_by' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      render: (type) => (type === 'user' ? 'Người dùng' : 'Hệ thống'),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      sortOrder: pagination.order_by === 'created_at' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      render: (date) => moment(date).format('DD-MM-YYYY'),
    },
    {
      key: 'x',
      width: 90,
      render: (record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button>
      </div>
    )
  }

  const handCreate = () => history.push('/manage-stocks/new')

  const handleView = (record) => history.push(`/manage-stocks/${record.id}/view`)

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Tạo phiếu mới
        </Button>
      </div>
    )
  }

  return (
    <div id="manage_export_import_stocks_table">
      <Table
        title={renderTitle}
        dataSource={data}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 620, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
    </div>
  )
})
