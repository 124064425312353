import React from 'react'
import { Typography } from 'antd'

import SupplierForm from '../Form'

const { Title } = Typography

export default () => {
  return (
    <div id="new_supplier_page">
      <Title className="page-title">Tạo nhà cung cấp mới</Title>

      <SupplierForm path={'new'} />
    </div>
  )
}
