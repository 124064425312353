import React from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Button, Alert, Typography, DatePicker, Select } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import moment from 'moment'

const { Title } = Typography
const { Option } = Select

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})



export default (props) => {
  const { values, isSubmitting, handleSubmit, setFieldValue, serverError } = props
  
  const loadingCondition = isSubmitting

  const commodityList = {
    all: 'Tất cả', 
    packing: 'Bao bì', 
    material: 'Nguyên vật liệu', 
    label: 'Tem', 
    semi_finish_product: 'Bán thành phẩm', 
    finish_product: 'Thành phẩm'
  }

  function disabledDate(current) {
    return current && current < moment(values.start_date, 'YYYY-MM-DD').startOf('day')
  }

  const onDateChange = (fieldName, val) => {
    setFieldValue(fieldName, val.format('YYYY-MM-DD'))

    if (fieldName === 'start_date' && val > moment(values.end_date, 'YYYY-MM-DD')) {
      setFieldValue('end_date', val)
    }
  }

  const handleSelectFieldChange = (value) => {
    setFieldValue('commodity_type', value)
  }

  return (
    <Form id="manage_stock_reports_form" className="form-wrapper" onSubmit={handleSubmit}>
      <Title className="page-sub-title">Xuất báo cáo xuất/nhập tồn</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">

        <div className="form-item">
            <Title className="field-title">
            Loại hàng hóa <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Select}
              name="commodity_type"
              style={{ width: '100%', maxWidth: 350 }}
              placeholder="Chọn loại hàng hóa"
              disabled={loadingCondition}
              value={values.commodity_type}
              optionFilterProp="children"
              // showSearch={true}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
              onChange={(value) => handleSelectFieldChange(value)}
            >
              {Object.keys(commodityList).map((key) => (
                <Option key={key} value={commodityList.key}>
                  {commodityList[key]}
                </Option>
              ))}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="country" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Từ ngày <span className="compulsory-field-symbol">&nbsp;*</span>
            </Title>
            <Field name="start_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={moment(values.start_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('start_date', val)}
                  style={{ width: '100%', maxWidth: 350 }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="start_date" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Đến ngày <span className="compulsory-field-symbol">&nbsp;*</span>
            </Title>
            <Field name="end_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={moment(values.end_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('end_date', val)}
                  style={{ width: '100%', maxWidth: 350 }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="end_date" />
          </div>
        </div>
      </div>

      <div className="form-button-contain">
        <Button
          type="primary"
          className="submit-button"
          icon={<DownloadOutlined />}
          loading={loadingCondition}
          onClick={() => handleSubmit()}
        >
          Xuất báo cáo
        </Button>
      </div>
    </Form>
  )
}
