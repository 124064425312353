import React, { useState, useEffect } from 'react'
import { Table, Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { useDataApi } from 'Hooks'
import { pageSizeConfig } from 'Utils'
import DeleteModal from './DeleteModal'

export default React.memo((props) => {
  const history = useHistory()
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/customers/',
    key: 'records',
  })

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    name: '',
    visible: false,
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const customerState = useStoreState((state) => state.menuTabs['customers'])

  useEffect(() => {
    let didCancel
    if (customerState && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'customers', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState])

  const columns = [
    {
      title: 'Mã khách hàng',
      dataIndex: 'code',
      sortOrder: pagination.order_by === 'code' ? `${pagination.order}` : null,
      width: 150,
      sorter: true,
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      sortOrder: pagination.order_by === 'name' ? `${pagination.order}` : null,
      width: 190,
      sorter: true,
    },
    {
      title: 'Quốc gia',
      dataIndex: 'country',
      sortOrder: pagination.order_by === 'country' ? `${pagination.order}` : null,
      width: 130,
      sorter: true,
    },
  ]

  if (isSuperAdmin) {
    columns.push({
      title: 'Phone/Fax',
      dataIndex: 'phone_number',
      sortOrder: pagination.order_by === 'phone_number' ? `${pagination.order}` : null,
      width: 140,
      sorter: true,
    })
  }

  columns.push({
    key: 'x',
    width: 150,
    render: (record) => actionColumn(record),
  })

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}
        <Button className="action-item update" type="link" onClick={(e) => handleEdit(record, e)}>
          Sửa
        </Button>

        <Button className="action-item delete" type="link" onClick={(e) => handleDelete(record, e)}>
          Xoá
        </Button>
      </div>
    )
  }

  const handleView = (record) => {
    history.push(`/customers/${record.id}/view`)
  }

  const handleEdit = (record, e) => {
    e.stopPropagation()
    history.push(`/customers/${record.id}/edit`)
  }

  const handleDelete = (record, e) => {
    e.stopPropagation()
    setDeleteModal({ id: record.id, name: record.name, visible: true })
  }

  const handCreate = () => history.push('/customers/new')

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Thêm khách hàng
        </Button>
      </div>
    )
  }

  return (
    <div id="manage_customer_table">
      <Table
        title={renderTitle}
        className="customers-table"
        dataSource={data}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: isSuperAdmin ? 800 : 650, y: 325 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />

      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
})
