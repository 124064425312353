import React from 'react'
import { Typography } from 'antd'

import UserForm from '../Form'

const { Title } = Typography

export default (props) => {
  const id = props.match?.params.id
  return (
    <div id="edit_user_page">
      <Title className="page-title">Cập nhật tài khoản</Title>
      <UserForm path={'edit'} editId={id} />
    </div>
  )
}
