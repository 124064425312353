import React, { useEffect, useMemo, useCallback } from 'react'
import { Table, Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import moment from 'moment'

import { useDataApi } from 'Hooks'
import { formatNumber, pageSizeConfig } from 'Utils'

const PaymentTransaction = React.memo(() => {
  const history = useHistory()
  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/payment_transactions/',
    key: 'records',
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const paymentTransactions = useStoreState((state) => state.menuTabs['payment-transactions'])

  useEffect(() => {
    let didCancel
    if (paymentTransactions && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'payment-transactions', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTransactions])

  const handCreate = useCallback(() => {
    history.push('/payment-transactions/new')
  }, [history])

  const handleView = (record) => {
    history.push(`/payment-transactions/${record.id}/view`)
  }

  const handleEdit = useCallback(
    (record, e) => {
      e.stopPropagation()
      history.push(`/payment-transactions/${record.id}/edit`)
    },
    [history]
  )

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = useCallback(() => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Tạo thanh toán order
        </Button>
      </div>
    )
  }, [handCreate, setSearchTerm])

  const actionColumn = useCallback(
    (record) => {
      return (
        <div className="table-actions">
          <Button className="action-item update" type="link" onClick={(e) => handleEdit(record, e)}>
            Sửa
          </Button>
        </div>
      )
    },
    [handleEdit]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Mã thanh toán order',
        dataIndex: 'code',
        sortOrder: pagination.order_by === 'code' ? `${pagination.order}` : null,
        width: 160,
        sorter: true,
      },
      {
        title: 'Ngày',
        dataIndex: 'date',
        width: 170,
        sorter: true,
        render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      },
      {
        title: 'Mã order',
        dataIndex: 'sale_order.order_number',
        sortOrder: pagination.order_by === 'sale_order.order_number' ? `${pagination.order}` : null,
        width: 180,
        sorter: true,
      },
      {
        title: 'Khách hàng',
        dataIndex: 'customer.code',
        sortOrder: pagination.order_by === 'customer.code' ? `${pagination.order}` : null,
        width: 200,
        sorter: true,
      },
      {
        title: 'Hình thức thanh toán',
        dataIndex: 'readable_payment_method',
        sortOrder: pagination.order_by === 'readable_payment_method' ? `${pagination.order}` : null,
        width: 170,
      },
      {
        title: 'Số tiền (USD)',
        dataIndex: 'amount',
        sortOrder: pagination.order_by === 'amount' ? `${pagination.order}` : null,
        width: 170,
        sorter: true,
        render: (record) => formatNumber(Number(record)),
      },
      {
        key: 'x',
        width: 160,
        render: (record) => actionColumn(record),
      },
    ],
    [actionColumn, pagination.order, pagination.order_by]
  )

  const dataSource = useMemo(
    () =>
      data.map((order) => ({
        ...order,
        'customer.code': `${order.customer.code} : ${order.customer.name}`,
        'sale_order.order_number': order.sale_order.order_number,
      })),
    [data]
  )

  return (
    <div id="manage_payment_transaction_table">
      <Table
        title={renderTitle}
        className="users-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1050, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
    </div>
  )
})

export default PaymentTransaction
