import React, { useState, useEffect } from 'react'
import { Typography, message } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'
import SaleOrderForm from '../Form'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  const [fetchEditData, setFetchEditData] = useState({ dataFetch: {}, isFetching: true })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/commodity_exports/${id}/edit?is_drafting=false`
      setFetchEditData((prev) => ({ ...prev, isFetching: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          const dataComputed = await computeData(resp.data)

          setFetchEditData({
            dataFetch: { ...dataComputed },
            isFetching: false,
          })
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setFetchEditData((prev) => ({ ...prev, isFetching: false }))
        }
      }
    }
    if (id) fetchData()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const computeData = async (response) => {
    const url = `/sale_orders/form-in-commodity-export/?customer_id=${response.customer_id}&current_sale_order_id=${response.sale_order_in_paper_id}&current_commodity_export_id=${id}&is_drafting=false`
    let result
    try {
      result = await axiosExpand.get(url)
    } catch (error) {}
    let cloneData = cloneDeep(response)

    const formatedExportItems = cloneData.commodity_export_items.map((item) => {
      const filteredOrder = result.data.records.filter((record) => record.id === item.sale_order_id)

      let filteredOrderItem = [
        { order_number: undefined, specification: '', box_size: 0, product: {} },
      ]
      if (filteredOrder.length) {
        filteredOrderItem = filteredOrder[0].sale_order_items.filter(
          (record) => item.sale_order_item_id === record.id
        )
      }

      // TODO: HERE
      let purchase_orders = [],
        purchaseOrderOptions = []

      if (item?.purchase_orders && isArray(item?.purchase_orders)) {
        purchase_orders = item.purchase_orders
          .map((purchase) => purchase?.id)
          .filter((purchase) => !!purchase)

        purchaseOrderOptions = item.purchase_orders
      }

      return {
        ...item,
        purchase_orders,
        purchaseOrderOptions,
        sale_order_items: filteredOrder[0]?.sale_order_items || [],
        order_number: filteredOrder[0]?.order_number,
        specification: filteredOrderItem[0].specification,
        box_size: Number(filteredOrderItem[0].box_size),
        product: filteredOrderItem[0].product,
        max_quantity: filteredOrderItem[0].box_quantity,
      }
    })

    cloneData = {
      ...cloneData,
      sale_order_id: cloneData.sale_order_id ? cloneData.sale_order_id : undefined,
      order_items: result.data.records,
      date: moment(cloneData.date, 'YYYY-MM-DD'),
      packing_date: moment(cloneData.packing_date, 'YYYY-MM-DD'),
      depart_date: moment(cloneData.depart_date, 'YYYY-MM-DD'),
      arrival_date: moment(cloneData.arrival_date, 'YYYY-MM-DD'),
      commodity_export_items: formatedExportItems,
    }

    return cloneData
  }

  return (
    <div id="edit_commodity_export_page">
      <Title className="page-title">Cập nhật đơn xuất hàng</Title>
      <SaleOrderForm path={'edit'} {...fetchEditData} editId={id} />
    </div>
  )
})
