import React from 'react'
import { Typography } from 'antd'

import ImportExportForm from '../Form'

const { Title } = Typography

export default React.memo((props) => {
  const purchaseOrderId = props.match?.params.purchase_order_id

  return (
    <div id="new_export_import_stocks_page">
      <Title className="page-title">Tạo phiếu nhập/xuất kho</Title>
      <ImportExportForm path={'new'} purchaseOrderId={purchaseOrderId} />
    </div>
  )
})
