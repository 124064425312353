import React from 'react'
import { Typography, Modal, Card, Row, Col } from 'antd'

import { formatNumber } from 'Utils'

const { Text } = Typography
const { Grid } = Card

const modalInitialValues = {
  sale_order_id: undefined,
  sale_order_materials: [
    {
      material_id: undefined,
      material_name: '',
      price: undefined,
      use_new_price: false,
    },
  ],
}

export default React.memo((props) => {
  const { data, loading, visible, setEditProductMaterial } = props

  const renderTitle = () => <Text className="modal-title">Giá nguyên vật liệu</Text>

  const handlePressOK = async () =>
    setEditProductMaterial({ visible: false, loading: true, data: modalInitialValues })

  const handleCancel = () =>
    setEditProductMaterial({ visible: false, loading: true, data: modalInitialValues })

  return (
    <Modal
      title={renderTitle()}
      centered
      visible={visible}
      cancelButtonProps={{
        loading,
        style: { display: 'none' },
      }}
      okButtonProps={{
        loading,
        style: { margin: 10 },
      }}
      okText={'OK'}
      onOk={handlePressOK}
      onCancel={handleCancel}
      className="edit-material-table-modal"
    >
      <Card className="view-wrapper">
        <Grid hoverable={false} className="view-item">
          <Row className="row">
            <Col className="content-wrapper" style={{ fontWeight: 'bold' }} span={8}>
              Tên
            </Col>
            <Col className="content-wrapper" style={{ fontWeight: 'bold' }} span={9}>
              Giá (VND)
            </Col>
          </Row>
        </Grid>
        {data.sale_order_materials.map((material, index) => {
          return (
            <Grid hoverable={false} className="view-item" key={index}>
              <Row className="row">
                <Col className="content-wrapper" span={8}>
                  {material.material_name}
                </Col>
                <Col className="content-wrapper" span={9}>
                  {material.price ? formatNumber(material.price) : '-'}
                </Col>
              </Row>
            </Grid>
          )
        })}
      </Card>
    </Modal>
  )
})
