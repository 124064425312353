import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Button, Alert, Typography, InputNumber } from 'antd'
import { RollbackOutlined, FormOutlined, ToolOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { useScrollToErrors, preventEnterSubmit, formatNumber } from 'Utils'
import { CustomError, OrderItems } from './CustomComponent'
import CustomUploadDocument from '../CustomUploadDocument'
import { OtherDocumentsTable, BlockNavigation } from 'Components'
const { Title } = Typography

export default React.memo((props) => {
  const {
    values,
    setSubmitting,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    errors,
    touched,
    setFieldValue,
    dataFetch,
    documents,
    setDocuments,
    initialValues,
    firstTimeFetchedValues,
  } = props
  const history = useHistory()
  // const [isFetching, setIsFetching] = useState(false)
  const [totalPI, setTotalPI] = useState(0)

  const [totalRow, setTotalRow] = useState({
    totalBoxQuantity: 0,
    totalQuantity: 0,
    totalSize: 0,
    totalFobProduct: 0,
    total_sticker_fees_quantity: 0,
  })
  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  useEffect(() => {
    let didCancel = false
    const calTotalPI = () => {
      // let totalPI = Math.ceil(
      //   Number(
      //     totalRow.totalFobProduct + totalRow.total_sticker_fees_quantity * values.sticker_fees
      //   )
      // )

      let totalPI = Number(
        totalRow.totalFobProduct + totalRow.total_sticker_fees_quantity * values.sticker_fees
      ).toFixed(2)

      setTotalPI(totalPI)
    }

    if (!didCancel) {
      calTotalPI()
    }

    return () => (didCancel = true)
  }, [totalRow.totalFobProduct, totalRow.total_sticker_fees_quantity, values.sticker_fees])

  useEffect(() => {
    if (dataFetch && Object.keys(dataFetch).length > 0) {
      setValues({ ...values, ...dataFetch })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetch])

  const handleStickerFeesChange = (limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue('sticker_fees', 0)
    } else {
      const roundDown = Number(value.toFixed(4))
      setFieldValue('sticker_fees', roundDown)
    }
  }

  const loadingCondition = isSubmitting

  return (
    <Form
      id="manage_proforma_invoice_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <BlockNavigation
        values={values}
        initialValues={initialValues}
        path={'edit'}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />
      <Title className="page-sub-title">Proforma Invoice</Title>
      {serverError && (
        <Alert
          message={serverError}
          type="error"
          className="server-error"
          showIcon
          style={{ maxWidth: 400, marginLeft: 0 }}
        />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item readonly">
            <Title className="field-title">Order No:</Title>
            <span>{values.order_number ? values.order_number : '-'}</span>
          </div>

          <div className="form-item readonly">
            <Title className="field-title">Ngày:</Title>
            <span>
              {values.date ? moment(values.date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
            </span>
          </div>

          <div className="form-item readonly">
            <Title className="field-title">Khách hàng:</Title>
            <span>{values.customer.name}</span>
          </div>

          <div className="total-wrapper">
            <div className="requirement">
              <Title className="requirement-title">Điều kiện sản xuất lô hàng:</Title>
              <span className="requirement-content" style={{ wordBreak: 'break-word' }}>
                {values.production_requirement ? values.production_requirement : '-'}
              </span>
            </div>
          </div>

          <div className="form-item">
            <Title className="field-title">Thông tin sản phẩm:</Title>
            <OrderItems
              name="purchase_order_items"
              values={values}
              loadingCondition={loadingCondition}
              setFieldValue={setFieldValue}
              totalRow={totalRow}
              setTotalRow={setTotalRow}
            />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Sticker fees <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={InputNumber}
              style={{ width: '100%' }}
              name={`sticker_fees`}
              placeholder="Nhập giá FOB"
              disabled={loadingCondition}
              value={values.sticker_fees}
              onChange={(value) => handleStickerFeesChange(100000, value)}
              // formatter={(value) => formatInputNumber(value)}
              min={0}
              max={100000}
              step={0.001}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name={`sticker_fees`} />
          </div>

          <div className="form-item readonly">
            <Title className="field-title">Tổng tiền:</Title>
            {/* {totalPI ? formatNumber(totalPI) + ' USD' : '-'} */}
            <span style={{ lineHeight: 1.23 }}>
              {totalPI ? `${formatNumber(totalPI)} USD` : '- USD'}
            </span>
          </div>

          <div className="other-documents">
            <OtherDocumentsTable documents={documents} setDocuments={setDocuments} action="edit" />
            <CustomUploadDocument
              documents={documents}
              setDocuments={setDocuments}
              setSubmitting={setSubmitting}
            />
          </div>
        </div>
      </div>

      <div
        className="form-button-contain"
        // style={values.status !== 'pending_pi' ? { justifyContent: 'flex-start' } : {}}
      >
        {/* <div className="left-button-contain"> */}
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/sale-orders')
          }
        >
          Trở lại
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={<FormOutlined />}
          loading={loadingCondition}
          onClick={() => {
            setShouldScroll(true)
          }}
        >
          Cập nhật
        </Button>
        {dataFetch.status === 'approved_pi' && values.qualify_to_produce ? (
          <Button
            type="primary"
            htmlType="submit"
            className="produce-button"
            icon={<ToolOutlined />}
            loading={loadingCondition}
            onClick={() => {
              setShouldScroll(true)
              setFieldValue('status', 'producing')
              // return handleSubmit()
            }}
          >
            Tiến hành sản xuất
          </Button>
        ) : null}
        {/* </div> */}

        {dataFetch.status === 'pending_pi' ? (
          <Button
            type="primary"
            htmlType="submit"
            className="produce-button"
            icon={<ToolOutlined />}
            loading={loadingCondition}
            onClick={() => {
              setShouldScroll(true)
              setFieldValue('status', 'approved_pi')
            }}
          >
            Duyệt Proforma Invoice
          </Button>
        ) : null}
      </div>
    </Form>
  )
})
