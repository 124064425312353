import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'

import axios from 'Utils/axiosRexyAdmin'
import TimeAttendenceForm from './Form'
import TimeAttendenceTable from './Table'
import useDataApi from './hooks'
import './index.scss'

const TimeAttendence = React.memo(() => {
  const {
    params,
    data,
    loading,
    searchTerm,
    pagination,
    errors,
    doFetch,
    setSearchTerm,
  } = useDataApi({
    url: `/time_attendance/`,
    key: 'records',
  })

  const [staffList, setStaffList] = useState({
    result: [],
    searching: true,
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const productsHistoryState = useStoreState((state) => state.menuTabs['products-history'])

  useEffect(() => {
    let didCancel
    if (productsHistoryState && !didCancel) {
      doFetch((prev) => ({ ...prev, current: 1, pageSize: 10, order_by: '', order: '' }))
      shouldRefresh({ key: 'products-history', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsHistoryState])

  useEffect(() => {
    let didCancel = false

    const fetchStaffs = async () => {
      setStaffList((prev) => ({
        ...prev,
        searching: true,
      }))

      const url = '/staffs/'
      try {
        const resp = await axios.get(url, {
          params: {
            page: 1,
            per_page: 100000,
          },
        })

        if (!didCancel) {
          setStaffList({
            result: resp.data.records,
            searching: false,
          })
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setStaffList((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    fetchStaffs()
    return () => (didCancel = true)
  }, [])

  return (
    <div id="edit_time_attendence_page">
      <TimeAttendenceForm
        serverError={errors}
        loading={loading}
        staffList={staffList}
        params={params}
        doFetch={doFetch}
      />
      <TimeAttendenceTable
        data={data}
        loading={loading}
        pagination={pagination}
        doFetch={doFetch}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
    </div>
  )
})

export default TimeAttendence
