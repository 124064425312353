import React, { useState, useEffect } from 'react'
import { Button, message, Typography } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { useDataApi } from 'Hooks'
// import DeleteModal from './DeleteModal'
import ExportFileExcelForm from '../Form/ExportFile'
import axiosExpand from 'Utils/axiosExpand'
const { Title } = Typography
const defaultDates = {
  startDate: moment(),
  endDate: moment(),
}

export default React.memo(() => {
  const { doFetch } = useDataApi({
    url: '/salary_calculation/',
    key: 'records',
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const salaryState = useStoreState((state) => state.menuTabs['salary'])
  const [dates, setDates] = useState(defaultDates)

  useEffect(() => {
    let didCancel
    if (salaryState && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'salary', status: false })
    }
    return () => (didCancel = true)
  }, [doFetch, salaryState, shouldRefresh])

  const FileDownload = require('js-file-download')

  const download = async ({ url, fileName }) => {
    try {
      const result = await axiosExpand.get(url, {
        responseType: 'arraybuffer',
        params: {
          start_date: moment(dates.startDate).format('YYYY-MM-DD'),
          end_date: moment(dates.endDate).format('YYYY-MM-DD'),
        },
      })
      FileDownload(result.data, fileName)
    } catch (error) {
      message.error('Đã có lỗi xảy ra, xin vui lòng tải lại trang và thử lại.')
    }
  }

  const handleOk = () => {
    download({
      url: `/salary_calculation/export/`,
      fileName: `bang-tinh-luong.xlsx`,
    })
    // setVisible(false)
    // setDates(defaultDates)
  }

  // const handleTableChange = (tablePagination, filters, sorter) =>
  //   doFetch((prev) => ({
  //     ...prev,
  //     per_page: tablePagination.pageSize,
  //     page: tablePagination.current,
  //     order_by: sorter.field && sorter.order ? sorter.field : '',
  //     order: sorter.order ? sorter.order : '',
  //     ...filters,
  //   }))

  const renderTitle = () => {
    return (
      <div className="title-container">
        {/* <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        /> */}
        <div>
          <Button
            type="primary"
            className="create-button"
            onClick={handleOk}
            icon={<DownloadOutlined />}
            style={{ marginRight: 10 }}
          >
            Xuất File Excel
          </Button>
          {/* 
          <Button
            className="create-button"
            type="primary"
            icon={<PlusOutlined className="create-icon" />}
            onClick={handCreate}
          >
            Thêm bảng tính lương
          </Button> */}
        </div>
      </div>
    )
  }

  return (
    <div id="manage_staffs_salary_table">
      {/* <Modal
        title="Bảng tính lương"
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        okText="Xuất Excel"
      > */}
      <Title className="page-sub-title">Xuất bảng tính lương</Title>
      <ExportFileExcelForm dates={dates} setDates={setDates} />

      {renderTitle()}
      {/* </Modal> */}
      {/* <Table
        title={renderTitle}
        className="staffs-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, showSizeChanger: true }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1300, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      /> */}

      {/* <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} /> */}
    </div>
  )
})
