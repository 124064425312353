import * as yup from 'yup'

const invalidEmail = 'Email không hợp lệ'
const requiredEmail = 'Vui lòng nhập email'
const passwordNotLongEnough = 'Password cần tối thiểu 4 ký tự'
const signinTokenNotLongEnough = 'Mã đăng nhập cần tối thiểu 6 ký tự'
const signinTokenRequired = 'Vui lòng nhập mã đăng nhập'
const requiredPass = 'Vui lòng nhập mật khẩu'

const loginStepOneSchema = yup.object().shape({
  email: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .email(invalidEmail)
    .required(requiredEmail),
  password: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .min(4, passwordNotLongEnough)
    .max(255)
    .required(requiredPass),
})

const loginStepTwoSchema = yup.object().shape({
  signinToken: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .min(6, signinTokenNotLongEnough)
    .max(255)
    .required(signinTokenRequired),
})

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .email(invalidEmail)
    .required(requiredEmail),
})

export { loginStepOneSchema, forgotPasswordSchema, loginStepTwoSchema }
