import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const invalidEmail = 'Email không hợp lệ'
const maxLength = 'Tối đa 255 ký tự'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageSuppliersSchema = yup.object().shape({
  code: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  contact_name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  email: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .email(invalidEmail)
    .required(requiredField)
    .max(255, maxLength),
  address: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  info: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  phone_number: yup.string().trim('Vui lòng không nhập khoảng trắng').strict().max(255, maxLength),
  bank_name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  bank_branch: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  bank_number: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  bank_account_name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
})

export default manageSuppliersSchema
