import React, { useEffect, useCallback, useState } from 'react'
import { Table, Input, Button, Modal, message } from 'antd'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'
import axiosExpand from 'Utils/axiosExpand'
import { DownloadOutlined } from '@ant-design/icons'

import useDataApi from './Hooks'
import Note from './Note'
import { formatNumber, pageSizeConfig } from 'Utils'
import ExportFileExcelForm from './Form'

const defaultDates = { startDate: moment(), endDate: moment() }

const computedData = (data) => {
  let result = []
  data.forEach((item) => {
    let row = {
      ...item,
      commodity_export_date: item.commodity_export.date,
      'commodity_export.invoice_number': item.commodity_export.invoice_number,
    }

    if (item.payment_transactions.length) {
      item.payment_transactions.forEach((item, i) => {
        row[`paid-${i}`] = item.amount
        row[`date-${i}`] = item.date
      })
    }

    result.push(row)
  })
  return result
}

const computedTableLength = (transactionCount) => {
  let length = 1240

  for (let i = 0; i < transactionCount; i++) {
    length += 140
  }
  return length
}

export default React.memo(() => {
  const { data, count, loading, pagination, doFetch, setSearchTerm } = useDataApi()
  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const customerPayment = useStoreState((state) => state.menuTabs['customer-payment'])
  const [visible, setVisible] = useState(false)

  const [dates, setDates] = useState(defaultDates)

  useEffect(() => {
    let didCancel
    if (customerPayment && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'customer-payment', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPayment])

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch((prev) => ({
      ...prev,
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
    }))
  }
  const showModal = () => {
    setVisible(!visible)
  }
  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <div>
          <Input.Search
            placeholder="Tìm kiếm..."
            className="search-bar"
            defaultValue={keyword}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div>
          <Button type="primary" onClick={showModal} icon={<DownloadOutlined />}>
            Xuất File Excel
          </Button>
        </div>
      </div>
    )
  }

  const computedColumns = useCallback(() => {
    let columns = [
      {
        title: 'Mã order',
        dataIndex: 'order_number',
        sortOrder: pagination.order_by === 'order_number' ? `${pagination.order}` : null,
        width: 190,
        sorter: true,
      },
      {
        title: 'Ngày hợp đồng',
        dataIndex: 'date',
        sortOrder: pagination.order_by === 'date' ? `${pagination.order}` : null,
        width: 150,
        sorter: true,
        // render: (date) => date ?? '-',
        render: (date) => (date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'),
      },
      {
        title: 'Số hoá đơn',
        dataIndex: 'commodity_export.invoice_number',
        sortOrder:
          pagination.order_by === 'commodity_export.invoice_number' ? `${pagination.order}` : null,
        width: 150,
        // sorter: true,
        render: (invoice) => invoice ?? '-',
      },
      {
        title: 'Ngày hoá đơn',
        dataIndex: 'commodity_export_date',
        sortOrder: pagination.order_by === 'commodity_export_date' ? `${pagination.order}` : null,
        width: 150,
        sorter: true,
        render: (date) => (date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'),
      },
      {
        title: 'Tổng tiền KH cần TT',
        dataIndex: 'total_amount',
        sortOrder: pagination.order_by === 'total_amount' ? `${pagination.order}` : null,
        width: 150,
        // sorter: true,
        render: (total) => (total ? formatNumber(Number(total.toFixed(2))) : '-'),
      },
      {
        title: 'Số tiền KH đã TT',
        dataIndex: 'paid_amount',
        sortOrder: pagination.order_by === 'paid_amount' ? `${pagination.order}` : null,
        width: 150,
        // sorter: true,
        render: (paid) => (paid ? formatNumber(Number(paid.toFixed(2))) : '-'),
      },
      {
        title: 'Số tiền KH còn phải TT',
        dataIndex: 'debt',
        sortOrder: pagination.order_by === 'debt' ? `${pagination.order}` : null,
        width: 150,
        // sorter: true,
        render: (debt) => (debt ? formatNumber(Number(debt.toFixed(2))) : '-'),
      },
    ]

    Array.from(Array(count).keys()).forEach((i) => {
      columns.push({
        title: `Số tiền USD KH đã TT (Lần ${i + 1})`,
        dataIndex: `paid-${i}`,
        width: 140,
        render: (paid) => (paid ? formatNumber(paid) : '-'),
      })
      columns.push({
        title: `Ngày TT (Lần ${i + 1})`,
        dataIndex: `date-${i}`,
        width: 140,
        render: (date) => (date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'),
      })
    })

    columns.push({
      title: 'Ghi chú',
      dataIndex: 'payment_note',
      width: 240,
      render: (note, row) => <Note note={note} row={row} doFetch={doFetch} />,
    })

    return columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, pagination.order, pagination.order_by])

  const FileDownload = require('js-file-download')

  const download = async ({ url, fileName }) => {
    try {
      const result = await axiosExpand.get(url, {
        responseType: 'arraybuffer',
        params: {
          start_date: moment(dates.startDate).format('YYYY-MM-DD'),
          end_date: moment(dates.endDate).format('YYYY-MM-DD'),
        },
      })
      FileDownload(result.data, fileName)
    } catch (error) {
      message.error('Đã có lỗi xảy ra, xin vui lòng tải lại trang và thử lại.')
    }
  }

  const handleOk = () => {
    download({
      url: `/payment_transactions/export/statistic/`,
      fileName: `thong-ke-cong-no-khach-hang.xlsx`,
    })
    setVisible(false)
    setDates(defaultDates)
  }

  const onCancel = () => {
    setVisible(false)
    setDates(defaultDates)
  }

  return (
    <div id="manage_customer_payment_table">
      <Modal
        title="Công nợ khách hàng thanh toán"
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        okText="Xuất Excel"
      >
        <ExportFileExcelForm dates={dates} setDates={setDates} />
      </Modal>
      <Table
        title={renderTitle}
        className="customer-payment-table"
        dataSource={computedData(data)}
        columns={computedColumns()}
        pagination={{ ...pagination, ...pageSizeConfig }}
        bordered
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: computedTableLength(count), y: 680 }}
      />
    </div>
  )
})
