import React, { useEffect, useState } from 'react'
import { Alert, Col, Button, InputNumber, Select, Spin, message } from 'antd'
import { PlusOutlined, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Field, FieldArray, ErrorMessage } from 'formik'

import { formatInputNumber, getFieldName } from 'Utils'
import { noData } from 'Components'
import { useDebounce } from 'Hooks'
import axios from 'Utils/axiosRexyAdmin'
import { removeVietnameseTones } from 'Utils'

import './OrderItems.scss'

const { Option } = Select

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const OrderItem = (props) => {
  const {
    editId,
    values,
    loadingCondition,
    setFieldValue,
    remove,
    index,
    unitList,
    focusingRow,
    setFocus,
    // item,
  } = props
  const [typeMaterial, setTypeMaterial] = useState([
    { value: 'material', label: 'Nguyên vật liệu' },
    { value: 'packing', label: 'Bao bì' },
  ])
  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)
  useEffect(() => {
    const checkMaterial = values.materials.filter(
      (material) => material.material_type === 'material'
    )
    setSearchTerm((prev) => ({
      ...prev,
      keyword: '',
      result: [],
      searching: false,
      material_type: props.material_type,
    }))
    if (values.typeMaterial === 'material' && checkMaterial.length > 0) {
      setTypeMaterial([{ value: 'packing', label: 'Bao bì' }])
    } else {
      setTypeMaterial([{ value: 'material', label: 'Nguyên vật liệu' }])
    }
  }, [values.materials, typeMaterial.value, values.typeMaterial, props.material_type])
  useEffect(() => {
    let didCancel = false

    const fetchProducts = async () => {
      const url = '/materials/form/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
            material_type: searchTerm.material_type,
          },
        })
        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (debouncedSearchTerm.length && searchTerm.keyword === debouncedSearchTerm) {
      fetchProducts()
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, searchTerm.keyword])

  useEffect(() => {
    let didCancel = false

    if (editId && !didCancel) {
      onSearch(values.materials[index].material_code)
    }

    return () => (didCancel = true)
  }, [editId, index, values.materials])

  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onResultSelect = (index, val, option) => {
    setFieldValue(`materials[${index}].material_id`, val)
    setFieldValue(`materials[${index}].price`, Number(option.attr.price))
    setFieldValue(`materials[${index}].material_unit`, option.attr.unit)
    setFieldValue(`materials[${index}].material_type`, option.attr.material_type)
    setFieldValue(`materials[${index}].specification`, option.attr.specification)
    const itemTotal = Math.floor(
      Number(Number(option.attr.price) * values.materials[index].material_quantity)
    )
    setFieldValue(`materials[${index}].total`, itemTotal)
  }
  const onResultSelectMaterial = (index, val, option) => {
    setSearchTerm((prev) => ({
      ...prev,
      keyword: '',
      result: [],
      searching: false,
      material_type: val,
    }))
    setFieldValue(`materials[${index}]`, {
      material_id: undefined,
      material_quantity: 0.01,
      material_code: '',
      material_type: val,
      unit: '',
    })
    // setFieldValue(`second_price`, 0)
    if (val === 'material') {
      setFieldValue(`typeMaterial`, val)
    } else {
      setFieldValue(`typeMaterial`, val)
    }
  }
  const handleNumberChange = (index, limit, value) => {
    const itemTotal = Math.floor(
      Number(
        values.materials[index].price === undefined
          ? props.material_price * value
          : values.materials[index].price * value
      )
    )

    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(`materials[${index}].material_quantity`, 0)
    } else {
      setFieldValue(`materials[${index}].material_quantity`, value)
      setFieldValue(`materials[${index}].total`, itemTotal)
    }
    if (values.materials[index].material_type === 'material') {
      setFieldValue(`first_price`, itemTotal)
    }
  }

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  const handleRowColor = () => {
    if (focusingRow === index) return
    setFocus(index)
  }

  return (
    <div className={`row-wrapper ${focusingRow === index ? 'is-focusing' : ''}`}>
      <div className="row-item" style={{ minWidth: 150 }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Loại sản phẩm"
          optionFilterProp="children"
          disabled={loadingCondition || unitList.loading}
          onFocus={handleRowColor}
          defaultValue={'packing'}
          value={values.materials[index].material_type}
          onChange={(val, option) => onResultSelectMaterial(index, val, option)}
          dropdownMatchSelectWidth={false}
          // dropdownStyle={{ maxWidth: 650 }}
        >
          {typeMaterial.map((item, index) => (
            <Option key={index} value={item.value} attr={item}>{`${item.label}`}</Option>
          ))}
        </Select>

        {/* <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`materials[${index}].material_id`}
        /> */}
      </div>
      <div className="row-item" style={{ minWidth: 229 }}>
        <Select
          name={`materials[${index}].material_id`}
          style={{ width: '100%' }}
          showSearch={true}
          suffixIcon={<SearchOutlined />}
          placeholder="Tìm kiếm vật liệu"
          onSearch={onSearch}
          value={values.materials[index].material_id}
          onChange={(val, option) => onResultSelect(index, val, option)}
          optionFilterProp="children"
          notFoundContent={searchDropDownCondition}
          loading={searchTerm.searching}
          disabled={loadingCondition || unitList.loading}
          // getPopupContainer={(trigger) => trigger.parentNode}
          filterOption={(input, option) => {
            const dataInput = removeVietnameseTones(input)
            const dataOption = removeVietnameseTones(option.children)
            return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
          }}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ maxWidth: 650 }}
          onFocus={handleRowColor}
        >
          {searchTerm.result.map((item) => {
            return (
              <Option
                key={item.id}
                value={item.id}
                attr={item}
              >{`${item.code}: ${item.name}`}</Option>
            )
          })}
        </Select>

        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`materials[${index}].material_id`}
        />
      </div>

      <div className="row-item" style={{ minWidth: 100 }}>
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`materials[${index}].material_quantity`}
          placeholder="Nhập số lượng"
          disabled={loadingCondition || unitList.loading}
          value={values.materials[index].material_quantity}
          onChange={(value) => handleNumberChange(index, 100000000, value)}
          formatter={(value) => formatInputNumber(value)}
          min={0.01}
          max={100000000}
          onFocus={handleRowColor}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`materials[${index}].material_quantity`}
        />
      </div>

      <div className="row-item" style={{ maxWidth: 160 }}>
        {values.materials[index].material_unit
          ? getFieldName(values.materials[index].material_unit, unitList.data)
          : '-'}
      </div>

      <div className="row-item">
        <Button type="link" danger icon={<MinusCircleOutlined />} onClick={() => remove(index)} />
      </div>
    </div>
  )
  // })
}

const OrderItems = React.memo((props) => {
  const {
    values,
    loadingCondition,
    setFieldValue,
    // , path, isSuperAdmin
  } = props

  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)
  const [focusingRow, setFocus] = useState(undefined)
  const [unitList, setUnitList] = useState({ data: [], loading: true })
  useEffect(() => {
    let didCancel = false

    setUnitList((prev) => ({ ...prev, loading: true }))

    const fetchUnitsList = async () => {
      const url = '/materials/new/'
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setUnitList({ data: resp.data.units, loading: false })
        }
      } catch (error) {
        if (!didCancel) {
          setUnitList((prev) => ({ ...prev, loading: false }))
          message.error(error.response?.data.errors?.message)
        }
      }
    }

    fetchUnitsList()
    return () => (didCancel = true)
  }, [])

  useEffect(() => {
    let didCancel = false

    let subtotal = values.sub_total || 0
    let vat = values.vat || 0

    const calTotal = () => {
      if (typeof subtotal !== 'number' || typeof vat !== 'number') {
        return
      } else {
        const total = Number(subtotal) + Number(vat)
        const formatted = Math.floor(total)
        setFieldValue('total', formatted)
      }
    }

    if (!didCancel && !first) {
      calTotal()
    }

    return () => {
      didCancel = true
      setFirst(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, values.sub_total, values.vat, values.materials])

  useEffect(() => {
    let didCancel = false

    const calSubtotal = () => {
      let subtotal = 0
      values.materials.forEach((item) => {
        subtotal += item.total
      })
      setFieldValue('sub_total', subtotal)
      setFieldValue('vat', subtotal * 0.1)
    }

    if (!didCancel && !second) {
      calSubtotal()
    }

    return () => {
      didCancel = true
      setSecond(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.materials.length, values.materials, second])

  return (
    <FieldArray
      name="materials"
      render={(arrayHelpers) => (
        <div id="product_order_items_container_part_5">
          <Col className="order-table-content container">
            <div className="inner-content">
              <div className="row-wrapper">
                <div className="row-item thread-row" style={{ minWidth: 150 }}>
                  Loại
                </div>
                <div className="row-item thread-row" style={{ minWidth: 229 }}>
                  Tên vật liệu
                </div>
                <div className="row-item thread-row" style={{ minWidth: 100 }}>
                  Số lượng
                </div>
                <div className="row-item thread-row" style={{ maxWidth: 160 }}>
                  Đơn vị
                </div>
                <div className="row-item thread-row">Actions</div>
              </div>

              {values.materials.map((item, index) => (
                <OrderItem
                  {...arrayHelpers}
                  {...props}
                  key={index}
                  {...item}
                  index={index}
                  unitList={unitList}
                  focusingRow={focusingRow}
                  setFocus={setFocus}
                />
              ))}
            </div>
          </Col>

          <div className="button-wrapper">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              className="add-more-button"
              disabled={loadingCondition || unitList.loading}
              onClick={() => {
                arrayHelpers.push({
                  material_id: undefined,
                  material_quantity: 0.01,
                  material_code: '',
                  material_type: 'packing',
                  unit: '',
                })
              }}
            />
          </div>
        </div>
      )}
    />
  )
})

export { CustomError, OrderItems }
