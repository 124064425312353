import React from 'react'
import { Typography } from 'antd'
import StandingOrderForm from '../Form'

const { Title } = Typography

export default React.memo(() => {
  return (
    <div id="new_standing_order_page">
      <Title className="page-title">Tạo uỷ nhiệm chi mới</Title>
      <StandingOrderForm path={'new'} />
    </div>
  )
})
