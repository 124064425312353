import React, { useState } from 'react'
import { Modal, Input, Button, Alert } from 'antd'

import get from 'lodash/get'
import axiosExpand from 'Utils/axiosExpand'

const { TextArea } = Input

const Note = React.memo(({ note, row = {}, doFetch }) => {
  const [state, setState] = useState({ value: '', visible: false, loading: false, error: null })

  const onChange = (event) => {
    setState((prev) => ({ ...prev, value: event.target.value }))
  }

  const onEdit = (e) => {
    setState((prev) => ({ ...prev, visible: true, value: note }))
  }

  const onAddNote = (e) => {
    setState((prev) => ({ ...prev, visible: true }))
  }

  const onSubmit = async () => {
    setState((prev) => ({ ...prev, loading: true }))
    try {
      await axiosExpand.put(`/sale_orders/${row.id}/payment-note`, {
        payment_note: state.value,
      })
      setState((prev) => ({ ...prev, loading: false, visible: false }))
      doFetch((prev) => ({ ...prev }))
    } catch (error) {
      // errors: error.response?.data.errors?.message,
      const err = get(error, 'response.data.errors.message', 'Không thể cập nhật ghi chú')
      setState((prev) => ({ ...prev, loading: false, error: err }))
    }
  }

  const onCancel = () => {
    setState({ value: '', visible: false, loading: false, error: null })
  }

  return (
    <div className="customer-payment-note-component">
      {note ? (
        <div className="note-area-wrapper">
          <p className="note-area">{note}</p>
          <Button className="action-item update" type="link" onClick={onEdit}>
            Sửa
          </Button>
        </div>
      ) : (
        <Button className="action-item update" type="link" onClick={onAddNote}>
          Thêm ghi chú
        </Button>
      )}
      <Modal
        className="note-material-modal"
        style={{ top: 200 }}
        visible={state.visible}
        onOk={onSubmit}
        okButtonProps={{ loading: state.loading }}
        cancelText="Huỷ"
        cancelButtonProps={{ loading: state.loading }}
        onCancel={state.loading ? null : onCancel}
      >
        <div>
          <p>Cập nhật ghi chú cho mã Order: {row.order_number}</p>
          {state.error && <Alert message={state.error} type="error" showIcon />}
          <TextArea
            value={state.value}
            onChange={onChange}
            autoSize={{ minRows: 4, maxRows: 6 }}
            maxLength={1000}
          />
        </div>
      </Modal>
    </div>
  )
})

export default Note
