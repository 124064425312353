import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Button, Alert, Typography, Select, DatePicker, message, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import { useDebounce } from 'Hooks'
import { noData } from 'Components'
import { useScrollToErrors, removeVietnameseTones } from 'Utils'
import { CustomError } from './CustomComponent'

const { Title } = Typography
const { Option } = Select

export default React.memo((props) => {
  const {
    values,
    handleSubmit,
    serverError,
    errors,
    touched,
    setFieldValue,
    loading,
    firstRender,
  } = props

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)

  useEffect(() => {
    if (firstRender) {
      const url = new URL(window.location.href)
      const productId = url.searchParams.get('product_id')
      const productCode = url.searchParams.get('product_code')
      const startDate = url.searchParams.get('start_date')
      const endDate = url.searchParams.get('end_date')

      if (productId && productCode && productId !== 'undefined' && productCode !== 'undefined') {
        setFieldValue('product_id', Number(productId))
        setFieldValue('product_code', productCode)
        onSearch(productCode)
      }
      if (startDate && endDate) {
        setFieldValue('start_date', startDate)
        setFieldValue('end_date', endDate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender])

  useEffect(() => {
    let didCancel = false

    const fetchProducts = async () => {
      const url = '/products/form/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
            get_all: true,
          },
        })

        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (debouncedSearchTerm.length && searchTerm.keyword) fetchProducts()

    return () => (didCancel = true)
  }, [debouncedSearchTerm, searchTerm.keyword])

  const onProductSelect = (val, option) => {
    setFieldValue('option_content', option.children)
    setFieldValue('product_id', val)
    if (option) setFieldValue('product_code', option.attr.code)
  }

  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onDateChange = (fieldName, val) => {
    setFieldValue(fieldName, val.format('YYYY-MM-DD'))

    if (fieldName === 'start_date' && val > moment(values.end_date, 'YYYY-MM-DD')) {
      setFieldValue('end_date', val)
    }
  }

  // function disabledDate(current) {
  //   return current && current < moment(values.start_date, 'YYYY-MM-DD').startOf('day')
  // }

  const loadingCondition = loading || firstRender

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  return (
    <Form id="manage_products_history_form" className="form-wrapper" onSubmit={handleSubmit}>
      <Title className="page-sub-title">Lịch sử giá sản phẩm</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Sản phẩm <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name={'product_id'} disabled={loadingCondition}>
              {() => {
                return (
                  <Select
                    name={'product_id'}
                    style={{ width: '100%' }}
                    showSearch
                    suffixIcon={<SearchOutlined />}
                    placeholder="Tìm kiếm sản phẩm"
                    onSearch={onSearch}
                    value={values.product_id}
                    onChange={onProductSelect}
                    optionFilterProp="children"
                    notFoundContent={searchDropDownCondition}
                    loading={searchTerm.searching}
                    disabled={loadingCondition}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {searchTerm.result.map((item) => (
                      <Option
                        key={item.id}
                        value={item.id}
                        attr={item}
                      >{`${item.code}: ${item.name}`}</Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name={'product_id'} />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Từ ngày <span className="compulsory-field-symbol">&nbsp;*</span>
            </Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={moment(values.start_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('start_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="start_date" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Đến ngày <span className="compulsory-field-symbol">&nbsp;*</span>
            </Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={moment(values.end_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('end_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="end_date" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={<SearchOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          Lọc lịch sử
        </Button>
      </div>
    </Form>
  )
})
