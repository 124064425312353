import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'

const manageProductsHistorySchema = yup.object().shape({
  //product_id: yup.number().required(requiredField).max(80, maxLength),
  product_id: yup.number().required(requiredField),
  start_date: yup.date().required(requiredField),
  end_date: yup.date().required(requiredField),
  option_content: yup.string().required(requiredField).max(80, maxLength)
})

export default manageProductsHistorySchema
