import React from 'react'
import { Typography } from 'antd'

import ProductForm from '../Form'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  return (
    <div id="edit_product_page">
      <Title className="page-title">Cập nhật sản phẩm</Title>

      <ProductForm path={'edit'} editId={id} duplicate={false} />
    </div>
  )
})
