import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography, Select } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import axios from 'Utils/axiosRexyAdmin'
import { useScrollToErrors, preventEnterSubmit } from 'Utils'
import { CustomError } from './CustomComponent'
import { BlockNavigation } from 'Components'
import _ from 'lodash'

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
    isSuperAdmin,
    initialValues,
  } = props

  const history = useHistory()
  const [isFetching, setIsFetching] = useState(false)
  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  const [countryList, setCountryList] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])

  useEffect(() => {
    let didCancel = false

    const fetchLabelTypes = async () => {
      const url = '/customers/new/'
      const resp = await axios.get(url)
      setCountryList(resp.data.countries)
      setPaymentMethods(resp.data.payment_methods)
    }

    if (!didCancel) {
      fetchLabelTypes()
    }
    return () => (didCancel = true)
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/customers/${editId}/edit`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)

        if (editId && !didCancel) {
          setValues({ ...values, ...resp.data })
          setIsFetching(false)
          const notSetFirstTimeFetchedValuesYet = _.size(firstTimeFetchedValues) === 0

          if (notSetFirstTimeFetchedValuesYet)
            setFirstTimeFetchedValues({ ...values, ...resp.data })
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    if (editId) {
      fetchData()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, setValues])

  const handleSelectFieldChange = (fieldName, value) => {
    setFieldValue(fieldName, value)
  }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_customers_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <BlockNavigation
        values={values}
        initialValues={initialValues}
        path={path}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />
      <Title className="page-sub-title">Thông tin khách hàng</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Tên khách hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="name"
              placeholder="Nhập tên khách hàng"
              disabled={loadingCondition}
              value={values.name}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="name" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Mã khách hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="code"
              placeholder="Nhập mã khách hàng"
              disabled={loadingCondition}
              value={values.code}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="code" />
          </div>

          {isSuperAdmin ? (
            <>
              <div className="form-item">
                <Title className="field-title">Email</Title>
                <Field
                  as={Input}
                  name="email"
                  placeholder="Nhập email khách hàng"
                  disabled={loadingCondition}
                  value={values.email}
                  onChange={handleChange}
                />
                <ErrorMessage component={(props) => <CustomError {...props} />} name="email" />
              </div>

              <div className="form-item">
                <Title className="field-title">
                  Địa chỉ <span className="compulsory-field-symbol">*</span>
                </Title>
                <Field
                  as={Input}
                  name="address"
                  placeholder="Nhập địa chỉ khách hàng"
                  disabled={loadingCondition}
                  value={values.address}
                  onChange={handleChange}
                />
                <ErrorMessage component={(props) => <CustomError {...props} />} name="address" />
              </div>

              <div className="form-item">
                <Title className="field-title">
                  Số Điện thoại/Fax <span className="compulsory-field-symbol">*</span>
                </Title>
                <Field
                  as={Input}
                  name="phone_number"
                  placeholder="Nhập số Điện thoại/Fax khách hàng"
                  disabled={loadingCondition}
                  value={values.phone_number}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="phone_number"
                />
              </div>
            </>
          ) : null}
          <div className="form-item">
            <Title className="field-title">
              Quốc gia <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Select}
              name="country"
              style={{ width: '100%' }}
              placeholder="Chọn quốc gia"
              disabled={loadingCondition}
              value={values.country}
              optionFilterProp="children"
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => handleSelectFieldChange('country', value)}
            >
              {countryList.map((country) => (
                <Option key={country.value} value={country.value}>
                  {country.name}
                </Option>
              ))}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="country" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Hình thức thanh toán <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Select}
              name="payment_method"
              style={{ width: '100%' }}
              placeholder="Chọn hình thức thanh toán"
              disabled={loadingCondition}
              value={values.payment_method}
              onChange={(value) => handleSelectFieldChange('payment_method', value)}
            >
              {paymentMethods.map((method) => (
                <Option key={method.value} value={method.value}>
                  {method.name}
                </Option>
              ))}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="payment_method" />
          </div>

          <div className="form-item">
            <Title className="field-title">Ghi chú</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Ghi chú..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/customers')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo khách hàng' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
