import React from 'react'
import { Typography } from 'antd'
import StandingOrderForm from '../Form'
// import { useStoreState } from 'easy-peasy'
// import { Redirect } from 'react-router-dom'
const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  return (
    <div id="edit_standing_order_page">
      <Title className="page-title">Cập nhật uỷ nhiệm chi</Title>
      <StandingOrderForm path={'edit'} editId={id} />
    </div>
  )
})
