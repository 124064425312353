import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

import "./index.scss";

export default () => {
  return (
    <div id="forbidden" className="container">
      <Result
        status="403"
        title="403"
        subTitle="Xin lỗi, bạn không có quyền truy cập trang này."
        extra={
          <Button type="primary">
            <Link to="/">Back Home</Link>
          </Button>
        }
      />
    </div>
  );
};
