import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'

import LoginView from './Login.view'
import { loginStepOneSchema } from 'Validation'

const initialValues = {
  email: '',
  password: '',
}

export default () => {
  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const isSendToForgotPasswordEmail = useStoreState(
    (state) => state.auth.isSendToForgotPasswordEmail
  )
  const signinStepOne = useStoreActions((actions) => actions.auth.signinStepOne)

  useEffect(() => {
    let didCancel = false
    if (isSendToForgotPasswordEmail && !didCancel) {
      const args = {
        message: 'Thiết lập mật khẩu mới thành công!',
        description: 'Một mật khẩu mới đã được gửi đến email của bạn.',
        duration: 10,
      }
      notification.success(args)
    }

    return () => (didCancel = true)
  }, [isSendToForgotPasswordEmail])

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    const resp = await signinStepOne(values)
    if (resp.errors) {
      setServerError(resp.errors)
      setSubmitting(false)
      return
    }
    setSubmitting(false)
    history.push('/login-step-two')
  }

  return (
    <div className="login-page-step-one">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <LoginView {...props} serverError={serverError} isSubmitting={isSubmitting} />
        )}
        validationSchema={loginStepOneSchema}
      />
    </div>
  )
}
