import React, { useState } from 'react'
import { Typography, Modal, Alert, Button, Row, Col } from 'antd'

import axiosExpand from 'Utils/axiosExpand'

const { Text } = Typography

export default ({ id, status, order_number, visible, setSubmittedToInStockModel, doFetch }) => {
  const [state, setState] = useState({ loading: false, errors: null })

  const handleCancel = async (value) => {
    setState({ loading: true, errors: null })
    try {
      await axiosExpand.put(`/purchase_orders/update-status/`, {
        id: id,
        status: status,
        auto_create_transactions: false,
      })
      setState((prev) => ({ ...prev, loading: false }))
      setSubmittedToInStockModel({ id: undefined, status: '', visible: false, order_number: '' })
      doFetch((prev) => ({ ...prev }))
    } catch (error) {
      setState({
        loading: false,
        errors: error.response?.data.errors?.message,
      })
    }
  }

  const handleOk = async () => {
    setState({ loading: true, errors: null })
    try {
      await axiosExpand.put(`/purchase_orders/update-status/`, {
        id: id,
        status: status,
        auto_create_transactions: true,
      })
      setState((prev) => ({ ...prev, loading: false }))
      setSubmittedToInStockModel({ id: undefined, status: '', visible: false, order_number: '' })
      doFetch((prev) => ({ ...prev }))
    } catch (error) {
      setState({
        loading: false,
        errors: error.response?.data.errors?.message,
      })
    }
  }

  const onClose = () => {
    setSubmittedToInStockModel({ id: undefined, status: '', visible: false })
  }
  // const renderSubmittedToInStockButton = () => {
  //   return <Text className="create-btn-text">No</Text>
  // }

  // const renderCancelButton = () => {
  //   return <Text className="cancel-btn-text">Yes</Text>
  // }

  const renderTitle = () => {
    return <Text className="modal-title">Đổi trạng thái đơn đặt hàng thành đã nhập hàng</Text>
  }

  return (
    <Modal
      className="delete-purchase-order-modal"
      style={{ top: 200 }}
      visible={visible}
      title={renderTitle()}
      // okText={renderSubmittedToInStockButton()}
      // okButtonProps={{ loading: state.loading, className: 'delete-button' }}
      // cancelText={renderCancelButton()}
      cancelButtonProps={{ loading: state.loading }}
      // onOk={handleOk}
      onCancel={onClose}
      footer={null}
    >
      <div id="purchase_order_delete_modal">
        <Text className="modal-description">
          <strong>Bạn có muốn hệ thống tự tạo phiếu nhập kho không.</strong>
        </Text>
        {state.errors && (
          <Alert style={{ marginBottom: 5 }} message={state.errors} type="error" showIcon />
        )}
        <Text className="order-no">{order_number}</Text>
      </div>
      <Row gutter={[12, 0]} justify="end" align="bottom">
        <Col>
          <Button onClick={() => handleCancel()}>Không</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => handleOk()}>
            Có
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}
