import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import FormView from './Form.view'
import { manageStandingOrderSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'
import { useStoreState } from 'easy-peasy'

const initialValues = {
  date: moment(),
  supplier_id: undefined,
  payment_method: undefined,
  bank_branch: '',
  bank_number: '',
  bank_account_name: '',
  bank_name: '',
  amount: undefined,
  note: '',
  purchase_order_id: undefined,
  purchase_order_invoice_id: undefined,
  total: 0,
  paid_amount: 0,
  not_paid_amount: undefined,
  approved: false,
}

export default React.memo((props) => {
  const { path, editId } = props
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleFormSubmit = async (values, actions) => {
    setServerError(null)
    setIsSubmitting(true)

    if (values.total < values.not_paid_amount) {
      values.approved = true
    }

    const purchaseOrderInvoiceId =
      values.purchase_order_invoice_id === undefined ? null : values.purchase_order_invoice_id

    const newValues = {
      ...values,
      purchaseOrderInvoiceId,
    }
    if (path === 'new') {
      createStandingOrder(newValues, actions)
    } else {
      updateStandingOrder(values, actions)
    }
  }

  const createStandingOrder = async (params, actions) => {
    const url = '/standing_orders/'
    try {
      const resp = await axios.post(url, params)
      setIsSubmitting(false)
      history.push(`/standing-orders/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setIsSubmitting(false)
    }
  }
  const updateStandingOrder = async (params, actions) => {
    const url = `/standing_orders/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setIsSubmitting(false)
      history.push(`/standing-orders/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setIsSubmitting(false)
    }
  }

  return (
    <div className="standing-order-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSuperAdmin={isSuperAdmin}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={manageStandingOrderSchema}
      />
    </div>
  )
})
