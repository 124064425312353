import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router'
import _ from 'lodash'

let clickedToSubmitButton = false

const getIdenticalKeys = (firstArray, secondArray) => {
  return _.filter(firstArray, (key) => _.includes(secondArray, key))
}

const filterObjectWithKeys = (object, keys) => {
  return _.pickBy(object, (v, k) => keys.includes(k))
}

const BlockNavigation = ({ values, initialValues, path, firstTimeFetchedValues }) => {
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)

  useEffect(() => {
    if (clickedToSubmitButton) return setShouldBlockNavigation(false)
    let valuesIsChanged = false
    // Only compare identical keys
    const valuesKeys = _.keys(values)

    if (path === 'edit') {
      // edit page
      const firstTimeFetchedValuesKeys = _.keys(firstTimeFetchedValues)
      const identicalKeys = _.take(getIdenticalKeys(valuesKeys, firstTimeFetchedValuesKeys), 10)

      const filteredValues = filterObjectWithKeys(values, identicalKeys)
      const filteredFirstTimeFetched = filterObjectWithKeys(firstTimeFetchedValues, identicalKeys)

      valuesIsChanged = !_.isEqual(filteredValues, filteredFirstTimeFetched)
    } else {
      // new or duplicate page
      const initialValuesKeys = _.keys(initialValues)
      const identicalKeys = getIdenticalKeys(valuesKeys, initialValuesKeys)

      const filteredValues = filterObjectWithKeys(values, identicalKeys)
      const filteredInitialValues = filterObjectWithKeys(initialValues, identicalKeys)

      valuesIsChanged = !_.isEqual(filteredValues, filteredInitialValues)
    }

    setShouldBlockNavigation(valuesIsChanged)
  }, [values, initialValues, path, firstTimeFetchedValues])

  useEffect(() => {
    document.addEventListener('click', handleAllDomClick, false)

    return () => document.removeEventListener('click', handleAllDomClick, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedToSubmitButton])

  // To hide prompt when click submit button
  const handleAllDomClick = (event) => {
    const elementIsSubmitButton = event.target.closest('button[type=submit]')
    if (!elementIsSubmitButton) return (clickedToSubmitButton = false)

    setShouldBlockNavigation(false)
    clickedToSubmitButton = true
  }

  // To prevent reload & close tab
  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [shouldBlockNavigation])

  return (
    <Prompt
      when={!clickedToSubmitButton && shouldBlockNavigation}
      // message="You have unsaved changes, are you sure you want to leave?"
      message="Bạn có chắc muốn rời khỏi trang? Những thay đổi bạn vừa thực hiện sẽ không được lưu."
    />
  )
}

export default BlockNavigation
