import React, { useState, useEffect } from 'react'
import { Typography, message } from 'antd'
import { Redirect } from 'react-router-dom'

import axios from 'Utils/axiosRexyAdmin'
import SaleOrderForm from '../Form'
import { Loading } from 'Components'
import size from 'lodash/size'
import moment from 'moment'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  const [fetchEditData, setFetchEditData] = useState({ dataFetch: {}, isFetching: true })
  const [statusSaleOrder, setStatusSaleOrder] = useState('drafting')
  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/sale_orders/${id}/edit`
      setFetchEditData((prev) => ({ ...prev, isFetching: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setStatusSaleOrder(resp.data.status)
          setFetchEditData({ dataFetch: { ...resp.data }, isFetching: false })

          const notSetFirstTimeFetchedValuesYet = size(firstTimeFetchedValues) === 0

          if (notSetFirstTimeFetchedValuesYet)
            setFirstTimeFetchedValues({
              ...resp.data,
              date: moment(resp.data.date, 'YYYY-MM-DD'),
            })
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setFetchEditData((prev) => ({ ...prev, isFetching: false }))
        }
      }
    }
    if (id) {
      fetchData()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (fetchEditData.isFetching) return <Loading loading={fetchEditData.isFetching} />

  return statusSaleOrder !== 'drafting' ? (
    <Redirect to={`/sale-orders/${id}/view`} />
  ) : (
    <div id="edit_sale_order_page">
      <Title className="page-title">Cập nhật đơn hàng</Title>
      <SaleOrderForm
        path={'edit'}
        {...fetchEditData}
        editId={id}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />
    </div>
  )
})
