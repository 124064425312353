import React, { useEffect } from 'react'
import { ErrorMessage, Field } from 'formik'
import { Typography, Select } from 'antd'
import { useStoreActions } from 'easy-peasy'

import axios from 'Utils/axiosExpand'
import { CustomError, MaterialInStock } from './CustomComponent'
import { removeVietnameseTones } from 'Utils'

const { Title, Text } = Typography
const { Option } = Select

export default React.memo((props) => {
  const {
    loadingCondition,
    values,
    setFieldValue,
    setValues,
    onSeaching,
    setOnSearching,
    orderSearchData,
    // setItemOptions,
    editId,
    path,
    firstRender2,
    setFirstRender2,
  } = props
  const toggleMaterialStatus = useStoreActions(
    (actions) => actions.purchaseOrder.toggleMaterialStatus
  )

  useEffect(() => {
    const url = new URL(window.location.href)
    const saleOrderId = url.searchParams.get('sale_order_id')
    const orderType = url.searchParams.get('order_type')
    let didCancel = false

    const fetchData = async (saleOrderId, orderType) => {
      setOnSearching((prev) => ({ ...prev, marterialOfOrder: true }))
      if (orderType === 'material' || orderType === 'packing') {
        await getMaterialOfSaleOrder(saleOrderId)
        setFirstRender2(false)
      } else if (orderType === 'label') {
        await getLabelOfSaleOrder(saleOrderId)
        setFirstRender2(false)
      } else if (orderType === 'finish_product') {
        await getFinishProductOfSaleOrder(saleOrderId)
      }
    }

    if (
      !didCancel &&
      firstRender2 &&
      path === 'new' &&
      orderType &&
      orderType !== 'undefined' &&
      saleOrderId &&
      saleOrderId !== 'undefined'
    ) {
      fetchData(Number(saleOrderId), orderType)
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onResultSelect = async (val) => {
    if (!val) {
      let updatePurchaseOrderItems = []

      if (values.order_type === 'label') {
        updatePurchaseOrderItems = values.purchase_order_items.map((item) => {
          return {
            ...item,
            name: item.product.name,
            code: item.product.code,
          }
        })
      } else if (values.order_type === 'finish_product') {
        updatePurchaseOrderItems = values.purchase_order_items.map((item) => {
          return {
            ...item,
            name: item.name,
            code: item.code,
          }
        })
      } else {
        updatePurchaseOrderItems = values.purchase_order_items.map((item) => {
          return {
            ...item,
            name: item.material.name,
            code: item.material.code,
          }
        })
      }

      setValues({
        ...values,
        sale_order_id: val,
        items_in_stock: [],
        sub_total: 0,
        vat: 0,
        total: 0,
        purchase_order_items: updatePurchaseOrderItems,
      })

      toggleMaterialStatus({ id: undefined, quantity_in_stock: undefined, action: 'use' })
      // setItemOptions([])
    } else if (val) {
      setOnSearching((prev) => ({ ...prev, marterialOfOrder: true }))

      if (values.order_type === 'material' || values.order_type === 'packing') {
        await getMaterialOfSaleOrder(val)
      } else if (values.order_type === 'label') {
        await getLabelOfSaleOrder(val)
      } else if (values.order_type === 'finish_product') {
        await getFinishProductOfSaleOrder(val)
      }
    }
  }
  const getMaterialOfSaleOrder = async (val) => {
    const url = '/sale_orders/materials/'
    try {
      const result = await axios.get(url, {
        params: {
          supplier_id: values.supplier_id,
          sale_order_id: val,
          order_type: values.order_type,
        },
      })
      computeMaterialData(result.data.materials_in_stock, result.data.materials)

      setFieldValue('sale_order_id', val)
      setOnSearching((prev) => ({ ...prev, marterialOfOrder: false }))
    } catch (error) {
      setOnSearching((prev) => ({ ...prev, marterialOfOrder: false }))
    }
  }
  const getLabelOfSaleOrder = async (val) => {
    const url = '/sale_orders/labels/'
    try {
      const result = await axios.get(url, {
        params: {
          supplier_id: values.supplier_id,
          sale_order_id: val,
          order_type: values.order_type,
        },
      })
      computeLabelData(result.data.labels_in_stock, result.data.labels)
      setFieldValue('sale_order_id', val)
      setOnSearching((prev) => ({ ...prev, marterialOfOrder: false }))
    } catch (error) {
      setOnSearching((prev) => ({ ...prev, marterialOfOrder: false }))
    }
  }
  const getFinishProductOfSaleOrder = async (val) => {
    const url = '/sale_orders/finish-products/'
    try {
      const result = await axios.get(url, {
        params: {
          supplier_id: values.supplier_id,
          sale_order_id: val,
          order_type: values.order_type,
        },
      })
      computeFinishProductData(result.data.products_in_stock, result.data.products)
      setFieldValue('sale_order_id', val)
      setOnSearching((prev) => ({ ...prev, marterialOfOrder: false }))
    } catch (error) {
      setOnSearching((prev) => ({ ...prev, marterialOfOrder: false }))
    }
  }
  const computeFinishProductData = (finishProductsInStock, purchaseOrderItems) => {
    if (purchaseOrderItems.length) {
      let subtotal = 0
      let formattedFinishProductsInStock = []
      const formattedFinishProducts = purchaseOrderItems.map((item) => {
        subtotal += Number(item.total)
        return {
          ...item,
          label_id: 0,
          material_id: 0,
          product_id: item.id,
          info: '',
          label_type: '',

          price: Number(item.price),
          quantity: Number(item.quantity),
          initQuantity: Number(item.quantity),
          total: Number(item.total),
        }
      })

      if (path === 'new') {
        formattedFinishProducts.forEach((item) => {
          delete item.id
        })
      }
      if (finishProductsInStock.length) {
        formattedFinishProductsInStock = finishProductsInStock.map((item) => ({
          ...item,
          quantity: Number(item.quantity_in_stock),
          action: Number(item.quantity_in_stock) > 0 ? 'use' : 'not-use',
          usingQuantity: Number(item.quantity_in_stock) || 0,
        }))
        const filteredMaterialsInStock = formattedFinishProductsInStock.filter(
          (item) => item.quantity_in_stock > 0
        )
        for (const finishProductInStock of filteredMaterialsInStock) {
          // eslint-disable-next-line no-loop-func
          formattedFinishProducts.forEach((item) => {
            if (finishProductInStock.id === item.product_id) {
              const indexOfMaterial = formattedFinishProductsInStock.findIndex(
                (element) => element.id === finishProductInStock.id
              )
              if (finishProductInStock.quantity_in_stock <= item.quantity) {
                formattedFinishProductsInStock.splice(indexOfMaterial, 1, {
                  ...finishProductInStock,
                  quantity: 0,
                  usingQuantity: Number(finishProductInStock.quantity_in_stock),
                })
                item.quantity =
                  Number(item.quantity) - Number(finishProductInStock.quantity_in_stock)
                item.total = Number(item.quantity) * Number(item.price)
                subtotal -= Number(finishProductInStock.quantity_in_stock) * Number(item.price)
              } else {
                formattedFinishProductsInStock.splice(indexOfMaterial, 1, {
                  ...finishProductInStock,
                  quantity: Number(finishProductInStock.quantity_in_stock) - Number(item.quantity),
                  usingQuantity: Number(item.quantity),
                })
                item.quantity = 0
                item.total = 0
                subtotal -= 0
              }
            } else {
              item.total = item.quantity * item.price
              subtotal += item.quantity * item.price
            }
          })
        }
      }

      setValues({
        ...values,
        sub_total: Math.floor(subtotal),
        vat: Math.floor(subtotal * 0.1),
        total: Math.floor(subtotal * 1.1),
        purchase_order_items: formattedFinishProducts,
        items_in_stock: formattedFinishProductsInStock,
      })
    } else {
      setValues({
        ...values,
        sub_total: 0,
        vat: 0,
        total: 0,
        items_in_stock: [],
        purchase_order_items: [],
      })
    }
  }
  const computeLabelData = (labelsInStock, purchaseOrderItems) => {
    if (purchaseOrderItems.length) {
      let subtotal = 0
      let formattedLabelsInStock = []

      const formattedLabels = purchaseOrderItems.map((item) => {
        subtotal += Number(item.total)
        return {
          ...item,
          material_id: 0,
          label_id: item.id,
          info: item.info,
          product_id: 0,
          price: Number(item.price),
          quantity: Number(item.quantity),
          initQuantity: Number(item.quantity),
          total: Number(item.total),
        }
      })
      if (path === 'new') {
        formattedLabels.forEach((item) => {
          delete item.id
        })
      }
      if (labelsInStock.length) {
        formattedLabelsInStock = labelsInStock.map((item) => ({
          ...item,
          quantity: Number(item.quantity_in_stock),
          action: Number(item.quantity_in_stock) > 0 ? 'use' : 'not-use',
          usingQuantity: Number(item.quantity_in_stock) || 0,
        }))
        const filteredMaterialsInStock = formattedLabelsInStock.filter(
          (item) => item.quantity_in_stock > 0
        )

        for (const labelInStock of filteredMaterialsInStock) {
          // eslint-disable-next-line no-loop-func
          formattedLabels.forEach((item) => {
            if (labelInStock.id === item.label_id) {
              const indexOfMaterial = formattedLabelsInStock.findIndex(
                (element) => element.id === labelInStock.id
              )

              if (labelInStock.quantity_in_stock <= item.quantity) {
                formattedLabelsInStock.splice(indexOfMaterial, 1, {
                  ...labelInStock,
                  quantity: 0,
                  usingQuantity: Number(labelInStock.quantity_in_stock),
                })
                item.quantity = Number(item.quantity) - Number(labelInStock.quantity_in_stock)
                item.total = Number(item.quantity) * Number(item.price)
                subtotal -= Number(labelInStock.quantity_in_stock) * Number(item.price)
              } else {
                formattedLabelsInStock.splice(indexOfMaterial, 1, {
                  ...labelInStock,
                  quantity: Number(labelInStock.quantity_in_stock) - Number(item.quantity),
                  usingQuantity: Number(item.quantity),
                })

                item.quantity = 0
                item.total = 0
                subtotal -= 0
              }
            } else {
              item.total = item.quantity * item.price
              subtotal += item.quantity * item.price
            }
          })
        }
      }

      setValues({
        ...values,
        sub_total: Math.floor(subtotal),
        vat: Math.floor(subtotal * 0.1),
        total: Math.floor(subtotal * 1.1),
        purchase_order_items: formattedLabels,
        items_in_stock: formattedLabelsInStock,
      })
    } else {
      setValues({
        ...values,
        sub_total: 0,
        vat: 0,
        total: 0,
        items_in_stock: [],
        purchase_order_items: [],
      })
    }
  }
  const computeMaterialData = (materialsInStock, purchaseOrderItems) => {
    if (purchaseOrderItems.length) {
      let subtotal = 0
      let formattedMaterialsInStock = []
      const formattedMaterials = purchaseOrderItems.map((item) => {
        subtotal += Number(item.total)
        return {
          ...item,
          unit: item.material.unit,
          readable_unit: item.material.readable_unit,
          label_id: 0,
          product_id: 0,
          info: '',
          label_type: '',
          price: Number(item.price),
          quantity: Number(item.quantity),
          initQuantity: Number(item.quantity),
          total: Number(item.total),
        }
      })

      if (materialsInStock.length) {
        formattedMaterialsInStock = materialsInStock.map((item) => {
          return {
            ...item,
            quantity: Number(item.quantity_in_stock),
            action: Number(item.quantity_in_stock) > 0 ? 'use' : 'not-use',
            usingQuantity: Number(item.quantity_in_stock) || 0,
          }
        })
        const filteredMaterialsInStock = formattedMaterialsInStock.filter(
          (item) => item.quantity_in_stock > 0
        )
        filteredMaterialsInStock.forEach((marterialInStock) => {
          formattedMaterials.forEach((item) => {
            if (marterialInStock.id === item.material_id) {
              const indexOfMaterial = formattedMaterialsInStock.findIndex(
                (element) => element.id === marterialInStock.id
              )

              if (marterialInStock.quantity_in_stock <= item.quantity) {
                formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                  ...marterialInStock,
                  quantity: 0,
                  usingQuantity: marterialInStock.quantity_in_stock,
                })
                item.quantity = item.quantity - marterialInStock.quantity_in_stock
                item.total = item.quantity * item.price
                subtotal -= marterialInStock.quantity_in_stock * item.price
              } else {
                formattedMaterialsInStock.splice(indexOfMaterial, 1, {
                  ...marterialInStock,
                  quantity: marterialInStock.quantity_in_stock - item.quantity,
                  usingQuantity: item.quantity,
                })
                item.quantity = 0
                item.total = 0
                subtotal -= 0
              }
            } else {
              item.total = item.quantity * item.price
              subtotal += item.quantity * item.price
            }
          })
        })
      }

      setValues({
        ...values,
        sub_total: Math.floor(subtotal),
        vat: Math.floor(subtotal * 0.1),
        total: Math.floor(subtotal * 1.1),
        purchase_order_items: formattedMaterials,
        items_in_stock: formattedMaterialsInStock,
      })
    } else {
      setValues({
        ...values,
        sub_total: 0,
        vat: 0,
        total: 0,
        items_in_stock: [],
        purchase_order_items: [],
      })
    }
  }

  const checkQuantityInStock = values.items_in_stock.filter((item, key) => {
    return item.quantity_in_stock > 0
  })

  return (
    <>
      <div className="form-item" style={{ maxWidth: 400 }}>
        <Title className="field-title">Order</Title>
        <Field name="sale_order_id" disabled={loadingCondition}>
          {() => {
            return (
              <Select
                name="sale_order_id"
                style={{ width: '100%' }}
                showSearch
                allowClear
                placeholder="Nhập mã order để tìm kiếm"
                value={values.sale_order_id}
                onChange={(val, option) => onResultSelect(val, option)}
                optionFilterProp="children"
                loading={
                  loadingCondition || onSeaching.marterialOfOrder || onSeaching.orderOfSupplier
                }
                disabled={
                  loadingCondition ||
                  onSeaching.marterialOfOrder ||
                  onSeaching.orderOfSupplier ||
                  editId
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={(input, option) => {
                  const dataInput = removeVietnameseTones(input)
                  const dataOption = removeVietnameseTones(option.children)
                  return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {orderSearchData.map((item, index) => (
                  <Option key={`${index}`} value={item.id} attr={item}>
                    {item.order_number}
                  </Option>
                ))}
              </Select>
            )
          }}
        </Field>
        <ErrorMessage component={(props) => <CustomError {...props} />} name="sale_order_id" />
      </div>
      {/* {values.order_type === 'material' || values.order_type === 'packing' ? ( */}
      {/* // Only show when chosed order */}

      {values.sale_order_id && values.items_in_stock.length && checkQuantityInStock.length ? (
        <div className="form-item">
          <Title className="field-title">
            {values.order_type === 'material'
              ? 'Nguyên vật liệu tồn kho'
              : values.order_type === 'packing'
              ? 'Bao bì tồn kho'
              : values.order_type === 'label'
              ? 'Tem tồn kho'
              : 'Thành phẩm tồn kho'}
          </Title>

          <MaterialInStock
            name="items_in_stock"
            values={values}
            loadingCondition={loadingCondition}
            orderType={values.order_type}
            itemsInStock={values.items_in_stock}
            setFieldValue={setFieldValue}
            loading={onSeaching.marterialOfOrder || onSeaching.orderOfSupplier}
          />
        </div>
      ) : (
        values.sale_order_id !== undefined && (
          <div className="form-item">
            <Title className="field-title">
              {values.order_type === 'material'
                ? 'Nguyên vật liệu tồn kho'
                : values.order_type === 'packing'
                ? 'Bao bì tồn kho'
                : values.order_type === 'label'
                ? 'Tem tồn kho'
                : 'Thành phẩm tồn kho'}
            </Title>
            <Text className="Text-warehouse">Không Có Tồn Kho</Text>
          </div>
        )
      )}
      {/* ) : null} */}
    </>
  )
})
