import React from 'react'
import { Typography } from 'antd'

import SaleOrderFormStep1 from '../Form'

const { Title } = Typography

export default React.memo(() => {
  return (
    <div id="new_sale_order_page">
      <Title className="page-title">Tạo đơn hàng mới</Title>
      <SaleOrderFormStep1 path={'new'} />
    </div>
  )
})
