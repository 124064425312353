import React, { useState } from 'react'
import { Typography, Modal, Alert } from 'antd'

import axiosExpand from 'Utils/axiosExpand'

const { Text } = Typography

export default ({ id, name, visible, setCancelModal, doFetch }) => {
  const [state, setState] = useState({ loading: false, errors: null })

  const handleCancel = () => {
    setCancelModal({ id: undefined, name: '', visible: false })
  }

  const handleOk = async () => {
    setState({ loading: true, errors: null })
    const url = '/sale_orders/update-status/'
    try {
      await axiosExpand.put(url, {
        id,
        status: 'cancelled',
      })

      setState((prev) => ({ ...prev, loading: false }))
      setCancelModal({ id: undefined, name: '', visible: false })
      doFetch({})
    } catch (error) {
      setState({
        loading: false,
        errors: error.response?.data.errors?.message,
      })
    }
  }

  const renderDeleteButton = () => {
    return <Text className="create-btn-text">Huỷ Order</Text>
  }

  const renderCancelButton = () => {
    return <Text className="cancel-btn-text">Không huỷ Order</Text>
  }

  const renderTitle = () => {
    return <Text className="modal-title">Hủy Order</Text>
  }

  return (
    <Modal
      className="cancel-sale-order-modal"
      style={{ top: 200 }}
      visible={visible}
      title={renderTitle()}
      okText={renderDeleteButton()}
      okButtonProps={{ loading: state.loading, className: 'delete-button' }}
      cancelText={renderCancelButton()}
      cancelButtonProps={{ loading: state.loading }}
      onOk={handleOk}
      onCancel={state.loading ? null : handleCancel}
    >
      <div id="order_cancel_modal">
        <Text className="modal-description">
          <strong>Bạn có chắc muốn hủy Order này.</strong>
          <br />
          Lưu ý: Một khi đã hủy sẽ không thể hoàn tác.
        </Text>
        {state.errors && (
          <Alert style={{ marginBottom: 5 }} message={state.errors} type="error" showIcon />
        )}
        <Text className="customer-name">{name}</Text>
      </div>
    </Modal>
  )
}
