import React from "react";

import { CheckPermission } from "Components";
import Edit from "./Edit";

import "./index.scss";

const EditSetting = (props) => (
  <CheckPermission component={Edit} {...props} />
);

export { EditSetting };
