import React from 'react'
import { Typography } from 'antd'

import CustomerForm from '../Form'

const { Title } = Typography

export default () => {
  return (
    <div id="new_customer_page">
      <Title className="page-title">Tạo khách hàng mới</Title>
      <CustomerForm path={'new'} />
    </div>
  )
}
