import React from 'react'
import { Typography } from 'antd'

import StaffForm from '../Form'

const { Title } = Typography

export default () => {
  return (
    <div id="new_staff_page">
      <Title className="page-title">Tạo nhân viên mới</Title>
      <StaffForm path={'new'} />
    </div>
  )
}
