import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const ListSalary = (props) => <CheckPermission component={List} {...props} />

const ViewSalary = (props) => <CheckPermission component={View} {...props} />

const NewSalary = (props) => <CheckPermission component={New} {...props} />

const EditSalary = (props) => <CheckPermission component={Edit} {...props} />

export { ListSalary, ViewSalary, NewSalary, EditSalary }
