import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['PP']

const ListCommodityExport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewCommodityExport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewCommodityExport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

const EditCommodityExport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export { ListCommodityExport, ViewCommodityExport, NewCommodityExport, EditCommodityExport }
