import React from 'react'
import Modal from './Modal'
import Slides from './Slides'

import './index.scss'

const ImageSlide = React.memo((props) => {
  const { visible, onCancel, images, currentIndex = 0 } = props

  return (
    <Modal visible={visible} onCancel={onCancel}>
      <Slides images={images} currentIndex={currentIndex} />
    </Modal>
  )
})

export default ImageSlide
