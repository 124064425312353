import React, { useState } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import FormView from './Form.view'
import { manageShippingScheduleSchema } from 'Validation'
import axiosExpand from 'Utils/axiosExpand'

const format = 'YYYY-MM-DD'

const initialValues = {
  start_date: moment().subtract(30, 'days').format(format),
  end_date: moment().format(format),
}

export default (props) => {
  const { handleFormSubmit } = props

  const [dowloadError, setDowloadError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const downloadShippingSchedule = async (params) => {
    setDowloadError(null)
    setIsSubmitting(true)
    try {
      const FileDownload = require('js-file-download')
      const url = `/sale_orders/export/shipping-schedule?start_date=${params.start_date}&end_date=${params.end_date}`
      const result = await axiosExpand.get(url, { responseType: 'arraybuffer' })
      FileDownload(result.data, `bao-cao-shipping-schedule.xlsx`)
      setIsSubmitting(false)
    } catch (error) {
      setDowloadError(error.response?.data.errors.message)
      setIsSubmitting(false)
    }
  }

  return (
    <div className="shipping_schedule-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(formProps) => (
          <FormView
            {...props}
            {...formProps}
            dowloadError={dowloadError}
            isSubmitting={isSubmitting}
            downloadShippingSchedule={downloadShippingSchedule}
          />
        )}
        validationSchema={manageShippingScheduleSchema}
      />
    </div>
  )
}
