import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['MO', 'R']

const ListPaymentTransaction = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewPaymentTransaction = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewPaymentTransaction = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

const EditPaymentTransaction = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export {
  ListPaymentTransaction,
  ViewPaymentTransaction,
  NewPaymentTransaction,
  EditPaymentTransaction,
}
