import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageSalaryCalculation = yup.object().shape({
  staff_id: yup.number().required(requiredField).typeError(typeError),
  bonus_amount: yup.number().required(requiredField).typeError(typeError),
  responsibility_amount: yup.number().when('staff_type', {
    is: (value) => value === 'office',
    then: yup.number().required(requiredField).typeError(typeError),
    otherwise: yup.number().nullable(true),
  }),
  total_day: yup.number().when('staff_type', {
    is: (value) => value === 'office',
    then: yup.number().required(requiredField).typeError(typeError),
    otherwise: yup.number().nullable(true),
  }),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
})

export default manageSalaryCalculation
