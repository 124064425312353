import React from 'react'
import { Typography } from 'antd'
import Form from '../Form'
import './index.scss'

const { Title } = Typography

export default React.memo(() => {
  return (
    <div id="new_supplier_debt_invoice_page">
      <Title className="page-title">Tạo hoá đơn thanh toán NCC</Title>
      <Form path={'new'} />
    </div>
  )
})
