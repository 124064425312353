import React, { useEffect } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Button, Alert, Typography, DatePicker } from 'antd'
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import moment from 'moment'

import { useScrollToErrors } from 'Utils'

const { Title } = Typography

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

export default (props) => {
  const {
    values,
    errors,
    touched,
    loading,
    firstRender,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    serverError,
    downloadShippingSchedule,
    handleFormSubmit,
  } = props

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  useEffect(() => {
    if (firstRender) {
      const url = new URL(window.location.href)
      const startDate = url.searchParams.get('start_date')
      const endDate = url.searchParams.get('end_date')

      if (startDate && endDate) {
        setFieldValue('start_date', startDate)
        setFieldValue('end_date', endDate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender])

  const loadingCondition = isSubmitting || loading

  function disabledDate(current) {
    return current && current < moment(values.start_date, 'YYYY-MM-DD').startOf('day')
  }

  const onDateChange = (fieldName, val) => {
    setFieldValue(fieldName, val.format('YYYY-MM-DD'))

    if (fieldName === 'start_date' && val > moment(values.end_date, 'YYYY-MM-DD')) {
      setFieldValue('end_date', val)
    }
  }

  return (
    <Form id="manage_shipping_schedules_form" className="form-wrapper" onSubmit={handleSubmit}>
      <Title className="page-sub-title">Shipping schedule</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Từ ngày <span className="compulsory-field-symbol">&nbsp;*</span>
            </Title>
            <Field name="start_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={moment(values.start_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('start_date', val)}
                  style={{ width: '100%', maxWidth: 400 }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="start_date" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Đến ngày <span className="compulsory-field-symbol">&nbsp;*</span>
            </Title>
            <Field name="end_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={moment(values.end_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('end_date', val)}
                  style={{ width: '100%', maxWidth: 400 }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="end_date" />
          </div>
        </div>
      </div>

      <div className="form-button-contain">
        <Button
          type="primary"
          className="submit-button"
          icon={<DownloadOutlined />}
          loading={loadingCondition}
          onClick={() => downloadShippingSchedule(values)}
        >
          Xuất báo cáo
        </Button>
        <Button
          type="primary"
          className="submit-button"
          icon={<SearchOutlined />}
          loading={loadingCondition}
          onClick={() => {
            setShouldScroll(true)
            return handleFormSubmit(values)
          }}
        >
          Lọc báo cáo
        </Button>
      </div>
    </Form>
  )
}
