import React from 'react'
import { ErrorMessage, Field } from 'formik'
import { Input, Typography, Row, Col, Checkbox } from 'antd'

import { CustomError } from './CustomComponent'
const { Title } = Typography
const { TextArea } = Input

export default React.memo((props) => {
  const { loadingCondition, values, handleChange } = props

  return (
    <div className="product-qualitifcations">
      <Title className="part-title">3. Chỉ tiêu sản phẩm</Title>
      <div className="part-field-wrapper">
        <div className="form-item">
          <Title className="field-title">
            Chỉ tiêu chất lượng
          </Title>
          <Field
            as={Input}
            name="quality_indicators"
            placeholder="Nhập chỉ tiêu chất lượng"
            disabled={loadingCondition}
            value={values.quality_indicators}
            onChange={handleChange}
          />
          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name="quality_indicators"
          />
        </div>

        <div className="form-item">
          <Title className="field-title">
            HS code <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={Input}
            name="hs_code"
            placeholder="Nhập HS code"
            disabled={loadingCondition}
            value={values.hs_code}
            onChange={handleChange}
          />
          <ErrorMessage component={(props) => <CustomError {...props} />} name="hs_code" />
        </div>

        <div className="form-item">
          <Title className="field-title">Ghi chú mặt hàng không xin CO</Title>
          <Field
            as={TextArea}
            name="co_note"
            placeholder="Ghi chú..."
            disabled={loadingCondition}
            value={values.co_note}
            onChange={handleChange}
            autoSize={{ minRows: 2, maxRows: 5 }}
          />

          <ErrorMessage component={(props) => <CustomError {...props} />} name="co_note" />
        </div>

        <div className="form-item">
          <Title className="field-title">Kiểm định sản phẩm</Title>
          <Row justify="space-between">
            <Col span={11}>
              <Field name="need_plan_quarantine">
                {(props) => (
                  <Checkbox
                    disabled={loadingCondition}
                    name="need_plan_quarantine"
                    checked={values.need_plan_quarantine}
                    onChange={props.form.handleChange}
                  >
                    Kiểm dịch thực vật
                  </Checkbox>
                )}
              </Field>

              <ErrorMessage
                component={(props) => <CustomError {...props} />}
                name="need_plan_quarantine"
              />
            </Col>
            <Col span={11}>
              <Field name="need_medical_quarantine">
                {(props) => (
                  <Checkbox
                    disabled={loadingCondition}
                    name="need_medical_quarantine"
                    checked={values.need_medical_quarantine}
                    onChange={props.form.handleChange}
                  >
                    Kiểm dịch y tế
                  </Checkbox>
                )}
              </Field>

              <ErrorMessage
                component={(props) => <CustomError {...props} />}
                name="need_medical_quarantine"
              />
            </Col>
          </Row>
        </div>

        <div className="form-item">
          <Title className="field-title">Kiểm khác</Title>
          <Field
            as={TextArea}
            name="need_other_quarantines"
            placeholder="Ghi chú..."
            disabled={loadingCondition}
            value={values.need_other_quarantines}
            onChange={handleChange}
            autoSize={{ minRows: 2, maxRows: 5 }}
          />

          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name="need_other_quarantines"
          />
        </div>

        <div className="form-item">
          <Title className="field-title">Ghi chú chất lượng sản phẩm</Title>
          <Field
            as={TextArea}
            name="quality_note"
            placeholder="Ghi chú..."
            disabled={loadingCondition}
            value={values.quality_note}
            onChange={handleChange}
            autoSize={{ minRows: 2, maxRows: 5 }}
          />

          <ErrorMessage component={(props) => <CustomError {...props} />} name="quality_note" />
        </div>

        <div className="form-item">
          <Title className="field-title">Ghi chú khác</Title>
          <Field
            as={TextArea}
            name="note"
            placeholder="Ghi chú..."
            disabled={loadingCondition}
            value={values.note}
            onChange={handleChange}
            autoSize={{ minRows: 2, maxRows: 5 }}
          />

          <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
        </div>
      </div>
    </div>
  )
})
