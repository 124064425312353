import * as yup from 'yup'

const passwordNotLongEnough = 'Password cần tối thiểu 4 ký tự'
const requiredOldPass = 'Vui lòng nhập mật khẩu cũ'
const requiredNewPass = 'Vui lòng nhập mật khẩu mới'
const requiredConfirmPass = 'Vui lòng xác nhận mật khẩu mới'

function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Chưa khớp với mật khẩu mới',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

yup.addMethod(yup.string, 'equalTo', equalTo)

const settingsAccountSchema = yup.object().shape({
  old_password: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredOldPass),
  new_password: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .min(4, passwordNotLongEnough)
    .max(255)
    .required(requiredNewPass),
  confirm_password: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .equalTo(yup.ref('new_password'))
    .min(4, passwordNotLongEnough)
    .max(255)
    .required(requiredConfirmPass),
})

export default settingsAccountSchema
