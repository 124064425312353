import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import { RollbackOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { formatNumber } from 'Utils'
import axios from 'Utils/axiosRexyAdmin'
import _ from 'lodash'

const { Title } = Typography

const typeOptionsCommodities = [
  { name: 'Bao bì', value: 'packing' },
  { name: 'Nguyên vật liệu', value: 'material' },
  { name: 'Tem', value: 'label' },
  { name: 'Bán thành phẩm', value: 'semi_finish_product' },
  { name: 'Thành phẩm', value: 'finish_product' },
]

const INITIAL_PROFILE = {
  transaction_number: '',
  transaction_type: 'in',
  note: '',
  created_at: moment().format('DD-MM-YYYY'),
  updated_at: moment().format('DD-MM-YYYY'),
  transaction_items: [
    {
      material_id: undefined,
      material: { id: undefined, name: '', code: '' },
      specification: '',
      unit: '',
      readable_unit: '',
      quantity_before: undefined,
      max_quantity: undefined,
      quantity: undefined,
      quantity_after: undefined,
      note: '',
    },
  ],
}

export default React.memo((props) => {
  const history = useHistory()
  const id = props.match?.params.id

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/transactions/${id}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            data: {
              ...resp.data,
              created_at: moment(resp.data.created_at).format('DD-MM-YYYY'),
              updated_at: moment(resp.data.updated_at).format('DD-MM-YYYY'),
            },
            loading: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    if (id) fetchData()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleBack = () =>
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/manage-stocks`)

  const itemName = (() => {
    return state.data.item_type === 'packing'
      ? ' bao bì '
      : state.data.item_type === 'material'
      ? ' nguyên vật liệu '
      : state.data.item_type === 'label'
      ? ' tem '
      : state.data.item_type === 'semi_finish_product'
      ? ' bán thành phẩm '
      : state.data.item_type === 'finish_product'
      ? ' thành phẩm '
      : ''
  })()

  return (
    <div id="view_export_import_stocks" className="table-container">
      <Title className="page-title">
        {state.data.transaction_type === 'in'
          ? 'Thông tin phiếu nhập kho'
          : state.data.transaction_type === 'out'
          ? 'Thông tin phiếu xuất kho'
          : state.data.transaction_type === 'deposit_in'
          ? 'Thông tin phiếu deposit nhập'
          : 'Thông tin phiếu deposit xuất'}
      </Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">Thông tin chung</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã phiếu
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.transaction_number}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ngày tạo
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.created_at}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  {state.data.transaction_type === 'in'
                    ? 'Ghi chú phiếu nhập kho'
                    : state.data.transaction_type === 'out'
                    ? 'Ghi chú phiếu xuất kho'
                    : state.data.transaction_type === 'deposit_in'
                    ? 'Ghi chú phiếu deposit nhập'
                    : 'Ghi chú phiếu deposit xuất'}
                </Col>
                <Col className="content-wrapper" span={16} style={{ wordBreak: 'unset' }}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Loại phiếu
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.transaction_type === 'in'
                    ? 'Phiếu nhập kho'
                    : state.data.transaction_type === 'out'
                    ? 'Phiếu xuất kho'
                    : state.data.transaction_type === 'deposit_in'
                    ? 'Nhập Deposit'
                    : 'Xuất deposit'}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Loại hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {_.get(_.find(typeOptionsCommodities, ['value', state.data.item_type]), 'name')}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Được tạo bởi
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.created_by === 'user' ? 'Người dùng' : 'Hệ thống'}
                </Col>
              </Row>
            </Card.Grid>

            <div className="commodity-export-table" style={{ marginTop: 30 }}>
              <Title className="commodity-export-table-title">Thông tin {itemName}</Title>
              <Col className="commodity-export-table-content">
                <div className="inner-content">
                  <div className="scroll-wrap">
                    <div className="row-wrapper">
                      <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                        STT
                      </div>
                      <div className="row-item thread-row" style={{ minWidth: 200 }}>
                        Mã hàng
                      </div>
                      <div className="row-item thread-row" style={{ minWidth: 200 }}>
                        Tên hàng
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 220 }}>
                        Quy cách
                      </div>
                      {(state.data.item_type === 'packing' ||
                        state.data.item_type === 'material') && (
                        <div className="row-item thread-row" style={{ maxWidth: 160 }}>
                          Đơn vị
                        </div>
                      )}
                      <div className="row-item thread-row" style={{ maxWidth: 160 }}>
                        Tồn đầu
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 160 }}>
                        {state.data.transaction_type === 'in'
                          ? 'Nhập kho'
                          : state.data.transaction_type === 'out'
                          ? 'Xuất kho'
                          : state.data.transaction_type === 'deposit_in'
                          ? 'Nhập deposit'
                          : 'Xuất deposit'}
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 160 }}>
                        Tồn cuối
                      </div>
                      <div className="last-child thread-row">Ghi chú</div>
                    </div>

                    <div className="scroll-vertical-table">
                      {state.data.transaction_items.map((item, index) => (
                        <div key={index} className="row-wrapper">
                          <div className="row-item" style={{ maxWidth: 60 }}>
                            {index + 1}
                          </div>
                          <div
                            className="row-item"
                            style={{ minWidth: 200, wordWrap: 'break-word' }}
                          >
                            {item.item?.code ? item.item.code : '-'}
                          </div>
                          <div
                            className="row-item"
                            style={{ minWidth: 200, wordWrap: 'break-word' }}
                          >
                            {item.item?.name ? item.item.name : '-'}
                          </div>
                          <div className="row-item" style={{ maxWidth: 220 }}>
                            {item.specification ? item.specification : '-'}
                          </div>

                          {(state.data.item_type === 'packing' ||
                            state.data.item_type === 'material') && (
                            <div className="row-item" style={{ maxWidth: 160 }}>
                              {item.readable_unit ? item.readable_unit : '-'}
                            </div>
                          )}
                          <div className="row-item" style={{ maxWidth: 160 }}>
                            {item.quantity_before
                              ? formatNumber(Number(item.quantity_before))
                              : '-'}
                          </div>
                          <div className="row-item" style={{ maxWidth: 160 }}>
                            {item.quantity ? formatNumber(Number(item.quantity)) : '-'}
                          </div>
                          <div className="row-item" style={{ maxWidth: 160 }}>
                            {item.quantity_after ? formatNumber(Number(item.quantity_after)) : '-'}
                          </div>
                          <div className="last-child">{item.note}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>
        </div>
      </div>
    </div>
  )
})
