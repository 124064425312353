import React from 'react'
import { Typography } from 'antd'

import Form from '../Form'
import './index.scss'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  return (
    <div id="edit_supplier_debt_invoice_page">
      <Title className="page-title">Cập nhật hoá đơn thanh toán NCC</Title>

      <Form path={'edit'} editId={id} />
    </div>
  )
})
