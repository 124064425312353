import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import {
  Input,
  Button,
  Alert,
  Typography,
  Select,
  Spin,
  DatePicker,
  message,
  Col,
  InputNumber,
  Checkbox,
} from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import filter from 'lodash/filter'

import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'
import { noData } from 'Components'
import { useDebounce } from 'Hooks'
import {
  useScrollToErrors,
  preventEnterSubmit,
  removeVietnameseTones,
  roundUpDecimal,
  formatInputNumber,
} from 'Utils'
import { CustomError, ExportItems, ExportAllProductOfOneOrder } from './CustomComponent'
import SaleOrderInPaper from './SaleOrderInPaper.Component'

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

const FormComponent = React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
    dataFetch,
    isFetching,
  } = props
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })

  const [orderOptions, setOrderOptions] = useState([])
  const [maxItems, setMaxItems] = useState(1)
  const [selectedSaleOrderItems, setSelectedSaleOrderItems] = useState([])
  const [selectedSaleOrder, setSelectedSaleOrder] = useState([])
  const [oneSaleOrder, setOneSaleOrder] = useState([])
  const [totalCubic, setTotalCubic] = useState(0)
  const [presentCubic, setPresentCubic] = useState(0)

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)
  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  useEffect(() => {
    let didCancel = false
    if (editId && !didCancel && Object.keys(dataFetch).length) {
      onSearch(dataFetch.customer.code)
      setValues({ ...values, ...dataFetch })
      setOrderOptions(dataFetch.order_items)
      setOneSaleOrder(dataFetch.order_items)
      computeData(dataFetch)
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, dataFetch])

  useEffect(() => {
    let didCancel = false

    const calTotalCubic = () => {
      const sum =
        28.5 * values.container_type_1.amount +
        58.5 * values.container_type_2.amount +
        66.5 * values.container_type_3.amount
      const formatted = Number(Number(sum).toFixed(2))
      setTotalCubic(formatted)
    }

    if (!didCancel) {
      calTotalCubic()
    }

    return () => (didCancel = true)
  }, [
    values.container_type_1.amount,
    values.container_type_2.amount,
    values.container_type_3.amount,
  ])

  useEffect(() => {
    let didCancel = false

    const calPresentCubic = () => {
      let totalCubic = 0
      // values.commodity_export_items.forEach((item) => {
      //   if (item.box_size && item.max_quantity) {
      //     totalCubic += Number(item.box_size * item.max_quantity)
      //   }
      // })
      // setPresentCubic(Number(totalCubic.toFixed(2)))
      values.commodity_export_items.forEach((item) => {
        if (item.box_size && item.max_quantity) {
          totalCubic += roundUpDecimal(Number(item.box_size) * Number(item.quantity))
        }
      })
      setPresentCubic(Number(totalCubic.toFixed(2)))
    }

    if (!didCancel) {
      calPresentCubic()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.commodity_export_items,
    values.commodity_export_items.total_cubic,
    values.commodity_export_items.length,
  ])

  useEffect(() => {
    let didCancel = false

    const fetchCustomers = async () => {
      const url = '/customers/form/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
          },
        })
        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (debouncedSearchTerm.length && debouncedSearchTerm === searchTerm.keyword) fetchCustomers()

    return () => (didCancel = true)
  }, [debouncedSearchTerm, searchTerm.keyword])

  const computeData = (editData) => {
    let maxItemsNumber = 0
    if (editData.order_items.length) {
      editData.order_items.forEach((item) => {
        if (item.sale_order_items.length) {
          maxItemsNumber += item.sale_order_items.length
        } else {
          maxItemsNumber += 1
        }
      })
    } else {
      maxItemsNumber += 1
    }
    setMaxItems(maxItemsNumber)

    let selectedSaleOrderItems = []
    let selectedSaleOrder = []
    if (editData.commodity_export_items.length) {
      selectedSaleOrderItems = editData.commodity_export_items.map(
        (item) => item.sale_order_item_id
      )

      selectedSaleOrder = editData.commodity_export_items.map((item) => ({
        id: item.sale_order_id,
        order_number: item.order_number,
      }))
    }

    setSelectedSaleOrderItems(selectedSaleOrderItems)
    setSelectedSaleOrder(selectedSaleOrder)
  }

  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onCustomerChange = async (val) => {
    setFieldValue('customer_id', val)
    setFieldValue('commodity_export_items', [
      {
        sale_order_id: undefined,
        sale_order_item_id: undefined,
        quantity: undefined,
        max_quantity: undefined,
        specification: '',
        sale_order_items: [],
      },
    ])
    setFieldValue('sale_order_items', [])
    const url = `/sale_orders/form-in-commodity-export/?customer_id=${val}&is_drafting=false`

    try {
      const result = await axiosExpand.get(url)
      setOrderOptions(result.data.records)
      const oneSaleFilteredOptions = filter(result.data.records, { status: 'produced' })
      setOneSaleOrder(oneSaleFilteredOptions)
      let maxItemsNumber = 0
      if (result.data.records.length) {
        result.data.records.forEach((item) => {
          if (item.sale_order_items.length) {
            maxItemsNumber += item.sale_order_items.length
          } else {
            maxItemsNumber += 1
          }
        })
      } else {
        maxItemsNumber += 1
      }
      setMaxItems(maxItemsNumber)
    } catch (error) {}
  }
  const handleNumberChange = (name, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(name, 0)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(name, roundDown)
    }
  }

  const onSelectOneSaleOrder = (val, option) => {
    setFieldValue('sale_order_id', val)
    setFieldValue('sale_order_in_paper_id', val)
    const commodityExportItems = option.attr.sale_order_items.map((item, index) => ({
      ...item,
      // sale_order_id: val,
      sale_order_item_id: item.id,
      quantity: item.box_quantity,
      max_quantity: item.box_quantity,
      specification: item.specification,
      box_size: Number(item.box_size),
      sale_order_items: [],
    }))
    if (editId) {
      commodityExportItems.forEach((item, index) => {
        delete item['id']
      })
    }

    setFieldValue('commodity_export_items', commodityExportItems)

    commodityExportItems.forEach((item, index) => {
      const totalCubic = roundUpDecimal(Number(item.box_size) * Number(item.quantity))
      setFieldValue(`commodity_export_items[${index}].total_cubic`, totalCubic)
    })
  }

  const onExportOneOrderChange = (event) => {
    if (!event.target.checked) {
      setSelectedSaleOrder([])
      setSelectedSaleOrderItems([])
      setValues({
        ...values,
        export_only_one_sale_order: event.target.checked,
        sale_order_id: undefined,
        sale_order_in_paper_id: undefined,
      })

      values.commodity_export_items.forEach((item) => {
        item.sale_order_items = [{ ...item }]
        item.total_cubic = Number(item.box_size * item.box_quantity).toFixed(2)
      })
    } else {
      setFieldValue('export_only_one_sale_order', event.target.checked)
    }
  }

  const generateSuggestion = () => {
    if (!totalCubic || !presentCubic) {
      return ''
    } else if (totalCubic > presentCubic) {
      return (
        <span style={{ color: 'green' }}>
          {`Thiếu ${(totalCubic - presentCubic).toFixed(2)} m3`}
        </span>
      )
    } else if (totalCubic < presentCubic) {
      return (
        <span style={{ color: 'red' }}>{`Thừa ${(presentCubic - totalCubic).toFixed(2)} m3`}</span>
      )
    } else return ''
  }

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  // function disabledDate(current) {
  //   return current && current < moment().startOf('day')
  // }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_commodity_export_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">Thông tin đơn hàng</Title>
      {serverError && (
        <Alert
          message={serverError}
          type="error"
          className="server-error"
          showIcon
          style={{ maxWidth: 400, marginLeft: 0 }}
        />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Khách hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="customer_id" disabled={loadingCondition || editId}>
              {() => {
                return (
                  <Select
                    name="customer_id"
                    style={{ width: '100%' }}
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    notFoundContent={searchDropDownCondition}
                    placeholder="Nhập mã hoặc tên khách hàng để tìm kiếm"
                    onSearch={onSearch}
                    value={values.customer_id}
                    onChange={onCustomerChange}
                    optionFilterProp="children"
                    loading={searchTerm.searching}
                    disabled={loadingCondition || editId}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {searchTerm.result.map((item) => (
                      <Option key={item.id} value={item.id}>{`${item.code}: ${item.name}`}</Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="customer_id" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Ngày xuất hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.date}
                  onChange={(val) => setFieldValue('date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="date" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Ngày dự kiến đóng hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="packing_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.packing_date}
                  onChange={(val) => setFieldValue('packing_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="packing_date" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Ngày dự kiến tàu chạy <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="depart_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.depart_date}
                  onChange={(val) => setFieldValue('depart_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="depart_date" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Ngày dự kiến tàu đến <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field name="arrival_date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.arrival_date}
                  onChange={(val) => setFieldValue('arrival_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="arrival_date" />
          </div>
          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">To</Title>
            <Field
              as={Input}
              name="to"
              placeholder="Nhập to"
              disabled={loadingCondition}
              value={values.to}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="to" />
          </div>
          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">Vessel name</Title>
            <Field
              as={Input}
              name="vessel_name"
              placeholder="Nhập vessel name"
              disabled={loadingCondition}
              value={values.vessel_name}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="vessel_name" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">Bill of lading no</Title>
            <Field
              as={Input}
              name="bill_of_lading_no"
              placeholder="Nhập bill of lading no"
              disabled={loadingCondition}
              value={values.bill_of_lading_no}
              onChange={handleChange}
            />
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="bill_of_lading_no"
            />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">Invoice number</Title>
            <Field
              as={Input}
              name="invoice_number"
              placeholder="Nhập invoice number"
              disabled={loadingCondition}
              value={values.invoice_number}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="invoice_number" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">Ghi chú giao hàng</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Ghi chú..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>

          <div className="form-item" style={{ maxWidth: 400, flexDirection: 'row' }}>
            <Title className="field-title" style={{ display: 'inline-block', marginRight: 10 }}>
              Xuất toàn bộ đơn hàng:
            </Title>
            <Field name="export_only_one_sale_order">
              {(props) => (
                <Checkbox
                  disabled={loadingCondition}
                  name="export_only_one_sale_order"
                  checked={values.export_only_one_sale_order}
                  onChange={onExportOneOrderChange}
                />
              )}
            </Field>
          </div>

          {values.export_only_one_sale_order ? (
            <div className="form-item" style={{ maxWidth: 400 }}>
              <Title className="field-title">
                Order No <span className="compulsory-field-symbol">*</span>
              </Title>
              <Field name={`sale_order_id`} disabled={loadingCondition}>
                {() => {
                  return (
                    <Select
                      showSearch
                      name={`sale_order_id`}
                      style={{ width: '100%' }}
                      placeholder="Chọn sản phẩm"
                      value={values.sale_order_id}
                      onChange={(val, option) => onSelectOneSaleOrder(val, option)}
                      disabled={loadingCondition}
                      optionFilterProp="children"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, option) => {
                        const dataInput = removeVietnameseTones(input)
                        const dataOption = removeVietnameseTones(option.children)
                        return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                      }}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {oneSaleOrder.map((item) => (
                        <Option key={item.id} value={item.id} attr={item}>
                          {item.order_number}
                        </Option>
                      ))}
                    </Select>
                  )
                }}
              </Field>
              <ErrorMessage
                component={(props) => <CustomError {...props} />}
                name={`sale_order_id`}
              />
            </div>
          ) : null}

          <div className="form-item ">
            <Title className="field-title">
              Thông tin xuất hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            {values.export_only_one_sale_order ? (
              <ExportAllProductOfOneOrder
                {...props}
                name="commodity_export_items"
                values={values}
                loadingCondition={loadingCondition}
                setFieldValue={setFieldValue}
                setPresentCubic={setPresentCubic}
              />
            ) : (
              <>
                <ExportItems
                  {...props}
                  name="commodity_export_items"
                  values={values}
                  loadingCondition={loadingCondition}
                  setFieldValue={setFieldValue}
                  editId={editId}
                  orderOptions={orderOptions}
                  dataFetch={dataFetch}
                  maxItems={maxItems}
                  selectedSaleOrderItems={selectedSaleOrderItems}
                  setSelectedSaleOrderItems={setSelectedSaleOrderItems}
                  selectedSaleOrder={selectedSaleOrder}
                  setSelectedSaleOrder={setSelectedSaleOrder}
                  setPresentCubic={setPresentCubic}
                />

                <SaleOrderInPaper
                  selectedSaleOrder={selectedSaleOrder}
                  oneSaleOrder={oneSaleOrder}
                  values={values}
                  loadingCondition={loadingCondition}
                  setFieldValue={setFieldValue}
                />
              </>
            )}
          </div>

          <div className="form-item" style={{ maxWidth: '100%' }}>
            <Title className="field-title">
              Đóng hàng vào container <span className="compulsory-field-symbol"> *</span>
            </Title>
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name={'container_type_1.amount'}
            />
            <Col className="order-table-content" style={{ marginTop: 10 }}>
              <div className="inner-content">
                <div className="scroll-wrap">
                  <div className="row-wrapper">
                    <div className="row-item thread-row">Loại container</div>
                    <div className="row-item thread-row">Cubic (m3)</div>
                    <div className="row-item thread-row" style={{ minWidth: 180 }}>
                      Seal No
                    </div>
                    <div className="row-item thread-row" style={{ minWidth: 180 }}>
                      Container No
                    </div>
                    <div className="last-child thread-row">Số lượng</div>
                  </div>

                  <div className="row-wrapper">
                    <div className="row-item">{`(1) Container 20'DC`}</div>
                    <div className="row-item">28.50 m3</div>
                    <div className="row-item" style={{ minWidth: 180 }}>
                      <Field
                        as={Input}
                        name="container_type_1.seal_no"
                        placeholder="Nhập seal no."
                        disabled={loadingCondition}
                        value={values.container_type_1.seal_no}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name="container_type_1.seal_no"
                      />
                    </div>
                    <div className="row-item" style={{ minWidth: 180 }}>
                      <Field
                        as={Input}
                        name="container_type_1.container_no"
                        placeholder="Nhập container no."
                        disabled={loadingCondition}
                        value={values.container_type_1.container_no}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name="container_type_1.container_no"
                      />
                    </div>
                    <div className="last-child">
                      <Field
                        as={InputNumber}
                        style={{ width: '100%' }}
                        name={'container_type_1.amount'}
                        placeholder="Nhập số lượng"
                        disabled={loadingCondition}
                        value={values.container_type_1.amount}
                        onChange={(value) =>
                          handleNumberChange('container_type_1.amount', 100000, value)
                        }
                        formatter={(value) => formatInputNumber(value)}
                        min={0}
                        max={100000}
                      />
                    </div>
                  </div>

                  <div className="row-wrapper">
                    <div className="row-item">{`(2) Container 40'DC`}</div>
                    <div className="row-item">58.50 m3</div>
                    <div className="row-item" style={{ minWidth: 180 }}>
                      <Field
                        as={Input}
                        name="container_type_2.seal_no"
                        placeholder="Nhập seal no."
                        disabled={loadingCondition}
                        value={values.container_type_2.seal_no}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name="container_type_2.seal_no"
                      />
                    </div>
                    <div className="row-item" style={{ minWidth: 180 }}>
                      <Field
                        as={Input}
                        name="container_type_2.container_no"
                        placeholder="Nhập container no."
                        disabled={loadingCondition}
                        value={values.container_type_2.container_no}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name="container_type_2.container_no"
                      />
                    </div>
                    <div className="last-child">
                      <Field
                        as={InputNumber}
                        style={{ width: '100%' }}
                        name={'container_type_2.amount'}
                        placeholder="Nhập số lượng"
                        disabled={loadingCondition}
                        value={values.container_type_2.amount}
                        onChange={(value) =>
                          handleNumberChange('container_type_2.amount', 100000, value)
                        }
                        formatter={(value) => formatInputNumber(value)}
                        min={0}
                        max={100000}
                      />
                    </div>
                  </div>

                  <div className="row-wrapper">
                    <div className="row-item">{`(3) Container 40'HC`}</div>
                    <div className="row-item">66.50 m3</div>
                    <div className="row-item" style={{ minWidth: 180 }}>
                      <Field
                        as={Input}
                        name="container_type_3.seal_no"
                        placeholder="Nhập seal no."
                        disabled={loadingCondition}
                        value={values.container_type_3.seal_no}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name="container_type_3.seal_no"
                      />
                    </div>
                    <div className="row-item" style={{ minWidth: 180 }}>
                      <Field
                        as={Input}
                        name="container_type_3.container_no"
                        placeholder="Nhập container no."
                        disabled={loadingCondition}
                        value={values.container_type_3.container_no}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name="container_type_3.container_no"
                      />
                    </div>
                    <div className="last-child">
                      <Field
                        as={InputNumber}
                        style={{ width: '100%' }}
                        name={'container_type_3.amount'}
                        placeholder="Nhập số lượng"
                        disabled={loadingCondition}
                        value={values.container_type_3.amount}
                        onChange={(value) =>
                          handleNumberChange('container_type_3.amount', 100000, value)
                        }
                        formatter={(value) => formatInputNumber(value)}
                        min={0}
                        max={100000}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>

          <div className="form-item container-information">
            <div className="item">
              <Title className="field-title">Tổng số khối:</Title>
              <span className="content">{totalCubic ? `${totalCubic} m3` : '- m3'}</span>
            </div>

            <div className="item">
              <Title className="field-title">Hiện tại:</Title>
              <span className="content">
                {presentCubic ? `${roundUpDecimal(presentCubic)} m3` : '- m3'}
              </span>
            </div>

            <div className="item">
              <Title className="field-title">Gợi ý:</Title>
              <span className="content">{generateSuggestion()}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="form-button-contain">
        <div className="left-button-contain">
          <Button
            loading={loadingCondition}
            type="primary"
            className="cancel-button"
            icon={<RollbackOutlined />}
            onClick={() =>
              !!history.goBack && typeof history.goBack === 'function'
                ? history.goBack()
                : history.push('/commodity-exports')
            }
          >
            Trở lại
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
            loading={loadingCondition}
            onClick={() => setShouldScroll(true)}
          >
            {path === 'new' ? 'Tạo đơn xuất hàng' : 'Cập nhật'}
          </Button>
        </div>
      </div>
    </Form>
  )
})

export default FormComponent
// shouldRefresh({ key: 'pricing', status: true })
