import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['MO', 'OM', 'PP', 'DI']

const ListProduct = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewProduct = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewProduct = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

const EditProduct = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export { ListProduct, ViewProduct, NewProduct, EditProduct }
