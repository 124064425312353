import React, { useEffect } from 'react'
import { Form } from 'formik'
import { Button, Alert, Typography, Upload } from 'antd'
import { PlusOutlined, FormOutlined, UploadOutlined } from '@ant-design/icons'
import get from 'lodash/get'

import { preventEnterSubmit } from 'Utils'

const { Title } = Typography

export default React.memo((props) => {
  const {
    isSubmitting,
    handleSubmit,
    serverError,
    path,
    setFieldValue,
    values,
    manualError,
    setManualError,
  } = props

  const loadingCondition = isSubmitting

  const choseFile = get(values.file, 'fileList', []).length

  useEffect(() => {
    let didCancel = false
    if (!didCancel && choseFile) setManualError(false)
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choseFile])

  return (
    <Form
      id="manage_time_attendence_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">Tải lên file chấm công</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title
              className="field-title"
              style={{ height: 22, display: 'flex', alignItems: 'center' }}
            >
              {`${choseFile ? 'File đã chọn: ' : 'Vui lòng chọn file: '}`}&nbsp;
            </Title>
            <Upload
              // disabled={notChoseFileYet}
              onChange={(info) => {
                setFieldValue('file', info)
              }}
              customRequest={({ onSuccess, file }) => {
                setTimeout(() => {
                  onSuccess('ok')
                }, 0)
              }}
              // onRemove={() => {setFieldValue('file', {})}}
              accept=".xlsx"
              fileList={get(values, 'file.fileList', [])}
            >
              {!choseFile && (
                <Button>
                  <UploadOutlined /> Chọn file
                </Button>
              )}
            </Upload>
          </div>
          {manualError && (
            <Alert message="Xin vui lòng chọn file" type="error" className="field-error" showIcon />
          )}
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          // onClick={() => setShouldScroll(true)}
        >
          Gửi
        </Button>
      </div>
    </Form>
  )
})
