import React, { useEffect, useState } from 'react'
import { Table, Input, Button, Modal, message } from 'antd'
import axiosExpand from 'Utils/axiosExpand'
import moment from 'moment'
import { DownloadOutlined } from '@ant-design/icons'

import { useStoreState, useStoreActions } from 'easy-peasy'

import { pageSizeConfig } from 'Utils'
import useDataApi from './Hooks'
import NestList from './NestedList'
import ExportFileExcelForm from './Form'

const columns = [{ title: 'Order', dataIndex: 'order_number' }]

const defaultDates = {
  startDate: moment(),
  endDate: moment(),
}

const FileDownload = require('js-file-download')

export default React.memo(() => {
  const { data, count, commodityExportCount, loading, pagination, doFetch, setSearchTerm } =
    useDataApi()

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const supplierPayment = useStoreState((state) => state.menuTabs['supplier-payment'])
  const [visible, setVisible] = useState(false)

  const [dates, setDates] = useState(defaultDates)

  useEffect(() => {
    let didCancel
    if (supplierPayment && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'supplier-payment', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierPayment])

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch((prev) => ({
      ...prev,
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
    }))
  }

  const showModal = () => {
    setVisible(!visible)
  }
  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <div>
          <Input.Search
            placeholder="Tìm kiếm..."
            className="search-bar"
            defaultValue={keyword}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div>
          <Button type="primary" onClick={showModal} icon={<DownloadOutlined />}>
            Xuất File Excel
          </Button>
        </div>
      </div>
    )
  }

  const download = async ({ url, fileName }) => {
    try {
      const result = await axiosExpand.get(url, {
        responseType: 'arraybuffer',
        params: {
          start_date: moment(dates.startDate).format('YYYY-MM-DD'),
          end_date: moment(dates.endDate).format('YYYY-MM-DD'),
        },
      })
      FileDownload(result.data, fileName)
    } catch (error) {
      message.error('Đã có lỗi xảy ra, xin vui lòng tải lại trang và thử lại.')
    }
  }

  const handleOk = () => {
    download({
      url: `/standing_orders/export/statistic/`,
      fileName: `thong-ke-cong-no-nha-cung-cap.xlsx`,
    })
    setVisible(false)
    setDates(defaultDates)
  }

  const onCancel = () => {
    setVisible(false)
    setDates(defaultDates)
  }

  return (
    <div id="manage_supplier_payment_table">
      <Modal
        title="Công nợ thanh toán NCC"
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        okText="Xuất Excel"
      >
        <ExportFileExcelForm dates={dates} setDates={setDates} />
      </Modal>
      <Table
        title={renderTitle}
        className="supplier-payment-table"
        rowClassName="order-name-row-styles"
        dataSource={data}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ y: 680 }}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (row, index) => (
            <NestList
              commodityExportCount={commodityExportCount}
              count={count}
              data={row.purchase_orders}
            />
          ),
        }}
      />
    </div>
  )
})
