import React, { useEffect } from 'react'
import { Table, Input, Button } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

export default React.memo((props) => {
  const history = useHistory()
  const { firstRender, data, loading, pagination, doFetch, setSearchTerm, searchTerm } = props

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const shippingScheduleState = useStoreState((state) => state.menuTabs['shipping-schedule'])

  useEffect(() => {
    let didCancel
    if (shippingScheduleState && !didCancel) {
      doFetch((prev) => ({ ...prev, current: 1, pageSize: 10, order_by: '', order: '' }))
      shouldRefresh({ key: 'shipping-schedule', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingScheduleState])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      width: 70,
    },
    {
      title: 'Mã order',
      dataIndex: 'order_number',
      sortOrder: pagination.order_by === 'order_number' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
    },
    {
      title: 'Ngày đơn hàng',
      dataIndex: 'date',
      sortOrder: pagination.order_by === 'date' ? `${pagination.order}` : null,
      width: 200,
      sorter: true,
      render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'customer.name',
      sortOrder: pagination.order_by === 'customer.name' ? `${pagination.order}` : null,
      width: 220,
      sorter: true,
    },
    {
      title: 'Ngày dự kiến xuất hàng',
      dataIndex: 'export_date',
      sortOrder: pagination.order_by === 'export_date' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
      render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    },
    {
      title: 'Ngày dự kiến tàu đến',
      dataIndex: 'commodity_exports',
      width: 180,
      render: (commodity_exports) => {
        return commodity_exports[0]?.arrival_date
          ? moment(commodity_exports[0].arrival_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
          : null
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      sortOrder: pagination.order_by === 'status' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
    },
    {
      title: 'Thông báo',
      dataIndex: 'order_number',
      // sortOrder: pagination.order_by === 'status' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
      render: (order_number) => actionColumn(order_number),
    },
  ]
  const handleEdit = (order_number, event) => {
    event.stopPropagation()
    history.push(
      `notifications?page=1&per_page=5&order_by=&order=&total_count=13&keyword=${order_number}`
    )
  }
  const actionColumn = (order_number) => {
    return (
      <div className="table-actions">
        <Button
          className="action-item update"
          type="link"
          onClick={(e) => handleEdit(order_number, e)}
        >
          Chi tiết
        </Button>
      </div>
    )
  }
  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch((prev) => ({
      ...prev,
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
    }))
  }

  const renderTitle = () => {
    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    )
  }
  const dataSource = data.map((item, index) => ({
    ...item,
    index: index + 1,
    'customer.name': item.customer.name,
    status: item.readable_status,
    'commodity_export.arrival_date': _.has(item.commodity_export, 'arrival_date')
      ? moment(item.commodity_export.arrival_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
      : '',
  }))

  const loadingCondition = loading || firstRender

  return (
    <div id="shipping_schedule_table">
      <Table
        title={renderTitle}
        className="shipping-schedule-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, showSizeChanger: true }}
        loading={loadingCondition}
        rowKey={(record) => record.index}
        onChange={handleTableChange}
        scroll={{ x: 1100, y: 680 }}
      />
    </div>
  )
})
