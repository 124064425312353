import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import {
  Input,
  Button,
  Alert,
  Typography,
  Select,
  Spin,
  DatePicker,
  message,
  Col,
  InputNumber,
} from 'antd'
import {
  RollbackOutlined,
  PlusOutlined,
  FormOutlined,
  SearchOutlined,
  ToolOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import { noData } from 'Components'
import { useDebounce } from 'Hooks'
import {
  useScrollToErrors,
  preventEnterSubmit,
  removeVietnameseTones,
  formatInputNumber,
  roundUpDecimal,
} from 'Utils'
import { CustomError, OrderItems } from './CustomComponent'
import CustomUploadDocument from '../CustomUploadDocument'
import { OtherDocumentsTable, BlockNavigation } from 'Components'

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

export default React.memo((props) => {
  const {
    isSubmitting,
    setSubmitting,
    isFetching,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    dataFetch,
    documents,
    setDocuments,
    initialValues,
    firstTimeFetchedValues,
  } = props

  const history = useHistory()
  // const [isFetching, setIsFetching] = useState(false)
  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)
  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  const [totalCubic, setTotalCubic] = useState(0)
  const [presentCubic, setPresentCubic] = useState(0)
  const [selectedStockItems, setSelectedStockItems] = useState([])

  useEffect(() => {
    let didCancel = false
    if (editId && !didCancel && Object.keys(dataFetch).length) {
      onSearch(dataFetch.customer.code)
      computeData(dataFetch)
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, dataFetch])

  useEffect(() => {
    let didCancel = false

    const fetchCustomers = async () => {
      const url = '/customers/form/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
          },
        })

        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (debouncedSearchTerm.length && searchTerm.keyword === debouncedSearchTerm) {
      fetchCustomers()
    }
    return () => (didCancel = true)
  }, [debouncedSearchTerm, searchTerm.keyword])

  useEffect(() => {
    let didCancel = false

    const calTotalCubic = () => {
      const sum =
        28.5 * values.container_type_1_amount +
        58.5 * values.container_type_2_amount +
        66.5 * values.container_type_3_amount
      const formatted = Number(Number(sum).toFixed(2))
      setTotalCubic(formatted)
    }

    if (!didCancel) {
      calTotalCubic()
    }

    return () => (didCancel = true)
  }, [
    values.container_type_1_amount,
    values.container_type_2_amount,
    values.container_type_3_amount,
  ])

  const computeData = (data) => {
    const exchange_rate = Number(data.usd_to_vnd_rate)
    const stickerFees = Number(data.sticker_fees)
    const saleOrderItems = data.sale_order_items.map((item) => ({
      ...item,
      old_fob: Number(item.old_fob),
      total_quantity: item.box_quantity * item.quantity_in_box,
    }))

    const formattedData = {
      ...values,
      ...data,
      date: moment(data.date, 'YYYY-MM-DD'),
      sale_order_items: saleOrderItems,
      usd_to_vnd_rate: exchange_rate,
      sticker_fees: stickerFees,
    }

    return setValues(formattedData)
  }

  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onResultSelect = (val) => {
    setFieldValue('customer_id', val)
  }

  const handleNumberChange = (name, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(name, 0)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(name, roundDown)
    }
  }

  const generateSuggestion = () => {
    if (!totalCubic || !presentCubic) {
      return ''
    } else if (totalCubic > presentCubic) {
      return (
        <span style={{ color: 'green' }}>
          {`Thiếu ${(totalCubic - presentCubic).toFixed(2)} m3`}
        </span>
      )
    } else if (totalCubic < presentCubic) {
      return (
        <span style={{ color: 'red' }}>{`Thừa ${(presentCubic - totalCubic).toFixed(2)} m3`}</span>
      )
    } else return ''
  }

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  // function disabledDate(current) {
  //   return current && current < moment().startOf('day')
  // }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_sale_orders_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <BlockNavigation
        values={values}
        initialValues={initialValues}
        path={path}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />
      <Title className="page-sub-title">Thông tin đơn hàng</Title>
      {serverError && (
        <Alert
          message={serverError}
          type="error"
          className="server-error"
          showIcon
          style={{ maxWidth: 400, marginLeft: 0 }}
        />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Order No <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="order_number"
              placeholder="Nhập mã đặt hàng"
              disabled={loadingCondition}
              value={values.order_number}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="order_number" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Ngày <span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field name="date" disabled={loadingCondition}>
              {() => (
                <DatePicker
                  inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={values.date}
                  onChange={(val) => setFieldValue('date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loadingCondition}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              )}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="date" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Khách hàng <span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field name="customer_id" disabled={loadingCondition}>
              {() => {
                return (
                  <Select
                    name="customer_id"
                    style={{ width: '100%' }}
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    notFoundContent={searchDropDownCondition}
                    placeholder="Nhập mã hoặc tên khách hàng để tìm kiếm"
                    onSearch={onSearch}
                    value={values.customer_id}
                    onChange={onResultSelect}
                    optionFilterProp="children"
                    loading={searchTerm.searching}
                    disabled={loadingCondition}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      const dataInput = removeVietnameseTones(input)
                      const dataOption = removeVietnameseTones(option.children)
                      return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                    }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {searchTerm.result.map((item) => (
                      <Option key={item.id} value={item.id}>{`${item.code}: ${item.name}`}</Option>
                    ))}
                  </Select>
                )
              }}
            </Field>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="customer_id" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">Ghi chú sản xuất lô hàng</Title>
            <Field
              as={TextArea}
              name="production_requirement"
              placeholder="Ghi chú..."
              disabled={loadingCondition}
              value={values.production_requirement}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="production_requirement"
            />
          </div>

          <div className="form-item scrollToBottomAdd">
            <Title className="field-title">
              Thông tin sản phẩm <span className="compulsory-field-symbol"> *</span>
            </Title>
            <OrderItems
              name="purchase_order_items"
              {...props}
              values={values}
              loadingCondition={loadingCondition}
              setFieldValue={setFieldValue}
              editId={editId}
              setPresentCubic={setPresentCubic}
              selectedStockItems={selectedStockItems}
              setSelectedStockItems={setSelectedStockItems}
            />
          </div>

          <div className="form-item" style={{ maxWidth: 600 }}>
            <Title className="field-title">
              Đóng hàng vào container <span className="compulsory-field-symbol"> *</span>
            </Title>
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name={'container_type_1_amount'}
            />
            <Col className="order-table-content" style={{ marginTop: 10 }}>
              <div className="inner-content">
                <div className="scroll-wrap">
                  <div className="row-wrapper">
                    <div className="row-item thread-row">Loại container</div>
                    <div className="row-item thread-row">Cubic (m3)</div>
                    <div className="last-child thread-row">Số lượng</div>
                  </div>

                  <div className="row-wrapper">
                    <div className="row-item">{`(1) Container 20'DC`}</div>
                    <div className="row-item">28.50 m3</div>
                    <div className="last-child">
                      <Field
                        as={InputNumber}
                        style={{ width: '100%' }}
                        name={'container_type_1_amount'}
                        placeholder="Nhập số lượng"
                        disabled={loadingCondition}
                        value={values.container_type_1_amount}
                        onChange={(value) =>
                          handleNumberChange('container_type_1_amount', 100000, value)
                        }
                        formatter={(value) => formatInputNumber(value)}
                        min={0}
                        max={100000}
                      />
                      {/* <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name={'container_type_1_amount'}
                      /> */}
                    </div>
                  </div>

                  <div className="row-wrapper">
                    <div className="row-item">{`(2) Container 40'DC`}</div>
                    <div className="row-item">58.50 m3</div>
                    <div className="last-child">
                      <Field
                        as={InputNumber}
                        style={{ width: '100%' }}
                        name={'container_type_2_amount'}
                        placeholder="Nhập số lượng"
                        disabled={loadingCondition}
                        value={values.container_type_2_amount}
                        onChange={(value) =>
                          handleNumberChange('container_type_2_amount', 100000, value)
                        }
                        formatter={(value) => formatInputNumber(value)}
                        min={0}
                        max={100000}
                      />
                      {/* <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name={'container_type_2_amount'}
                      /> */}
                    </div>
                  </div>

                  <div className="row-wrapper">
                    <div className="row-item">{`(3) Container 40'HC`}</div>
                    <div className="row-item">66.50 m3</div>
                    <div className="last-child">
                      <Field
                        as={InputNumber}
                        style={{ width: '100%' }}
                        name={'container_type_3_amount'}
                        placeholder="Nhập số lượng"
                        disabled={loadingCondition}
                        value={values.container_type_3_amount}
                        onChange={(value) =>
                          handleNumberChange('container_type_3_amount', 100000, value)
                        }
                        formatter={(value) => formatInputNumber(value)}
                        min={0}
                        max={100000}
                      />
                      {/* <ErrorMessage
                        component={(props) => <CustomError {...props} />}
                        name={'container_type_3_amount'}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>

          <div className="form-item container-information">
            <div className="item">
              <Title className="field-title">Tổng số khối:</Title>
              <span className="content">{totalCubic ? `${totalCubic} m3` : '- m3'}</span>
            </div>

            <div className="item">
              <Title className="field-title">Hiện tại:</Title>
              <span className="content">
                {presentCubic ? `${roundUpDecimal(presentCubic)} m3` : '- m3'}
              </span>
            </div>

            <div className="item">
              <Title className="field-title">Gợi ý:</Title>
              <span className="content">{generateSuggestion()}</span>
            </div>
          </div>
          <div className="other-documents">
            {path === 'edit' && (
              <>
                <OtherDocumentsTable
                  documents={documents}
                  setDocuments={setDocuments}
                  action="edit"
                />
                <CustomUploadDocument
                  documents={documents}
                  setDocuments={setDocuments}
                  setSubmitting={setSubmitting}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="form-button-contain">
        <div className="left-button-contain">
          <Button
            loading={loadingCondition}
            type="primary"
            className="cancel-button"
            icon={<RollbackOutlined />}
            onClick={() =>
              !!history.goBack && typeof history.goBack === 'function'
                ? history.goBack()
                : history.push('/sale-orders')
            }
          >
            Trở lại
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
            loading={loadingCondition}
            onClick={() => {
              setShouldScroll(true)
              setFieldValue('status', 'drafting')
            }}
          >
            {path === 'new' ? 'Lưu đơn hàng' : 'Cập nhật'}
          </Button>
        </div>
        {path === 'edit' ? (
          <Button
            type="primary"
            htmlType="submit"
            className="produce-button"
            icon={<ToolOutlined />}
            loading={loadingCondition}
            onClick={() => {
              setShouldScroll(true)
              setFieldValue('status', 'pending_pi')
            }}
          >
            Cập nhật và tạo PI
          </Button>
        ) : null}
      </div>
    </Form>
  )
})

// shouldRefresh({ key: 'pricing', status: true })
