import React, { useState, useEffect } from 'react'
import { ErrorMessage, Field } from 'formik'
import { Input, InputNumber, Typography } from 'antd'
import { useStoreState } from 'easy-peasy'

import { CustomError } from './CustomComponent'
import { formatInputNumber } from 'Utils'

const { Title } = Typography

export default React.memo((props) => {
  const { loadingCondition, values, setFieldValue, path } = props

  const exchangeRate = useStoreState((state) => state.auth.usd_to_vnd_rate)
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)

  useEffect(() => {
    let didCancel = false
    let firstPrice = values.first_price || 0
    let productCost = values.production_cost || 0

    const handleSecondPrice = () => {
      if (typeof firstPrice !== 'number' || typeof productCost !== 'number') {
        return
      } else if (firstPrice === 0 && productCost === 0) {
        return
      } else {
        const sum = Number(firstPrice) + Number(productCost)
        const formatted = Math.floor(sum)
        setFieldValue('second_price', formatted)
      }
    }

    if (!didCancel && !first) {
      handleSecondPrice()
    }

    return () => {
      didCancel = true
      setFirst(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.first_price, values.production_cost])

  useEffect(() => {
    let didCancel = false
    let secondPrice = values.second_price || 0
    let managamentCost = values.managament_cost || 0

    const handleFOBPrice = () => {
      if (typeof secondPrice !== 'number' || typeof managamentCost !== 'number') {
        return
      } else if (secondPrice === 0 && managamentCost === 0) {
        return
      } else {
        if (path === 'edit') return

        const sum = (Number(secondPrice) * (1 + Number(managamentCost) / 100)) / exchangeRate
        const fobPrice = Number(sum.toFixed(2))
        setFieldValue('fob_price', fobPrice)
      }
    }

    if (!didCancel && !second) {
      handleFOBPrice()
    }

    return () => {
      didCancel = true
      setSecond(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.second_price, values.managament_cost])

  const handlePriceChange = (fieldName, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value && value !== 0) {
      setFieldValue(fieldName, undefined)
    } else {
      const roundDown = Math.floor(value).toFixed(2)
      setFieldValue(fieldName, Number(roundDown))
    }
  }
  const handleProductCostchange = (fieldName, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value && value !== 0) {
      setFieldValue(fieldName, undefined)
    } else {
      const roundDown = value.toFixed(2)
      setFieldValue(fieldName, Number(roundDown))
    }
  }
  const handleFOBChange = (fieldName, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value && value !== 0) {
      setFieldValue(fieldName, undefined)
    } else {
      const roundDown = value.toFixed(2)
      setFieldValue(fieldName, Number(roundDown))
    }
  }

  return (
    <div className="product-expenses">
      <Title className="part-title">4. Giá thành sản phẩm</Title>
      <div className="part-field-wrapper">
        {values.product_type === 'semifinish' && (
          <>
            <div className="form-item">
              <Title className="field-title">
                Giá mua sản phẩm chưa thành phẩm (thùng)
                <span className="compulsory-field-symbol">*</span>
              </Title>
              <Input.Group compact>
                <Field
                  as={InputNumber}
                  name="first_price"
                  // disabled={true}
                  value={values.first_price}
                  onChange={(value) => handlePriceChange('first_price', 1000000000000, value)}
                  style={{ width: '78%' }}
                  formatter={(value) => formatInputNumber(value)}
                  placeholder="Nhập giá mua sản phẩm chưa thành phẩm"
                  min={0}
                  max={1000000000000}
                />
                <Input
                  className="unit-disable-field"
                  disabled={true}
                  defaultValue="VND"
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </Input.Group>

              <ErrorMessage component={(props) => <CustomError {...props} />} name="first_price" />
            </div>
            <div className="form-item">
              <Title className="field-title">
                Chi phí sản xuất (thùng)<span className="compulsory-field-symbol">*</span>
              </Title>

              <Input.Group compact>
                <Field
                  as={InputNumber}
                  name="production_cost"
                  disabled={loadingCondition}
                  value={values.production_cost}
                  onChange={(value) => handlePriceChange('production_cost', 1000000000000, value)}
                  style={{ width: '78%' }}
                  formatter={(value) => formatInputNumber(value)}
                  placeholder="Nhập chi phí sản xuất"
                  min={0}
                  max={1000000000000}
                />
                <Input className="unit-disable-field" disabled={true} defaultValue="VND" />
              </Input.Group>

              <ErrorMessage
                component={(props) => <CustomError {...props} />}
                name="production_cost"
              />
            </div>
          </>
        )}

        <div className="form-item">
          <Title className="field-title">
            Giá mua thành phẩm (thùng)<span className="compulsory-field-symbol">*</span>
          </Title>

          <Input.Group compact>
            <Field
              as={InputNumber}
              name="second_price"
              disabled={loadingCondition}
              value={values.second_price}
              onChange={(value) => handlePriceChange('second_price', 1000000000000, value)}
              style={{ width: '78%' }}
              formatter={(value) => formatInputNumber(value)}
              placeholder="Nhập giá mua thành phẩm"
              min={0}
              max={1000000000000}
              defaultValue={0}
            />
            <Input className="unit-disable-field" disabled={true} defaultValue="VND" />
          </Input.Group>

          <ErrorMessage component={(props) => <CustomError {...props} />} name="second_price" />
        </div>

        {isSuperAdmin ? (
          <>
            <div className="form-item">
              <Title className="field-title">Chi phí quản lý</Title>

              <Input.Group compact>
                <Field
                  as={InputNumber}
                  name="managament_cost"
                  disabled={loadingCondition}
                  value={values.managament_cost}
                  onChange={(value) => handleProductCostchange('managament_cost', 10000, value)}
                  style={{ width: '78%' }}
                  formatter={(value) => formatInputNumber(value)}
                  placeholder="Nhập chi phí quản lý"
                  min={0}
                  max={10000}
                />
                <Input className="unit-disable-field" disabled={true} defaultValue="%" />
              </Input.Group>

              <ErrorMessage
                component={(props) => <CustomError {...props} />}
                name="managament_cost"
              />
            </div>

            <div className="form-item">
              <Title className="field-title">Giá FOB (thùng)</Title>

              <Input.Group compact>
                <Field
                  as={InputNumber}
                  name="fob_price"
                  disabled={loadingCondition}
                  value={values.fob_price}
                  onChange={(value) => handleFOBChange('fob_price', 100000000000, value)}
                  style={{ width: '78%' }}
                  formatter={(value) => formatInputNumber(value)}
                  placeholder="Nhập giá FOB"
                  min={0}
                  max={100000000000}
                />
                <Input className="unit-disable-field" disabled={true} defaultValue="USD" />
              </Input.Group>

              <ErrorMessage component={(props) => <CustomError {...props} />} name="fob_price" />
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
})
