import React from 'react'
// import { Typography } from 'antd'

import SettingForm from '../Form'

// const { Title } = Typography

export default (props) => {
  return (
    <div id="edit_setting_page">
      {/* <Title className="page-title">Cập nhật thiết lập</Title> */}
      <SettingForm />
    </div>
  )
}
