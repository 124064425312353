import React, { useState } from 'react'
import { Formik } from 'formik'
import { Typography, message } from 'antd'

import './index.scss'

import axios from 'Utils/axiosExpand'
import { settingsAccountSchema } from 'Validation'
import SettingAccountView from './SettingAccount.view'

const { Title } = Typography

export default React.memo(() => {
  const initialValues = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  }

  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values, actions) => {
    setServerError(null)
    setSubmitting(true)

    const params = {
      ...values,
    }
    const url = '/users/change-password/'

    try {
      await axios.post(url, params)
      setSubmitting(false)
      actions.resetForm(initialValues)
      message.success('Đổi mật khẩu mới thành công!')
    } catch (error) {
      setServerError(error.response.data.errors.message)
      setSubmitting(false)
    }

    // history.push('/')
  }

  return (
    <div className="settings-account-page">
      <Title className="page-title">Thiết lập tài khoản</Title>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={settingsAccountSchema}
        children={(props) => (
          <SettingAccountView {...props} serverError={serverError} isSubmitting={isSubmitting} />
        )}
      />
    </div>
  )
})
