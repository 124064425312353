import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import View from './View'
import New from './New'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['OM', 'PP', 'DI']

const ListMaterial = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)
const ViewMaterial = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)
const NewMaterial = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)
const EditMaterial = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export { NewMaterial, ViewMaterial, EditMaterial, ListMaterial }
