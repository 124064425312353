import isNumber from 'lodash/isNumber'

export const formatInputNumber = (value) => {
  // return `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
  const number = `${value}`.replace(
    /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d*)\d*$/g,
    function (m, s1, s2) {
      return s2 || s1 + ','
    }
  )
  const e = number === 'undefined' ? 0 : number
  return e
}

export const roundUpDecimal = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const formatToNumber = (number) => {
  const parseToNumber = Number(number)
  if (isNaN(parseToNumber)) return 0

  return parseToNumber
}

const numbWithTwoDecimal = (value) => {
  const parsedNumber = formatToNumber(value)
  if (!parsedNumber || !isNumber(parsedNumber)) return '0.00'

  return formatInputNumber(parsedNumber.toFixed(2))
}

export default numbWithTwoDecimal
