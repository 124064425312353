import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import FormView from './Form.view'
import { manageUsersSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  name: '',
  email: '',
  is_super_admin: false,
  roles: [],
}

export default React.memo((props) => {
  const { path, editId } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    if (path === 'new') {
      createUser(values)
    } else if (path === 'edit') {
      updateUser(values)
    }
  }

  const createUser = async (params) => {
    const url = '/users/'
    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/accounts/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updateUser = async (params) => {
    const url = `/users/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setSubmitting(false)
      history.push(`/accounts/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="user-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={manageUsersSchema}
      />
    </div>
  )
})
