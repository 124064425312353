import React, { useState, useEffect, memo } from 'react'
import { Layout, Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import axiosExpand from 'Utils/axiosExpand'

import smallLogo from 'Assets/images/logo.svg'
import bigLogo from 'Assets/images/logo2.png'

import { generateMenu } from './buildLeftMenu'

const { Sider } = Layout
const { Item, SubMenu } = Menu

const validRolesCheck = (roles, rolesPermission) => {
  let isHasPermission = false

  roles.forEach((role) => {
    if (rolesPermission.includes(role)) {
      isHasPermission = true
    }
  })
  return isHasPermission
}

const LeftSideMenu = (props) => {
  const { bpCollapsed, setBreakpointCollapsed } = props

  const location = useLocation()

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)

  const [selected, setSelected] = useState([])
  const [count, setCount] = useState(0)

  useEffect(() => {
    let didCancel = false

    const key = location.pathname.split('/')[1]
    setSelected([key])

    const fetchData = async () => {
      const url = `/notifications/pending-notifications-count`
      try {
        const resp = await axiosExpand.get(url)
        if (!didCancel) setCount(resp.data.pending_notifications_count)
      } catch (error) {
        if (!didCancel) setCount(0)
      }
    }
    fetchData()

    return () => (didCancel = true)
  }, [location.pathname])

  const handleClick = (event) => {
    if (event.key === location.pathname.split('/')[1])
      shouldRefresh({ key: event.key, status: true })
    if (bpCollapsed.isTrigger) {
      setBreakpointCollapsed((prev) => ({ ...prev, isCollapsed: true }))
    }
  }

  const menuItems = generateMenu(count)

  return (
    <Sider
      className="left-side-menu"
      zeroWidthTriggerStyle={{ top: 10 }}
      width={280}
      trigger={bpCollapsed.isTrigger}
      collapsible
      collapsed={bpCollapsed.isCollapsed}
      onBreakpoint={(broken) => {
        if (broken) {
          setBreakpointCollapsed((prev) => ({
            ...prev,
            collapsedButton: 0,
            isCollapsed: broken,
            leftPst: 0,
            collapsedWidth: 0,
            isTrigger: false,
          }))
        } else {
          setBreakpointCollapsed((prev) => ({
            ...prev,
            collapsedButton: true,
            isCollapsed: false,
            leftPst: 280,
            collapsedWidth: 80,
            isTrigger: null,
          }))
        }
      }}
      onCollapse={(collapsed, type) => {
        if (type === 'clickTrigger') {
          setBreakpointCollapsed((prev) => ({
            ...prev,
            isCollapsed: collapsed,
            // leftPst: 280,
            // collapsedWidth: 80,
          }))
        }
      }}
      breakpoint="lg"
      collapsedWidth={bpCollapsed.collapsedWidth}
    >
      <Link className="logo" to="/">
        {bpCollapsed.isCollapsed ? (
          <img src={smallLogo} alt="datafood logo" />
        ) : (
          <img src={bigLogo} alt="datafood logo" />
        )}
      </Link>
      <div className="menu-container">
        <Menu
          mode="inline"
          className="menu-wrapper"
          onClick={handleClick}
          selectedKeys={selected}
          theme="dark"
          defaultOpenKeys={['manage-orders', 'admin']}
        >
          {menuItems.map((item) => (
            <MenuMethodRender key={item.id} item={item} />
          ))}
        </Menu>
      </div>
    </Sider>
  )
}

const MenuMethodRender = memo((props) => {
  const { item } = props

  const roles = useStoreState((state) => state.auth.roles)
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  if (item.onlySuperadmin && isSuperAdmin) {
    return item.isHasSubMenu ? (
      <HasSubMenu {...props} key={item.id} item={item} />
    ) : (
      <NotHasSubMenu {...props} key={item.id} item={item} />
    )
  }

  if (validRolesCheck(roles, item.allowRoles)) {
    return item.isHasSubMenu ? (
      <HasSubMenu {...props} key={item.id} item={item} />
    ) : (
      <NotHasSubMenu {...props} key={item.id} item={item} />
    )
  }

  return null
})

const HasSubMenu = memo((props) => {
  const { item, ...other } = props
  return (
    <SubMenu {...other} key={item.id} icon={<item.icon />} title={item.name} className="sub-menu">
      {item.subMenus.map((submenu) => (
        <SubMenuMethodRender key={submenu.id} submenu={submenu} />
      ))}
    </SubMenu>
  )
})

const NotHasSubMenu = memo((props) => {
  const { item, ...other } = props
  return (
    <Item {...other} icon={<item.icon />} key={item.id}>
      <Link to={item.route}>{item.name}</Link>
    </Item>
  )
})

const SubMenuItem = memo((props) => {
  const { item } = props
  return (
    <Item {...props} key={item.id}>
      <Link to={item.route}>{item.name}</Link>
    </Item>
  )
})

const SubMenuMethodRender = memo((props) => {
  const roles = useStoreState((state) => state.auth.roles)
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const { submenu } = props
  if (submenu.onlySuperadmin && isSuperAdmin) {
    return <SubMenuItem {...props} key={submenu.id} item={submenu} />
  } else if (validRolesCheck(roles, submenu.allowRoles)) {
    return <SubMenuItem {...props} key={submenu.id} item={submenu} />
  } else return null
})

export default LeftSideMenu
