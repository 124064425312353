import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import FormView from './Form.view'
import { manageStocksSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  transaction_number: '',
  transaction_type: 'in',
  item_type: 'packing',
  sale_order_id: null,
  note: '',
  transaction_items: [
    {
      id: undefined,
      code: '',
      name: '',
      specification: '',
      unit: '',
      note: '',
      readable_unit: undefined,
      quantity: undefined,
      quantity_before: undefined,
      max_quantity: undefined,
      quantity_after: undefined,
    },
  ],
}

export default React.memo((props) => {
  const { path, editId, dataFetch, isFetching, purchaseOrderId } = props
  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    if (path === 'new') {
      createStockTransaction(values)
    }
  }

  const createStockTransaction = async (params) => {
    const url = '/transactions/'
    try {
      const resp = await axios.post(url, params)
      setSubmitting(false)
      history.push(`/manage-stocks/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="export-import-stocks-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            dataFetch={dataFetch}
            isFetching={isFetching}
            purchaseOrderId={purchaseOrderId}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={manageStocksSchema}
      />
    </div>
  )
})
