import React from 'react'
import { Typography } from 'antd'
import PaymentTransactionForm from '../Form'
// import { useStoreState } from 'easy-peasy'
// import { Redirect } from 'react-router-dom'
const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  return (
    <div id="edit_payment_transaction_page">
      <Title className="page-title">Cập nhật thanh toán order</Title>
      <PaymentTransactionForm path={'edit'} editId={id} />
    </div>
  )
})
