import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageSaleOrderSchema = yup.object().shape({
  order_number: yup.string().required(requiredField).max(255, maxLength),
  date: yup.date().required(requiredField),
  produce_date: yup.date().required(requiredField),
  complete_date: yup.date().required(requiredField),
  export_date: yup.date().required(requiredField),
  customer_id: yup.number().required(requiredField),
  production_requirement: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
  sale_order_items: yup
    .array()
    .of(
      yup.object().shape({
        product_id: yup.number().required(requiredField).typeError(typeError),
        box_quantity: yup.number().required(requiredField).typeError(typeError), // these constraints take precedence
        note: yup
          .string()
          .trim('Vui lòng không nhập khoảng trắng')
          .strict()
          .nullable(true)
          .max(255, maxLength),
      })
    )
    .required(requiredField) // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Cần tối thiểu 1 sản phẩm'),

  container_type_1_amount: yup
    .number()
    .test('oneOfRequired', 'Cần chọn ít nhất 1 container', function (item) {
      return (
        this.parent.container_type_1_amount ||
        this.parent.container_type_2_amount ||
        this.parent.container_type_3_amount
      )
    }),
  container_type_2_amount: yup
    .number()
    .test('oneOfRequired', 'Cần chọn ít nhất 1 container', function (item) {
      return (
        this.parent.container_type_1_amount ||
        this.parent.container_type_2_amount ||
        this.parent.container_type_3_amount
      )
    }),
  container_type_3_amount: yup
    .number()
    .test('oneOfRequired', 'Cần chọn ít nhất 1 container', function (item) {
      return (
        this.parent.container_type_1_amount ||
        this.parent.container_type_2_amount ||
        this.parent.container_type_3_amount
      )
    }),
})

const manageProformalInvoiceSchema = yup.object().shape({
  sticker_fees: yup.number().required(requiredField).typeError(typeError),
  sale_order_items: yup
    .array()
    .of(
      yup.object().shape({
        new_fob: yup.number().required(requiredField).typeError(typeError), // these constraints take precedence
      })
    )
    .required(requiredField) // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Cần tối thiểu 1 sản phẩm'),
})

export { manageSaleOrderSchema, manageProformalInvoiceSchema }
