import React from 'react'
import { Upload, message, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import _ from 'lodash'
import originalAxios from 'axios'
import axiosExpand from 'Utils/axiosExpand'

export default React.memo((props) => {
  const { documents, setDocuments, setSubmitting } = props

  const beforeUpload = (file) => {
    const validSize = file.size / 1024 / 1024 < 8
    if (!validSize) message.error('Kích thước file không thể lớn hơn 8mb')
    return validSize
  }

  const sendFileToAws = async (file, aws_signedRequestURL, onSuccess) => {
    const url = aws_signedRequestURL
    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Encoding': 'base64',
      },
    }

    try {
      await originalAxios.put(url, file, options)
      message.success(`${file.name} file uploaded successfully`)
      onSuccess('ok')
    } catch (error) {
      message.error(`${file.name} file upload failed.`)
    }
  }

  const handleOnChange = ({ fileList }) => {
    const formattedFileList = _.reject(fileList, (file) => {
      const fileTooLarge = file.size / 1024 / 1024 >= 8
      // aws just not receive svg lol
      const inValidFileType = file.type === 'image/svg+xml'

      return fileTooLarge || inValidFileType
    })
    setDocuments(formattedFileList)
  }

  const customUpload = async ({ onError, onSuccess, file }) => {
    setSubmitting(true)
    try {
      let fileType = file.type
      const resp = await axiosExpand.post(`/sale_orders/signed-url/?type=${fileType}`)
      file.aws_signedRequestURL = resp.data.aws_signedRequestURL
      file.object_key = resp.data.object_key
      await sendFileToAws(file, resp.data.aws_signedRequestURL, onSuccess)
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      onError(error)
    }
  }

  return (
    <Upload
      beforeUpload={beforeUpload}
      customRequest={customUpload}
      fileList={documents}
      onChange={handleOnChange}
      showUploadList={false}
      multiple
    >
      <Button
        icon={<PlusOutlined />}
        type="primary"
        style={styles.addMoreButton}
        // disabled={loadingCondition}
      />
    </Upload>
  )
})

const styles = {
  addMoreButton: {
    border: 'none',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: 'none',
    backgroundColor: '#0097c4',
  },
}
