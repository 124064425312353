import React, { useState, useEffect } from 'react'
import { Table, Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { useDataApi } from 'Hooks'
import { formatNumber, pageSizeConfig } from 'Utils'
import DeleteModal from './DeleteModal'

export default () => {
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    name: '',
    visible: false,
  })

  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/materials?material_type=packing',
    key: 'records',
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const packagesState = useStoreState((state) => state.menuTabs['packages'])

  useEffect(() => {
    let didCancel
    if (packagesState && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'packages', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagesState])

  const columns = [
    {
      title: 'Mã bao bì',
      dataIndex: 'code',
      sortOrder: pagination.order_by === 'code' ? `${pagination.order}` : null,
      width: 140,
      sorter: true,
    },
    {
      title: 'Tên bao bì',
      dataIndex: 'name',
      sortOrder: pagination.order_by === 'name' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'supplie-list',
      // sortOrder: pagination.order_by === 'supplier.code' ? `${pagination.order}` : null,
      width: 220,
      sorter: true,
    },
    {
      title: 'Quy cách',
      dataIndex: 'specification',
      sortOrder: pagination.order_by === 'specification' ? `${pagination.order}` : null,
      width: 210,
      sorter: true,
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      sortOrder: pagination.order_by === 'quantity' ? `${pagination.order}` : null,
      width: 140,
      sorter: true,
      render: (record) => formatNumber(record),
    },
    {
      title: 'Số lượng khả dụng',
      dataIndex: 'temporary_quantity',
      width: 160,
      sortOrder: pagination.order_by === 'temporary_quantity' ? `${pagination.order}` : null,
      sorter: true,
      render: (record) => formatNumber(record),
    },
    {
      title: 'Đơn giá (VND)',
      dataIndex: 'price',
      width: 140,
      render: (record) => formatNumber(record),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: 200,
    },
    {
      key: 'x',
      width: 280,
      // fixed: 'right',
      render: (record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}

        <Button className="action-item update" type="link" onClick={(e) => handleEdit(record, e)}>
          Sửa
        </Button>

        <Button
          className="action-item create-duplicate"
          type="link"
          onClick={(e) => handleCreateDuplicate(record, e)}
        >
          Tạo bản sao
        </Button>

        <Button
          className="action-item delete"
          type="link"
          danger
          onClick={(e) => handleDelete(record, e)}
        >
          Xoá
        </Button>
      </div>
    )
  }

  const handCreate = () => {
    history.push('/packages/new')
  }

  const handleCreateDuplicate = (record, e) => {
    e.stopPropagation()
    history.push(`/packages/new/${record.id}`)
  }

  const handleView = (record) => {
    history.push(`/packages/${record.id}/view`)
  }

  const handleEdit = (record, e) => {
    e.stopPropagation()
    history.push(`/packages/${record.id}/edit`)
  }

  const handleDelete = (record, e) => {
    e.stopPropagation()
    setDeleteModal({ id: record.id, name: record.name, visible: true })
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Thêm bao bì
        </Button>
      </div>
    )
  }

  // const dataSource = data.map((material) => ({
  //   ...material,
  //   // 'supplier.code': `${material.supplier.code} : ${material.supplier.name}`,
  // }))

  const suppliersListRender = (supplierList) => {
    return (
      <ul>
        {supplierList.map((item) => (
          <li key={item.id}>
            {item.code}: {item.name}
          </li>
        ))}
      </ul>
    )
  }
  const dataSource = data.map((material) => ({
    ...material,
    'supplie-list': suppliersListRender(material.suppliers),
  }))

  return (
    <div id="manage_packages_table">
      <Table
        title={renderTitle}
        className="materials-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1800, y: 420 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
}
