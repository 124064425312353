import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageStandingOrderSchema = yup.object().shape({
  supplier_id: yup.number().required(requiredField),
  purchase_order_id: yup.number().required(requiredField),
  invoice_number: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  date: yup.date().required(requiredField),
  total: yup.number().required(requiredField).typeError(typeError),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .required(requiredField)
    .max(1000, maxLength1000),
})


export default manageStandingOrderSchema
