import React, { useEffect } from 'react'
import { ErrorMessage, Field } from 'formik'
import { Typography, Select, Spin, message } from 'antd'
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import { useStoreActions } from 'easy-peasy'

import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'
import { noData } from 'Components'
import { useDebounce } from 'Hooks'
import { CustomError } from './CustomComponent'
import { removeVietnameseTones } from 'Utils'

const { Title } = Typography
const { Option } = Select

export default React.memo((props) => {
  const {
    loadingCondition,
    values,
    setFieldValue,
    dataFetch,
    editId,
    setItemOptions,
    onSeaching,
    setOnSearching,
    setOrderSearchData,
    searchTerm,
    setSearchTerm,
  } = props

  const toggleMaterialStatus = useStoreActions(
    (actions) => actions.purchaseOrder.toggleMaterialStatus
  )

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)

  useEffect(() => {
    let didCancel = false

    const fetchSuppilers = async () => {
      const url = '/suppliers/form/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
            order_type: values.order_type,
          },
        })

        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (debouncedSearchTerm.length && searchTerm.keyword === debouncedSearchTerm) fetchSuppilers()
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, searchTerm.keyword])

  useEffect(() => {
    let didCancel = false

    const fetchOrderList = async (supplierId, supplierCode, saleOrderId) => {
      setSearchTerm((prev) => ({ ...prev, searching: true }))
      setOnSearching((prev) => ({ ...prev, orderOfSupplier: true }))
      try {
        const url = '/suppliers/form/'
        // const url2 = '/sale_orders/form/'

        const supplierResult = await axios.get(url, {
          params: {
            keyword: supplierCode,
            per_page: 20,
            order_type: dataFetch.order_type,
          },
        })

        // const orderResult = await axiosExpand.get(url2, {
        //   params: {
        //     supplier_id: supplierId,
        //     order_type: values.order_type,
        //     sale_order_id: saleOrderId,
        //   },
        // })
        if (!didCancel) {
          setOrderSearchData([
            { id: dataFetch.sale_order_id, order_number: dataFetch.sale_order_order_number },
          ])
          const filteredSupplier = supplierResult.data.records.filter(
            (item) => item.id === supplierId
          )

          setSearchTerm((prev) => ({
            ...prev,
            result: filteredSupplier,
            searching: false,
          }))
          if (dataFetch.order_type === 'material' || dataFetch.order_type === 'packing') {
            setItemOptions(filteredSupplier[0].materials)
          }
          if (dataFetch.order_type === 'label') {
            setItemOptions(filteredSupplier[0].labels)
          }
          if (dataFetch.order_type === 'finish_product') {
            setItemOptions(filteredSupplier[0].products)
          }
        }
        setOnSearching((prev) => ({ ...prev, orderOfSupplier: false }))
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setOnSearching((prev) => ({ ...prev, orderOfSupplier: false }))
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (editId && Object.keys(dataFetch).length)
      fetchOrderList(dataFetch.supplier_id, dataFetch.supplier.code, dataFetch.sale_order_id)

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, dataFetch])

  const onSearch = (val) => {
    if (!val) return setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onResultSelect = async (val, option) => {
    setOnSearching((prev) => ({ ...prev, orderOfSupplier: true }))
    setFieldValue('sale_order_id', undefined)
    setFieldValue('items_in_stock', [])
    toggleMaterialStatus({ id: undefined, quantity_in_stock: undefined, action: 'use' })
    try {
      const url = '/sale_orders/form/'
      const orderResult = await axiosExpand.get(url, {
        params: { supplier_id: val, order_type: values.order_type },
      })
      setOrderSearchData(orderResult.data.records)
      setFieldValue('supplier_id', val)
      setFieldValue(`purchase_order_items`, [
        {
          label_id: 0,
          product_id: 0,
          material_id: 0,
          product: {},
          price: 0,
          unit: '',
          code: '',
          name: '',
          readable_unit: '',
          specification: '',
          quantity: 1,
          initQuantity: 0,
          total: 0,
          note: '',
          info: '',
          label_type: '',
        },
      ])
      if (values.order_type === 'material' || values.order_type === 'packing') {
        setItemOptions(option.attr.materials)
      } else if (values.order_type === 'label') {
        setItemOptions(option.attr.labels)
      } else if (values.order_type === 'finish_product') {
        setItemOptions(option.attr.products)
      }

      setOnSearching((prev) => ({ ...prev, orderOfSupplier: false }))
    } catch (error) {
      message.error(error.response?.data.errors?.message)
      setOnSearching((prev) => ({ ...prev, orderOfSupplier: false }))
    }
  }

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  return (
    <div className="form-item" style={{ maxWidth: 400 }}>
      <Title className="field-title">
        Nhà cung cấp <span className="compulsory-field-symbol">*</span>
      </Title>
      <Field name="supplier_id" disabled={loadingCondition}>
        {() => {
          return (
            <Select
              name="supplier_id"
              style={{ width: '100%' }}
              showSearch
              suffixIcon={onSeaching.orderOfSupplier ? <LoadingOutlined /> : <SearchOutlined />}
              notFoundContent={searchDropDownCondition}
              placeholder="Nhập mã hoặc tên nhà cung cấp để tìm kiếm"
              onSearch={onSearch}
              value={values.supplier_id}
              onChange={(val, option) => onResultSelect(val, option)}
              optionFilterProp="children"
              loading={
                searchTerm.searching || onSeaching.orderOfSupplier || loadingCondition || true
              }
              disabled={loadingCondition || onSeaching.orderOfSupplier || editId}
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(input, option) => {
                const dataInput = removeVietnameseTones(input)
                const dataOption = removeVietnameseTones(option.children)
                return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
              }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
            >
              {searchTerm.result.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  attr={item}
                >{`${item.code}: ${item.name}`}</Option>
              ))}
            </Select>
          )
        }}
      </Field>
      <ErrorMessage component={(props) => <CustomError {...props} />} name="supplier_id" />
    </div>
  )
})
