import './index.scss'
// import { useStoreState } from 'easy-peasy'

// import { CheckPermission } from 'Components'
import React from 'react'
// import Chart from './Chart'
import View from './View'

const Home = (props) => {
  // const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)
  // if (isSuperAdmin) {
  // return <CheckPermission component={Chart} {...props} />
  // }
  return <View />
}
export { Home }
