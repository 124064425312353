import React from 'react'
import axiosExpand from 'Utils/axiosExpand'
import { message, Typography, Col } from 'antd'
import { useStoreState } from 'easy-peasy'

const { Title } = Typography

const DownloadableDocument = ({ name, onClick }) => {
  return (
    <div className="row-wrapper">
      <div className="row-item">{name}</div>
      <div className="row-item">
        <span className="downloadable-button" onClick={onClick}>
          Tải về
        </span>
      </div>
    </div>
  )
}

const NotDownloadableDocument = (props) => {
  return (
    <div className="row-wrapper">
      <div className="row-item">{props.name}</div>
      <div className="row-item">
        <span className="undownloadable-button">Chưa xuất hàng</span>
      </div>
    </div>
  )
}

const Document = (props) => {
  if (props.downloadable) return <DownloadableDocument {...props} />

  return <NotDownloadableDocument {...props} />
}

const DocumentTitle = React.memo((props) => {
  const {
    documentsFor,
    commodityExportId,
    saleOrderId,
    orderNumber,
    exportNumber,
    customerCountry,
  } = props

  const roles = useStoreState((state) => state.auth.roles)
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  // const { data, viewId } = props

  const country = customerCountry ? customerCountry : ''
  const readableCountry = (() => {
    if (customerCountry === 'kr') return 'Korea'
    if (customerCountry === 'us') return 'Us'
    if (customerCountry === 'ca') return 'Canada'
    if (customerCountry === 'se') return 'Sweden'
    if (customerCountry === 'cy') return 'Cyprus'
    if (customerCountry === 'bg') return 'England'
    if (customerCountry === 'cz') return 'Czech'
    if (customerCountry === 'cn') return 'China'
    return ''
  })()

  const proformaInvoiceCountry = ['us', 'se', 'ca', 'cn'].includes(country) ? 'us' : 'kr'
  const commercialInvoiceCountry = ['us', 'ca', 'cn'].includes(country) ? 'us' : 'kr'
  const packingListInvoiceCountry = ['us', 'ca', 'cn'].includes(country) ? 'us' : 'kr'
  // const CdInvoiceCountry = ['us'].includes(country) ? 'us' : 'kr'

  const FileDownload = require('js-file-download')

  const download = async ({ url, fileName }) => {
    try {
      const result = await axiosExpand.get(url, { responseType: 'arraybuffer' })
      FileDownload(result.data, fileName)
    } catch (error) {
      message.error('Đã có lỗi xảy ra, xin vui lòng tải lại trang và thử lại.')
    }
  }

  const handleDownloadCdCommercialInvoice = () => {
    download({
      url: `/sale_orders/${commodityExportId}/export/cd/commercial-invoice/`,
      fileName: `${exportNumber}-commercial-invoice-hai-quan-${country}.xlsx`,
    })
  }

  const handleDownloadCdPackingList = () => {
    download({
      url: `/sale_orders/${commodityExportId}/export/cd/packing-list/`,
      fileName: `${exportNumber}-packing-list-hai-quan-${country}.xlsx`,
    })
  }

  const handleDownloadCdContract = () => {
    download({
      url: `/sale_orders/${commodityExportId}/export/cd/contract/`,
      fileName: `${exportNumber}-contract-hai-quan-${country}.xlsx`,
    })
  }

  const handleDownloadCommercialInvoice = () => {
    download({
      url: `/sale_orders/${commodityExportId}/export/${commercialInvoiceCountry}/commercial-invoice/`,
      fileName: `${exportNumber}-commercial-invoice-${country}.xlsx`,
    })
  }

  const handleDownloadPackingList = () => {
    download({
      url: `/sale_orders/${commodityExportId}/export/${packingListInvoiceCountry}/packing-list/`,
      fileName: `${exportNumber}-packing-list-${country}.xlsx`,
    })
  }

  const handleDownloadProformaInvoice = () => {
    download({
      url: `/sale_orders/${saleOrderId}/export/${proformaInvoiceCountry}/proforma-invoice/`,
      fileName: `${orderNumber}-proforma-invoice-${country}.xlsx`,
    })
  }

  const handleDownloadProductPrices = () => {
    download({
      url: `/sale_orders/${saleOrderId}/export/product-prices/`,
      fileName: `${orderNumber}-bang-gia-thanh.xlsx`,
    })
  }

  const handleDownloadCapacityList = () => {
    download({
      url: `/sale_orders/${commodityExportId}/export/capacity-list/`,
      fileName: `${exportNumber}-bang-tinh-so-khoi.xlsx`,
    })
  }

  const handleDownloadCargoList = () => {
    download({
      url: `/sale_orders/${commodityExportId}/export/cargo-list/`,
      fileName: `${exportNumber}-cargo-list.xlsx`,
    })
  }

  return (
    <div className="form-item" style={{ flexDirection: 'column' }}>
      <div className="container-types">
        <Title className="order-table-title">Documents</Title>
        <Col className="order-table-content">
          <div className="inner-content">
            <div className="scroll-wrap" style={{ minWidth: 'auto' }}>
              <div className="row-wrapper">
                <div className="row-item thread-row">Loại document</div>
                <div className="row-item thread-row">Download</div>
              </div>

              {documentsFor === 'saleOrder' && (isSuperAdmin || roles.includes('EPLD')) && (
                <Document
                  name={'Bảng giá thành'}
                  onClick={handleDownloadProductPrices}
                  downloadable
                />
              )}

              {documentsFor === 'saleOrder' && (
                <Document
                  name={`Proforma Invoice ${readableCountry}`}
                  onClick={handleDownloadProformaInvoice}
                  downloadable
                />
              )}

              <Document
                name={`Commercial Invoice ${readableCountry}`}
                onClick={handleDownloadCommercialInvoice}
                downloadable={!!commodityExportId}
              />
              <Document
                name={`Packing List ${readableCountry}`}
                onClick={handleDownloadPackingList}
                downloadable={!!commodityExportId}
              />
              <Document
                name={`Commercial Invoice Hải Quan ${readableCountry}`}
                onClick={handleDownloadCdCommercialInvoice}
                downloadable={!!commodityExportId}
              />

              <Document
                name={`Packing List Hải Quan ${readableCountry}`}
                onClick={handleDownloadCdPackingList}
                downloadable={!!commodityExportId}
              />
              <Document
                name={`Contract Hải Quan ${readableCountry}`}
                onClick={handleDownloadCdContract}
                downloadable={!!commodityExportId}
              />
              <Document
                name="Bảng tính số khối"
                onClick={handleDownloadCapacityList}
                downloadable={!!commodityExportId}
              />
              <Document
                name="Cargo List"
                onClick={handleDownloadCargoList}
                downloadable={!!commodityExportId}
              />
            </div>
          </div>
        </Col>
      </div>
    </div>
  )
})

export default DocumentTitle
