import React, { useState, useEffect } from 'react'
import { Table, Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import axios from 'Utils/axiosRexyAdmin'
import { useDataApi } from 'Hooks'
import { pageSizeConfig } from 'Utils'
import DeleteModal from './DeleteModal'

export default () => {
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    name: '',
    visible: false,
  })

  const [clickedRow, setClickedRow] = useState({
    id: undefined,
    loading: false,
  })

  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/users/',
    key: 'records',
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const accountsState = useStoreState((state) => state.menuTabs['accounts'])

  useEffect(() => {
    let didCancel
    if (accountsState && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'accounts', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsState])

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      sortOrder: pagination.order_by === 'email' ? `${pagination.order}` : null,
      width: 190,
      sorter: true,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      sortOrder: pagination.order_by === 'name' ? `${pagination.order}` : null,
      width: 190,
      sorter: true,
    },
    {
      title: 'Quản trị viên',
      dataIndex: 'is_super_admin',
      sortOrder: pagination.order_by === 'is_super_admin' ? `${pagination.order}` : null,
      width: 130,
      sorter: true,
      render: (record) => (record ? 'Yes' : 'No'),
    },
    {
      title: 'Kích hoạt tài khoản',
      dataIndex: 'active',
      sortOrder: pagination.order_by === 'active' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      render: (record, row) => activeAccount(record, row),
    },
    {
      key: 'x',
      width: 140,
      render: (record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}

        <Button className="action-item update" type="link" onClick={(e) => handleEdit(record, e)}>
          Sửa
        </Button>
        <Button
          className="action-item delete"
          type="link"
          danger
          onClick={(e) => handleDelete(record, e)}
        >
          Xoá
        </Button>
      </div>
    )
  }

  const activeAccount = (active, row) => {
    const activeLoading = clickedRow.loading && row.id === clickedRow.id

    return (
      <div className="active-button-wrapper">
        {active ? (
          <Button
            className="action-item deactive"
            type="primary"
            loading={activeLoading}
            onClick={(e) => handleActive(row, e)}
          >
            Vô hiệu hoá
          </Button>
        ) : (
          <Button
            className="action-item active"
            type="primary"
            loading={activeLoading}
            onClick={(e) => handleActive(row, e)}
          >
            Khôi phục
          </Button>
        )}
      </div>
    )
  }

  const handCreate = () => {
    history.push('/accounts/new')
  }

  const handleView = (record) => {
    history.push(`/accounts/${record.id}/view`)
  }

  const handleEdit = (record, event) => {
    event.stopPropagation()
    history.push(`/accounts/${record.id}/edit`)
  }

  const handleActive = async (record, event) => {
    event.stopPropagation()
    setClickedRow({ id: record.id, loading: true })

    try {
      await axios.patch(`/users/${record.id}/`)
      doFetch({})
      setClickedRow({ id: undefined, loading: false })
    } catch (error) {
      setClickedRow({ id: undefined, loading: false })
    }
  }

  const handleDelete = (record, event) => {
    event.stopPropagation()
    setDeleteModal({ id: record.id, name: record.name, visible: true })
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Thêm tài khoản
        </Button>
      </div>
    )
  }

  return (
    <div id="manage_users_table">
      {/* <Title className="page-title">Quản lý nhân viên</Title> */}
      <Table
        title={renderTitle}
        className="users-table"
        dataSource={data}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 850, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
}
