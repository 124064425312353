import React, { useState } from 'react'
import { Typography, Modal, Alert } from 'antd'

import axios from 'Utils/axiosRexyAdmin'

const { Text } = Typography

export default ({ id, name, visible, setDeleteModal, doFetch }) => {
  const [state, setState] = useState({ loading: false, errors: null })

  const handleCancel = () => {
    setDeleteModal({ id: undefined, name: '', visible: false })
    setState({ loading: false, error: null })
  }

  const handleOk = async () => {
    setState({ loading: true, errors: null })
    const url = '/suppliers'
    try {
      await axios.delete(url, { data: { ids: [id] } })

      setState((prev) => ({ ...prev, loading: false }))
      setDeleteModal({ id: undefined, name: '', visible: false })
      doFetch({})
    } catch (error) {
      setState({ loading: false, errors: error.response?.data.errors.message })
    }
  }

  const renderDeleteButton = () => {
    return <Text className="create-btn-text">Xoá</Text>
  }

  const renderCancelButton = () => {
    return <Text className="cancel-btn-text">Huỷ</Text>
  }

  const renderTitle = () => {
    return <Text className="modal-title">Xoá tài khoản</Text>
  }

  return (
    <Modal
      className="delete-supplier-modal"
      style={{ top: 200 }}
      visible={visible}
      title={renderTitle()}
      okText={renderDeleteButton()}
      okButtonProps={{ loading: state.loading, className: 'delete-button' }}
      cancelText={renderCancelButton()}
      cancelButtonProps={{ loading: state.loading }}
      onOk={handleOk}
      onCancel={state.loading ? null : handleCancel}
    >
      <div id="supplier_delete_modal">
        <Text className="modal-description">
          <strong>Bạn có chắc muốn xoá nhà cung cấp này.</strong>
          <br />
          Lưu ý: Một khi đã xoá sẽ không thể hoàn tác.
        </Text>
        <Text className="supplier-name">{name}</Text>
        {state.errors && (
          <Alert style={{ marginBottom: 5 }} message={state.errors} type="error" showIcon />
        )}
      </div>
    </Modal>
  )
}
