import React, { useState, useEffect } from 'react'
import { Typography, Col, Button } from 'antd'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { formatNumber, roundUpDecimal, formatToNumber } from 'Utils'
import { DocumentsTable } from 'Components'
import { OtherDocumentsTable } from 'Components'

const { Title } = Typography

const format = 'DD-MM-YYYY'

export default React.memo((props) => {
  const { data, loading, viewId, totalCubic, presentCubic, isSuperAdmin } = props
  const history = useHistory()

  const [totalData, setTotalData] = useState({
    total_box_quantity: 0,
    total_quantity: 0,
    total_size: 0,
    total_money: 0,
  })

  useEffect(() => {
    let didCancel = false

    const calTotal = () => {
      let total_box_quantity = 0
      let total_quantity = 0
      let total_size = 0
      let total_money = 0

      data.sale_order_items.forEach((item) => {
        total_box_quantity += formatToNumber(item.box_quantity)
        total_quantity += formatToNumber(item.total_quantity)
        total_size += formatToNumber(item.total_size)
        total_money += formatToNumber(item.new_fob * item.box_quantity)
      })
      setTotalData({
        total_box_quantity,
        total_quantity,
        total_size,
        total_money,
      })
    }

    if (!didCancel) {
      calTotal()
    }

    return () => (didCancel = true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.sale_order_items])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/sale-orders`)
  }

  const handleProduced = () => {
    history.push(`/sale-orders/${viewId}/produced`)
  }

  const generateSuggestion = () => {
    if (!totalCubic || !presentCubic) {
      return ''
    } else if (totalCubic > presentCubic) {
      return (
        <span style={{ color: 'green' }}>
          {`Thiếu ${(totalCubic - presentCubic).toFixed(2)} m3`}
        </span>
      )
    } else if (totalCubic < presentCubic) {
      return (
        <span style={{ color: 'red' }}>{`Thừa ${(presentCubic - totalCubic).toFixed(2)} m3`}</span>
      )
    } else return ''
  }

  const isPendingPIandIsSuperAdmin = data.status === 'pending_pi' && isSuperAdmin

  return (
    <div id="view_sale_order_producing_employee" className="table-container">
      <div className="inner-view">
        <div className="header-view">
          <Title className="page-title">Thông tin đơn hàng</Title>
        </div>

        <div className="view-wrapper">
          <div className="form-item">
            <Title className="field-title">Order No:</Title>
            <div className="content">{data.order_number}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Ngày:</Title>
            <div className="content">{moment(data.date, 'YYYY-MM-DD').format(format)}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Khách hàng:</Title>
            <div className="content">{`${data.customer.code}: ${data.customer.name}`}</div>
          </div>

          <div className="order-table">
            <Title className="order-table-title">Thông tin sản phẩm: </Title>
            <Col className="order-table-content">
              <div className="inner-content">
                <div
                  className="scroll-wrap"
                  style={{ minWidth: isPendingPIandIsSuperAdmin ? 1650 : 1200 }}
                >
                  <div className="row-wrapper">
                    <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                      STT
                    </div>
                    <div className="row-item thread-row">Tên sản phẩm</div>
                    <div style={{ maxWidth: 160 }} className="row-item thread-row">
                      Packing
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 140 }}>
                      Số lượng (thùng)
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 140 }}>
                      Số lượng(đơn vị)
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 150 }}>
                      Cubic (m3)
                    </div>
                    {isPendingPIandIsSuperAdmin ? (
                      <>
                        <div style={{ maxWidth: 120 }} className="row-item thread-row">
                          Giá FOB lô trước (USD)
                        </div>
                        <div style={{ maxWidth: 120 }} className="row-item thread-row">
                          Duyệt giá FOB (USD)
                        </div>
                        <div style={{ maxWidth: 120 }} className="row-item thread-row">
                          Thành tiền (USD)
                        </div>
                      </>
                    ) : null}
                    <div className="last-child thread-row">Ghi chú mặt hàng</div>
                  </div>

                  <div className="scroll-vertical-table">
                    {data.sale_order_items.map((item, index) => {
                      return (
                        <div key={index} className="row-wrapper">
                          <div className="row-item" style={{ maxWidth: 60 }}>
                            {index + 1}
                          </div>
                          <div className="row-item">{`${item.product.code}: ${item.product.name}`}</div>
                          <div style={{ maxWidth: 160 }} className="row-item">
                            {item.specification}
                          </div>
                          <div className="row-item" style={{ maxWidth: 140 }}>
                            {formatNumber(Number(item.box_quantity))}
                          </div>
                          <div className="row-item" style={{ maxWidth: 140 }}>
                            {formatNumber(Number(item.total_quantity))}
                          </div>
                          <div className="row-item" style={{ maxWidth: 150 }}>
                            {formatNumber(Number(item.total_size))}
                          </div>
                          {isPendingPIandIsSuperAdmin ? (
                            <>
                              <div style={{ maxWidth: 120 }} className="row-item">
                                {formatNumber(Number(item.old_fob))}
                              </div>
                              <div style={{ maxWidth: 120 }} className="row-item">
                                {formatNumber(Number(item.new_fob))}
                              </div>
                              <div style={{ maxWidth: 120 }} className="row-item">
                                {formatNumber(Number(item.new_fob))}
                              </div>
                            </>
                          ) : null}
                          <div className="last-child">{item.note}</div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="row-wrapper">
                    <div className="row-item thread-row" style={{ maxWidth: 60 }} />
                    <div className="row-item thread-row" />
                    <div style={{ maxWidth: 160 }} className="row-item thread-row" />
                    <div className="row-item thread-row" style={{ maxWidth: 140 }}>
                      {totalData.total_box_quantity
                        ? formatNumber(totalData.total_box_quantity)
                        : '-'}
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 140 }}>
                      {totalData.total_quantity ? formatNumber(totalData.total_quantity) : '-'}
                    </div>
                    <div className="row-item thread-row" style={{ maxWidth: 150 }}>
                      {totalData.total_size ? formatNumber(totalData.total_size) : '-'}
                    </div>
                    {isPendingPIandIsSuperAdmin ? (
                      <>
                        <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                        <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                        <div style={{ maxWidth: 120 }} className="row-item thread-row">
                          {totalData.total_money ? formatNumber(totalData.total_money) : '-'}
                        </div>
                      </>
                    ) : null}
                    <div className="last-child thread-row">Ghi chú mặt hàng</div>
                  </div>
                </div>
              </div>
            </Col>
          </div>

          <div className="form-item" style={{ flexDirection: 'column' }}>
            <div className="container-types">
              <Title className="order-table-title">Đóng hàng vào container</Title>
              <Col className="order-table-content">
                <div className="inner-content">
                  <div className="scroll-wrap">
                    <div className="row-wrapper">
                      <div className="row-item thread-row">Loại container</div>
                      <div className="row-item thread-row">Cubic (m3)</div>
                      <div className="last-child thread-row">Số lượng</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(1) Container 20'DC`}</div>
                      <div className="row-item">28.50 m3</div>
                      <div className="last-child">{data.container_type_1_amount}</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(2) Container 40'DC`}</div>
                      <div className="row-item">58.50 m3</div>
                      <div className="last-child">{data.container_type_2_amount}</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">{`(3) Container 40'HC`}</div>
                      <div className="row-item">66.50 m3</div>
                      <div className="last-child">{data.container_type_3_amount}</div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>

          <div className="form-item container-information" style={{ marginBottom: 0 }}>
            <div className="item">
              <Title className="field-title">Tổng số khối:</Title>
              <span className="content">{totalCubic ? `${totalCubic} m3` : '- m3'}</span>
            </div>

            <div className="item">
              <Title className="field-title">Hiện tại:</Title>
              <span className="content">
                {presentCubic ? `${roundUpDecimal(presentCubic)} m3` : '- m3'}
              </span>
            </div>

            <div className="item">
              <Title className="field-title">Gợi ý:</Title>
              <span className="content">{generateSuggestion()}</span>
            </div>
          </div>

          <div className="form-item container-information">
            <div className="date-item">
              <Title className="field-title">Ngày dự kiến sản xuất:</Title>
              <div className="content">
                {moment(data.produce_date, 'YYYY-MM-DD').format(format)}
              </div>
            </div>

            <div className="date-item">
              <Title className="field-title">Ngày dự kiến hoàn thành:</Title>
              <div className="content">
                {moment(data.complete_date, 'YYYY-MM-DD').format(format)}
              </div>
            </div>

            <div className="date-item">
              <Title className="field-title">Ngày dự kiến xuất hàng:</Title>
              <div className="content">{moment(data.export_date, 'YYYY-MM-DD').format(format)}</div>
            </div>
          </div>

          <DocumentsTable
            documentsFor="saleOrder"
            commodityExportId={data.commodity_export.id}
            saleOrderId={data.id}
            saleOrderStatus={data.status}
            orderNumber={data.order_number}
            exportNumber={data.commodity_export.export_number}
            customerCountry={data.customer.country}
          />

          <div className="other-documents">
            <OtherDocumentsTable documents={data.documents} action="view" />
          </div>

          <div className="button-container">
            <Button
              icon={<RollbackOutlined />}
              type="primary"
              loading={loading}
              className="cancel-button"
              onClick={handleBack}
            >
              Trở lại
            </Button>

            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={loading}
              className="process-button"
              onClick={handleProduced}
            >
              Sửa thông tin sản xuất
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})
