import axiosExpand from 'Utils/axiosExpand'
import React, { useState, useEffect } from 'react'
import { Typography, Col, Button, Tag, message, Input } from 'antd'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import { formatNumber, numToWord, purchaseOrderStatusColorGenerator } from 'Utils'

const { Title } = Typography
const { TextArea } = Input

const format = 'DD-MM-YYYY'
const INITIAL_PROFILE = {
  order_number: '',
  order_type: 'material',
  date: moment().format('YYYY-MM-DD'),
  receive_date: moment().format('YYYY-MM-DD'),
  supplier_id: '',
  supplier: {
    id: '',
    code: '',
    name: '',
  },
  purchase_order_items: [
    {
      material: {},
      product: {},
      info: '',
      price: '',
      readable_unit: '',
      specification: '',
      quantity: '',
      total: '',
      note: '',
    },
  ],
  sub_total: '',
  vat: '',
  vat_percentage: 0,
  total: '',
  production_requirement: '',
  note: '',
  status: '',
  readable_status: 'Đang chờ xử lý',
}

const ItemInStock = React.memo((props) => {
  const { data } = props
  return (
    <div className="form-item" style={{ flexDirection: 'column', paddingTop: 10 }}>
      <div className="container-types" style={{ marginBottom: 0 }}>
        <Title className="order-table-title">Mặt hàng tồn kho</Title>
        <Col className="order-table-content">
          <div className="inner-content">
            <div className="scroll-wrap">
              <div className="row-wrapper">
                <div className="row-item thread-row" style={{ maxWidth: 150 }}>
                  Mã hàng
                </div>
                <div className="row-item thread-row">Tên hàng</div>
                <div className="row-item thread-row">Số lượng tồn kho sử dụng</div>
                <div className="row-item thread-row">Dùng tồn kho</div>
              </div>

              <div className="scroll-vertical-table">
                {data.items_in_stock?.map((item, index) => {
                  return (
                    <div key={index} className="row-wrapper">
                      <div className="row-item" style={{ maxWidth: 150 }}>
                        {item.code}
                        {item.product?.code}
                      </div>
                      <div className="row-item">
                        {item.name} {item.product?.name}
                      </div>
                      <div className="row-item" style={{ maxWidth: 150 }}>
                        {item.quantity ? item.quantity : '-'}
                      </div>
                      <div className="row-item">
                        {item.action === 'use' ? 'Dùng' : 'Không dùng'}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </Col>
      </div>
    </div>
  )
})

const MaterialType = React.memo((props) => {
  const { data } = props
  return (
    <>
      <Title className="order-table-title">
        {data.order_type === 'material' ? 'Thông tin nguyên vật liệu' : 'Thông tin bao bì:'}
      </Title>
      <Col className="order-table-content">
        <div className="inner-content">
          <div className="scroll-wrap">
            <div className="row-wrapper">
              <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                STT
              </div>
              <div className="row-item thread-row">Tên hàng</div>
              <div className="row-item thread-row">Quy cách</div>
              <div className="row-item thread-row" style={{ maxWidth: 160 }}>
                Đơn vị
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                Đơn giá
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                Số lượng
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                Thành tiền
              </div>
              <div style={{ maxWidth: 180 }} className="last-child thread-row">
                Ghi chú
              </div>
            </div>

            <div className="scroll-vertical-table">
              {data.purchase_order_items.map((item, index) => {
                return (
                  <div key={index} className="row-wrapper">
                    <div className="row-item" style={{ maxWidth: 60 }}>
                      {index + 1}
                    </div>
                    <div className="row-item">{`${item.material.code}: ${item.material.name}`}</div>
                    <div className="row-item">{item.specification}</div>
                    <div className="row-item" style={{ maxWidth: 160 }}>
                      {item.readable_unit}
                    </div>
                    <div className="row-item" style={{ maxWidth: 130 }}>
                      {formatNumber(Number(item.price))}
                    </div>
                    <div className="row-item" style={{ maxWidth: 130 }}>
                      {formatNumber(Number(item.quantity))}
                    </div>
                    <div className="row-item" style={{ maxWidth: 180 }}>
                      {formatNumber(Number(item.total))}
                    </div>
                    <div style={{ maxWidth: 180 }} className="last-child">
                      {item.note}
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="row-wrapper">
              <div className="row-item thread-row" style={{ maxWidth: 60 }} />
              <div className="row-item thread-row" />
              <div className="row-item thread-row" />
              <div className="row-item thread-row" style={{ maxWidth: 160 }} />
              <div className="row-item thread-row" style={{ maxWidth: 130 }} />
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                Tổng cộng
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                {formatNumber(Math.round(data.sub_total))}
              </div>
              <div className="last-child thread-row" />
            </div>

            <div className="row-wrapper">
              <div className="row-item thread-row" style={{ maxWidth: 60 }} />
              <div className="row-item thread-row" />
              <div className="row-item thread-row" />
              <div className="row-item thread-row" style={{ maxWidth: 160 }} />
              <div className="row-item thread-row" style={{ maxWidth: 130 }} />
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                Tỷ lệ VAT
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                {`${formatNumber(data.vat_percentage)}%`}
              </div>
              <div style={{ maxWidth: 180 }} className="last-child thread-row" />
            </div>
            <div className="row-wrapper">
              <div className="row-item thread-row" style={{ maxWidth: 60 }} />
              <div className="row-item thread-row" />
              <div className="row-item thread-row" />
              <div className="row-item thread-row" style={{ maxWidth: 160 }} />
              <div className="row-item thread-row" style={{ maxWidth: 130 }} />
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                VAT
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                {formatNumber(Math.round(data.vat))}
              </div>
              <div style={{ maxWidth: 180 }} className="last-child thread-row" />
            </div>
          </div>
        </div>
      </Col>
    </>
  )
})

const LabelType = React.memo((props) => {
  const { data } = props

  return (
    <>
      <Title className="order-table-title">
        {data.order_type === 'label' ? 'Thông tin tem sản phẩm' : 'Thông tin thành phẩm:'}
      </Title>
      <Col className="order-table-content">
        <div className="inner-content">
          <div className="scroll-wrap label-type-scroll">
            <div className="row-wrapper">
              <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                STT
              </div>
              <div className="row-item thread-row">Tên hàng</div>
              {data.order_type === 'label' ? (
                <div className="row-item thread-row">Nội dung tem</div>
              ) : null}
              <div style={{ maxWidth: 160 }} className="row-item thread-row">
                Quy cách
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                Đơn giá
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                Số lượng
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                Thành tiền
              </div>
              <div style={{ maxWidth: 180 }} className="last-child thread-row">
                Ghi chú
              </div>
            </div>

            <div className="scroll-vertical-table">
              {data.purchase_order_items.map((item, index) => {
                useEffect(() => {
                  const labelDom = document.getElementById(`label_content_${index}`)
                  if (item.info && labelDom) {
                    const inforr = new DOMParser().parseFromString(item.info, 'text/html')
                    labelDom.innerHTML = inforr.body.innerHTML
                  }
                }, [index, item.info])

                return (
                  <div key={index} className="row-wrapper">
                    <div className="row-item" style={{ maxWidth: 60 }}>
                      {index + 1}
                    </div>
                    <div className="row-item">{`${item.product.code}: ${item.product.name}`}</div>
                    {data.order_type === 'label' ? (
                      <div className="row-item">
                        {item.info ? (
                          <div id={`label_content_${index}`} className="label-content" />
                        ) : (
                          '-'
                        )}
                      </div>
                    ) : null}
                    <div style={{ maxWidth: 160 }} className="row-item">
                      {item.specification}
                    </div>
                    <div className="row-item" style={{ maxWidth: 130 }}>
                      {formatNumber(Number(item.price))}
                    </div>
                    <div className="row-item" style={{ maxWidth: 130 }}>
                      {formatNumber(Number(item.quantity))}
                    </div>
                    <div className="row-item" style={{ maxWidth: 180 }}>
                      {formatNumber(Number(item.total))}
                    </div>
                    <div style={{ maxWidth: 180 }} className="last-child">
                      {item.note}
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="row-wrapper">
              <div className="row-item thread-row" style={{ maxWidth: 60 }} />
              <div className="row-item thread-row" />
              {data.order_type === 'label' ? (
                <div className="row-item thread-row"></div>
              ) : null}{' '}
              {/* <div className="row-item thread-row" /> */}
              <div style={{ maxWidth: 160 }} className="row-item thread-row" />
              <div className="row-item thread-row" style={{ maxWidth: 130 }} />
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                Tổng cộng
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                {formatNumber(Math.round(data.sub_total))}
              </div>
              <div style={{ maxWidth: 180 }} className="last-child thread-row" />
            </div>

            <div className="row-wrapper">
              <div className="row-item thread-row" style={{ maxWidth: 60 }} />
              <div className="row-item thread-row" />
              {data.order_type === 'label' ? <div className="row-item thread-row"></div> : null}
              <div style={{ maxWidth: 160 }} className="row-item thread-row" />
              <div className="row-item thread-row" style={{ maxWidth: 130 }} />
              <div className="row-item thread-row" style={{ maxWidth: 130 }}>
                VAT
              </div>
              <div className="row-item thread-row" style={{ maxWidth: 180 }}>
                {formatNumber(Math.round(data.vat))}
              </div>
              <div style={{ maxWidth: 180 }} className="last-child thread-row" />
            </div>
          </div>
        </div>
      </Col>
    </>
  )
})

export default React.memo((props) => {
  const history = useHistory()
  const viewId = props.match?.params.id

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/purchase_orders/${viewId}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setState((prev) => ({ ...prev, data: resp.data, loading: false }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [viewId])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/purchase-orders`)
  }

  const handleEdit = () => {
    history.push(`/purchase-orders/${viewId}/edit`)
  }

  const handleDownloadPurchaseOrder = async () => {
    try {
      let order_type = state.data.order_type
      order_type =
        order_type === 'label'
          ? 'label'
          : order_type === 'finish_product'
          ? 'finish-product'
          : 'material'

      const result = await axiosExpand.get(
        `/purchase_orders/${viewId}/export/${order_type}-order/`,
        { responseType: 'arraybuffer' }
      )
      const FileDownload = require('js-file-download')
      FileDownload(
        result.data,
        `${state.data.order_number}-don-dat-hang-${
          order_type === 'label'
            ? 'tem'
            : order_type === 'finish-product'
            ? 'thanh-pham'
            : 'vat-lieu'
        }.xlsx`
      )
    } catch (error) {
      // message.error(error.response?.data.errors?.message)
      message.error('Đã có lỗi xảy ra, xin vui lòng tải lại trang và thử lại.')
    }
  }

  const handleDownloadPurchaseOrderContract = async () => {
    try {
      let order_type = state.data.order_type
      order_type = order_type === 'label' ? 'label' : 'material'

      const result = await axiosExpand.get(`/purchase_orders/${viewId}/export/contract/`, {
        responseType: 'arraybuffer',
      })

      const FileDownload = require('js-file-download')
      FileDownload(
        result.data,
        `${state.data.order_number}-hop-dong-mua-${
          order_type === 'material' ? 'vat-lieu' : 'tem'
        }.xlsx`
      )
    } catch (error) {
      // message.error(error.response?.data.errors?.message)
      message.error('Đã có lỗi xảy ra, xin vui lòng tải lại trang và thử lại.')
    }
  }

  return (
    <div id="view_purchase_order" className="table-container">
      <div className="inner-view">
        <div className="header-view">
          <Title className="page-title">Thông tin đơn đặt hàng</Title>
          <Tag className="status-tag" color={purchaseOrderStatusColorGenerator(state.data.status)}>
            {state.data.readable_status}
          </Tag>
        </div>

        <div className="view-wrapper">
          <div className="form-item">
            <Title className="field-title">Order No:</Title>
            <div className="content">{state.data.order_number}</div>
          </div>
          <div className="form-item">
            <Title className="field-title">Sale Order Number:</Title>
            <div className="content">{state.data.sale_order_order_number}</div>
          </div>
          <div className="form-item">
            <Title className="field-title">Ngày:</Title>
            <div className="content">{moment(state.data.date, 'YYYY-MM-DD').format(format)}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Ngày dự kiến giao hàng:</Title>
            <div className="content">
              {moment(state.data.receive_date, 'YYYY-MM-DD').format(format)}
            </div>
          </div>

          <div className="form-item">
            <Title className="field-title">Nhà cung cấp:</Title>
            <div className="content">{`${state.data.supplier.code}: ${state.data.supplier.name}`}</div>
          </div>

          <div className="form-item">
            <Title className="field-title">Loại Hàng:</Title>
            <div className="content">
              {state.data.order_type === 'material'
                ? 'Nguyên vật liệu'
                : state.data.order_type === 'packing'
                ? 'Bao bì'
                : state.data.order_type === 'label'
                ? 'Tem '
                : 'Thành phẩm'}
            </div>
          </div>
          <ItemInStock data={state.data} />

          <div className="order-table">
            {state.data.order_type === 'material' || state.data.order_type === 'packing' ? (
              <MaterialType data={state.data} />
            ) : (
              <LabelType data={state.data} />
            )}

            <div className="total-wrapper">
              <div className="total-number">
                <Title className="total-number-title">Thành tiền:</Title>
                <span className="total-number-content">
                  {state.data.total ? formatNumber(Math.round(state.data.total)) : '-'}
                </span>
              </div>
              <div className="total-number">
                <Title className="total-number-title">Bằng chữ:</Title>
                <span className="total-number-content">
                  {state.data.total ? numToWord(Math.round(state.data.total)) : '-'}
                </span>
              </div>

              <div className="requirement">
                <Title className="requirement-title">Điều kiện sản xuất lô hàng:</Title>
                <TextArea
                  className="requirement-content"
                  value={state.data.production_requirement || '-'}
                  autoSize={{ minRows: 2, maxRows: 13 }}
                />
              </div>

              <div className="requirement">
                <Title className="requirement-title">Ghi chú:</Title>
                <TextArea
                  className="requirement-content"
                  value={state.data.note || '-'}
                  autoSize={{ minRows: 2, maxRows: 13 }}
                />
              </div>
            </div>
          </div>

          <div className="form-item" style={{ flexDirection: 'column', paddingTop: 0 }}>
            <div className="container-types">
              <Title className="order-table-title">Documents</Title>
              <Col className="order-table-content">
                <div className="inner-content">
                  <div className="scroll-wrap">
                    <div className="row-wrapper">
                      <div className="row-item thread-row">Loại document</div>
                      <div className="last-child thread-row">Download</div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">
                        Đơn đặt hàng{' '}
                        {state.data.order_type === 'material'
                          ? 'vật liệu'
                          : state.data.order_type === 'packing'
                          ? 'bao bì'
                          : state.data.order_type === 'finish_product'
                          ? 'thành phẩm'
                          : 'tem'}
                      </div>
                      <div className="last-child">
                        <span className="downloadable-button" onClick={handleDownloadPurchaseOrder}>
                          Tải về
                        </span>
                      </div>
                    </div>

                    <div className="row-wrapper">
                      <div className="row-item">Hợp đồng</div>
                      <div className="last-child">
                        <span
                          className="downloadable-button"
                          onClick={handleDownloadPurchaseOrderContract}
                        >
                          Tải về
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>

          <div className="button-container">
            <Button
              icon={<RollbackOutlined />}
              type="primary"
              loading={state.loading}
              className="cancel-button"
              onClick={handleBack}
            >
              Trở lại
            </Button>

            {state.data.status !== 'pending' ? null : (
              <Button
                icon={<FormOutlined />}
                type="primary"
                loading={state.loading}
                className="process-button"
                onClick={handleEdit}
              >
                Chỉnh sửa
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
