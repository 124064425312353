import React, { useEffect, useMemo } from 'react'
import { Table, Input } from 'antd'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { formatNumber } from 'Utils'

export default React.memo((props) => {
  const { firstRender, data, loading, pagination, doFetch, setSearchTerm, searchTerm } = props

  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)
  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const productsHistoryState = useStoreState((state) => state.menuTabs['products-history'])

  useEffect(() => {
    let didCancel
    if (productsHistoryState && !didCancel) {
      doFetch((prev) => ({ ...prev, current: 1, pageSize: 5, order_by: '', order: '' }))
      shouldRefresh({ key: 'products-history', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsHistoryState])

  const columns = useMemo(() => {
    let cols = [
      {
        title: 'STT',
        dataIndex: 'index',
        width: 70,
      },
      {
        title: 'Mã order',
        dataIndex: 'sale_order.order_number',
        sortOrder: pagination.order_by === 'sale_order.order_number' ? `${pagination.order}` : null,
        width: 170,
        sorter: true,
      },
      {
        title: 'Ngày đơn hàng',
        dataIndex: 'sale_order.date',
        sortOrder: pagination.order_by === 'sale_order.date' ? `${pagination.order}` : null,
        width: 160,
        sorter: true,
        render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      },
      {
        title: 'Mã khách hàng',
        dataIndex: 'sale_order.customer.code',
        sortOrder:
          pagination.order_by === 'sale_order.customer.code' ? `${pagination.order}` : null,
        width: 170,
        sorter: true,
      },
      {
        title: 'Tên khách hàng',
        dataIndex: 'sale_order.customer.name',
        sortOrder:
          pagination.order_by === 'sale_order.customer.name' ? `${pagination.order}` : null,
        width: 200,
        sorter: true,
      },
      {
        title: 'Giá mua SP chưa thành phẩm (VND)',
        dataIndex: 'first_price',
        sortOrder: pagination.order_by === 'first_price' ? `${pagination.order}` : null,
        width: 220,
        sorter: true,
        render: (value, row) => {
          const productType = row.product?.product_type
          if (productType && productType === 'semifinish' && value) return formatNumber(value)
          return '-'
        },
      },
      {
        title: 'Giá mua SP thành phẩm (VND)',
        dataIndex: 'second_price',
        sortOrder: pagination.order_by === 'second_price' ? `${pagination.order}` : null,
        width: 220,
        sorter: true,
        render: (value, row) => {
          const productType = row.product?.product_type
          if (productType && productType === 'finish' && value) return formatNumber(value)
          return '-'
        },
      },
    ]
    if (isSuperAdmin)
      cols.push({
        title: 'Giá FOB (USD)',
        dataIndex: 'new_fob',
        sortOrder: pagination.order_by === 'new_fob' ? `${pagination.order}` : null,
        width: 160,
        sorter: true,
        render: (value) => (value ? formatNumber(value) : '-'),
      })
    return cols
  }, [isSuperAdmin, pagination.order, pagination.order_by])

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch((prev) => ({
      ...prev,
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
    }))
  }

  const renderTitle = () => {
    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    )
  }
  const dataSource = data.map((item, index) => ({
    ...item,
    index: index + 1,
    'sale_order.order_number': item.sale_order.order_number,
    'sale_order.customer.code': item.sale_order.customer?.code,
    'sale_order.customer.name': item.sale_order.customer?.name,
    'sale_order.date': item.sale_order.date,
  }))

  const loadingCondition = loading || firstRender

  return (
    <div id="products_history_table">
      <Table
        title={renderTitle}
        className="products-history-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, showSizeChanger: true }}
        loading={loadingCondition}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1500, y: 680 }}
      />
    </div>
  )
})
