import React from 'react'
import { Typography } from 'antd'
import PurchaseOrderForm from '../Form'

const { Title } = Typography

export default React.memo(() => {
  return (
    <div id="new_purchase_order_page">
      <Title className="page-title">Tạo đơn đặt hàng mới</Title>
      <PurchaseOrderForm path={'new'} />
    </div>
  )
})
