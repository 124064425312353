import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['MO', 'DI']

const ListCustomer = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewCustomer = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewCustomer = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

const EditCustomer = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export { ListCustomer, ViewCustomer, NewCustomer, EditCustomer }
