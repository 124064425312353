import React, { useState } from 'react'
import { Formik } from 'formik'
import { useStoreActions } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

import './index.scss'

import ForgotPasswordView from './ForgotPassword.view'
import { forgotPasswordSchema } from 'Validation'

const initialValues = {
  email: '',
}

export default React.memo(() => {
  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const forgotPassword = useStoreActions((actions) => actions.auth.forgotPassword)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    const resp = await forgotPassword(values)
    if (resp.errors) {
      setServerError(resp.errors)
      setSubmitting(false)
      return
    }
    setSubmitting(false)
    history.push('/login-step-one')
  }

  return (
    <div className="forgot-password-page">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <ForgotPasswordView {...props} serverError={serverError} isSubmitting={isSubmitting} />
        )}
        validationSchema={forgotPasswordSchema}
      />
    </div>
  )
})
