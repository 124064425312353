import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button, Tag } from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RollbackOutlined,
  FormOutlined,
} from '@ant-design/icons'

import { useHistory } from 'react-router-dom'
import { generateRoleName } from 'Utils'

import axios from 'Utils/axiosRexyAdmin'

const { Title } = Typography

const INITIAL_PROFILE = {
  name: '',
  email: '',
  roles: [],
  is_super_admin: false,
  active: false,
}

export default React.memo((props) => {
  const history = useHistory()
  const id = props.match?.params.id

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/users/${id}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setState((prev) => ({ ...prev, data: resp.data, loading: false }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors?.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [id])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/accounts`)
  }

  const handleEdit = () => {
    history.push(`/accounts/${id}/edit`)
  }

  return (
    <div id="view_user" className="table-container">
      <Title className="page-title">Thông tin tài khoản</Title>

      <div className="inner-content">
        <Card className="view-wrapper">
          <div className="general-information">
            <Title className="part-title">Thông tin chung</Title>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Email
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.email}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Chức vụ/Vị trí
                </Col>
                <Col className="content-wrapper" span={16}>
                  <ul>
                    {state.data.roles.map((role) => (
                      <li key={role}>{generateRoleName(role)}</li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Quản trị viên
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.is_super_admin ? 'Yes' : 'No'}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Trạng thái tài khoản
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.active ? (
                    <Tag icon={<CheckCircleOutlined />} color="success" key={true}>
                      Đang kích hoạt
                    </Tag>
                  ) : (
                    <Tag icon={<CloseCircleOutlined />} color="error" key={false}>
                      Vô hiệu hoá
                    </Tag>
                  )}
                </Col>
              </Row>
            </Card.Grid>
          </div>
          <div className="button-container">
            <Button
              icon={<RollbackOutlined />}
              type="primary"
              loading={state.loading}
              className="cancel-button"
              onClick={handleBack}
            >
              Trở lại
            </Button>

            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={state.loading}
              className="process-button"
              onClick={handleEdit}
            >
              Chỉnh sửa
            </Button>
          </div>
        </Card>
      </div>
    </div>
  )
})
