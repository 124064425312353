import React, { useState } from 'react'
import { Formik } from 'formik'
import { useStoreActions } from 'easy-peasy'

import FormView from './Form.view'
import { manageSettingsSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  id: undefined,
  usd_to_vnd_rate: undefined,
  sticker_fees: undefined,
}

export default React.memo(() => {
  const [serverError, setServerError] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [initialData, setInitialData] = useState({ isEdit: false, data: initialValues })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const updateExchangeRate = useStoreActions((actions) => actions.auth.updateExchangeRate)

  const handleFormSubmit = async (values, actions) => {
    setServerError(null)
    updateSetting(values, actions)
  }

  const updateSetting = async (params, actions) => {
    setIsSubmitting(true)
    const url = `/settings/${params.id}/edit/`
    try {
      await axios.put(url, { ...params })
      setShouldFetch(true)
      setInitialData({ isEdit: false, data: params })
      setIsSubmitting(false)
      updateExchangeRate(params.usd_to_vnd_rate)
    } catch (error) {
      setServerError(error.response?.data.errors.message)
      setIsSubmitting(false)
    }
  }

  return (
    <div className="setting-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            shouldFetch={shouldFetch}
            serverError={serverError}
            setShouldFetch={setShouldFetch}
            initialData={initialData}
            setInitialData={setInitialData}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={manageSettingsSchema}
      />
    </div>
  )
})
