import React from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'

import { preventEnterSubmit } from 'Utils'
import form_logo from 'Assets/images/logo3.svg'
import email_icon from 'Assets/images/email_icon.png'

const { Title } = Typography

export default React.memo((props) => {
  const { handleChange, values, isSubmitting, isValidating, handleSubmit, serverError } = props

  const CustomError = (props) => {
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }

  const EmailIcon = (props) => {
    return <img {...props} src={email_icon} alt="email icon" width={16} height={14} />
  }

  const loadingCondition = isSubmitting || isValidating

  return (
    <Form
      id="forgot_password_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <div className="form-logo">
        <img src={form_logo} alt="form logo" />
      </div>

      <Title className="form-description">
        Vui lòng nhập địa chỉ email. Mật khẩu mới sẽ được gửi qua email của bạn.
      </Title>

      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">Email</Title>
        <Field
          as={Input}
          name="email"
          placeholder="Nhập email để thiết lập mật khẩu mới"
          className="email-field"
          size="large"
          prefix={<EmailIcon style={{ marginRight: 5 }} />}
          disabled={loadingCondition}
          value={values.email}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="email" />
      </div>

      <Button type="primary" htmlType="submit" className="submit-button" loading={loadingCondition}>
        Xác nhận
      </Button>
    </Form>
  )
})
