import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['PP']

const ListDraftCommodityExport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewDraftCommodityExport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewDraftCommodityExport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

const EditDraftCommodityExport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export {
  ListDraftCommodityExport,
  ViewDraftCommodityExport,
  NewDraftCommodityExport,
  EditDraftCommodityExport,
}
