import React from 'react'
import { Typography } from 'antd'

import CustomerForm from '../Form'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  return (
    <div id="edit_customer_page">
      <Title className="page-title">Cập nhật khách hàng</Title>

      <CustomerForm path={'edit'} editId={id} />
    </div>
  )
})
