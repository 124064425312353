import React, { useEffect, useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'

import axios from 'Utils/axiosRexyAdmin'
import { useScrollToErrors, preventEnterSubmit } from 'Utils'
import { BlockNavigation } from 'Components'

const { Title } = Typography

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const { TextArea } = Input

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    initialValues,
  } = props

  const history = useHistory()
  const [isFetching, setIsFetching] = useState(false)
  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/suppliers/${editId}/edit`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)

        if (editId && !didCancel) {
          setValues({ ...values, ...resp.data })
          setIsFetching(false)

          const notSetFirstTimeFetchedValuesYet = size(firstTimeFetchedValues) === 0
          if (notSetFirstTimeFetchedValuesYet)
            setFirstTimeFetchedValues({ ...values, ...resp.data })
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    if (editId) {
      fetchData()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, setValues])

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_suppliers_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <BlockNavigation
        values={values}
        initialValues={initialValues}
        path={path}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />

      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <Title className="part-title">1. Thông tin chung</Title>
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Mã nhà cung cấp<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="code"
              placeholder="Nhập mã nhà cung cấp"
              disabled={loadingCondition}
              value={values.code}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="code" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Tên nhà cung cấp<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="name"
              placeholder="Nhập tên nhà cung cấp"
              disabled={loadingCondition}
              value={values.name}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="name" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Người liên hệ<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="contact_name"
              placeholder="Nhập người liên hệ"
              disabled={loadingCondition}
              value={values.contact_name}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="contact_name" />
          </div>

          <div className="form-item">
            <Title className="field-title">Số Điện thoại/Fax</Title>
            <Field
              as={Input}
              name="phone_number"
              placeholder="Nhập số Điện thoại/Fax nhà cung cấp"
              disabled={loadingCondition}
              value={values.phone_number}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="phone_number" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Email<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="email"
              placeholder="Nhập email"
              disabled={loadingCondition}
              value={values.email}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="email" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Địa chỉ<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="address"
              placeholder="Nhập địa chỉ"
              disabled={loadingCondition}
              value={values.address}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="address" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Thông tin nhà cung cấp<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="info"
              placeholder="Nhập thông tin nhà cung cấp"
              disabled={loadingCondition}
              value={values.info}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="info" />
          </div>

          <div className="form-item">
            <Title className="field-title">Ghi chú</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Nhập ghi chú"
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
        </div>
        <Title className="part-title">2. Tài khoản ngân hàng</Title>
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Tên ngân hàng<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="bank_name"
              placeholder="Nhập tên ngân hàng"
              disabled={loadingCondition}
              value={values.bank_name}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="bank_name" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Chi nhánh ngân hàng<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="bank_branch"
              placeholder="Nhập chi nhánh ngân hàng"
              disabled={loadingCondition}
              value={values.bank_branch}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="bank_branch" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Số tài khoản<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="bank_number"
              placeholder="Nhập số tài khoản"
              disabled={loadingCondition}
              value={values.bank_number}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="bank_number" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Đơn vị thụ hưởng<span className="compulsory-field-symbol"> *</span>
            </Title>
            <Field
              as={Input}
              name="bank_account_name"
              placeholder="Nhập đơn vị thụ hưởng"
              disabled={loadingCondition}
              value={values.bank_account_name}
              onChange={handleChange}
            />
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="bank_account_name"
            />
          </div>
        </div>
      </div>

      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/suppliers')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Thêm nhà cung cấp' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
