import React from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import { formatNumber } from 'Utils'

const computedData = (data) => {
  let result = []
  data.forEach((item) => {
    if (item.purchase_order_invoices.length) {
      item.purchase_order_invoices.forEach((invoice, i) => {
        const isHeader = i === 0
        const row = combineEachRow(item, invoice, isHeader)
        result.push(combineEachRowCommodityExport(row))
      })
    } else {
      const row = {
        ...item,
        debt: 0,
        isHeader: true,
        can_create_standing_order: false,
        purchase_orders_amount: item.total,
        invoice_amount: 0,
        reason_why_can_not_create_standing_order: '',
        supplier_name: `${item.supplier.code}: ${item.supplier.name} `,
        bank_note: item.supplier.bank_name,
      }
      result.push(combineEachRowCommodityExport(row))
    }
  })
  return result
}

const combineEachRowCommodityExport = (data) => {
  let row = { ...data }
  if (data?.commodity_export_items && isArray(data?.commodity_export_items)) {
    data.commodity_export_items.forEach((commodity, i) => {
      if (!isEmpty(commodity.commodity_export)) {
        row[`export-number-${i}`] = commodity.commodity_export.export_number
        row[`export-date-${i}`] = commodity.commodity_export.date
      }
    })
  }
  return row
}

const combineEachRow = (item, invoice, isHeader) => {
  let row = {
    ...item,
    ...invoice,
    isHeader,
    purchase_order_id: item.id,
    purchase_order_number: item.order_number,
    purchase_orders_amount: item.total,
    invoice_amount: invoice.total,
    reason_why_can_not_create_standing_order: invoice.reason_why_can_not_create_standing_order,
    supplier_name: `${item.supplier.code}: ${item.supplier.name} (${item.order_number})`,
    bank_note: item.supplier.bank_name,
    purchase_order_invoice_id: invoice.id,
    purchase_order_invoice_number: invoice.invoice_number,
  }
  invoice.standing_orders.forEach((standing, i) => {
    row[`standing-amount-${i}`] = standing.amount
    row[`standing-date-${i}`] = standing.date
  })
  return row
}

const computedColumns = (count, commodityExportCount) => {
  let columns = [
    {
      title: 'Nhà cung cấp',
      dataIndex: 'supplier_name',
      width: 210,
      render: (value, row) => renderOrderHeader(value, row),
    },
    {
      title: 'Mã đơn đặt hàng',
      dataIndex: 'order_number',
      width: 160,
      render: (value) => <p>{value}</p>,
    },
  ]

  for (let i = 0; i < commodityExportCount; i++) {
    const column_1 = {
      title: `Mã Đơn XH ${i + 1}`,
      dataIndex: `export-number-${i}`,
      width: 140,
      render: (exportNumber, row) => {
        const formatValue = exportNumber ?? '-'
        const obj = { children: formatValue, props: {} }
        const rowSpanNumb = row.purchase_order_invoices.length || 1
        if (row.isHeader) {
          obj.props.rowSpan = rowSpanNumb
        } else {
          obj.props.rowSpan = 0
        }
        return obj
      },
    }
    columns.push(column_1)

    const column_2 = {
      title: `Ngày Đơn XH ${i + 1}`,
      dataIndex: `export-date-${i}`,
      width: 140,
      render: (date, row) => {
        const formatValue = date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'
        const obj = { children: formatValue, props: {} }
        const rowSpanNumb = row.purchase_order_invoices.length || 1
        if (row.isHeader) {
          obj.props.rowSpan = rowSpanNumb
        } else {
          obj.props.rowSpan = 0
        }
        return obj
      },
    }

    columns.push(column_2)
  }

  columns = [
    ...columns,
    {
      title: 'Số tiền đơn đặt hàng (VND)',
      dataIndex: 'purchase_orders_amount',
      width: 130,
      render: (value, row) => renderTotalHeader(value, row),
    },
    {
      title: 'Số tiền hoá đơn (VND)',
      dataIndex: 'invoice_amount',
      width: 130,
      render: (invoice_amount) =>
        invoice_amount ? formatNumber(Number(invoice_amount.toFixed(2))) : '-',
    },
    {
      title: 'Số hoá đơn',
      dataIndex: 'invoice_number',
      width: 130,
      render: (invoice_number) => invoice_number ?? '-',
    },
    {
      title: 'Ngày hoá đơn',
      dataIndex: 'date',
      width: 130,
      render: (date) => (date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'Số tiền trên HĐ chưa TT',
      dataIndex: 'debt',
      width: 140,
      render: (notPaid) => (notPaid ? formatNumber(Number(notPaid.toFixed(2))) : '-'),
    },
    {
      title: 'Tạo uỷ nhiệm chi',
      dataIndex: 'can_create_standing_order',
      width: 150,
      render: (canCreate, row) => <CreateStandingOrderButton row={row} canCreate={canCreate} />,
    },
    {
      title: 'Ghi chú ngân hàng',
      dataIndex: 'bank_note',
      width: 160,
      render: (note) => note ?? '-',
    },
    {
      title: 'Ghi chú khác',
      dataIndex: 'note',
      width: 180,
      render: (note) => note ?? '-',
    },
  ]

  for (let i = 0; i < count; i++) {
    let column_1 = {
      title: `Số tiền TT lần ${i + 1}`,
      dataIndex: `standing-amount-${i}`,
      width: 140,
      render: (paid) => (paid ? formatNumber(Number(paid.toFixed(2))) : '-'),
    }
    columns.push(column_1)

    let column_2 = {
      title: `Ngày TT lần ${i + 1}`,
      dataIndex: `standing-date-${i}`,
      width: 140,
      render: (date) => (date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'),
    }
    columns.push(column_2)
  }

  return columns
}

const renderOrderHeader = (value, row) => {
  const obj = { children: value, props: {} }
  const rowSpanNumb = row.purchase_order_invoices.length || 1
  if (row.isHeader) {
    obj.props.rowSpan = rowSpanNumb
  } else {
    obj.props.rowSpan = 0
  }
  return obj
}

const renderTotalHeader = (value, row) => {
  const formatValue = value ? formatNumber(Number(value.toFixed(2))) : '-'
  const obj = { children: formatValue, props: {} }
  const rowSpanNumb = row.purchase_order_invoices.length || 1
  if (row.isHeader) {
    obj.props.rowSpan = rowSpanNumb
  } else {
    obj.props.rowSpan = 0
  }
  return obj
}

const computedTableLength = (count) => {
  let length = 1260

  for (let i = 0; i < count; i++) {
    length += 300
  }
  return length
}

const CreateStandingOrderButton = ({ row, canCreate }) => {
  const history = useHistory()
  if (!canCreate) {
    return <div style={{ fontStyle: 'italic' }}>{row.reason_why_can_not_create_standing_order}</div>
  }

  const handleCreateStandingOrder = () => {
    const supplierId = get(row, 'supplier.id')
    const supplierCode = get(row, 'supplier.code')
    const purchaseOrderId = get(row, 'purchase_order_id')
    const orderNumber = get(row, 'purchase_order_number')
    const purchaseOrderInvoiceId = get(row, 'purchase_order_invoice_id')
    const purchaseOrderInvoiceNumber = get(row, 'purchase_order_invoice_number')
    history.push({
      pathname: `/standing-orders/new`,
      search: `?&supplier_id=${supplierId}&supplier_code=${supplierCode}&purchase_order_id=${purchaseOrderId}&order_number=${orderNumber}&purchase_order_invoice_id=${purchaseOrderInvoiceId}&purchase_order_invoice_number=${purchaseOrderInvoiceNumber}`,
    })
  }

  return (
    <Button type="primary" onClick={handleCreateStandingOrder}>
      Tạo UNC
    </Button>
  )
}

const NestList = ({ data = [], count, commodityExportCount }) => {
  const dataSource = computedData(data, commodityExportCount)
  const columns = computedColumns(count, commodityExportCount)
  const xLength = computedTableLength(count + commodityExportCount)

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey={(record) => record.id}
      pagination={false}
      className="table-nestList"
      bordered
      scroll={{ x: xLength, y: 300 }}
    />
  )
}

export default NestList
