import * as yup from 'yup'
import { size, filter } from 'lodash'

const requiredField = 'Trường này không được để trống'
const requiredIntegerField = 'Trường này phải là là số nguyên'
const maxLength = 'Tối đa 255 ký tự'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

function checkMinOneMaterial(ref, msg) {
  return this.test({
    name: 'checkMinOneMaterial',
    exclusive: false,
    message: msg || 'Vui lòng chọn 1 nguyên vật liệu.',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return (
        size(filter(value, { material_type: 'material' })) > 0
      )
    },
  })
}

yup.addMethod(yup.array, 'checkMinOneMaterial', checkMinOneMaterial)

const manageProductsSchema = yup.object().shape({
  name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  name_on_proforma_invoice: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  code: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  customer_code: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(255, maxLength),
  specification: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  quantity_in_box: yup
    .number()
    .integer(requiredIntegerField)
    .required(requiredField)
    .typeError(requiredField),
  name_on_foreign_document: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  name_on_bill: yup.string().trim('Vui lòng không nhập khoảng trắng').strict().max(255, maxLength),
  name_on_declaration: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  brand_name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  aws_object_keys: yup.array().required(requiredField),
  package_barcode: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  box_barcode: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  box_size: yup.number().typeError(typeError),
  net_weight: yup.number().required(requiredField).typeError(typeError),
  gross_weight: yup.number().required(requiredField).typeError(typeError),
  expire_date: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  quality_indicators: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .max(255, maxLength),
  hs_code: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  product_type: yup.string().required(requiredField).max(255, maxLength),
  // first_price: yup.number().required(requiredField),
  // production_cost: yup.number().required(requiredField),
  first_price: yup.number().when('product_type', {
    is: (value) => value === 'finish',
    then: yup.number().notRequired(),
    otherwise: yup.number().required(requiredField).typeError(typeError),
  }),
  production_cost: yup.number().when('product_type', {
    is: (value) => value === 'finish',
    then: yup.number().notRequired(),
    otherwise: yup.number().required(requiredField).typeError(typeError),
  }),
  second_price: yup.number().required(requiredField).typeError(typeError),

  suppliers: yup.array().when('product_type', {
    is: (value) => value === 'finish',
    then: yup.array().required(requiredField).of(yup.number().typeError(typeError)),
    otherwise: yup.array().notRequired(),
  }),
  barcode_note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
  co_note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
  need_other_quarantines: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
  quality_note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),

  // materials: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       material_id: yup.number().required(requiredField).typeError(typeError),
  //       material_quantity: yup.number().required(requiredField),
  //       // material_type:yup.string(),
  //     })
  //   )
  //   .checkMinOneMaterial(yup.ref('materials')),
  materials: yup.array().when('product_type', {
    is: (value) => value !== 'finish',
    then: yup
      .array()
      .of(
        yup.object().shape({
          material_id: yup.number().required(requiredField).typeError(typeError),
          material_quantity: yup.number().required(requiredField),
          // material_type:yup.string(),
        })
      )
      .checkMinOneMaterial(yup.ref('materials')),
    otherwise: yup.array().notRequired(),
  }),

  quantity: yup.number().integer(requiredIntegerField).required(requiredField).typeError(typeError),
})

export default manageProductsSchema
