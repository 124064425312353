import React, { useState, useCallback } from 'react'
import { Typography, Modal, Alert, Upload } from 'antd'

import axios from 'Utils/axiosRexyAdmin'

const { Text } = Typography

export default ({ id, image_link, visible, setDeleteModal, doFetch }) => {
  const [state, setState] = useState({ loading: false, errors: null })

  const [previewImage, setPreviewImage] = useState({
    file: '',
    visible: false,
  })

  const handleCancel = () => {
    setDeleteModal({ id: undefined, image_link: '', visible: false })
  }

  const handleCancelPreview = () => {
    setPreviewImage((prevState) => ({ ...prevState, visible: false }))
  }

  const getBase64 = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage({ file: file.url || file.preview, visible: true })
  }

  const handleOk = async () => {
    setState({ loading: true, errors: null })
    const url = '/labels'
    try {
      await axios.delete(url, { data: { ids: [id] } })

      setState((prev) => ({ ...prev, loading: false }))
      setDeleteModal({ id: undefined, image_link: '', visible: false })
      doFetch({})
    } catch (error) {
      setState({
        loading: false,
        errors: error.response?.data.errors?.message,
      })
    }
  }

  const renderDeleteButton = () => {
    return <Text className="create-btn-text">Xoá</Text>
  }

  const renderCancelButton = () => {
    return <Text className="cancel-btn-text">Huỷ</Text>
  }

  const renderTitle = () => {
    return <Text className="modal-title">Xoá tem</Text>
  }

  return (
    <Modal
      className="delete-label-modal"
      style={{ top: 200 }}
      visible={visible}
      title={renderTitle()}
      okText={renderDeleteButton()}
      okButtonProps={{ loading: state.loading, className: 'delete-button' }}
      cancelText={renderCancelButton()}
      cancelButtonProps={{ loading: state.loading }}
      onOk={handleOk}
      onCancel={state.loading ? null : handleCancel}
    >
      <div id="label_delete_modal">
        <Text className="modal-description">
          <strong>Bạn có chắc muốn xoá tem này.</strong>
          <br />
          Lưu ý: Một khi đã xoá sẽ không thể hoàn tác.
        </Text>
        {state.errors && (
          <Alert style={{ marginBottom: 5 }} message={state.errors} type="error" showIcon />
        )}
        {/* <Text className="user-name">{name}</Text> */}
        <div className="label-image-wrapper">
          <Upload
            listType="picture-card"
            fileList={[
              {
                uid: `uid-${id}`,
                status: 'done',
                url: image_link,
              },
            ]}
            onPreview={handlePreview}
            onRemove={false}
          />
        </div>

        <Modal
          visible={previewImage.visible}
          footer={null}
          onCancel={handleCancelPreview}
          centered
          bodyStyle={{ padding: 5 }}
          width={500}
        >
          <img
            alt="preview"
            className="image-preview"
            style={{ width: '100%', height: 'auto' }}
            src={previewImage.file}
          />
        </Modal>
      </div>
    </Modal>
  )
}
