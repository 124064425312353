import React, { useState, useEffect, useCallback } from 'react'
import { Table, Input, Button, Switch, message } from 'antd'
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import axiosExpand from 'Utils/axiosExpand'
import { useDataApi } from 'Hooks'
import { formatNumber, pageSizeConfig } from 'Utils'
import DeleteModal from './DeleteModal'
import './index.scss'

const computedData = (data) => {
  return data.map((item) => ({
    ...item,
    'purchase_order.order_number': item.purchase_order.order_number,
    'supplier.code': item.supplier.code,
    'supplier.name': item.supplier.name,
  }))
}

export default () => {
  const history = useHistory()
  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/purchase_order_invoices/',
    key: 'records',
  })

  const [state, setState] = useState({
    id: undefined,
    toggling: false,
  })
  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    code: '',
    changeCode: '',
    error: false,
    visible: false,
  })
  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const supplierDebtInvoice = useStoreState((state) => state.menuTabs['supplier-debt-invoice'])
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  useEffect(() => {
    let didCancel
    if (supplierDebtInvoice && !didCancel) {
      doFetch({})
      shouldRefresh({ key: 'supplier-debt-invoice', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierDebtInvoice])

  const computedColumns = useCallback(() => {
    let columns = [
      {
        title: 'Mã đơn đặt hàng NCC',
        dataIndex: 'purchase_order.order_number',
        sortOrder:
          pagination.order_by === 'purchase_order.order_number' ? `${pagination.order}` : null,
        width: 190,
        sorter: true,
      },
      {
        title: 'Số hoá đơn',
        dataIndex: 'invoice_number',
        sortOrder: pagination.order_by === 'invoice_number' ? `${pagination.order}` : null,
        width: 160,
        sorter: true,
      },
      {
        title: 'Ngày hoá đơn',
        dataIndex: 'date',
        sortOrder: pagination.order_by === 'date' ? `${pagination.order}` : null,
        width: 180,
        sorter: true,
        render: (date) => (date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'),
      },
      {
        title: 'Số tiền hoá đơn (VND)',
        dataIndex: 'total',
        sortOrder: pagination.order_by === 'total' ? `${pagination.order}` : null,
        width: 180,
        sorter: true,
        render: (money) => (money ? formatNumber(Number(money)) : '-'),
      },
      {
        title: 'Mã nhà cung cấp',
        dataIndex: 'supplier.code',
        sortOrder: pagination.order_by === 'supplier.code' ? `${pagination.order}` : null,
        width: 150,
        sorter: true,
      },
      {
        title: 'Tên nhà cung cấp',
        dataIndex: 'supplier.name',
        sortOrder: pagination.order_by === 'supplier.name' ? `${pagination.order}` : null,
        width: 200,
        sorter: true,
      },
    ]

    if (isSuperAdmin) {
      columns.push({
        title: 'Đã duyệt',
        dataIndex: 'approved',
        sortOrder: pagination.order_by === 'approved' ? `${pagination.order}` : null,
        width: 120,
        sorter: true,
        render: (isApproved, row) => renderToggle(isApproved, row),
      })
    } else {
      columns.push({
        title: 'Đã duyệt',
        dataIndex: 'approved',
        sortOrder: pagination.order_by === 'approved' ? `${pagination.order}` : null,
        width: 120,
        sorter: true,
        render: (isApproved) =>
          isApproved ? (
            <CheckCircleOutlined style={{ paddingLeft: 17, color: 'green', fontSize: 18 }} />
          ) : (
            <CloseCircleOutlined style={{ paddingLeft: 17, color: 'red', fontSize: 18 }} />
          ),
      })
    }

    columns.push({
      key: 'x',
      width: 150,
      render: (record) => actionColumn(record),
    })
    return columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperAdmin, pagination.order, pagination.order_by])

  const onApprove = useCallback(async (id, checked) => {
    setState({ id, toggling: true })

    try {
      await axiosExpand.patch(`/purchase_order_invoices/${id}/toggle-approve/`)
      doFetch((prev) => ({ ...prev }))
      setState({ id: undefined, toggling: false })
    } catch (error) {
      setState({ id: undefined, toggling: false })
      message.error(error.response?.data.errors?.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderToggle = useCallback(
    (isApproved, row) => {
      const activeLoading = state.toggling && row.id === state.id

      if (!row.show_approved) return
      return (
        <div className="toggle-button-wrapper" onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={isApproved}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(checked) => onApprove(row.id, checked)}
            loading={activeLoading}
            disabled={row.approved === true ? true : false}
          />
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.id, state.toggling]
  )

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {isSuperAdmin ? (
          <>
            <Button
              className="action-item update"
              type="link"
              onClick={(e) => handleEdit(record, e)}
            >
              Sửa
            </Button>
            <Button
              className="action-item delete"
              type="link"
              danger
              onClick={(e) => handleDelete(record, e)}
            >
              Xoá
            </Button>
          </>
        ) : (
          record.approved || (
            <>
              <Button
                className="action-item update"
                type="link"
                onClick={(e) => handleEdit(record, e)}
              >
                Sửa
              </Button>
              <Button
                className="action-item delete"
                type="link"
                danger
                onClick={(e) => handleDelete(record, e)}
              >
                Xoá
              </Button>
            </>
          )
        )}
      </div>
    )
  }
  const handleDelete = (record, e) => {
    e.stopPropagation()
    setDeleteModal({
      id: record.id,
      code: record.invoice_number,
      changeCode: '',
      error: false,
      visible: true,
    })
  }
  const handCreate = () => {
    history.push('/supplier-debt-invoice/new')
  }

  const handleView = (record) => {
    history.push(`/supplier-debt-invoice/${record.id}/view`)
  }

  const handleEdit = (record, event) => {
    event.stopPropagation()
    history.push(`/supplier-debt-invoice/${record.id}/edit`)
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch((prev) => ({
      ...prev,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    }))
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Tạo hoá đơn
        </Button>
      </div>
    )
  }

  return (
    <div id="manage_supplier_debt_invoice_table">
      <Table
        title={renderTitle}
        className="supplier-debt-invoice-table"
        dataSource={computedData(data)}
        columns={computedColumns()}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        showSizeChanger={true}
        scroll={{ x: 1300, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
}
