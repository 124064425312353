import React, { useEffect, useState } from 'react'
import { Table, Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { useDataApi } from 'Hooks'
import { pageSizeConfig } from 'Utils'
import DeleteModal from './DeleteModal'

export default React.memo(() => {
  const history = useHistory()
  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi(
    {
      url: '/commodity_exports/',
      key: 'records',
    },
    undefined,
    { is_drafting: true }
  )

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    name: '',
    visible: false,
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const commodityExports = useStoreState((state) => state.menuTabs['commodity-exports-draft'])

  useEffect(() => {
    let didCancel
    if (commodityExports && !didCancel) {
      doFetch({ order_by: '', order: '', is_drafting: true })
      shouldRefresh({ key: 'commodity-exports-draft', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commodityExports])

  const columns = [
    {
      title: 'Mã đơn xuất hàng',
      dataIndex: 'export_number',
      sortOrder: pagination.order_by === 'export_number' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
    },
    {
      title: 'Ngày xuất hàng',
      dataIndex: 'date',
      sortOrder: pagination.order_by === 'date' ? `${pagination.order}` : null,
      width: 160,
      sorter: true,
      render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer.code',
      sortOrder: pagination.order_by === 'customer.code' ? `${pagination.order}` : null,
      width: 190,
      sorter: true,
    },
    {
      key: 'x',
      width: 150,
      render: (record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}
        <Button className="action-item update" type="link" onClick={(e) => handleEdit(record, e)}>
          Sửa
        </Button>
        <Button className="action-item delete" type="link" onClick={(e) => handleDelete(record, e)}>
          Xóa
        </Button>
      </div>
    )
  }

  const handCreate = () => {
    history.push('/commodity-exports-draft/new')
  }

  const handleView = (record) => {
    history.push(`/commodity-exports-draft/${record.id}/view`)
  }

  const handleEdit = (record, e) => {
    e.stopPropagation()
    history.push(`/commodity-exports-draft/${record.id}/edit`)
  }

  const handleDelete = (record, e) => {
    e.stopPropagation()
    setDeleteModal({ id: record.id, name: record.export_number, visible: true })
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      is_drafting: true,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Tạo đơn xuất hàng nháp
        </Button>
      </div>
    )
  }

  const dataSource = data.map((order, index) => ({
    ...order,
    'customer.code': `${order.customer.code} : ${order.customer.name}`,
  }))

  return (
    <div id="manage_draft_commodity_export_table">
      <Table
        title={renderTitle}
        className="commodity-export-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 720, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
})
