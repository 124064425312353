import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { LoginRoute, DashboardRoute } from './Layouts'
import {
  PageNotFound,
  Forbidden,
  LoginStep1,
  LoginStep2,
  Home,
  ForgotPassword,
  ViewUser,
  ListUser,
  NewUser,
  EditUser,
  ListProduct,
  ViewProduct,
  NewProduct,
  EditProduct,
  NewMaterial,
  ViewMaterial,
  EditMaterial,
  ListMaterial,
  NewPackage,
  ViewPackage,
  EditPackage,
  ListPackage,
  NewSupplier,
  ViewSupplier,
  EditSupplier,
  ListSupplier,
  EditSetting,
  SettingsAccount,
  ListLabel,
  EditLabel,
  NewLabel,
  ViewLabel,
  ListCustomer,
  NewCustomer,
  EditCustomer,
  ViewCustomer,
  ListPurchaseOrder,
  NewPurchaseOrder,
  EditPurchaseOrder,
  ViewPurchaseOrder,
  ListStandingOrder,
  NewStandingOrder,
  ViewStandingOrder,
  ListSaleOrder,
  NewSaleOrder,
  ViewSaleOrder,
  EditSaleOrder,
  ProformalInvoice,
  EditStandingOrder,
  ListStaff,
  ViewStaff,
  NewStaff,
  EditStaff,
  ListCommodityExport,
  ViewCommodityExport,
  NewCommodityExport,
  EditCommodityExport,
  ListDraftCommodityExport,
  ViewDraftCommodityExport,
  NewDraftCommodityExport,
  EditDraftCommodityExport,
  ListStocks,
  ViewStocks,
  NewStocks,
  ListPaymentTransaction,
  ViewPaymentTransaction,
  NewPaymentTransaction,
  EditPaymentTransaction,
  ListNotification,
  ProducingSaleOrder,
  ListSalary,
  ViewSalary,
  NewSalary,
  EditSalary,
  TimeAttendenceIndex,
  ViewStockReport,
  ViewSaleReport,
  ProductsHistory,
  MaterialHistory,
  ViewShippingSchedule,
  ImportTimeAttendance,
  ListCustomerPayment,
  ListSupplierDebtInvoice,
  ViewSupplierDebtInvoice,
  NewSupplierDebtInvoice,
  EditSupplierDebtInvoice,
  ListSupplierPayment,
  ProducedSaleOrder,
} from 'Pages'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <LoginRoute exact path="/login-step-one" component={LoginStep1} />
      <LoginRoute exact path="/login-step-two" component={LoginStep2} />
      <LoginRoute exact path="/forgot-password" component={ForgotPassword} />

      <DashboardRoute exact path="/" component={Home} />

      {/* Manage Users */}
      <DashboardRoute exact path="/accounts" component={ListUser} />
      <DashboardRoute exact path="/accounts/new" component={NewUser} />
      <DashboardRoute exact path="/accounts/:id/edit" component={EditUser} />
      <DashboardRoute exact path="/accounts/:id/view" component={ViewUser} />

      {/* Manage Product */}
      <DashboardRoute exact path="/products" component={ListProduct} />
      <DashboardRoute exact path="/products/new" component={NewProduct} />
      <DashboardRoute exact path="/products/new/:id" component={NewProduct} />
      <DashboardRoute exact path="/products/:id/view" component={ViewProduct} />
      <DashboardRoute exact path="/products/:id/edit" component={EditProduct} />

      {/* Manage Material */}
      <DashboardRoute exact path="/materials" component={ListMaterial} />
      <DashboardRoute exact path="/materials/new" component={NewMaterial} />
      <DashboardRoute exact path="/materials/new/:id" component={NewMaterial} />
      <DashboardRoute exact path="/materials/:id/view" component={ViewMaterial} />
      <DashboardRoute exact path="/materials/:id/edit" component={EditMaterial} />

      {/* Manage Packages */}
      <DashboardRoute exact path="/packages" component={ListPackage} />
      <DashboardRoute exact path="/packages/new" component={NewPackage} />
      <DashboardRoute exact path="/packages/new/:id" component={NewPackage} />
      <DashboardRoute exact path="/packages/:id/view" component={ViewPackage} />
      <DashboardRoute exact path="/packages/:id/edit" component={EditPackage} />

      {/* Manage Supplier */}
      <DashboardRoute exact path="/suppliers/new" component={NewSupplier} />
      <DashboardRoute exact path="/suppliers" component={ListSupplier} />
      <DashboardRoute exact path="/suppliers/:id/view" component={ViewSupplier} />
      <DashboardRoute exact path="/suppliers/:id/edit" component={EditSupplier} />

      {/* Manage Label */}
      <DashboardRoute exact path="/labels/new" component={NewLabel} />
      <DashboardRoute exact path="/labels/new/:id" component={NewLabel} />
      <DashboardRoute exact path="/labels" component={ListLabel} />
      <DashboardRoute exact path="/labels/:id/view" component={ViewLabel} />
      <DashboardRoute exact path="/labels/:id/edit" component={EditLabel} />

      {/* Manage Customer */}
      <DashboardRoute exact path="/customers/new" component={NewCustomer} />
      <DashboardRoute exact path="/customers" component={ListCustomer} />
      <DashboardRoute exact path="/customers/:id/view" component={ViewCustomer} />
      <DashboardRoute exact path="/customers/:id/edit" component={EditCustomer} />

      {/* Manage Supplier Debt Invoice */}
      <DashboardRoute exact path="/supplier-debt-invoice" component={ListSupplierDebtInvoice} />
      <DashboardRoute exact path="/supplier-debt-invoice/new" component={NewSupplierDebtInvoice} />
      <DashboardRoute
        exact
        path="/supplier-debt-invoice/:id/view"
        component={ViewSupplierDebtInvoice}
      />
      <DashboardRoute
        exact
        path="/supplier-debt-invoice/:id/edit"
        component={EditSupplierDebtInvoice}
      />

      {/* Manage Commodity Export */}
      <DashboardRoute exact path="/commodity-exports/new" component={NewCommodityExport} />
      <DashboardRoute exact path="/commodity-exports" component={ListCommodityExport} />
      <DashboardRoute exact path="/commodity-exports/:id/view" component={ViewCommodityExport} />
      <DashboardRoute exact path="/commodity-exports/:id/edit" component={EditCommodityExport} />

      {/* Manage Commodity Export Draft */}
      <DashboardRoute
        exact
        path="/commodity-exports-draft/new"
        component={NewDraftCommodityExport}
      />
      <DashboardRoute exact path="/commodity-exports-draft" component={ListDraftCommodityExport} />
      <DashboardRoute
        exact
        path="/commodity-exports-draft/:id/view"
        component={ViewDraftCommodityExport}
      />
      <DashboardRoute
        exact
        path="/commodity-exports-draft/:id/edit"
        component={EditDraftCommodityExport}
      />

      {/* Manage PurchaseOrder */}
      <DashboardRoute exact path="/purchase-orders/new" component={NewPurchaseOrder} />
      <DashboardRoute exact path="/purchase-orders" component={ListPurchaseOrder} />
      <DashboardRoute exact path="/purchase-orders/:id/view" component={ViewPurchaseOrder} />
      <DashboardRoute exact path="/purchase-orders/:id/edit" component={EditPurchaseOrder} />

      {/* Manage StandingOrders */}
      <DashboardRoute exact path="/standing-orders/new" component={NewStandingOrder} />
      <DashboardRoute exact path="/standing-orders" component={ListStandingOrder} />
      <DashboardRoute exact path="/standing-orders/:id/view" component={ViewStandingOrder} />
      <DashboardRoute exact path="/standing-orders/:id/edit" component={EditStandingOrder} />

      {/* Manage SaleOrder */}
      <DashboardRoute exact path="/sale-orders/new" component={NewSaleOrder} />
      <DashboardRoute exact path="/sale-orders" component={ListSaleOrder} />
      <DashboardRoute exact path="/sale-orders/:id/view" component={ViewSaleOrder} />
      <DashboardRoute exact path="/sale-orders/:id/edit" component={EditSaleOrder} />
      <DashboardRoute
        exact
        path="/sale-orders/:id/proformal-invoice"
        component={ProformalInvoice}
      />
      <DashboardRoute exact path="/sale-orders/:id/producing" component={ProducingSaleOrder} />
      <DashboardRoute exact path="/sale-orders/:id/produced" component={ProducedSaleOrder} />

      {/* Manage import/export stocks */}
      <DashboardRoute exact path="/manage-stocks" component={ListStocks} />
      <DashboardRoute exact path="/manage-stocks/:id/view" component={ViewStocks} />
      <DashboardRoute exact path="/manage-stocks/new" component={NewStocks} />
      <DashboardRoute exact path="/manage-stocks/new/:purchase_order_id/" component={NewStocks} />

      <DashboardRoute exact path="/stocks-report" component={ViewStockReport} />

      <DashboardRoute exact path="/sales-report" component={ViewSaleReport} />

      <DashboardRoute exact path="/shipping-schedule" component={ViewShippingSchedule} />

      <DashboardRoute exact path="/import-time-attendance" component={ImportTimeAttendance} />

      {/* Manage Settings */}
      <DashboardRoute exact path="/settings" component={EditSetting} />

      {/* Settings Account */}
      <DashboardRoute exact path="/settings-account" component={SettingsAccount} />

      {/* Manage Staffs */}
      <DashboardRoute exact path="/staffs" component={ListStaff} />
      <DashboardRoute exact path="/staffs/new" component={NewStaff} />
      <DashboardRoute exact path="/staffs/:id/edit" component={EditStaff} />
      <DashboardRoute exact path="/staffs/:id/view" component={ViewStaff} />

      {/* Manage Salary */}
      <DashboardRoute exact path="/salary" component={ListSalary} />
      <DashboardRoute exact path="/salary/new" component={NewSalary} />
      <DashboardRoute exact path="/salary/:id/edit" component={EditSalary} />
      <DashboardRoute exact path="/salary/:id/view" component={ViewSalary} />

      {/* Manage Time Attendence */}
      <DashboardRoute exact path="/time-attendance" component={TimeAttendenceIndex} />

      {/* Manage Products History */}
      <DashboardRoute exact path="/products-history" component={ProductsHistory} />

      {/* Manage Materials History */}
      <DashboardRoute exact path="/materials-history" component={MaterialHistory} />

      {/* Manage PaymentTransactions */}
      <DashboardRoute exact path="/payment-transactions/new" component={NewPaymentTransaction} />
      <DashboardRoute exact path="/payment-transactions" component={ListPaymentTransaction} />
      <DashboardRoute
        exact
        path="/payment-transactions/:id/view"
        component={ViewPaymentTransaction}
      />
      <DashboardRoute
        exact
        path="/payment-transactions/:id/edit"
        component={EditPaymentTransaction}
      />

      {/* Manage Notifications */}
      <DashboardRoute exact path="/notifications" component={ListNotification} />

      {/* Manage Customer Payment */}
      <DashboardRoute exact path="/customer-payment" component={ListCustomerPayment} />

      {/* Manage Supplier Payment */}
      <DashboardRoute exact path="/supplier-payment" component={ListSupplierPayment} />

      <Route exact path="/forbidden" component={Forbidden} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  </BrowserRouter>
)

export default Routes
