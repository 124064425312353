import React, { useCallback } from 'react'
import { Alert, Upload, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import originalAxios from 'axios'
import filter from 'lodash/filter'

import axiosExpand from 'Utils/axiosExpand'
import findIndex from 'lodash/findIndex'

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const CustomUploadLabelImage = React.memo((props) => {
  const { setPreviewLabelImage, form, field, setUploading, loadingCondition } = props

  const handleRemove = (file) => {}
  const handleOnChange = ({ fileList }) => {
    const listFileImage = fileList.filter((fileList) => fileList.type !== 'image/gif')

    const uploadingFiles = filter(...listFileImage, { status: 'uploading' })
    if (uploadingFiles.length > 0) {
      setUploading(true)
    } else {
      setUploading(false)
    }
    form.setFieldValue('aws_object_keys', listFileImage)
    const files = listFileImage.map((item) => ({
      url: item.originFileObj.base64 ?? item.thumbUrl,
      original: item.originFileObj.base64 ?? item.thumbUrl,
      thumbnail: item.thumbUrl,
      ...item,
    }))
    setPreviewLabelImage((prev) => ({ ...prev, files }))
  }

  const beforeUpload = (file) => {
    const isImage = file.type.indexOf('image/') === 0
    if (!isImage) {
      message.error('You can only upload image file!')
    }
    const isImageGif = file.type.indexOf('image/gif') === 0
    if (isImageGif) {
      message.error('You can only upload image Gif!')
    }
    // You can remove this validation if you want
    const isSmallerThan8M = file.size / 1024 / 1024 < 8
    if (!isSmallerThan8M) {
      message.error('Image must smaller than 8MB!')
    }
    return isImage && isSmallerThan8M
  }

  const customUpload = async ({ onError, onSuccess, file }) => {
    if (file.type !== 'image/gif') {
      try {
        const resp = await axiosExpand.post(`/products/signed-url/?type=${file.type}`)
        await sendFileToAws(file, resp.data, onSuccess, onError)
      } catch (error) {
        onError(error)
      }
    }
  }

  const sendFileToAws = async (file, signedUrl, onSuccess, onError) => {
    const url = signedUrl.aws_signedRequestURL

    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Encoding': 'base64',
      },
    }

    const base64 = await getBase64(file)
    file.base64 = base64

    const buf = new Buffer(file.base64.replace(/^data:image\/\w+;base64,/, ''), 'base64')
    try {
      await originalAxios.put(url, buf, options)
      message.success(`${file.name} file uploaded successfully`)
      onSuccess('ok')
      file.aws_object_key = signedUrl.object_key
    } catch (error) {
      if (originalAxios.isCancel(error)) {
        console.log(error.message)
      } else {
        onError(error)
        message.error(`${file.name} file upload failed.`)
      }
    }
  }
  const getBase64 = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const handlePreview = (file) => {
    const index = findIndex(field.value, file)
    if (index > -1) {
      setPreviewLabelImage((prev) => ({ ...prev, visible: true, currentIndex: index }))
    } else {
      setPreviewLabelImage((prev) => ({ ...prev, visible: true, currentIndex: 0 }))
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined className="upload-plus-icon" />
      <div className="upload-text">Upload</div>
    </div>
  )

  return (
    <Upload
      name="aws_object_keys"
      accept="image/png,image/jpg,image/jpeg"
      listType="picture-card"
      onPreview={handlePreview}
      beforeUpload={beforeUpload}
      customRequest={customUpload}
      fileList={field.value}
      onRemove={handleRemove}
      onChange={handleOnChange}
      multiple
      disabled={loadingCondition}
    >
      {uploadButton}
    </Upload>
  )
})

export { CustomError, CustomUploadLabelImage }
