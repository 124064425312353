import React, { useRef, useEffect } from 'react'
import { Carousel, Image } from 'antd'

const Slides = ({ images, currentIndex }) => {
  const ref = useRef(null)

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
  }, [])
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.goTo(currentIndex, true)
    }
  }, [currentIndex])

  const onKeyDown = (keyEvent) => {
    if (ref && ref.current) {
      // left arrow
      if ((keyEvent.charCode || keyEvent.keyCode) === 37) {
        return ref.current.prev()
      }

      // right arrow
      if ((keyEvent.charCode || keyEvent.keyCode) === 39) {
        return ref.current.next()
      }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Carousel ref={ref} dots={{ className: 'slide-dot' }} className="custom-carousel-slide">
        {images.map((image, index) => (
          <Image key={`${index}`} src={image.url} className="image-item" />
        ))}
      </Carousel>
    </div>
  )
}

export default Slides
