import React, { useState } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import { useScrollToErrors, preventEnterSubmit } from 'Utils'
import { CustomError, TicketType, StockItems, SectorType } from './CustomComponent'

const { Title } = Typography
const { TextArea } = Input
const typeOptions = [
  { name: 'Phiếu nhập kho', value: 'in' },
  { name: 'Phiếu xuất kho', value: 'out' },
  { name: 'Deposit nhập', value: 'deposit_in' },
  { name: 'Deposit xuất', value: 'deposit_out' },
]

const typeOptionsCommodities = [
  { name: 'Bao bì', value: 'packing' },
  { name: 'Nguyên vật liệu', value: 'material' },
  { name: 'Tem', value: 'label' },
  { name: 'Bán thành phẩm', value: 'semi_finish_product' },
  { name: 'Thành phẩm', value: 'finish_product' },
]
export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    serverError,
    path,
    errors,
    touched,
    setFieldValue,
    isFetching,
  } = props

  // useEffect(() => {
  //   let didCancel = false

  //   const fetchData = async () => {
  //     const url = `/purchase_orders/${purchaseOrderId}/material-transactions/`
  //     setState((prev) => ({ ...prev, loading: true }))
  //     try {
  //       const resp = await axiosExpand.get(url)
  //       if (!didCancel) {
  //         setState((prev) => ({
  //           ...prev,
  //           loading: false,
  //         }))
  //         const values = { ...initialValues, ...resp.data }
  //         setValues(values)
  //         setSelectedStockItems(values.transaction_items.map((item) => item.material_id))
  //       }
  //     } catch (error) {
  //       if (!didCancel) {
  //         setState((prev) => ({
  //           loading: false,
  //           errors: error.response?.data.errors.message,
  //         }))
  //       }
  //     }
  //   }

  //   if (purchaseOrderId) fetchData()

  //   return () => (didCancel = true)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [purchaseOrderId])

  const history = useHistory()
  const [selectedStockItems, setSelectedStockItems] = useState([])
  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)
  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_export_import_stocks_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <Title className="page-sub-title">
        {values.transaction_type === 'in'
          ? 'Thông tin phiếu nhập kho'
          : values.transaction_type === 'out'
          ? 'Thông tin phiếu xuất kho'
          : values.transaction_type === 'deposit_in'
          ? 'Thông tin phiếu deposit nhập'
          : 'Thông tin phiếu deposit xuất'}
      </Title>
      {serverError && (
        <Alert
          message={serverError}
          type="error"
          className="server-error"
          showIcon
          style={{ maxWidth: 400, marginLeft: 0 }}
        />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Mã phiếu <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="transaction_number"
              placeholder="Nhập mã phiếu"
              disabled={loadingCondition}
              value={values.transaction_number}
              onChange={handleChange}
            />
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="transaction_number"
            />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              Loại phiếu <span className="compulsory-field-symbol">*</span>
            </Title>
            <TicketType
              {...props}
              name="transaction_type"
              loading={loadingCondition}
              typeOptions={typeOptions}
            />
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="transaction_type"
            />
          </div>

          <div className="form-item order-type" style={{ maxWidth: 600 }}>
            <Title className="field-title">
              Loại hàng <span className="compulsory-field-symbol">*</span>
            </Title>

            <SectorType
              {...props}
              name="item_type"
              loading={loadingCondition}
              typeOptions={typeOptionsCommodities}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="item_type" />
          </div>

          <div className="form-item" style={{ maxWidth: 400 }}>
            <Title className="field-title">
              {values.transaction_type === 'in'
                ? 'Ghi chú nhập kho'
                : values.transaction_type === 'out'
                ? 'Ghi chú xuất kho'
                : 'Ghi chú deposit'}
              &nbsp;
              <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Ghi chú..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
          <div className="form-item scrollToBottomAdd">
            <Title className="field-title">
              Thông tin
              {values.item_type === 'packing'
                ? ' bao bì '
                : values.item_type === 'material'
                ? ' nguyên vật liệu '
                : values.item_type === 'label'
                ? ' tem '
                : values.item_type === 'semi_finish_product'
                ? ' bán thành phẩm '
                : values.item_type === 'finish_product'
                ? ' thành phẩm '
                : ''}
              <span className="compulsory-field-symbol">*</span>
            </Title>
            <StockItems
              name="transaction_items"
              {...props}
              values={values}
              loadingCondition={loadingCondition}
              setFieldValue={setFieldValue}
              selectedStockItems={selectedStockItems}
              setSelectedStockItems={setSelectedStockItems}
            />
          </div>
        </div>
      </div>

      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push(`/manage-stocks`)
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          onClick={() => setShouldScroll(true)}
        >
          {path === 'new' ? 'Tạo phiếu' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})

// shouldRefresh({ key: 'pricing', status: true })
