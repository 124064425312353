import React, { useMemo, useCallback } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

import isHotkey from './hotkeys'
import { BUTTONS, HOTKEYS, MarkButton, toggleMark } from './helper'

import './index.scss'

// const BLOCK_TYPE = ['bulleted-list', 'paragraph', 'list-item', 'numbered-list']

const TextEditor2 = React.memo(({ labelContent, onChange }) => {
  const editor = useMemo(() => withReact(createEditor()), [])

  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

  return (
    <div id="text_editor">
      <Slate editor={editor} value={labelContent} onChange={onChange}>
        <div className="button-tools-wrap">
          {BUTTONS.map((button, index) => (
            <MarkButton key={index} {...button} editor={editor} />
          ))}
        </div>
        <Editable
          className="input-area"
          renderElement={renderElement}
          spellCheck={false}
          renderLeaf={renderLeaf}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </Slate>
    </div>
  )
})

const Element = React.memo((props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
})

const Leaf = React.memo(({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }
  return <span {...attributes}>{children}</span>
})

export default TextEditor2
