import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import { formatNumber, numToWord } from 'Utils'
import axios from 'Utils/axiosRexyAdmin'
import moment from 'moment'

const { Title } = Typography

const INITIAL_PROFILE = {
  code: '',
  note: '',
  date: moment().format('YYYY-MM-DD'),
  customer_id: undefined,
  sale_order_id: undefined,
  amount: undefined,
  payment_method: undefined,
  customer: {
    id: 0,
    code: '',
    name: '',
  },
  sale_order: {
    id: 0,
    order_number: '',
  },
}

export default React.memo((props) => {
  const history = useHistory()
  const id = props.match?.params.id
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)
  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/payment_transactions/${id}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            data: {
              ...resp.data,
            },
            loading: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [id])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push('/payment-transactions')
  }

  const handleEdit = () => {
    history.push(`/payment-transactions/${id}/edit`)
  }

  return (
    <div id="view_payment_transaction" className="table-container">
      <Title className="page-title">Thông tin thanh toán order</Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">Thông tin chung</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã thanh toán order
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ngày
                </Col>
                <Col className="content-wrapper" span={16}>
                  {moment(state.data.date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã Order
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.sale_order.order_number}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã khách hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.customer.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên khách hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.customer.name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Hình thức thanh toán
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.readable_payment_method}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số tiền bằng số (USD)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(state.data.amount)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số tiền bằng chữ
                </Col>
                <Col className="content-wrapper" span={16}>
                  {numToWord(state.data.amount, 'đô')}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Nội dung
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>

          {isSuperAdmin && (
            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={state.loading}
              className="process-button"
              onClick={handleEdit}
            >
              Chỉnh sửa
            </Button>
          )}
        </div>
      </div>
    </div>
  )
})
