import React from 'react'

import { CheckPermission } from 'Components'
import View from './View'

import './index.scss'

const allowRoles = ['R']

const ViewShippingSchedule = (props) => (
  <CheckPermission {...props} rolesPermission={allowRoles} component={View} />
)

export { ViewShippingSchedule }
