import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Layout } from 'antd'
import { useStoreState } from 'easy-peasy'

import './index.scss'

const { Content, Footer } = Layout

const LoginLayout = (props) => {
  return (
    <Layout id="login_layout">
      {/* <Header className="login-layout-header">
        <div className="logo" to="/">
          <img src={logo} alt="datafood logo" />
        </div>
      </Header> */}
      <Content className="login-layout-content">
        {/* <div className="layout-background" />
        <div className="layout-background-image">
          <img src={loginlayout_background} alt="background" />
        </div> */}
        <div className="site-layout-wrapper">{props.children}</div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Datafood ©2020 Powered by Rexy Tech</Footer>
    </Layout>
  )
}

const LoginRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useStoreState((state) => state.auth)

  if (isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <LoginLayout>
          <Component {...props} />
        </LoginLayout>
      )}
    />
  )
}

export { LoginLayout, LoginRoute }
