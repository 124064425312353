import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageStaffsSchema = yup.object().shape({
  code: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  name: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  start_date: yup.date().required(requiredField),
  // job_title: yup.string().required(requiredField).max(255, maxLength),
  staff_type: yup.string().required(requiredField).max(255, maxLength),
  salary: yup.number().required(requiredField).typeError(typeError),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
})

export default manageStaffsSchema
