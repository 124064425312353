import React from "react";
import { Typography } from "antd";

import UserForm from "../Form";

const { Title } = Typography;

export default () => {
  return (
    <div id="new_user_page">
      <Title className="page-title">Tạo tài khoản mới</Title>
      <UserForm path={"new"} />
    </div>
  );
};
