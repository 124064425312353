import React from 'react'

import { CheckPermission } from 'Components'
import View from './ProductHistory'

import './index.scss'

const allowRoles = ['MO', 'PP', 'MOD']

const ProductsHistory = (props) => (
  <CheckPermission {...props} rolesPermission={allowRoles} component={View} />
)

export default ProductsHistory
