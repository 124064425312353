import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const allowRoles = ['OM', 'R']

const ListStandingOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

const ViewStandingOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

const NewStandingOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
)

const EditStandingOrder = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
)

export { ListStandingOrder, ViewStandingOrder, NewStandingOrder, EditStandingOrder }
