import React, { useState, useEffect } from 'react'
import { message, Table, Input, Select, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'
import _ from 'lodash'
import axiosExpand from 'Utils/axiosExpand'
import axios from 'Utils/axiosRexyAdmin'
import CancelModal from './CancelModal'
import { formatNumber, pageSizeConfig } from 'Utils'

import { useDataApi } from 'Hooks'
const { Option } = Select

const FILTER_VALUES = [
  {
    text: 'Drafting',
    value: 'drafting',
  },
  {
    text: 'Chờ duyệt PI',
    value: 'pending_pi',
  },
  {
    text: 'Đã duyệt PI',
    value: 'approved_pi',
  },
  {
    text: 'Đang sản xuất',
    value: 'producing',
  },
  {
    text: 'Đã sản xuất',
    value: 'produced',
  },
  {
    text: 'Đã xuất hàng',
    value: 'exporting',
  },
  {
    text: 'Hoàn thành',
    value: 'completed',
  },
  {
    text: 'Đã huỷ',
    value: 'cancelled',
  },
]

export default React.memo(() => {
  const history = useHistory()

  const [clickedRow, setClickedRow] = useState({
    id: undefined,
    status: '',
    loading: false,
  })

  const roles = useStoreState((state) => state.auth.roles)
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)
  const [listStatus, setListStatus] = useState({ data: [], loading: false })

  const [cancelModal, setCancelModal] = useState({
    id: undefined,
    name: '',
    visible: false,
  })

  const { data, params, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/sale_orders/',
    key: 'records',
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const saleOrders = useStoreState((state) => state.menuTabs['sale-orders'])

  useEffect(() => {
    let didCancel
    if (saleOrders && !didCancel) {
      doFetch({ per_page: 5, order_by: '', order: '' })
      shouldRefresh({ key: 'sale-orders', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleOrders])

  const renderFiltered = (value) => {
    if (value) {
      if (Array.isArray(value)) return value
      return value.split(',')
    }
    return null
  }

  const columns = [
    {
      title: 'Mã order',
      dataIndex: 'order_number',
      sortOrder: pagination.order_by === 'order_number' ? `${pagination.order}` : null,
      width: 140,
      sorter: true,
    },
    {
      title: 'Ngày đơn hàng',
      dataIndex: 'date',
      sortOrder: pagination.order_by === 'date' ? `${pagination.order}` : null,
      width: 140,
      sorter: true,
      render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer.code',
      sortOrder: pagination.order_by === 'customer.code' ? `${pagination.order}` : null,
      width: 150,
      sorter: true,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      sortOrder: pagination.order_by === 'status' ? `${pagination.order}` : null,
      width: 210,
      filters: FILTER_VALUES,
      sorter: true,
      render: (status, row) => renderStatusOrder(status, row),
      onFilter: (value, record) => record.status === value,
      filteredValue: renderFiltered(params.status),
    },
  ]

  if (isSuperAdmin) {
    columns.push({
      title: 'Số tiền đã nhận/Tổng tiền (USD)',
      width: 170,
      render: (record) => priceColumn(record),
    })
  }

  const priceColumn = (record) => {
    const receivedAmount = formatNumber(_.get(record, 'received_amount', 0))
    const totalAmount = formatNumber(_.get(record, 'total_amount', 0))

    const receivedAll = _.get(record, 'received_amount', 0) >= _.get(record, 'total_amount', 0)

    return (
      <div
        className="table-actions"
        style={{
          justifyContent: 'flex-end',
          color: receivedAll ? '#1E9A30' : '#E9A046',
        }}
      >
        <div>{`${receivedAmount}`}&nbsp;/</div>
        <div>&nbsp;{`${totalAmount}`}</div>
      </div>
    )
  }

  columns.push({
    key: 'x',
    width: 240,
    render: (record) => actionColumn(record),
  })

  useEffect(() => {
    let didCancel = false
    const fetchStatus = async () => {
      const url = '/sale_orders/new/'

      setListStatus((prev) => ({ ...prev, loading: true }))

      try {
        const resp = await axios.get(url)

        // not show cancelled status here
        // const statuses = _.reject(_.get(resp, 'data.status', []), ['value', 'cancelled'])
        const statuses = _.get(resp, 'data.status', [])

        if (!didCancel) {
          setListStatus({ data: statuses, loading: false })
        }
      } catch (error) {
        if (!didCancel) {
          setListStatus((prev) => ({ ...prev, loading: false }))
          message.error(error.response?.data.errors?.message)
        }
      }
    }

    fetchStatus()

    return () => (didCancel = true)
  }, [])

  const actionColumn = (record) => {
    const isDrafting = record.status === 'drafting'
    const isPendingPI = record.status === 'pending_pi'
    const isApprovedPI = record.status === 'approved_pi'
    const isProducing = record.status === 'producing'
    const isCancelled = record.status === 'cancelled'

    return isCancelled ? null : (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}
        {isDrafting ? (
          <Button
            className="action-item update"
            type="link"
            onClick={(event) => handleEdit(record, event)}
          >
            Sửa
          </Button>
        ) : null}

        {(isPendingPI || isApprovedPI) && isSuperAdmin ? (
          <Button
            className="action-item update"
            type="link"
            onClick={(event) => handlePI(record, event)}
          >
            Sửa Proforma Invoice
          </Button>
        ) : null}

        {isProducing ? (
          <Button
            className="action-item in-producing"
            type="link"
            onClick={(event) => handleProducing(record, event)}
          >
            Sửa thông tin sản xuất
          </Button>
        ) : null}
        {isDrafting || isPendingPI || isApprovedPI || isProducing ? (
          <Button
            className="action-item delete"
            type="link"
            onClick={(event) => handleCancel(record, event)}
          >
            Hủy
          </Button>
        ) : null}
      </div>
    )
  }

  const onChangeStatus = async (status, row) => {
    setClickedRow({ id: row.id, loading: true, status })

    try {
      await axiosExpand.put(`/sale_orders/update-status/`, {
        id: row.id,
        status,
      })
      doFetch((prev) => ({ ...prev, per_page: 5, order_by: '', order: '' }))
      setClickedRow({ id: undefined, loading: false })
    } catch (error) {
      setClickedRow({ id: undefined, loading: false })
      doFetch((prev) => ({ ...prev, per_page: 5, order_by: '', order: '' }))
      message.error(error.response?.data.errors?.message)
    }
  }

  const renderStatusOrder = (status, row) => {
    if (['drafting', 'pending_pi', 'approved_pi', 'cancelled'].includes(row.status)) {
      return listStatus.data.find((x) => x.value === row.status)?.name
    }

    const activeLoading = clickedRow.loading && row.id === clickedRow.id

    return (
      <Select
        onChange={(status) => onChangeStatus(status, row)}
        value={status}
        style={{ width: '80%' }}
        disabled={activeLoading}
        onClick={(event) => event.stopPropagation()}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ maxWidth: 650 }}
      >
        {renderStatusOptions(status)}
      </Select>
    )
  }

  const renderStatusOptions = (presentStatus) => {
    const statusIndex = listStatus.data.findIndex((x) => x.value === presentStatus)
    const newListStatus = listStatus.data.filter(
      (_, index) => index === statusIndex || index === statusIndex + 1
    )
    return newListStatus.map((status) => (
      <Option key={status.value} value={status.value}>
        {status.name}
      </Option>
    ))
  }

  const handleCancel = (record, event) => {
    event.stopPropagation()
    setCancelModal({ id: record.id, name: record.order_number, visible: true })
  }

  const handCreate = () => {
    history.push('/sale-orders/new')
  }

  const handleView = (record) => {
    history.push(`/sale-orders/${record.id}/view`)
  }
  const handlePI = (record, event) => {
    event.stopPropagation()
    history.push(`/sale-orders/${record.id}/proformal-invoice`)
  }

  const handleProducing = (record, event) => {
    event.stopPropagation()
    history.push(`/sale-orders/${record.id}/producing`)
  }

  const handleEdit = (record, event) => {
    event.stopPropagation()
    history.push(`/sale-orders/${record.id}/edit`)
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')
    const creatable = isSuperAdmin || _.includes(roles, 'MO')

    return (
      <div className="title-container">
        <div>
          <Input.Search
            placeholder="Tìm kiếm..."
            className="search-bar"
            defaultValue={keyword}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {creatable && (
          <Button
            className="create-button"
            type="primary"
            icon={<PlusOutlined className="create-icon" />}
            onClick={handCreate}
          >
            Tạo đơn hàng mới
          </Button>
        )}
      </div>
    )
  }
  const dataSource = data.map((order, index) => ({
    ...order,
    'customer.code': `${order.customer.code} : ${order.customer.name}`,
    order_type: order.status,
  }))

  return (
    <div id="manage_sale_order_table">
      <Table
        bordered
        className="users-table"
        dataSource={dataSource}
        title={renderTitle}
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        onChange={handleTableChange}
        scroll={{ x: 1150, y: 680 }}
        pagination={{ ...pagination, ...pageSizeConfig }}
        onRow={(record) => ({
          onClick: () => {
            if (record.status === 'cancelled') return
            handleView(record)
          },
        })}
      />
      <CancelModal {...cancelModal} setCancelModal={setCancelModal} doFetch={doFetch} />
    </div>
  )
})
