import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'

import { useHistory } from 'react-router-dom'

import axios from 'Utils/axiosRexyAdmin'

const { Title } = Typography

const INITIAL_PROFILE = {
  code: '',
  name: '',
  email: '',
  roles: [],
  is_super_admin: false,
  active: false,
}

export default React.memo((props) => {
  const history = useHistory()
  const id = props.match?.params.id

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/suppliers/${id}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setState((prev) => ({ ...prev, data: resp.data, loading: false }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [id])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/suppliers`)
  }

  const handleEdit = () => {
    history.push(`/suppliers/${id}/edit`)
  }

  return (
    <div id="view_supplier" className="table-container">
      <Title className="page-title">Thông tin nhà cung cấp</Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">1. Thông tin chung</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Người liên hệ
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.contact_name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số Điện thoại/Fax
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.phone_number}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Email
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.email}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Địa chỉ
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.address}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Thông tin nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.info}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>
          </div>
          <Title className="part-title">2. Tài khoản ngân hàng</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên ngân hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.bank_name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Chi nhánh ngân hàng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.bank_branch}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số tài khoản
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.bank_number}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Đơn vị thụ hưởng
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.bank_account_name}
                </Col>
              </Row>
            </Card.Grid>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>

          <Button
            icon={<FormOutlined />}
            type="primary"
            loading={state.loading}
            className="process-button"
            onClick={handleEdit}
          >
            Chỉnh sửa
          </Button>
        </div>
      </div>
    </div>
  )
})
