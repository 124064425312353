import React, { useEffect, useState } from 'react'
import { Alert, Col, Input, Button, InputNumber, Select, Spin, message, Switch } from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { Field, FieldArray, ErrorMessage } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import { formatNumber, formatInputNumber, scrollToBottomAdd, formatToNumber } from 'Utils'
import { noData } from 'Components'
import { useDebounce } from 'Hooks'
import axios from 'Utils/axiosRexyAdmin'
// import axiosExpand from 'Utils/axiosExpand'
import { removeVietnameseTones } from 'Utils'

import './custom.scss'

const { TextArea } = Input
const { Option } = Select

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const OrderItem = (props) => {
  const {
    editId,
    values,
    loadingCondition,
    setFieldValue,
    remove,
    index,
    selectedStockItems,
    setSelectedStockItems,
    focusingRow,
    setFocus,
  } = props

  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)

  useEffect(() => {
    let didCancel = false

    const fetchProducts = async () => {
      const url = '/products/form/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
          },
        })
        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (debouncedSearchTerm.length && searchTerm.keyword) fetchProducts()

    return () => (didCancel = true)
  }, [debouncedSearchTerm, searchTerm.keyword])

  useEffect(() => {
    let didCancel = false
    if (editId && !didCancel) {
      onSearch(values.sale_order_items[index].product.code)
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, index])

  const computeOptions = (data) => {
    return data.filter((element) => {
      let cloneSelected = cloneDeep(selectedStockItems)
      if (cloneSelected.length) {
        // Remove itselt from selected material transaction items array
        // (for display selected option of that row not be error: show id of that option)
        if (values.sale_order_items[index].product_id)
          cloneSelected.splice(cloneSelected.indexOf(values.sale_order_items[index].product_id), 1)
        // ==========
        // remove all options except itself and not selected options
        return !cloneSelected.some((item) => item === element.id)
        // ==========
      } else {
        return true
      }
    })
  }
  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onResultSelect = (index, val, option) => {
    const { code, name, id } = option.attr
    let cloneSelected = cloneDeep(selectedStockItems)
    if (cloneSelected.includes(values.sale_order_items[index].product_id)) {
      const valueIndex = cloneSelected.indexOf(values.sale_order_items[index].product_id)
      //   // replaces element at valueIndex
      cloneSelected.splice(valueIndex, 1, id)
      setSelectedStockItems(cloneSelected)
    } else {
      setSelectedStockItems((prev) => [...prev, id])
    }

    const params = {
      ...values.sale_order_items[index],
      product_id: id,
      product: { id: id, code: code, name: name },

      note: option.attr.co_note,
      co_note: option.attr.co_note,
      specification: option.attr.specification,
      quantity_in_box: option.attr.quantity_in_box,
      box_size: Number(option.attr.box_size),
      net_weight: Number(option.attr.net_weight),
      gross_weight: Number(option.attr.gross_weight),
      product_type: option.attr.product_type,
      production_cost: Number(option.attr.production_cost),
      first_price: Number(option.attr.first_price),
      second_price: Number(option.attr.second_price),
      total_quantity: option.attr.quantity_in_box * values.sale_order_items[index].box_quantity,
      total_size: Number(
        (option.attr.box_size * values.sale_order_items[index].box_quantity).toFixed(2)
      ),
    }
    setFieldValue(`sale_order_items[${index}]`, params)
  }

  const handleBoxQuantityChange = (index, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(`sale_order_items[${index}].box_quantity`, 1)
    } else {
      const roundDown = Math.floor(value)
      const newParams = {
        ...values.sale_order_items[index],
        box_quantity: roundDown,
        total_quantity: roundDown * values.sale_order_items[index].quantity_in_box,
        total_size: Number((roundDown * values.sale_order_items[index].box_size).toFixed(2)),
      }
      setFieldValue(`sale_order_items[${index}]`, newParams)
    }
  }

  const onApprove = (index, checked) => {
    setFieldValue(`sale_order_items[${index}].apply_new_product_price`, checked)
  }
  const handleNumberChange = (name, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(name, 0)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(name, roundDown)
    }
  }

  const handleNoteChange = (e) => {
    setFieldValue(`sale_order_items[${index}].note`, e.target.value)
  }

  // const onEditMaterial = async (productIndex) => {
  //   const productId = values.sale_order_items[productIndex].product_id
  //   setEditProductMaterial((prev) => ({ ...prev, loading: true, visible: true }))

  //   try {
  //     const url = '/sale_orders/get-sale-order-materials/'
  //     const response = await axiosExpand.get(url, {
  //       params: {
  //         sale_order_id: Number(editId),
  //         product_id: productId,
  //       },
  //     })

  //     const newSaleOrderMaterials = response.data.sale_order_materials.map((item) => ({
  //       ...item,
  //       use_new_price: false,
  //     }))

  //     setEditProductMaterial((prev) => ({
  //       ...prev,
  //       loading: false,
  //       data: {
  //         ...prev.data,
  //         sale_order_id: Number(editId),
  //         sale_order_materials: newSaleOrderMaterials,
  //       },
  //     }))
  //   } catch (error) {}
  // }

  const renderSemiFinish = (itemIndex) => {
    const type = values.sale_order_items[itemIndex].product_type
    const firstPrice = values.sale_order_items[itemIndex].first_price

    if (!(type === 'semifinish' && firstPrice >= 0)) return '-'

    return (
      <>
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`sale_order_items[${itemIndex}].first_price`}
          placeholder="Nhập giá"
          disabled={loadingCondition}
          value={values.sale_order_items[itemIndex].first_price}
          onChange={(value) =>
            handleNumberChange(`sale_order_items[${itemIndex}].first_price`, 10000000000, value)
          }
          formatter={(value) => formatInputNumber(value)}
          min={0}
          max={10000000000}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`sale_order_items[${itemIndex}].first_price`}
        />
      </>
    )
  }

  const renderFinish = (itemIndex) => {
    const type = values.sale_order_items[itemIndex].product_type
    const secondPrice = values.sale_order_items[itemIndex].second_price

    if (!(type === 'finish' && secondPrice >= 0)) return '-'

    return (
      <>
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`sale_order_items[${itemIndex}].second_price`}
          placeholder="Nhập giá"
          disabled={loadingCondition}
          value={values.sale_order_items[itemIndex].second_price}
          onChange={(value) =>
            handleNumberChange(`sale_order_items[${itemIndex}].second_price`, 10000000000, value)
          }
          formatter={(value) => formatInputNumber(value)}
          min={0}
          max={10000000000}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`sale_order_items[${itemIndex}].second_price`}
        />
      </>
    )
  }

  const handleRowColor = () => {
    if (focusingRow === index) return
    setFocus(index)
  }

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null
  const filteredOptions = computeOptions(searchTerm.result)

  return (
    <div
      className={`row-wrapper scroll-to-bottom-add${index} ${
        focusingRow === index ? 'is-focusing' : ''
      }`}
    >
      <div style={{ maxWidth: 60 }} className="row-item">
        {index + 1}
      </div>
      <div style={{ minWidth: 250 }} className="row-item">
        <Field name={`sale_order_items[${index}].product_id`} disabled={loadingCondition}>
          {() => {
            return (
              <Select
                name={`sale_order_items[${index}].product_id`}
                style={{ width: '100%' }}
                showSearch={true}
                suffixIcon={<SearchOutlined />}
                placeholder="Tìm kiếm sản phẩm"
                onSearch={onSearch}
                value={`${values.sale_order_items[index].product.code}${
                  values.sale_order_items[index].product.code && ':'
                } ${values.sale_order_items[index].product.name}`}
                onChange={(val, option) => onResultSelect(index, val, option)}
                optionFilterProp="children"
                notFoundContent={searchDropDownCondition}
                loading={searchTerm.searching}
                disabled={loadingCondition}
                filterOption={(input, option) => {
                  const dataInput = removeVietnameseTones(input)
                  const dataOption = removeVietnameseTones(option.children)
                  return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
                onFocus={handleRowColor}
              >
                {filteredOptions.map((item) => {
                  return (
                    <Option key={item.id} value={`${item.code} ${item.name}`} attr={item}>
                      {item.code && item.name ? `${item.code}: ${item.name}` : '-'}
                    </Option>
                  )
                })}
              </Select>
            )
          }}
        </Field>
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`sale_order_items[${index}].product_id`}
        />
      </div>
      <div className="row-item">
        {values.sale_order_items[index].specification
          ? values.sale_order_items[index].specification
          : '-'}
      </div>
      <div style={{ maxWidth: 130 }} className="row-item">
        {renderSemiFinish(index)}
      </div>
      <div style={{ maxWidth: 120 }} className="row-item">
        {renderFinish(index)}
      </div>

      {/* {editId ? ( */}
      {values.status === 'drafting' && editId && (
        <div style={{ maxWidth: 120 }} className="row-item edit-material">
          <Switch
            className="switcher"
            checked={values.sale_order_items[index].apply_new_product_price}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) => onApprove(index, value)}
            disabled={loadingCondition}
          />
        </div>
      )}
      {/* ) : null} */}
      <div style={{ maxWidth: 120 }} className="row-item">
        {/* ------------------- */}
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`sale_order_items[${index}].box_quantity`}
          placeholder="Nhập số lượng"
          disabled={loadingCondition}
          value={values.sale_order_items[index].box_quantity}
          onChange={(value) => handleBoxQuantityChange(index, 100000, value)}
          formatter={(value) => formatInputNumber(value)}
          min={1}
          max={100000}
          onFocus={handleRowColor}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`sale_order_items[${index}].box_quantity`}
        />
      </div>

      <div style={{ maxWidth: 120 }} className="row-item">
        {values.sale_order_items[index].total_quantity
          ? formatNumber(values.sale_order_items[index].total_quantity)
          : '-'}
      </div>
      <div style={{ maxWidth: 120 }} className="row-item">
        {values.sale_order_items[index].total_size
          ? formatNumber(values.sale_order_items[index].total_size)
          : '-'}
      </div>

      <div className="row-item">
        <Field
          as={TextArea}
          name={`sale_order_items[${index}].note`}
          placeholder="Ghi chú..."
          disabled={loadingCondition}
          //value={values.sale_order_items[index].note}
          value={values.sale_order_items[index].note}
          //defaultValue = {values.sale_order_items[index].co_note}
          //onChange={form.handleChange}
          onChange={handleNoteChange}
          autoSize={{ minRows: 1, maxRows: 3 }}
          onFocus={handleRowColor}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`sale_order_items[${index}].note`}
        />
      </div>

      <div className="row-item">
        {values.sale_order_items.length > 1 ? (
          <Button
            type="link"
            danger
            icon={<MinusCircleOutlined />}
            onClick={() => {
              // let cloneSearchTerm = cloneDeep(searchTerm.result)
              // cloneSearchTerm.splice(index, 1)
              // setSearchTerm({ ...searchTerm, result: cloneSearchTerm })

              let cloneSelected = cloneDeep(selectedStockItems)
              cloneSelected.splice(index, 1)
              setSelectedStockItems(cloneSelected)

              setFocus(focusingRow - 1)
              remove(index)
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

const OrderItems = React.memo((props) => {
  const {
    values,
    loadingCondition,
    setPresentCubic,
    editId,
    // touched
  } = props
  const [focusingRow, setFocus] = useState(undefined)
  const [totalData, setTotalData] = useState({
    total_box_quantity: 0,
    total_quantity: 0,
    total_size: 0,
  })

  useEffect(() => {
    let didCancel = false

    const calPresentCubic = () => {
      let totalCubic = 0
      let total_box_quantity = 0
      let total_quantity = 0

      values.sale_order_items.forEach((item) => {
        total_box_quantity += formatToNumber(item.box_quantity)
        total_quantity += formatToNumber(item.total_quantity)
        totalCubic += Number(item.total_size)
      })
      setPresentCubic(Number(totalCubic.toFixed(2)))
      setTotalData({ total_box_quantity, total_quantity, total_size: totalCubic })
    }

    if (!didCancel) {
      calPresentCubic()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.sale_order_items])

  return (
    <FieldArray
      name="sale_order_items"
      render={(arrayHelpers) => (
        <div>
          <Col className="container order-table-content">
            <div className="inner-content">
              <div className="row-wrapper fixed-scroll-header">
                <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                  STT
                </div>
                <div className="row-item thread-row" style={{ minWidth: 250 }}>
                  Tên sản phẩm
                </div>
                <div className="row-item thread-row">Packing</div>
                {/* {editId ? <div className="row-item thread-row">Nguyên vật liệu</div> : null} */}
                <div style={{ maxWidth: 130 }} className="row-item thread-row">
                  Giá mua sản phẩm chưa thành phẩm(Kg)
                </div>
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  Giá mua sản phẩm thành phẩm(Thùng)
                </div>
                {values.status === 'drafting' && editId && (
                  <div style={{ maxWidth: 120 }} className="row-item thread-row">
                    Áp dụng giá mua sản phẩm cho lô sau
                  </div>
                )}
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  Số lượng (thùng)
                </div>
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  Số lượng (đơn vị)
                </div>
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  Cubic (m3)
                </div>
                <div className="row-item thread-row">Ghi chú mặt hàng</div>
                <div className="row-item thread-row">Actions</div>
              </div>

              <div className="scroll-vertical-table">
                {values.sale_order_items.map((item, index) => {
                  return (
                    <OrderItem
                      {...arrayHelpers}
                      {...props}
                      key={index}
                      {...item}
                      index={index}
                      focusingRow={focusingRow}
                      setFocus={setFocus}
                    />
                  )
                })}
              </div>

              <div className="row-wrapper fixed-scroll-header">
                <div className="row-item thread-row" style={{ maxWidth: 60 }} />
                <div className="row-item thread-row" style={{ minWidth: 250 }} />
                <div className="row-item thread-row" />
                {/* {editId ? <div className="row-item thread-row">Nguyên vật liệu</div> : null} */}
                <div style={{ maxWidth: 130 }} className="row-item thread-row" />
                <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                {values.status === 'drafting' && editId && (
                  <div style={{ maxWidth: 120 }} className="row-item thread-row" />
                )}
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  {totalData.total_box_quantity ? formatNumber(totalData.total_box_quantity) : '-'}
                </div>
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  {totalData.total_quantity ? formatNumber(totalData.total_quantity) : '-'}
                </div>
                <div style={{ maxWidth: 120 }} className="row-item thread-row">
                  {totalData.total_size ? formatNumber(totalData.total_size) : '-'}
                </div>
                <div className="row-item thread-row" />
                <div className="row-item thread-row" />
              </div>
            </div>
          </Col>

          <div className="button-wrapper">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              className="add-more-button"
              disabled={loadingCondition}
              onClick={() => {
                arrayHelpers.push({
                  completed: false,
                  product_id: undefined,
                  product: { id: undefined, code: '', name: '' },
                  second_price: 0,
                  first_price: 0,
                  product_type: '',
                  specification: '',
                  box_quantity: 1,
                  quantity_in_box: 0,
                  box_size: 0,
                  note: '',
                  total_quantity: 0,
                  total_size: 0,
                  old_fob: 0,
                  new_fob: 0,
                  use_new_prices: false,
                  net_weight: 0,
                  gross_weight: 0,
                  total: 0,
                  apply_new_product_price: false,
                })
                scrollToBottomAdd(
                  `scroll-to-bottom-add${focusingRow === undefined ? 1 : focusingRow + 1}`
                )
                setFocus(focusingRow === undefined ? 1 : focusingRow + 1)
              }}
            />
          </div>
        </div>
      )}
    />
  )
})

export { CustomError, OrderItems }
