import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import { Route, Redirect, useHistory, withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import './index.scss'
import axios from 'Utils/axiosExpand'
import { scrollToTop } from 'Utils'
import LeftSiderMenu from './LeftSiderMenu'
import Header from './Header'
import { Loading } from 'Components'
// import loginlayout_background from "Assets/images/login_background.svg";

const { Content, Footer } = Layout

const DashboardLayout = withRouter((props) => {
  // const [collapsed, setCollapsed] = useState(false)
  const [bpCollapsed, setBreakpointCollapsed] = useState({
    collapsedButton: true,
    isCollapsed: false,
    leftPst: 280,
    collapsedWidth: 80,
    isTrigger: null,
  })

  useEffect(() => {
    scrollToTop()
  }, [props.location.pathname])

  const toggleMenu = () => {
    // setCollapsed((prev) => !prev)
    if (!bpCollapsed.isCollapsed) {
      setBreakpointCollapsed((prev) => ({ ...prev, leftPst: 80, isCollapsed: true }))
    } else {
      setBreakpointCollapsed((prev) => ({ ...prev, leftPst: 280, isCollapsed: false }))
    }
  }

  return (
    <Layout id="dashboard_layout_contain">
      <LeftSiderMenu
        {...props}
        bpCollapsed={bpCollapsed}
        setBreakpointCollapsed={setBreakpointCollapsed}
      />
      <Layout className="site-layout-contain" style={{ marginLeft: bpCollapsed.leftPst }}>
        <Header bpCollapsed={bpCollapsed} toggleMenu={toggleMenu} />
        <Content className="dashboard-layout-content">
          <div className="site-layout-wrapper">{props.children}</div>
        </Content>
        <Footer className="dashboard-layout-footer" style={{ textAlign: 'center' }}>
          Datafood ©2020 Powered by Rexy Tech
        </Footer>
      </Layout>
    </Layout>
  )
})

const DashboardRoute = ({ component: Component, ...rest }) => {
  const history = useHistory()

  const authToken = useStoreState((state) => state.auth.token)

  const saveUserInfor = useStoreActions((actions) => actions.auth.saveUserInfor)

  const [isFetchMe, setIsFetchMe] = useState(false)

  useEffect(() => {
    const fetchMe = async () => {
      try {
        const resp = await axios.get('/users/fetch/')
        saveUserInfor(resp.data)
        setIsFetchMe(true)
      } catch (error) {
        setIsFetchMe(true)
      }
    }

    fetchMe()

    return () => setIsFetchMe(true)
  }, [authToken, history, saveUserInfor])

  if (!isFetchMe) return <Loading loading={!isFetchMe} />

  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login-step-one',
              state: { referer: props.location },
            }}
          />
        )
      }
    />
  )
}

export { DashboardLayout, DashboardRoute }
