import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import moment from 'moment'
import { useStoreState } from 'easy-peasy'
import _ from 'lodash'

import { manageSaleOrderSchema } from 'Validation'
import EditProducing from './EditProducing'
import axios from 'Utils/axiosRexyAdmin'
import './custom.scss'

const INITIAL_PROFILE = {
  order_number: '',
  date: moment().format('YYYY-MM-DD'),
  production_requirement: '',
  customer_id: '',
  sale_order_items: [
    {
      product_id: undefined,
      product: { id: undefined, code: '', name: '' },
      specification: '',
      box_quantity: 1,
      quantity_in_box: 0,
      box_size: 0,
      note: '',
      total_quantity: 0,
      total_size: 0,
      old_fob: 0,
      new_fob: 0,
      use_new_prices: false,
      net_weight: 0,
      gross_weight: 0,
      total: 0,
      produced_box_quantity: undefined,
      completed: false,
    },
  ],
  customer: { id: '', code: '', name: '' },
  container_type_1_amount: '',
  container_type_2_amount: '',
  container_type_3_amount: '',

  produce_date: moment().format('YYYY-MM-DD'),
  complete_date: moment().format('YYYY-MM-DD'),
  export_date: moment().format('YYYY-MM-DD'),

  status: 'producing',
  sticker_fees: 0,
  usd_to_vnd_rate: 0,
}

export default React.memo((props) => {
  const history = useHistory()
  const viewId = props.match?.params.id
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const [state, setState] = useState({ data: INITIAL_PROFILE, loading: true, errors: null })

  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [statusSaleOrder, setStatusSaleOrder] = useState('producing')
  const [documents, setDocuments] = useState([])
  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    updatePurchaseOrder(values)
  }

  const formattedDocumentsParam = _.map(documents, (document) => ({
    file_name: _.get(document, 'originFileObj.name'),
    aws_object_key: _.get(document, 'originFileObj.object_key'),
  }))

  const updatePurchaseOrder = async (params) => {
    const url = `/sale_orders/${viewId}/edit/`
    const formatParams = {
      ...state.data,
      ...params,
      date: params.date.format('YYYY-MM-DD'),
      produce_date: params.produce_date.format('YYYY-MM-DD'),
      complete_date: params.complete_date.format('YYYY-MM-DD'),
      export_date: params.export_date.format('YYYY-MM-DD'),
      usd_to_vnd_rate: Number(params.usd_to_vnd_rate),
      sticker_fees: Number(params.sticker_fees),
      documents: formattedDocumentsParam,
    }
    try {
      const resp = await axios.put(url, formatParams)
      setSubmitting(false)
      history.push(`/sale-orders/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const [totalCubic, setTotalCubic] = useState(0)
  const [presentCubic, setPresentCubic] = useState(0)
  // const [totalPI, setTotalPI] = useState(0)
  // const [totalRow, setTotalRow] = useState({
  //   totalBoxQuantity: 0,
  //   totalQuantity: 0,
  //   totalSize: 0,
  //   totalFobProduct: 0,
  // })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/sale_orders/${viewId}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setStatusSaleOrder(resp.data.status)
          computeData(resp.data)

          const notSetFirstTimeFetchedValuesYet = _.size(firstTimeFetchedValues) === 0

          if (notSetFirstTimeFetchedValuesYet)
            setFirstTimeFetchedValues({
              ...resp.data,
              date: moment(resp.data.date, 'YYYY-MM-DD'),
              export_date: moment(resp.data.export_date, 'YYYY-MM-DD'),
              produce_date: moment(resp.data.produce_date, 'YYYY-MM-DD'),
              complete_date: moment(resp.data.complete_date, 'YYYY-MM-DD'),
            })
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId])

  const calTotalCubic = (type1, type2, type3) => {
    const sum = 28.5 * type1 + 58.5 * type2 + 66.5 * type3
    const formatted = Number(Number(sum).toFixed(2))
    setTotalCubic(formatted)
  }

  const calPresentCubic = (orderItems = []) => {
    let sum = 0
    orderItems.forEach((item) => (sum += Number(item.total_size)))
    setPresentCubic(sum)
  }

  // const calTotalRowAndPI = (orderItems = [], stickerFees) => {
  //   // let totalBoxQuantity = 0
  //   // let totalQuantity = 0
  //   // let totalSize = 0
  //   // let totalFobProduct = 0
  //   orderItems.forEach((item) => {
  //     // totalBoxQuantity += item.box_quantity
  //     // totalQuantity += item.total_quantity
  //     // totalSize += item.total_size
  //     // totalFobProduct += item.new_fob * item.box_quantity
  //   })

  //   // let totalPI = Number((totalFobProduct * (1 + stickerFees)).toFixed(2))
  //   // setTotalPI(totalPI)
  //   // setTotalRow({ totalBoxQuantity, totalQuantity, totalSize, totalFobProduct })
  // }

  const computeData = (data) => {
    const type1 = data.container_type_1_amount
    const type2 = data.container_type_2_amount
    const type3 = data.container_type_3_amount
    const exchange_rate = Number(data.usd_to_vnd_rate)
    // const stickerFees = Number(data.sticker_fees)

    calTotalCubic(type1, type2, type3)
    calPresentCubic(data.sale_order_items)
    // calTotalRowAndPI(data.sale_order_items, stickerFees)
    const saleOrderItems = data.sale_order_items.map((item) => ({
      ...item,
      new_fob:
        item.new_fob === 0
          ? // ? Number((item.second_price / exchange_rate).toFixed(2))
            Number(
              ((item.second_price * (1 + item.managament_cost / 100)) / exchange_rate).toFixed(2)
            )
          : Number(item.new_fob),
    }))
    const formattedData = {
      ...state.data,
      ...data,
      date: moment(data.date, 'YYYY-MM-DD'),
      produce_date: moment(data.produce_date, 'YYYY-MM-DD'),
      complete_date: moment(data.complete_date, 'YYYY-MM-DD'),
      export_date: moment(data.export_date, 'YYYY-MM-DD'),
      sale_order_items: saleOrderItems,
    }
    setState((prev) => ({ ...prev, data: formattedData, loading: false }))
  }

  return statusSaleOrder !== 'producing' ? (
    <Redirect to={`/sale-orders/${viewId}/view`} />
  ) : (
    <div className="sale-order-form" style={{ width: '100%' }}>
      <Formik
        initialValues={INITIAL_PROFILE}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <EditProducing
            {...props}
            totalCubic={totalCubic}
            presentCubic={presentCubic}
            isSuperAdmin={isSuperAdmin}
            serverError={serverError}
            editId={viewId}
            documents={documents}
            setDocuments={setDocuments}
            // path={path}
            dataFetch={state.data}
            isFetching={state.loading}
            isSubmitting={isSubmitting}
            setSubmitting={setSubmitting}
            initialValues={INITIAL_PROFILE}
            firstTimeFetchedValues={firstTimeFetchedValues}
          />
        )}
        validationSchema={manageSaleOrderSchema}
      />
    </div>
  )
})
