import React from 'react'
import { Typography } from 'antd'

import SalaryForm from '../Form'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  return (
    <div id="edit_staffs_salary_page">
      <Title className="page-title">Cập nhật bảng lương</Title>

      <SalaryForm path={'edit'} editId={id} />
    </div>
  )
})
