import React, { useState, useEffect } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import axios from 'Utils/axiosExpand'

import form_logo from 'Assets/images/logo3.svg'

const { Title } = Typography
const { Password } = Input

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    isValidating,
    handleSubmit,
    serverError,
    setServerError,
    email,
  } = props

  const [timer, setTimer] = useState(150)
  const [resendError, setResendError] = useState(null)

  useEffect(() => {
    if (!timer) return

    const intervalId = setInterval(() => {
      setTimer(timer - 1)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
  }, [timer])

  const redirectToEmail = () => {
    const regex = /yahoo.com$/
    if (email.match(regex)) return 'https://mail.yahoo.com'
    return 'https://mail.google.com'
  }

  const hideEmail = (email) => {
    var i = email.indexOf('@')
    var startIndex = (i * 0.2) | 0
    var endIndex = (i * 0.9) | 0
    return (
      email.slice(0, startIndex) +
      email.slice(startIndex, endIndex).replace(/./g, '*') +
      email.slice(endIndex)
    )
  }

  const handleResendToken = async () => {
    setResendError(null)
    setServerError(null)
    setTimer(150)
    const url = '/users/resend-sign-in-token/'
    try {
      await axios.post(url, { email })
    } catch (error) {
      setResendError(error.response.data.errors.message)
    }
  }

  const CustomError = (props) => {
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }

  const loadingCondition = isSubmitting || isValidating

  return (
    <Form id="login_step_two_form" className="form-wrapper" onSubmit={handleSubmit}>
      <div className="form-logo">
        <img src={form_logo} alt="form logo" />
      </div>

      <Title className="form-description">
        Một văn bản có chứa mã đăng nhập đã được gửi đến địa chỉ email của bạn:{' '}
        <a href={redirectToEmail()} target="_blank" rel="noopener noreferrer">
          {hideEmail(email)}
        </a>
      </Title>

      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      {resendError && (
        <Alert message={resendError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">Mã đăng nhập</Title>
        <Field
          as={Password}
          name="signinToken"
          placeholder="Nhập mã đặng nhập"
          className="password-field"
          size="large"
          prefix={<LockOutlined style={{ marginRight: 5 }} />}
          value={values.signinToken}
          onChange={handleChange}
          disabled={loadingCondition}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="signinToken" />
      </div>
      <div className="count-down">
        {timer === 0 ? (
          <>
            <span>Chưa nhận được mã? </span>
            <span className="resend" onClick={handleResendToken}>
              Gửi lại mã
            </span>
          </>
        ) : (
          <span>Mã đăng nhập sẽ hết hạn sau {timer} giây</span>
        )}
      </div>

      <Button type="primary" htmlType="submit" className="submit-button" loading={loadingCondition}>
        Đăng nhập
      </Button>
    </Form>
  )
})
