import { useState, useEffect } from 'react'

export const scrollToTop = () => {
  const contain = document.getElementsByClassName('site-layout-contain')
  setTimeout(() => {
    if (contain.length) {
      contain[0].scrollTo({
        behavior: 'smooth',
        top: contain[0]?.offsetTop - 30,
      })
    }
  }, 200)
}
export const scrollToBottomAdd = (className, type) => {
  const contain =
    type === 'container'
      ? document.getElementsByClassName(`container`)
      : document.getElementsByClassName(`inner-content`)
  const itemContain = document.getElementsByClassName(`${className}`)
  setTimeout(() => {
    if (contain.length) {
      contain[0].scrollTo({
        behavior: 'smooth',
        top: itemContain[0]?.offsetTop,
      })
    }
  }, 10)
}

export const useScrollToErrors = (formErrors, serverError, touched) => {
  const [shouldScroll, setShouldScroll] = useState(true)

  const contain = document.getElementsByClassName('site-layout-contain')
  const errorsListElement = document.getElementsByClassName('field-error')
  const errorsListServer = document.getElementsByClassName('server-error')

  useEffect(() => {
    let didCancel = false

    if (errorsListServer.length && !didCancel && shouldScroll) {
      setTimeout(() => {
        if (contain.length) {
          contain[0].scrollTo({
            behavior: 'smooth',
            top: errorsListServer[0]?.offsetTop - 30,
          })
          setShouldScroll(false)
        }
      }, 200)
    } else if (errorsListElement.length && !didCancel && shouldScroll) {
      setTimeout(() => {
        if (contain.length) {
          contain[0].scrollTo({
            behavior: 'smooth',
            top: errorsListElement[0]?.offsetTop - 75,
          })
          setShouldScroll(false)
        }
      }, 200)
    }

    return () => {
      didCancel = true
    }
  }, [contain, errorsListElement, errorsListServer, shouldScroll, formErrors, serverError, touched])

  return { setShouldScroll }
}
