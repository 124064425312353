import React, { useEffect, useState, useCallback } from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography, Row, Col, Radio, InputNumber } from 'antd'
import { RollbackOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import { BlockNavigation, QuillEditor, ImageSlide, SearchField } from 'Components'
// import axiosExpand from 'Utils/axiosExpand'
import axios from 'Utils/axiosRexyAdmin'
import { useScrollToErrors, formatInputNumber, preventEnterSubmit } from 'Utils'
import { CustomError, CustomUploadLabelImage } from './CustomComponent'
import _ from 'lodash'

const { Title } = Typography
const { TextArea } = Input

export default React.memo((props) => {
  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    setFieldValue,
    initialValues,
  } = props

  const history = useHistory()
  const [isFetching, setIsFetching] = useState(false)
  const [labelTypes, setLabelTypes] = useState([])
  const [previewLabelImage, setPreviewLabelImage] = useState({
    files: [],
    visible: false,
    currentIndex: 0,
  })

  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})

  const [triggerKeywordSearch, setKeywordSearch] = useState({
    product: '',
    customer: '',
    supplier: '',
  })
  const [triggerOptions, setTriggerOptions] = useState({
    product: [],
    customer: [],
    supplier: [],
  })
  const [uploading, setUploading] = useState(false)

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)

  const handlePriceChange = (fieldName, limit, value) => {
    if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(fieldName, undefined)
    } else {
      const roundDown = Math.floor(value)
      setFieldValue(fieldName, roundDown)
    }
  }

  useEffect(() => {
    let didCancel = false

    const fetchLabelTypes = async () => {
      const url = '/labels/new/'
      const resp = await axios.get(url)
      setLabelTypes(resp.data.label_types)
    }

    if (!didCancel) {
      fetchLabelTypes()
    }
    return () => (didCancel = true)
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/labels/${editId}/edit`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          let formattedImages = []

          if (resp.data.addtional_hashes.length) {
            formattedImages = resp.data.addtional_hashes.map((image, index) => ({
              uid: `uid-${index}`,
              name: 'image.png',
              status: 'done',
              url: image.image_link,
              original: image.image_link,
              thumbnail: image.image_link,
              originFileObj: { aws_object_key: image.aws_object_key },
            }))
          }

          let supplierIds = []
          if (resp.data.suppliers.length) {
            supplierIds = resp.data.suppliers.map((supplier) => supplier.supplier_id)

            const supplierOptions = resp.data.suppliers.map((supplier) => ({
              ...supplier,
              id: supplier.supplier_id,
            }))

            setTriggerOptions((prev) => ({
              ...prev,
              supplier: supplierOptions,
            }))
          }
          setKeywordSearch((prev) => ({
            ...prev,
            product: resp.data.product.code,
            customer: resp.data.customer.code,
          }))

          setValues({
            ...values,
            ...resp.data,
            suppliers: supplierIds,
            aws_object_keys: formattedImages,
          })
          setPreviewLabelImage((prev) => ({ ...prev, files: formattedImages }))

          const notSetFirstTimeFetchedValuesYet = _.size(firstTimeFetchedValues) === 0

          if (notSetFirstTimeFetchedValuesYet)
            setFirstTimeFetchedValues({ ...values, ...resp.data })

          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    if (editId) {
      fetchData()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, setValues])

  const onProductSelect = (val) => {
    setFieldValue('product_id', val)
  }

  const onCustomerSelect = (val) => {
    setFieldValue('customer_id', val)
  }

  const onSupplierSelect = (ids) => {
    setFieldValue('suppliers', ids)
  }

  const handleOK = () => {
    setShouldScroll(true)
    // setFieldValue('aws_object_keys', labelImages)
  }

  const handleChangeLabelContent = useCallback(
    (html, pureContent) => {
      setValues({ ...values, info: html, pure_content: pureContent })
    },
    [setValues, values]
  )

  const filteredValues = {
    ...values,
    pure_content: '',
  }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_labels_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <BlockNavigation
        values={filteredValues}
        initialValues={initialValues}
        path={path}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />
      <Title className="page-sub-title">Thông tin tem</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="general-information">
        <div className="part-field-wrapper">
          <div className="form-item">
            <Title className="field-title">
              Sản phẩm <span className="compulsory-field-symbol">*</span>
            </Title>
            <SearchField
              axios={axios}
              url="/labels/new/"
              apiKey="products"
              value={values.product_id}
              onChange={onProductSelect}
              disabled={loadingCondition}
              optionParams={{
                value: 'id',
                label: {
                  prefix: 'code',
                  suffix: 'name',
                },
              }}
              placeholder="Nhập mã hoặc tên sản phẩm để tìm kiếm"
              triggerKeywordSearch={triggerKeywordSearch.product}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="product_id" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Khách hàng <span className="compulsory-field-symbol">*</span>
            </Title>
            <SearchField
              axios={axios}
              url="/customers/form/"
              value={values.customer_id}
              onChange={onCustomerSelect}
              disabled={loadingCondition}
              optionParams={{
                value: 'id',
                label: {
                  prefix: 'code',
                  suffix: 'name',
                },
              }}
              placeholder="Nhập mã hoặc tên khách hàng để tìm kiếm"
              triggerKeywordSearch={triggerKeywordSearch.customer}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="customer_id" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Nhà cung cấp <span className="compulsory-field-symbol">*</span>
            </Title>
            <SearchField
              mode="multiple"
              axios={axios}
              url="/suppliers/form/"
              value={values.suppliers}
              onChange={onSupplierSelect}
              disabled={loadingCondition}
              optionParams={{
                value: 'id',
                label: {
                  prefix: 'code',
                  suffix: 'name',
                },
              }}
              placeholder="Nhập mã hoặc tên nhà cung cấp để tìm kiếm"
              triggerKeywordSearch={triggerKeywordSearch.supplier}
              triggerOptions={triggerOptions.supplier}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="suppliers" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Đơn giá <span className="compulsory-field-symbol">*</span>
            </Title>
            <Input.Group compact>
              <Field
                as={InputNumber}
                name="price"
                disabled={loadingCondition}
                value={values.price}
                onChange={(value) => handlePriceChange('price', 1000000000000, value)}
                style={{ width: '78%' }}
                formatter={(value) => formatInputNumber(value)}
                placeholder="Nhập đơn giá"
                min={0}
                max={1000000000000}
              />
              <Input
                className="unit-disable-field"
                disabled={true}
                defaultValue="VND"
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Input.Group>

            <ErrorMessage component={(props) => <CustomError {...props} />} name="price" />
          </div>
          {/* <div className="form-item">
            <Title className="field-title">
              Số lượng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={InputNumber}
              style={{ width: '100%' }}
              name="quantity"
              placeholder="Nhập số lượng"
              disabled={path === 'new' ? loadingCondition : true}
              value={values.quantity}
              onChange={(value) => {
                if (value > 1000000000) {
                  return
                } else {
                  setFieldValue('quantity', value)
                  setFieldValue('temporary_quantity', value)
                }
              }}
              formatter={(value) => formatInputNumber(value)}
              step={0.1}
              precision={2}
              min={0}
              max={1000000000}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="quantity" />
          </div>
          <div className="form-item">
            <Title className="field-title">
              Số lượng khả dụng <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={InputNumber}
              style={{ width: '100%' }}
              name="temporary_quantity"
              placeholder="Nhập số lượng khả dụng"
              disabled={true}
              value={values.quantity}
              onChange={(value) => {
                if (value > 1000000000) {
                  return
                } else {
                  setFieldValue('temporary_quantity', value)
                }
              }}
              formatter={(value) => formatInputNumber(value)}
              step={0.1}
              precision={2}
              min={0}
              max={1000000000}
            />
          </div> */}
          <div className="form-item">
            <Title className="field-title">
              Thành phần <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={TextArea}
              name="ingredient"
              placeholder="Nhập thành phần"
              disabled={loadingCondition}
              value={values.ingredient}
              autoSize={{ minRows: 2, maxRows: 5 }}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="ingredient" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Nội dung tem <span className="compulsory-field-symbol">*</span>
            </Title>

            <QuillEditor
              placeholder="Nhập nội dung tem"
              handleChangeLabelContent={handleChangeLabelContent}
              value={values.info}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="pure_content" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Kích thước tem <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="specification"
              placeholder="Nhập kích thước tem"
              disabled={loadingCondition}
              value={values.specification}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="specification" />
          </div>

          <div className="form-item">
            <Title className="field-title">
              Cách dán tem <span className="compulsory-field-symbol">*</span>
            </Title>
            <Field
              as={Input}
              name="guide"
              placeholder="Nhập cách dán tem"
              disabled={loadingCondition}
              value={values.guide}
              onChange={handleChange}
            />
            <ErrorMessage component={(props) => <CustomError {...props} />} name="guide" />
          </div>

          <div className="form-item">
            <Row justify="space-between">
              <Col span={8}>
                <Title className="field-title">
                  Hình ảnh minh hoạ <span className="compulsory-field-symbol">*</span>
                </Title>
              </Col>
              <Col span={16}>
                <Field name="aws_object_keys" disabled={loadingCondition} onChange={handleChange}>
                  {(props) => (
                    <CustomUploadLabelImage
                      {...props}
                      name="aws_object_keys"
                      setUploading={setUploading}
                      loadingCondition={loadingCondition}
                      setPreviewLabelImage={setPreviewLabelImage}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <ErrorMessage
              component={(props) => <CustomError {...props} />}
              name="aws_object_keys"
            />
            <ImageSlide
              visible={previewLabelImage.visible}
              onCancel={() =>
                setPreviewLabelImage((prev) => ({ ...prev, visible: false, currentIndex: 0 }))
              }
              images={previewLabelImage.files}
              currentIndex={previewLabelImage.currentIndex}
            />
          </div>

          <div className="form-item">
            <Row justify="space-between" className="label-types">
              <Col span={6}>
                <Title className="field-title">
                  Loại tem <span className="compulsory-field-symbol">*</span>
                </Title>
              </Col>
              <Col span={18}>
                <Field name="label_type" disabled={loadingCondition}>
                  {() => {
                    return (
                      <Radio.Group
                        buttonStyle="solid"
                        className="labels-wrapper"
                        value={values.label_type}
                        onChange={(e) => {
                          setFieldValue('label_type', e.target.value)
                        }}
                      >
                        {labelTypes.map((label) => {
                          return (
                            <Radio.Button
                              key={label.value}
                              className="label-item"
                              value={label.value}
                            >
                              {label.name}
                            </Radio.Button>
                          )
                        })}
                      </Radio.Group>
                    )
                  }}
                </Field>
              </Col>
            </Row>
            <ErrorMessage component={(props) => <CustomError {...props} />} name="label_type" />
          </div>
          <div className="form-item">
            <Title className="field-title">Ghi chú</Title>
            <Field
              as={TextArea}
              name="note"
              placeholder="Ghi chú..."
              disabled={loadingCondition}
              value={values.note}
              onChange={handleChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
            />

            <ErrorMessage component={(props) => <CustomError {...props} />} name="note" />
          </div>
        </div>
      </div>
      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          disabled={uploading}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() =>
            !!history.goBack && typeof history.goBack === 'function'
              ? history.goBack()
              : history.push('/labels')
          }
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
          loading={loadingCondition}
          disabled={uploading}
          onClick={handleOK}
        >
          {path === 'new' ? 'Tạo tem' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  )
})
