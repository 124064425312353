import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import './index.scss'

const allowRoles = ['MO', 'R']

const ListCustomerPayment = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
)

export default ListCustomerPayment
