import React, { useState } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import FormView from './Form.view'
import { manageStockReportSchema } from 'Validation'
import axiosExpand from 'Utils/axiosExpand'

const format = 'YYYY-MM-DD'

const initialValues = {
  start_date: moment().format(format),
  end_date: moment().format(format),
  commodity_type: 'all'
}

export default () => {
  const [serverError, setServerError] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [initialData, setInitialData] = useState({ isEdit: false, data: initialValues })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleFormSubmit = async (values, actions) => {
    setServerError(null)
    downloadStockReport(values, actions)
  }

  const downloadStockReport = async (params, actions) => {
    setIsSubmitting(true)
    try {
      const FileDownload = require('js-file-download')
      const url = `/transactions/export?start_date=${params.start_date}&end_date=${params.end_date}&commodity_type=${params.commodity_type}`
      const result = await axiosExpand.get(url, { responseType: 'arraybuffer' })
      FileDownload(result.data, `bao-cao-xuat-nhap-ton.xlsx`)

      setShouldFetch(true)
      setInitialData({ isEdit: false, data: params })
      setIsSubmitting(false)
    } catch (error) {
      setServerError(error.response?.data.errors.message)
      setIsSubmitting(false)
    }
  }

  return (
    <div className="stock_report-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            shouldFetch={shouldFetch}
            serverError={serverError}
            setShouldFetch={setShouldFetch}
            initialData={initialData}
            setInitialData={setInitialData}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={manageStockReportSchema}
      />
    </div>
  )
}
