import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const maxLength1000 = 'Tối đa 1000 ký tự'
const maxLength10000 = 'Tối đa 10000 ký tự'
const requiredIntegerField = 'Trường này phải là là số nguyên'
const typeError = 'Vui lòng nhập số'

const manageLabelsSchema = yup.object().shape({
  product_id: yup.number().integer(requiredIntegerField).required(requiredField),
  customer_id: yup.number().integer(requiredIntegerField).required(requiredField),
  suppliers: yup.array().required(requiredField).of(yup.number().typeError(typeError)),
  price: yup.number().required(requiredField).typeError(typeError),
  ingredient: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(1000, maxLength1000),
  // info: yup
  //   .string()
  //   .trim('Vui lòng không nhập khoảng trắng')
  //   .strict()
  //   .required(requiredField)
  //   .max(10000, maxLength10000),
  pure_content: yup.string().required(requiredField).max(10000, maxLength10000),
  specification: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  guide: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .required(requiredField)
    .max(255, maxLength),
  aws_object_keys: yup.array().required(requiredField),
  label_type: yup.string().required(requiredField).max(255, maxLength),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000),
  quantity: yup.number().required(requiredField).typeError(typeError),
})

export default manageLabelsSchema
