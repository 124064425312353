import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'

const manageShippingScheduleSchema = yup.object().shape({
  start_date: yup.date().required(requiredField),
  end_date: yup.date().required(requiredField),
})

export default manageShippingScheduleSchema
