import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Table, Input, Button, Switch, message } from 'antd'
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import moment from 'moment'

import axiosExpand from 'Utils/axiosExpand'
import { useDataApi } from 'Hooks'
import { formatNumber, pageSizeConfig } from 'Utils'

import DeleteModal from './DeleteModal'

const StandingOrder = React.memo(() => {
  const history = useHistory()
  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/standing_orders/',
    key: 'records',
  })

  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)
  const roles = useStoreState((state) => state.auth.roles)
  const standingOrders = useStoreState((state) => state.menuTabs['standing-orders'])
  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    code: '',
    changeCode: '',
    error: false,
    visible: false,
  })
  const [state, setState] = useState({
    id: undefined,
    toggling: false,
    receiving: false,
  })

  useEffect(() => {
    let didCancel
    if (standingOrders && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'standing-orders', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standingOrders])

  const onApprove = useCallback(async (id) => {
    setState({ id, toggling: true })

    try {
      await axiosExpand.patch(`/standing_orders/${id}/toggle-approve/`)
      doFetch((prev) => ({ ...prev }))
      setState({ id: undefined, toggling: false })
    } catch (error) {
      setState({ id: undefined, toggling: false })
      message.error(error.response?.data.errors?.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onReceived = useCallback(async (id) => {
    setState({ id, toggling: true })

    try {
      await axiosExpand.patch(`/standing_orders/${id}/toggle-received/`)
      doFetch((prev) => ({ ...prev }))
      setState({ id: undefined, receiving: false })
    } catch (error) {
      setState({ id: undefined, receiving: false })
      message.error(error.response?.data.errors?.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderToggle = useCallback(
    (isApproved, row) => {
      const activeLoading = state.toggling && row.id === state.id
      // if (!!row.purchase_order_invoice_id) return null
      if (!row.show_approved) return null

      if (!isSuperAdmin) {
        return isApproved ? (
          <CheckCircleOutlined style={{ paddingLeft: 17, color: 'green', fontSize: 18 }} />
        ) : (
          <CloseCircleOutlined style={{ paddingLeft: 17, color: 'red', fontSize: 18 }} />
        )
      }

      return (
        <div className="toggle-button-wrapper" onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={isApproved}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={() => onApprove(row.id)}
            loading={activeLoading}
            disabled={!!row.approved}
          />
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.id, state.toggling]
  )

  const handCreate = useCallback(() => {
    history.push('/standing-orders/new')
  }, [history])

  const handleView = useCallback(
    (record) => {
      history.push(`/standing-orders/${record.id}/view`)
    },
    [history]
  )

  const handleEdit = useCallback(
    (record, event) => {
      event.stopPropagation()
      history.push(`/standing-orders/${record.id}/edit`)
    },
    [history]
  )

  const renderReceived = useCallback(
    (isApproved, row) => {
      const activeLoading = state.receiving && row.id === state.id

      if (!roles.includes('R')) {
        return isApproved ? (
          <CheckCircleOutlined style={{ paddingLeft: 17, color: 'green', fontSize: 18 }} />
        ) : (
          <CloseCircleOutlined style={{ paddingLeft: 17, color: 'red', fontSize: 18 }} />
        )
      }

      return (
        <div className="toggle-button-wrapper" onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={isApproved}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={() => onReceived(row.id)}
            loading={activeLoading}
          />
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.id, state.receiving]
  )
  const actionColumn = useCallback(
    (record) => {
      return (
        <div className="table-actions">
          {isSuperAdmin ? (
            <>
              <Button
                className="action-item update"
                type="link"
                onClick={(e) => handleEdit(record, e)}
              >
                Sửa
              </Button>
              <Button
                className="action-item delete"
                type="link"
                danger
                onClick={(e) => handleDelete(record, e)}
              >
                Xoá
              </Button>
            </>
          ) : (
            !record.approved && (
              <>
                <Button
                  className="action-item update"
                  type="link"
                  onClick={(e) => handleEdit(record, e)}
                >
                  Sửa
                </Button>
                <Button
                  className="action-item delete"
                  type="link"
                  danger
                  onClick={(e) => handleDelete(record, e)}
                >
                  Xoá
                </Button>
              </>
            )
          )}
        </div>
      )
    },
    [handleEdit, isSuperAdmin]
  )

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const handleDelete = (record, e) => {
    e.stopPropagation()
    setDeleteModal({
      id: record.id,
      code: record.code,
      changeCode: '',
      error: false,
      visible: true,
    })
  }

  const computedColumns = useMemo(() => {
    return [
      {
        title: 'Mã uỷ nhiệm chi',
        dataIndex: 'code',
        sortOrder: pagination.order_by === 'code' ? `${pagination.order}` : null,
        width: 170,
        sorter: true,
      },
      {
        title: 'Ngày',
        dataIndex: 'date',
        width: 170,
        sorter: true,
        render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      },
      {
        title: 'Mã đơn đặt hàng NCC',
        dataIndex: 'purchase_order.order_number',
        sortOrder:
          pagination.order_by === 'purchase_order.order_number' ? `${pagination.order}` : null,
        width: 220,
        sorter: true,
      },
      {
        title: 'Nhà cung cấp',
        dataIndex: 'supplier.code',
        sortOrder: pagination.order_by === 'supplier.code' ? `${pagination.order}` : null,
        width: 220,
        sorter: true,
      },
      {
        title: 'Số tiền (VND)',
        dataIndex: 'amount',
        sortOrder: pagination.order_by === 'amount' ? `${pagination.order}` : null,
        width: 160,
        sorter: true,
        render: (record) => formatNumber(Number(record)),
      },
      {
        title: 'Số hoá đơn thanh toán công nợ NCC',
        dataIndex: 'purchase_order_invoice',
        sortOrder: pagination.order_by === 'purchase_order_invoice' ? `${pagination.order}` : null,
        width: 220,
        sorter: true,
      },
      {
        title: 'Hình thức thanh toán',
        dataIndex: 'readable_payment_method',
        sortOrder: pagination.order_by === 'readable_payment_method' ? `${pagination.order}` : null,
        width: 170,
      },
      {
        title: 'Đã duyệt',
        dataIndex: 'approved',
        sortOrder: pagination.order_by === 'approved' ? `${pagination.order}` : null,
        width: 120,
        sorter: true,
        render: (isApproved, row) => renderToggle(isApproved, row),
      },
      {
        title: 'Đã nhận UNC',
        dataIndex: 'is_received',
        sortOrder: pagination.order_by === 'is_received' ? `${pagination.order}` : null,
        width: 120,
        sorter: true,
        render: (isReceived, row) => renderReceived(isReceived, row),
      },
      {
        key: 'x',
        width: 150,
        render: (record) => actionColumn(record),
      },
    ]
  }, [actionColumn, pagination.order, pagination.order_by, renderReceived, renderToggle])

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Tạo uỷ nhiệm chi
        </Button>
      </div>
    )
  }

  const dataSource = data.map((order, index) => ({
    ...order,
    'supplier.code':
      order.supplier?.code && order.supplier?.name
        ? `${order.supplier?.code} : ${order.supplier?.name}`
        : '',
    'purchase_order.order_number': order.purchase_order?.order_number || '',
    purchase_order_invoice: order.purchase_order_invoice?.invoice_number || '',
  }))

  return (
    <div id="manage_standing_order_table">
      <Table
        title={renderTitle}
        className="users-table"
        dataSource={dataSource}
        columns={computedColumns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 970, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
})

export default StandingOrder
