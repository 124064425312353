import React from 'react'
import { Editor, Transforms, Node, Text } from 'slate'
import escapeHtml from 'escape-html'
import { jsx } from 'slate-hyperscript'
import { useSlate } from 'slate-react'
import { Button, Tooltip } from 'antd'
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'

export const BUTTONS = [
  {
    title: 'Bold',
    format: 'bold',
    Icon: BoldOutlined,
    type: 'text',
  },
  {
    title: 'Italic',
    format: 'italic',
    Icon: ItalicOutlined,
    type: 'text',
  },
  {
    title: 'Underline',
    format: 'underline',
    Icon: UnderlineOutlined,
    type: 'text',
  },
  {
    title: 'Number list',
    format: 'numbered-list',
    Icon: OrderedListOutlined,
    type: 'block',
  },
  {
    title: 'Bullet list',
    format: 'bulleted-list',
    Icon: UnorderedListOutlined,
    type: 'block',
  },
]

export const LIST_TYPES = ['numbered-list', 'bulleted-list']

export const HOTKEYS = { 'mod+b': 'bold', 'mod+i': 'italic', 'mod+u': 'underline', 'mod+`': 'code' }

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  })

  return !!match
}

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const MarkButton = ({ title, format, Icon, type }) => {
  const editor = useSlate()
  if (type === 'block') {
    return (
      <Tooltip title={title}>
        <Button
          className="button-tool-item"
          onMouseDown={(event) => {
            event.preventDefault()
            toggleBlock(editor, format)
          }}
          type="link"
          icon={<Icon style={{ color: isBlockActive(editor, format) ? '#000000' : '#AAAAAA' }} />}
        />
      </Tooltip>
    )
  }
  return (
    <Tooltip title={title}>
      <Button
        className="button-tool-item"
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, format)
        }}
        type="link"
        icon={<Icon style={{ color: isMarkActive(editor, format) ? '#000000' : '#AAAAAA' }} />}
      />
    </Tooltip>
  )
}

// Define a serializing function that takes a value and returns a string.
export const serializeContentToString = (value) => {
  return (
    value
      // Return the string content of each paragraph in the value's children.
      .map((n) => Node.string(n))
      // Join them all with line breaks denoting paragraphs.
      .join('')
  )
}

// Define a deserializing function that takes a string and returns a value.

const deserializeTextFormat = (element, type) => {
  if (!element.length) return [{ text: '' }]
  return [{ ...element[0], text: element[0].text ?? '', [type]: true }]
}

// Define a deserializing function that takes a string and returns a value.
export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  }
  const children = Array.from(el.childNodes).map(deserialize)
  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return `\n`
    // Block --------------
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'UL':
      const elementBullet = jsx('element', { type: 'bulleted-list' }, children)
      // const formattedBullet = deserializeBlockFormat(elementBullet, 'bulleted-list')
      return elementBullet
    case 'LI':
      const elementListItem = jsx('element', { type: 'list-item' }, children)
      // const formattedListItem = deserializeBlockFormat(elementListItem, 'list-item')
      return elementListItem
    case 'OL':
      const elementNumber = jsx('element', { type: 'numbered-list' }, children)
      // const formattedNumber = deserializeBlockFormat(elementNumber, 'numbered-list')
      return elementNumber
    // Text -------------
    case 'STRONG':
      const elementStrong = jsx('fragment', { type: 'paragraph' }, children)
      const formattedStrong = deserializeTextFormat(elementStrong, 'bold')
      return formattedStrong
    case 'EM':
      const elementEm = jsx('fragment', { type: 'paragraph' }, children)
      const formattedEm = deserializeTextFormat(elementEm, 'italic')
      return formattedEm
    case 'U':
      const elementUnderLine = jsx('fragment', { type: 'paragraph' }, children)
      const formattedUnderLine = deserializeTextFormat(elementUnderLine, 'underline')
      return formattedUnderLine

    default:
      return el.textContent
  }
}

export const serialize = (node) => {
  if (Text.isText(node)) {
    const string = escapeHtml(node.text)
    return textFormat(node, string)
  }
  const children = node.children.map((n) => serialize(n)).join('')

  switch (node.type) {
    case 'list-item':
      return `<li>${children}</li>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'header-one':
      return `<h1>${children}</h1>`
    case 'header-two':
      return `<h2>${children}</h2>`
    default:
      return children
  }
}

const textFormat = (node, string) => {
  let children = string
  if (node.bold) children = `<strong>${children}</strong>`

  if (node.italic) children = `<em>${children}</em>`

  if (node.underline) children = `<u>${children}</u>`

  return children
}
