import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import FormView from './Form.view'
import { managePurchaseOrderSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  order_number: '',
  date: moment(),
  receive_date: moment(),
  order_type: 'material',
  supplier_id: undefined,
  sale_order_id: undefined,
  vat_percentage: 0,
  purchase_order_items: [
    {
      label_id: 0,
      product_id: 0,
      material_id: 0,
      product: {},
      price: 0,
      unit: '',
      code: '',
      name: '',
      readable_unit: '',
      specification: '',
      quantity: 1,
      initQuantity: 0,
      total: 0,
      note: '',
      info: '',
      label_type: '',
    },
  ],
  items_in_stock: [],
  sub_total: 0,
  vat: 0,
  total: 0,
  production_requirement: '',
  note: '',
}

export default React.memo((props) => {
  const { path, editId } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)

    const formattedPurchaseOrderItems = values.purchase_order_items.map((item) => ({
      ...item,
      unit: item.unit ? item.unit : 'kg',
      material_id:
        values.order_type === 'material'
          ? item.material_id
          : values.order_type === 'packing'
          ? item.material_id
          : 0,
      label_id: values.order_type === 'label' ? item.label_id : 0,
      product_id: values.order_type === 'finish_product' ? item.product_id : 0,
      note: item.note ?? '',
    }))
    let formattedItemInStock = []
    // if (
    //   (values.order_type === 'material' || values.order_type === 'packing') &&
    //   values.items_in_stock.length &&
    //   values.items_in_stock[0].id
    // ) {
    //   formattedItemInStock = values.items_in_stock.map((item) => ({
    //     ...item,
    //     quantity: item.usingQuantity,
    //   }))
    // }

    if (values.order_type && values.items_in_stock.length && values.items_in_stock[0].id) {
      formattedItemInStock = values.items_in_stock.map((item) => ({
        ...item,
        // quantity: item.usingQuantity,

        quantity: item.usingQuantity > 0 ? Number(item.usingQuantity) : 0,
        quantity_in_stock: Number(item.quantity_in_stock),
        usingQuantity: Number(item.usingQuantity)
      }))
    }

    const formatParams = {
      ...values,
      date: values.date.format('YYYY-MM-DD'),
      receive_date: values.receive_date.format('YYYY-MM-DD'),
      sale_order_id: values.sale_order_id || 0,
      items_in_stock: formattedItemInStock,
      purchase_order_items: formattedPurchaseOrderItems,
    }

    // if (!formatParams.total) {
    //   setServerError('Đơn đặt hàng đã đủ số lượng tồn kho, không thể tạo đơn đặt hàng NCC.')
    //   setSubmitting(false)
    //   return
    // }
    if (path === 'new') {
      createPurchaseOrder(formatParams)
    } else if (path === 'edit') {
      updatePurchaseOrder(formatParams)
    }
  }

  const createPurchaseOrder = async (params) => {
    const url = '/purchase_orders/'

    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/purchase-orders/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updatePurchaseOrder = async (params) => {
    const url = `/purchase_orders/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setSubmitting(false)
      history.push(`/purchase-orders/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="purchase-order-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(formProps) => (
          <FormView
            {...props}
            {...formProps}
            serverError={serverError}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={managePurchaseOrderSchema}
      />
    </div>
  )
})
