import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const maxLength = 'Tối đa 255 ký tự'
const typeError = 'Vui lòng nhập số'
const maxLength1000 = 'Tối đa 1000 ký tự'

const manageStandingOrderSchema = yup.object().shape({
  date: yup.date(),
  // .required(requiredField)
  bank_branch: yup.string().when('payment_method', {
    is: (value) => value === 'atm',
    then: yup
      .string()
      .trim('Vui lòng không nhập khoảng trắng')
      .strict()
      .required(requiredField)
      .max(255, maxLength),
    // .required(requiredField),
    otherwise: yup.string().notRequired(),
  }),
  bank_number: yup.string().when('payment_method', {
    is: (value) => value === 'atm',
    then: yup
      .string()
      .trim('Vui lòng không nhập khoảng trắng')
      .strict()
      .required(requiredField)
      .max(255, maxLength),
    // .required(requiredField),
    otherwise: yup.string().notRequired(),
  }),
  bank_account_name: yup.string().when('payment_method', {
    is: (value) => value === 'atm',
    then: yup
      .string()
      .trim('Vui lòng không nhập khoảng trắng')
      .strict()
      .required(requiredField)
      .max(255, maxLength),
    // .required(requiredField),
    otherwise: yup.string().notRequired(),
  }),
  bank_name: yup.string().when('payment_method', {
    is: (value) => value === 'atm',
    then: yup
      .string()
      .trim('Vui lòng không nhập khoảng trắng')
      .strict()
      .required(requiredField)
      .max(255, maxLength),
    // .required(requiredField),
    otherwise: yup.string().notRequired(),
  }),
  note: yup
    .string()
    .trim('Vui lòng không nhập khoảng trắng')
    .strict()
    .nullable(true)
    .max(1000, maxLength1000)
    .required(requiredField),
  // note: yup.string().when(['amount', 'not_paid_amount'], {
  //   is: (amount, not_paid_amount) => (amount > not_paid_amount ? true : false),
  //   then: yup
  //     .string()
  //     .trim('Vui lòng không nhập khoảng trắng')
  //     .strict()
  //     .nullable(true)
  //     .max(1000, maxLength1000)
  //     .required(requiredField),
  //   otherwise: yup
  //     .string()
  //     .trim('Vui lòng không nhập khoảng trắng')
  //     .strict()
  //     .nullable(true)
  //     .max(1000, maxLength1000)
  //     .notRequired(), 
  // }),
  payment_method: yup.string().required(requiredField).max(255, maxLength),
  supplier_id: yup.number().required(requiredField),
  purchase_order_id: yup.number().required(requiredField),
  purchase_order_invoice_id: yup.number(),
  // .required(requiredField)
  amount: yup.number().required(requiredField).typeError(typeError),
})

export default manageStandingOrderSchema
