import React, { useMemo } from 'react'
import { useStoreState } from 'easy-peasy'
import { Redirect } from 'react-router-dom'

const CheckPermission = (props) => {
  const { rolesPermission = [], component: Component, ...rest } = props
  const roles = useStoreState((state) => state.auth.roles)
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const isHasPermission = useMemo(() => {
    if (isSuperAdmin) return true

    let hasPermission = false
    roles.every((role) => {
      if (rolesPermission.includes(role)) {
        hasPermission = true
        return false
      }
      return true
    })
    return hasPermission
  }, [isSuperAdmin, roles, rolesPermission])

  if (isHasPermission) {
    return <Component {...rest} />
  }

  return <Redirect to="/forbidden" />
}

export default CheckPermission
