import React from 'react'
import { Typography } from 'antd'

import ProductForm from '../Form'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  return (
    <div id="new_product_page">
      <Title className="page-title">Tạo sản phẩm mới</Title>

      <ProductForm path={'new'} editId={id} duplicate={true}/>
    </div>
  )
})
