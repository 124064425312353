import React from 'react'
import { Typography } from 'antd'
import PaymentTransactionForm from '../Form'

const { Title } = Typography

export default React.memo(() => {
  return (
    <div id="new_payment_transaction_page">
      <Title className="page-title">Tạo thanh toán order mới</Title>
      <PaymentTransactionForm path={'new'} />
    </div>
  )
})
