import React from 'react'
import { Typography } from 'antd'

import SaleOrderFormStep1 from '../Form'

const { Title } = Typography

export default React.memo(() => {
  return (
    <div id="new_commodity_export_page">
      <Title className="page-title">Tạo đơn xuất hàng mới</Title>
      <SaleOrderFormStep1 path={'new'} />
    </div>
  )
})
