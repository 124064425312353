import React from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import { manageMaterialsHistorySchema } from 'Validation'

import FormView from './Form.view'

const format = 'YYYY-MM-DD'

const initialValues = {
  option_content: '',
  material_id: undefined,
  material_code: undefined,
  start_date: moment().format(format),
  end_date: moment().format(format),
}

export default React.memo((props) => {
  const { handleFormSubmit } = props

  return (
    <div id="materials_history_filter_form">
      <div className="materials-history-form">
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          children={(formProps) => <FormView {...props} {...formProps} />}
          validationSchema={manageMaterialsHistorySchema}
        />
      </div>
    </div>
  )
})
