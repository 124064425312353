import React, { useEffect, useState } from 'react'
import { Alert, Col, Input, Button, InputNumber, Select, Spin, message, Radio } from 'antd'
import { PlusOutlined, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Field, FieldArray, ErrorMessage } from 'formik'
import cloneDeep from 'lodash/cloneDeep'

import { formatNumber, formatInputNumber, scrollToBottomAdd, removeVietnameseTones } from 'Utils'
import { noData } from 'Components'
import { useDebounce } from 'Hooks'
import axios from 'Utils/axiosExpand'

const { TextArea } = Input
const { Option } = Select

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const TicketType = React.memo((props) => {
  const { name, typeOptions, loading, setFieldValue, values, initialValues } = props
  const onChangeSetValue = (e) => {
    // const transactionItems = values.transaction_items.map((item) => {
    //   return {
    //     ...item,
    //     quantity_after: handleQuantityAfter(
    //       e.target.value,
    //       Number(item.quantity_before),
    //       Number(item.quantity)
    //     ),
    //   }
    // })
    setFieldValue('transaction_items', initialValues.transaction_items)
    setFieldValue('transaction_type', e.target.value)
  }
  // const handleQuantityAfter = (type, before, quantity) => {
  //   let afterNumber = 0

  //   if (type === 'in') {
  //     afterNumber = Number(before) + Number(quantity)
  //   } else if (type === 'out') {
  //     if (quantity >= before) {
  //       afterNumber = 0
  //     } else afterNumber = Number(before) - Number(quantity)
  //   }
  //   return afterNumber
  // }
  return (
    <Field name={name} disabled={loading}>
      {() => {
        return (
          <Radio.Group
            buttonStyle="solid"
            className="labels-wrapper"
            value={values[name]}
            onChange={(e) => onChangeSetValue(e)}
          >
            {typeOptions.map((type) => {
              return (
                <Radio.Button key={type.value} className="label-item" value={type.value}>
                  {type.name}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        )
      }}
    </Field>
  )
})

const SectorType = React.memo((props) => {
  const { name, typeOptions, loading, values, setValues } = props
  const onChangeSetValue = (e) => {
    setValues({
      ...values,
      item_type: e.target.value,
      transaction_items: [
        {
          id: undefined,
          code: '',
          name: '',
          specification: '',
          unit: '',
          note: '',
          readable_unit: undefined,
          quantity: undefined,
          quantity_before: undefined,
          max_quantity: undefined,
          quantity_after: undefined,
        },
      ],
    })
  }
  return (
    <Field name={name} disabled={loading}>
      {() => {
        return (
          <Radio.Group
            buttonStyle="solid"
            className="labels-wrapper"
            value={values[name]}
            onChange={(e) => onChangeSetValue(e)}
          >
            {typeOptions.map((type) => {
              return (
                <Radio.Button key={type.value} className="label-item" value={type.value}>
                  {type.name}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        )
      }}
    </Field>
  )
})
const StockItem = React.memo((props) => {
  const {
    values,
    loadingCondition,
    form,
    setFieldValue,
    remove,
    index,
    selectedStockItems,
    setSelectedStockItems,
    focusingRow,
    setFocus,
  } = props
  const [searchTerm, setSearchTerm] = useState({
    item_type: values.item_type,
    keyword: '',
    result: [],
    searching: false,
  })

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)

  useEffect(() => {
    let didCancel = false

    const fetchProducts = async () => {
      // const url = '/materials/form/'
      const url = '/transactions/items/'
      try {
        const resp = await axios.get(url, {
          params: {
            item_type: values.item_type,
            transaction_type: values.transaction_type,
            keyword: debouncedSearchTerm,
            per_page: 20,
          },
        })
        if (values.item_type === 'label') {
          const formatData = resp.data.records.map((item) => {
            return {
              id: item.id,
              code: item.product.code,
              name: item.product.name,
              specification: item.specification,
              readable_label_type: item.readable_label_type,
              quantity: item.quantity,
              unit: '',
            }
          })

          if (!didCancel) {
            setSearchTerm((prev) => ({
              ...prev,
              result: formatData,
              searching: false,
            }))

            // computeOptions(resp.data.records)
          }
        } else if (values.item_type === 'semi_finish_product') {
          const formatData = resp.data.records.map((item) => {
            return {
              ...item,
              unit: '',
            }
          })
          if (!didCancel) {
            setSearchTerm((prev) => ({
              ...prev,
              result: formatData,
              searching: false,
            }))

            // computeOptions(resp.data.records)
          }
        } else if (values.item_type === 'finish_product') {
          const formatData = resp.data.records.map((item) => {
            return {
              ...item,
              unit: '',
            }
          })
          if (!didCancel) {
            setSearchTerm((prev) => ({
              ...prev,
              result: formatData,
              searching: false,
            }))

            // computeOptions(resp.data.records)
          }
        } else {
          if (!didCancel) {
            setSearchTerm((prev) => ({
              ...prev,
              result: resp.data.records,
              searching: false,
            }))
          }
        }
        //   // computeOptions(resp.data.records)
        // }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (
      (debouncedSearchTerm.length || selectedStockItems.length) &&
      searchTerm.keyword === debouncedSearchTerm
    ) {
      fetchProducts()
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, searchTerm.keyword, selectedStockItems, values.item_type])

  useEffect(() => {
    let didCancel = false
    if (!didCancel && values.transaction_items[index].id) {
      const value = handleQuantityAfter(
        values.transaction_items[index].quantity_before,
        values.transaction_items[index].quantity
      )

      setFieldValue(`transaction_items[${index}].quantity_after`, value)
    }
    if (values.transaction_items[0].id === undefined) {
      setSearchTerm({ item_type: values.item_type, keyword: '', result: [], searching: false })
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.transaction_items, values.item_type, values.transaction_items])

  const computeOptions = (data) => {
    return data.filter((element) => {
      let cloneSelected = cloneDeep(selectedStockItems)
      if (cloneSelected.length) {
        // Remove itselt from selected material transaction items array
        // (for display selected option of that row not be error: show id of that option)
        if (values.transaction_items[index].id)
          cloneSelected.splice(cloneSelected.indexOf(values.transaction_items[index].id), 1)
        // ==========

        // remove all options except itself and not selected options
        return !cloneSelected.some((item) => item === element.id)
        // ==========
      } else {
        return true
      }
    })
  }

  const onSearch = (val) => {
    if (!val) {
      setSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onSelectMaterial = (index, val, option) => {
    let cloneSelected = cloneDeep(selectedStockItems)
    if (cloneSelected.includes(values.transaction_items[index].id)) {
      const valueIndex = cloneSelected.indexOf(values.transaction_items[index].id)
      // replaces element at valueIndex
      cloneSelected.splice(valueIndex, 1, val)
      setSelectedStockItems(cloneSelected)
    } else setSelectedStockItems((prev) => [...prev, val])
    setFieldValue(`transaction_items[${index}]`, {
      id: val,
      code: option.attr.code,
      name: option.attr.name === undefined ? option.attr.product.name : option.attr.name,
      specification: option.attr.specification,
      unit: option.attr.unit,
      readable_unit: option.attr.readable_unit,
      quantity_before: Number(option.attr.quantity),
      max_quantity: Number(option.attr.quantity),
      quantity: 1,
      quantity_after: handleQuantityAfter(option.attr.quantity, 1),
    })
  }

  const onRemoveItem = (index) => {
    let cloneSelected = cloneDeep(selectedStockItems)
    const removeId = values.transaction_items[index].id
    const removeIdIndex = cloneSelected.indexOf(removeId)
    if (removeIdIndex > -1) {
      cloneSelected.splice(removeIdIndex, 1)
      setSelectedStockItems(cloneSelected)
    }
    remove(index)
  }

  const handleNumberChange = (index, limit, value) => {
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(`transaction_items[${index}]`, {
        ...values.transaction_items[index],
        quantity: 1,
        quantity_after: handleQuantityAfter(values.transaction_items[index].quantity_before, 1),
      })
    } else {
      //OLD
      // const roundDown = Math.floor(value)
      //NEW
      const roundDown = value
      setFieldValue(`transaction_items[${index}]`, {
        ...values.transaction_items[index],
        quantity: roundDown,
        quantity_after: handleQuantityAfter(
          values.transaction_items[index].quantity_before,
          roundDown
        ),
      })
    }
  }

  const handleQuantityAfter = (before, quantity) => {
    let afterNumber = 0

    if (values.transaction_type === 'in' || values.transaction_type === 'deposit_in') {
      afterNumber = Number(before) + Number(quantity)
    } else if (values.transaction_type === 'out' || values.transaction_type === 'deposit_out') {
      if (quantity >= before) {
        afterNumber = 0
      } else afterNumber = Number(before) - Number(quantity)
    }
    return afterNumber
  }

  const handleLimitNumber = () => {
    let limit = 1
    if (values.transaction_type === 'in' || values.transaction_type === 'deposit_in') {
      limit = 100000000
    } else limit = values.transaction_items[index].max_quantity

    return limit
  }

  const handleRowColor = () => {
    if (focusingRow === index) return
    setFocus(index)
  }

  const limitNumber = handleLimitNumber()

  const searchDropDownCondition = searchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : searchTerm.keyword.length && !searchTerm.result.length ? (
    noData()
  ) : null

  const filteredOptions = computeOptions(searchTerm.result)
  return (
    <div
      className={`scroll-to-bottom-add${index}`}
      style={{ ...styles.rowWrapper, ...(focusingRow === index ? styles.isFocusing : {}) }}
    >
      <div style={{ ...styles.rowItem, maxWidth: 60 }}>{index + 1}</div>
      <div style={{ ...styles.rowItem, maxWidth: 200 }}>
        <Field name={`transaction_items[${index}].id`} disabled={loadingCondition}>
          {() => {
            return (
              <Select
                name={`transaction_items[${index}].id`}
                style={{ width: '100%' }}
                showSearch
                suffixIcon={<SearchOutlined />}
                placeholder={'Tìm nguyên vật liệu'}
                onSearch={onSearch}
                value={`${
                  values.transaction_items[index].code && values.transaction_items[index].code + `:`
                } ${values.transaction_items[index].name} `}
                onChange={(val, option) => onSelectMaterial(index, val, option)}
                optionFilterProp="children"
                disabled={loadingCondition}
                notFoundContent={searchDropDownCondition}
                loading={searchTerm.searching}
                filterOption={(input, option) => {
                  const dataInput = removeVietnameseTones(input)
                  const dataOption = removeVietnameseTones(option.children)
                  return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                }}
                onFocus={handleRowColor}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {filteredOptions.map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    attr={item}
                  >{`${item.code}: ${item.name}`}</Option>
                ))}
              </Select>
            )
          }}
        </Field>

        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`transaction_items[${index}].id`}
        />
      </div>

      <div style={{ ...styles.rowItem, maxWidth: 200 }}>
        {values.transaction_items[index].specification
          ? values.transaction_items[index].specification
          : '-'}
      </div>

      {(values.item_type === 'packing' || values.item_type === 'material') && (
        <div style={{ ...styles.rowItem, maxWidth: 160 }}>
          {values.transaction_items[index].readable_unit
            ? values.transaction_items[index].readable_unit
            : '-'}
        </div>
      )}
      <div style={{ ...styles.rowItem, maxWidth: 160 }}>
        {values.transaction_items[index].quantity_before
          ? formatNumber(values.transaction_items[index].quantity_before)
          : '-'}
      </div>

      <div style={{ ...styles.rowItem, maxWidth: 160 }}>
        <Field
          as={InputNumber}
          style={{ width: '100%' }}
          name={`transaction_items[${index}].quantity`}
          placeholder="Nhập số lượng"
          disabled={loadingCondition}
          value={values.transaction_items[index].quantity}
          onChange={(value) => handleNumberChange(index, limitNumber, value)}
          formatter={(value) => formatInputNumber(value)}
          step={0.1}
          min={0}
          max={limitNumber}
          onFocus={handleRowColor}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`transaction_items[${index}].quantity`}
        />
      </div>

      <div style={{ ...styles.rowItem, maxWidth: 160 }}>
        {values.transaction_items[index].quantity_after
          ? formatNumber(values.transaction_items[index].quantity_after)
          : '-'}
      </div>

      <div style={{ ...styles.rowItem, maxWidth: 220 }}>
        <Field
          as={TextArea}
          name={`transaction_items[${index}].note`}
          placeholder="Ghi chú..."
          disabled={loadingCondition}
          value={values.transaction_items[index].note}
          onChange={form.handleChange}
          autoSize={{ minRows: 1, maxRows: 3 }}
          onFocus={handleRowColor}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`transaction_items[${index}].note`}
        />
      </div>

      <div style={{ ...styles.lastChild, maxWidth: 120 }}>
        {values.transaction_items.length > 1 ? (
          <Button
            type="link"
            danger
            icon={<MinusCircleOutlined />}
            onClick={() => {
              onRemoveItem(index)
              setFocus(focusingRow - 1)
            }}
          />
        ) : null}
      </div>
    </div>
  )
})

const StockItems = React.memo((props) => {
  const { values, loadingCondition } = props

  const [focusingRow, setFocus] = useState(undefined)
  return (
    <FieldArray
      name="transaction_items"
      render={(arrayHelpers) => (
        <div style={{ paddingLeft: 10 }}>
          <Col style={styles.container} className="export-import-stocks-content">
            <div style={styles.innerContent} className="inner-content">
              <div style={styles.rowWrapper} className="fixed-scroll-header">
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 60 }}>STT</div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 200 }}>
                  Tên hàng
                </div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 200 }}>
                  Quy cách
                </div>
                {(values.item_type === 'packing' || values.item_type === 'material') && (
                  <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
                    Đơn vị
                  </div>
                )}
                {/* <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>Đơn vị</div> */}
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>Tồn đầu</div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
                  {values.transaction_type === 'in'
                    ? 'Nhập kho'
                    : values.transaction_type === 'out'
                    ? 'Xuất kho'
                    : values.transaction_type === 'deposit_in'
                    ? 'Deposit nhập'
                    : 'Deposit xuất'}
                </div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 160 }}>
                  Tồn cuối
                </div>
                <div style={{ ...styles.rowItem, ...styles.threadRow, maxWidth: 220 }}>Ghi chú</div>
                <div style={{ ...styles.lastChild, ...styles.threadRow, maxWidth: 120 }} />
              </div>
              {values.transaction_items.map((item, index) => (
                <StockItem
                  {...props}
                  {...arrayHelpers}
                  {...item}
                  key={index}
                  index={index}
                  focusingRow={focusingRow}
                  setFocus={setFocus}
                />
              ))}
            </div>
          </Col>

          {values.transaction_items.length ? (
            <div style={styles.buttonWrapper}>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                style={styles.addMoreButton}
                disabled={loadingCondition}
                onClick={() => {
                  arrayHelpers.push({
                    id: undefined,
                    code: '',
                    name: '',
                    specification: '',
                    unit: '',
                    note: '',
                    readable_unit: undefined,
                    quantity: undefined,
                    quantity_before: undefined,
                    max_quantity: undefined,
                    quantity_after: undefined,
                  })
                  scrollToBottomAdd(
                    `scroll-to-bottom-add${focusingRow === undefined ? 1 : focusingRow + 1}`
                  )
                  setFocus(focusingRow === undefined ? 1 : focusingRow + 1)
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    />
  )
})

export { CustomError, StockItems, TicketType, SectorType }

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'none',
    width: '100%',
    overflow: 'scroll',
    maxHeight: '400px',
  },
  innerContent: {
    minWidth: 1450,
    border: '1px solid #CCCCCC',
    borderBottom: 'none',
    overflowX: 'scroll',
  },
  cardWrapper: {
    width: '100%',
    padding: 0,
    boxShadow: 'none',
  },
  rowWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 0,
  },
  rowItem: {
    flex: 1,
    borderRight: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
    padding: 10,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  threadRow: {
    backgroundColor: '#FAFAFA',
    fontWeight: 500,
  },
  lastChild: {
    flex: 1,
    maxWidth: 80,
    padding: 10,
    borderBottom: '1px solid #CCCCCC',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  addMoreButton: {
    border: 'none',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: 'none',
    backgroundColor: '#0097c4',
  },
  totalWrapper: {
    marginTop: 20,
  },
  totalNumber: {
    width: '100%',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  totalNumberTitle: {
    fontSize: 14,
    color: '#474747',
    marginBottom: 10,
    marginRight: 20,
    width: 80,
  },
  totalNumberContent: {
    fontSize: 14,
    color: '#474747',
  },
  isFocusing: {
    backgroundColor: '#daf1f8d7',
  },
}
