import React from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { LockOutlined, UnlockOutlined, RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const { Title } = Typography
const { Password } = Input

export default React.memo((props) => {
  const { handleChange, values, isSubmitting, isValidating, handleSubmit, serverError } = props
  const history = useHistory()

  const CustomError = (props) => {
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }

  const loadingCondition = isSubmitting || isValidating

  return (
    <Form id="settings_account_form" className="form-wrapper" onSubmit={handleSubmit}>
      <Title className="page-sub-title">Mật khẩu đăng nhập</Title>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">
          Mật khẩu cũ <span className="compulsory-field-symbol">*</span>
        </Title>
        <Field
          as={Password}
          name="old_password"
          placeholder="Nhập mật khẩu cũ"
          className="password-field"
          visibilityToggle={false}
          prefix={<UnlockOutlined style={{ marginRight: 5 }} />}
          value={values.old_password}
          onChange={handleChange}
          disabled={loadingCondition}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="old_password" />
      </div>

      <div className="form-item">
        <Title className="field-title">
          Mật khẩu mới <span className="compulsory-field-symbol">*</span>
        </Title>
        <Field
          as={Password}
          name="new_password"
          placeholder="Nhập mật khẩu mới"
          className="password-field"
          visibilityToggle={false}
          prefix={<LockOutlined style={{ marginRight: 5 }} />}
          value={values.new_password}
          onChange={handleChange}
          disabled={loadingCondition}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="new_password" />
      </div>

      <div className="form-item">
        <Title className="field-title">
          Xác nhận mật khẩu mới <span className="compulsory-field-symbol">*</span>
        </Title>
        <Field
          as={Password}
          name="confirm_password"
          placeholder="Xác nhận mật khẩu mới"
          className="password-field"
          visibilityToggle={false}
          prefix={<LockOutlined style={{ marginRight: 5 }} />}
          value={values.confirm_password}
          onChange={handleChange}
          disabled={loadingCondition}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="confirm_password" />
      </div>

      <div className="form-button-contain">
        <Button
          loading={loadingCondition}
          type="primary"
          className="cancel-button"
          icon={<RollbackOutlined />}
          onClick={() => history.push('/')}
        >
          Trở lại
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-button"
          icon={<FormOutlined />}
          loading={loadingCondition}
        >
          Đổi mật khẩu
        </Button>
      </div>
    </Form>
  )
})
