import React from 'react'
import { Typography } from 'antd'

import LabelForm from '../Form'

const { Title } = Typography

export default React.memo((props) => {
  const id = props.match?.params.id

  return (
    <div id="edit_label_page">
      <Title className="page-title">Cập nhật tem</Title>

      <LabelForm path={'edit'} editId={id} duplicate={false} />
    </div>
  )
})
