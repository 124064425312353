import React from 'react'

import { CheckPermission } from 'Components'
import View from './TimeAttendence'

import './index.scss'

const TimeAttendenceIndex = (props) => <CheckPermission component={View} {...props} />

export { TimeAttendenceIndex }
