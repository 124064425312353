import React, { useEffect, useState, useMemo } from 'react'
import { Alert, Col, InputNumber, Switch } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { Field, FieldArray, ErrorMessage } from 'formik'

import { formatInputNumber, formatNumber } from 'Utils'

import './custom.scss'

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

const OrderItem = (props) => {
  const { item, loadingCondition, setFieldValue, index, focusingRow, setFocus } = props

  const handleFOBChange = (index, limit, value) => {
    if (item.using_new_prices) {
      setFieldValue(`sale_order_items[${index}].using_new_prices`, false)
    }
    if (typeof value !== 'number') {
      return
    } else if (value > limit) {
      return
    } else if (!value) {
      setFieldValue(`sale_order_items[${index}].new_fob`, 0)
    } else {
      const roundDown = Number(value.toFixed(2))
      setFieldValue(`sale_order_items[${index}].new_fob`, roundDown)
    }
  }

  const handleUseNewFOB = (checked) => {
    setFieldValue(`sale_order_items[${index}].use_new_prices`, checked)
  }

  const handleUsingNewFOB = (checked) => {
    setFieldValue(`sale_order_items[${index}].using_new_prices`, checked)
    if (checked) {
      setFieldValue(`sale_order_items[${index}].new_fob`, item.default_new_fob)
    } else {
      setFieldValue(`sale_order_items[${index}].new_fob`, item.manual_fob)
    }
  }

  const isRedBorder = useMemo(() => {
    // notify when new FOB > old FOB or new FOB small 3% than old FOB
    let newFob = Number(item.new_fob)
    let oldFob = Number(item.old_fob)
    if (newFob > oldFob) {
      return 'red'
    } else if (newFob < oldFob) {
      const percent = (oldFob - newFob) / oldFob
      if (percent >= 0.03) return 'red'
    } else return null
  }, [item.new_fob, item.old_fob])

  const handleRowColor = () => {
    if (focusingRow === index) return
    setFocus(index)
  }

  return (
    <div className={`row-wrapper ${focusingRow === index ? 'is-focusing' : ''}`}>
      <div style={{ maxWidth: 60 }} className="row-item">
        {index + 1}
      </div>
      <div style={{ minWidth: 250, maxWidth: 320 }} className="row-item">
        {item.product.id ? `${item.product.code}: ${item.product.name}` : '-'}
      </div>
      <div style={{ maxWidth: 150 }} className="row-item">
        {item.specification ? item.specification : '-'}
      </div>
      <div style={{ maxWidth: 110 }} className="row-item">
        <span>{item.box_quantity ? formatNumber(item.box_quantity) : '-'}</span>
      </div>
      <div style={{ maxWidth: 110 }} className="row-item">
        {item.total_quantity ? formatNumber(item.total_quantity) : '-'}
      </div>
      <div style={{ maxWidth: 110 }} className="row-item">
        {item.total_size ? formatNumber(item.total_size) : '-'}
      </div>
      <div style={{ maxWidth: 150 }} className="row-item">
        <span>{item.note ? item.note : '-'}</span>
      </div>
      <div style={{ maxWidth: 120 }} className="row-item">
        <span>{item.old_fob ? formatNumber(item.old_fob) : '-'}</span>
      </div>
      <div style={{ maxWidth: 120 }} className="row-item">
        <span>{item.default_new_fob ? formatNumber(item.default_new_fob) : '-'}</span>
      </div>
      <div style={{ maxWidth: 130 }} className="row-item switch-field">
        <Switch
          checked={item.using_new_prices}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={handleUsingNewFOB}
          loading={loadingCondition}
        />
      </div>

      <div style={{ maxWidth: 130 }} className="row-item">
        <Field
          as={InputNumber}
          style={{ width: '100%', borderColor: isRedBorder }}
          name={`sale_order_items[${index}].new_fob`}
          placeholder="Nhập giá FOB"
          disabled={loadingCondition}
          value={item.new_fob}
          onChange={(value) => handleFOBChange(index, 100000000, value)}
          formatter={(value) => formatInputNumber(value)}
          min={0}
          max={100000000}
          onFocus={handleRowColor}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name={`sale_order_items[${index}].new_fob`}
        />
      </div>
      <div style={{ maxWidth: 130 }} className="row-item switch-field">
        <Switch
          checked={item.use_new_prices}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={handleUseNewFOB}
          loading={loadingCondition}
        />
      </div>
      <div style={{ minWidth: 100 }} className="row-item">
        <span> {formatNumber(Number((item.new_fob * item.box_quantity).toFixed(2)))}</span>
      </div>
    </div>
  )
}

const OrderItems = React.memo((props) => {
  const { values, totalRow, setTotalRow } = props

  const [focusingRow, setFocus] = useState(undefined)

  useEffect(() => {
    let didCancel = false

    const calTotal = () => {
      let totalBoxQuantity = 0
      let totalQuantity = 0
      let totalSize = 0
      let totalFobProduct = 0
      let total_sticker_fees_quantity = values.total_label_quantity

      values.sale_order_items.forEach((item) => {
        totalBoxQuantity += item.box_quantity
        totalQuantity += item.total_quantity
        totalSize += item.total_size
        totalFobProduct += item.new_fob * item.box_quantity
      })

      // values.sale_order_items.forEach((item) => {
      //   total_sticker_fees_quantity += Number(item.box_quantity * item.labels_rate)
      // })
      setTotalRow({
        totalBoxQuantity,
        totalQuantity,
        totalSize,
        totalFobProduct,
        total_sticker_fees_quantity,
      })
    }

    if (!didCancel) {
      calTotal()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.sale_order_items.length, values.sale_order_items])

  return (
    <FieldArray
      name="sale_order_items"
      render={(arrayHelpers) => (
        <Col className="container order-table-content">
          <div className="inner-content">
            <div className="row-wrapper">
              <div style={{ maxWidth: 60 }} className="row-item thread-row">
                STT
              </div>
              <div style={{ minWidth: 250, maxWidth: 320 }} className="row-item thread-row">
                Tên sản phẩm
              </div>
              <div style={{ maxWidth: 150 }} className="row-item thread-row">
                Packing
              </div>
              {/* <div style={{  minWidth: 200 }} className="row-item thread-row">
                  Giá mua sản phẩm chưa thành phẩm
                </div>
                <div style={{  minWidth: 200 }} className="row-item thread-row">
                  Giá mua sản phẩm thành phẩm
                </div> */}
              <div style={{ maxWidth: 110 }} className="row-item thread-row">
                Số lượng (thùng)
              </div>
              <div style={{ maxWidth: 110 }} className="row-item thread-row">
                Số lượng(đơn vị)
              </div>
              <div style={{ maxWidth: 110 }} className="row-item thread-row">
                Cubic (m3)
              </div>
              <div style={{ maxWidth: 150 }} className="row-item thread-row">
                Ghi chú mặt hàng
              </div>
              <div style={{ maxWidth: 120 }} className="row-item thread-row">
                Giá FOB lô trước (USD)
              </div>
              <div style={{ maxWidth: 120 }} className="row-item thread-row">
                Giá FOB mới (USD)
              </div>
              <div style={{ maxWidth: 130 }} className="row-item thread-row">
                Áp dụng giá FOB mới cho lô này
              </div>
              <div style={{ maxWidth: 130 }} className="row-item thread-row">
                Duyệt giá FOB (USD)
              </div>
              <div style={{ maxWidth: 130 }} className="row-item thread-row">
                Áp dụng giá FOB đã duyệt cho lô sau
              </div>
              <div style={{ minWidth: 100 }} className="row-item thread-row">
                Thành tiền (USD)
              </div>
            </div>

            <div className="scroll-vertical-table">
              {values.sale_order_items.map((item, index) => (
                <OrderItem
                  {...props}
                  key={index}
                  item={item}
                  index={index}
                  focusingRow={focusingRow}
                  setFocus={setFocus}
                />
              ))}
            </div>

            <div className="row-wrapper">
              <div style={{ maxWidth: 60 }} className="row-item thread-row" />
              <div style={{ minWidth: 250, maxWidth: 320 }} className="row-item thread-row">
                Tổng
              </div>
              <div style={{ maxWidth: 150 }} className="row-item thread-row" />
              <div style={{ maxWidth: 110 }} className="row-item thread-row">
                {formatNumber(totalRow.totalBoxQuantity)}
              </div>
              <div style={{ maxWidth: 110 }} className="row-item thread-row">
                {formatNumber(totalRow.totalQuantity)}
              </div>
              <div style={{ maxWidth: 110 }} className="row-item thread-row">
                {formatNumber(totalRow.totalSize.toFixed(2))}
              </div>
              <div style={{ maxWidth: 150 }} className="row-item thread-row" />
              <div style={{ maxWidth: 120 }} className="row-item thread-row" />
              <div style={{ maxWidth: 120 }} className="row-item thread-row" />
              <div style={{ maxWidth: 130 }} className="row-item thread-row" />
              <div style={{ maxWidth: 130 }} className="row-item thread-row" />
              <div style={{ maxWidth: 130 }} className="row-item thread-row" />
              <div style={{ minWidth: 100 }} className="row-item thread-row">
                {formatNumber(Number(totalRow.totalFobProduct.toFixed(2)))}
              </div>
            </div>
          </div>
        </Col>
      )}
    />
  )
})

export { CustomError, OrderItems }
