import React, { useEffect } from 'react'
import { ErrorMessage, Field } from 'formik'
import { Input, InputNumber, Typography, Row, Col, Select, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { formatInputNumber } from 'Utils'
import axios from 'Utils/axiosRexyAdmin'
import { noData, ImageSlide } from 'Components'
import { useDebounce } from 'Hooks'
import { CustomError, CustomUploadProductImage } from './CustomComponent'
import { removeVietnameseTones } from 'Utils'

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

const PRODUCT_TYPES = [
  { value: 'finish', label: 'Thành phẩm' },
  { value: 'semifinish', label: 'Bán thành phẩm' },
]

export default React.memo((props) => {
  const {
    values,
    handleChange,
    loadingCondition,
    setFieldValue,
    setValues,
    productImages,
    // path,
    // setProductImages,
    previewProductImage,
    setPreviewProductImage,
    supplierSearchTerm,
    setSupplierSearchTerm,
    uploading,
    setUploading,
  } = props

  const debouncedSupplierSearchTerm = useDebounce(supplierSearchTerm.keyword, 500)

  useEffect(() => {
    let didCancel = false

    const fetchSuppliers = async () => {
      const url = '/suppliers/form/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSupplierSearchTerm,
            per_page: 20,
          },
        })

        if (!didCancel) {
          setSupplierSearchTerm((prev) => ({
            ...prev,
            result: resp.data.records,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setSupplierSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (
      debouncedSupplierSearchTerm.length &&
      supplierSearchTerm.keyword === debouncedSupplierSearchTerm
    )
      fetchSuppliers()

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSupplierSearchTerm, supplierSearchTerm.keyword])

  const onSearchSupplier = (val) => {
    if (!val) {
      setSupplierSearchTerm((prev) => ({ ...prev, keyword: '', searching: false }))
      return
    }
    setSupplierSearchTerm((prev) => ({ ...prev, keyword: val, searching: true }))
  }

  const onSupplierSelect = (ids) => {
    // setFieldValue('supplier_id', val)
    setFieldValue('suppliers', ids)
  }

  const onChangeProductType = (val) => {
    if (values.product_type) {
      setValues({
        ...values,
        product_type: val,
        first_price: undefined,
        production_cost: undefined,
        second_price: undefined,
        managament_cost: undefined,
        fob_price: undefined,
      })
    } else {
      setFieldValue('product_type', val)
    }
  }

  const searchDropDownCondition3 = supplierSearchTerm.searching ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Spin />
    </div>
  ) : supplierSearchTerm.keyword.length && !supplierSearchTerm.result.length ? (
    noData()
  ) : null

  return (
    <div className="product-details">
      <Title className="part-title">2. Chi tiết sản phẩm</Title>
      <div className="part-field-wrapper">
        <div className="form-item">
          <Row>
            <Col span={6}>
              <Title className="field-title">
                Hình ảnh sản phẩm <span className="compulsory-field-symbol">*</span>
              </Title>
            </Col>
            <Col span={1} />
            <Col span={17}>
              <Field
                name="aws_object_keys"
                disabled={true}
                value={values.aws_object_keys}
                onChange={handleChange}
              >
                {(props) => (
                  <CustomUploadProductImage
                    {...props}
                    name="aws_object_keys"
                    productImages={productImages}
                    loadingCondition={loadingCondition}
                    uploading={uploading}
                    setUploading={setUploading}
                    setPreviewProductImage={setPreviewProductImage}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <ErrorMessage component={(props) => <CustomError {...props} />} name="aws_object_keys" />

          <ImageSlide
            visible={previewProductImage.visible}
            onCancel={() =>
              setPreviewProductImage((prev) => ({ ...prev, visible: false, currentIndex: 0 }))
            }
            images={previewProductImage.files}
            currentIndex={previewProductImage.currentIndex}
          />
        </div>

        <div className="form-item">
          <Title className="field-title">
            Tên nhà cung cấp{' '}
            {values.product_type === 'finish' ? (
              <span className="compulsory-field-symbol">*</span>
            ) : (
              ''
            )}
          </Title>
          <Field name="suppliers" disabled={loadingCondition}>
            {() => {
              return (
                <Select
                  mode="multiple"
                  allowClear
                  name="suppliers"
                  style={{ width: '100%' }}
                  showSearch={true}
                  suffixIcon={<SearchOutlined />}
                  notFoundContent={searchDropDownCondition3}
                  placeholder="Nhập mã hoặc tên nhà cung cấp để tìm kiếm"
                  onSearch={onSearchSupplier}
                  value={values.suppliers}
                  onChange={onSupplierSelect}
                  optionFilterProp="children"
                  loading={supplierSearchTerm.searching}
                  disabled={loadingCondition}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(input, option) => {
                    const dataInput = removeVietnameseTones(input)
                    const dataOption = removeVietnameseTones(option.children)
                    return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                  }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {supplierSearchTerm.result.map((item) => (
                    <Option key={item.id} value={item.id}>{`${item.code}: ${item.name}`}</Option>
                  ))}
                </Select>
              )
            }}
          </Field>
          <ErrorMessage component={(props) => <CustomError {...props} />} name="suppliers" />
        </div>

        <div className="form-item">
          <Title className="field-title">
            Loại sản phẩm <span className="compulsory-field-symbol">*</span>
          </Title>

          <Field name={'product_type'} disabled={loadingCondition}>
            {() => (
              <Select
                name={'product_type'}
                style={{ width: '100%' }}
                placeholder="Chọn loại sản phẩm"
                value={values.product_type}
                onChange={(val) => onChangeProductType(val)}
                disabled={loadingCondition}
                defaultValue="finish"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {PRODUCT_TYPES.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Field>

          <ErrorMessage component={(props) => <CustomError {...props} />} name="product_type" />
        </div>

        <div className="form-item">
          <Row justify="space-between" className="product-packing-row">
            <Col span={14}>
              <div className="form-item">
                <Title className="field-title">
                  Packing <span className="compulsory-field-symbol">*</span>
                </Title>
                <Field
                  as={Input}
                  name="specification"
                  placeholder="Nhập quy cách sản phẩm"
                  disabled={loadingCondition}
                  value={values.specification}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="specification"
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="form-item">
                <Title className="field-title">
                  Số sản phẩm trong thùng <span className="compulsory-field-symbol">*</span>
                </Title>
                <Field
                  as={InputNumber}
                  name="quantity_in_box"
                  style={{ width: '100%' }}
                  disabled={loadingCondition}
                  value={values.quantity_in_box}
                  onChange={(value) => {
                    if (value > 100000 || typeof value !== 'number') {
                      return
                    } else if (!value) {
                      setFieldValue('quantity_in_box', undefined)
                    } else {
                      setFieldValue('quantity_in_box', value)
                    }
                  }}
                  formatter={(value) => formatInputNumber(value)}
                  placeholder="Nhập số lượng"
                  min={0}
                  max={100000}
                />
                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="quantity_in_box"
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* 
        <div className="form-item">
          <Title className="field-title">
            Số lượng <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={InputNumber}
            style={{ width: '100%' }}
            name="quantity"
            placeholder="Nhập số lượng"
            disabled={path === 'new' ? loadingCondition : true}
            value={values.quantity}
            onChange={(value) => {
              if (value > 1000000000) {
                return
              } else {
                setFieldValue('quantity', value)
                setFieldValue('temporary_quantity', value)
              }
            }}
            formatter={(value) => formatInputNumber(value)}
            step={0.1}
            precision={2}
            min={0}
            max={1000000000}
          />
          <ErrorMessage component={(props) => <CustomError {...props} />} name="quantity" />
        </div>
        <div className="form-item">
          <Title className="field-title">
            Số lượng khả dụng <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={InputNumber}
            style={{ width: '100%' }}
            name="temporary_quantity"
            placeholder="Nhập số lượng khả dụng"
            disabled={true}
            value={values.quantity}
            onChange={(value) => {
              if (value > 1000000000) {
                return
              } else {
                setFieldValue('temporary_quantity', value)
              }
            }}
            formatter={(value) => formatInputNumber(value)}
            step={0.1}
            precision={2}
            min={0}
            max={1000000000}
          />
        </div> */}
        <div className="form-item">
          <Title className="field-title">
            Tên tiếng Anh (trên chứng từ ngoại) <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={Input}
            name="name_on_foreign_document"
            placeholder="Nhập tên sản phẩm"
            disabled={loadingCondition}
            value={values.name_on_foreign_document}
            onChange={handleChange}
          />
          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name="name_on_foreign_document"
          />
        </div>

        <div className="form-item">
          <Title className="field-title">Tên rút gọn (trên bill)</Title>
          <Field
            as={Input}
            name="name_on_bill"
            placeholder="Nhập tên rút gọn sản phẩm"
            disabled={loadingCondition}
            value={values.name_on_bill}
            onChange={handleChange}
          />
          <ErrorMessage component={(props) => <CustomError {...props} />} name="name_on_bill" />
        </div>

        <div className="form-item">
          <Title className="field-title">
            Tên sản phẩm trên tờ khai <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={Input}
            name="name_on_declaration"
            placeholder="Nhập tên sản phẩm"
            disabled={loadingCondition}
            value={values.name_on_declaration}
            onChange={handleChange}
          />
          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name="name_on_declaration"
          />
        </div>

        <div className="form-item">
          <Title className="field-title">
            Tên thương hiệu trên tờ khai <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={Input}
            name="brand_name"
            placeholder="Nhập tên thương hiệu"
            disabled={loadingCondition}
            value={values.brand_name}
            onChange={handleChange}
          />
          <ErrorMessage component={(props) => <CustomError {...props} />} name="brand_name" />
        </div>

        <div className="form-item">
          <Row justify="space-between" className="barcode-contain">
            <Col span={11}>
              <div className="form-item">
                <Title className="field-title">
                  Barcode gói <span className="compulsory-field-symbol">*</span>
                </Title>
                <Field
                  as={Input}
                  name="package_barcode"
                  placeholder="Nhập barcode gói"
                  disabled={loadingCondition}
                  value={values.package_barcode}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="package_barcode"
                />
              </div>
            </Col>
            <Col span={11}>
              <div className="form-item">
                <Title className="field-title">
                  Barcode thùng <span className="compulsory-field-symbol">*</span>
                </Title>
                <Field
                  as={Input}
                  name="box_barcode"
                  placeholder="Nhập barcode thùng"
                  disabled={loadingCondition}
                  value={values.box_barcode}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="box_barcode"
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="form-item">
          <Title className="field-title">Lưu ý về barcode</Title>
          <Field
            as={TextArea}
            name="barcode_note"
            placeholder="Ghi chú..."
            disabled={loadingCondition}
            value={values.barcode_note}
            onChange={handleChange}
            autoSize={{ minRows: 2, maxRows: 5 }}
          />

          <ErrorMessage component={(props) => <CustomError {...props} />} name="barcode_note" />
        </div>

        <div className="form-item">
          <Title className="field-title">
            Kích thước thùng (D x R x C) m3 <span className="compulsory-field-symbol">*</span>
          </Title>
          <Input.Group compact>
            <Field
              as={InputNumber}
              name="box_size"
              disabled={loadingCondition}
              value={values.box_size}
              onChange={(value) => {
                if (value > 1000000000) {
                  return
                } else {
                  setFieldValue('box_size', value)
                }
              }}
              style={{ width: '78%' }}
              formatter={(value) => formatInputNumber(value)}
              placeholder="Nhập kích thước thùng"
              min={0}
              max={1000000000}
            />
            <Input className="unit-disable-field" disabled={true} defaultValue="m3" />
          </Input.Group>

          <ErrorMessage component={(props) => <CustomError {...props} />} name="box_size" />
        </div>

        <div className="form-item">
          <Row justify="space-between" className="weight-contain">
            <Col span={11}>
              <div className="form-item">
                <Title className="field-title">
                  Net Weight (kgs) <span className="compulsory-field-symbol">*</span>
                </Title>
                <Input.Group compact>
                  <Field
                    as={InputNumber}
                    name="net_weight"
                    disabled={loadingCondition}
                    value={values.net_weight}
                    onChange={(value) => {
                      if (value > 1000000000) {
                        return
                      } else {
                        setFieldValue('net_weight', value)
                      }
                    }}
                    style={{ width: '78%' }}
                    formatter={(value) => formatInputNumber(value)}
                    placeholder="Nhập khối lượng tịnh"
                    min={0}
                    max={1000000000}
                  />
                  <Input className="unit-disable-field" disabled={true} defaultValue="Kg" />
                </Input.Group>

                <ErrorMessage component={(props) => <CustomError {...props} />} name="net_weight" />
              </div>
            </Col>
            <Col span={11}>
              <div className="form-item">
                <Title className="field-title">
                  Gross weight (kgs) <span className="compulsory-field-symbol">*</span>
                </Title>

                <Input.Group compact>
                  <Field
                    as={InputNumber}
                    name="gross_weight"
                    disabled={loadingCondition}
                    value={values.gross_weight}
                    onChange={(value) => {
                      if (value > 1000000000) {
                        return
                      } else {
                        setFieldValue('gross_weight', value)
                      }
                    }}
                    style={{ width: '78%' }}
                    formatter={(value) => formatInputNumber(value)}
                    placeholder="Nhập trọng lượng"
                    min={0}
                    max={1000000000}
                  />
                  <Input className="unit-disable-field" disabled={true} defaultValue="Kg" />
                </Input.Group>

                <ErrorMessage
                  component={(props) => <CustomError {...props} />}
                  name="gross_weight"
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="form-item">
          <Title className="field-title">
            Hạn sử dụng <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={Input}
            name="expire_date"
            placeholder="Nhập hạn sử dụng sản phẩm"
            disabled={loadingCondition}
            value={values.expire_date}
            onChange={handleChange}
          />
          <ErrorMessage component={(props) => <CustomError {...props} />} name="expire_date" />
        </div>
      </div>
    </div>
  )
})
