import React, { useState } from 'react'
import { Typography, Modal, Alert, Input } from 'antd'

import axios from 'Utils/axiosRexyAdmin'

const { Text } = Typography

export default ({ id, code, changeCode, error, visible, setDeleteModal, doFetch }) => {
  const [state, setState] = useState({ loading: false, errors: null })
  const handleCancel = () => {
    setDeleteModal({ id: undefined, code: '', changeCode: '', error: '', visible: false })
  }

  const handleOk = async () => {
    if (code !== changeCode)
      return setDeleteModal({
        id: id,
        code: code,
        changeCode: changeCode,
        error: true,
        visible: true,
      })
    setState({ loading: true, errors: null })
    const url = '/standing_orders'
    try {
      await axios.delete(url, { data: { ids: [id] } })

      setState((prev) => ({ ...prev, loading: false }))
      setDeleteModal({ id: undefined, code: '', changeCode: '', error: '', visible: false })
      doFetch({})
    } catch (error) {
      setState({ loading: false, errors: error.response?.data.errors.message })
    }
  }

  const renderDeleteButton = () => {
    return <Text className="create-btn-text">Xoá</Text>
  }

  const renderCancelButton = () => {
    return <Text className="cancel-btn-text">Huỷ</Text>
  }

  const renderTitle = () => {
    return <Text className="modal-title">Xoá ủy nhiệm chi này không</Text>
  }

  return (
    <Modal
      className="delete-material-modal"
      style={{ top: 200 }}
      visible={visible}
      title={renderTitle()}
      okText={renderDeleteButton()}
      okButtonProps={{ loading: state.loading, className: 'delete-button' }}
      cancelText={renderCancelButton()}
      cancelButtonProps={{ loading: state.loading }}
      onOk={handleOk}
      onCancel={state.loading ? null : handleCancel}
    >
      <div id="material_delete_modal">
        <Text className="modal-description">
          <strong>Bạn có chắc muốn xoá ủy nhiệm chi này.</strong>
          <br />
          Lưu ý: Một khi đã xoá sẽ không thể hoàn tác.
        </Text>
        <Text className="material-name">{code}</Text>
        <Input
          value={changeCode}
          onChange={(e) => {
            const { value } = e.target
            setDeleteModal({
              id: id,
              code: code,
              changeCode: value,
              error: false,
              visible: true,
            })
          }}
        />
        {error && (
          <Alert
            style={{ marginBottom: 5, marginTop: '10px' }}
            message={'Vui lòng kiểm tra lại mã ủy nhiệm chi'}
            type="error"
            showIcon
          />
        )}
        {state.errors && (
          <Alert style={{ marginBottom: 5 }} message={state.errors} type="error" showIcon />
        )}
      </div>
    </Modal>
  )
}
