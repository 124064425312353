import React from 'react'
import { StoreProvider } from 'easy-peasy'

import store from './configureStore'
import Routes from './Routes'

import './App.less'

require('dotenv').config()

if (process.env.NODE_ENV !== 'development') {
  console.log = () => null
  console.error = () => null
  console.debug = () => null
}

const App = () => (
  <StoreProvider store={store}>
    <Routes />
  </StoreProvider>
)

export default App
