import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useHistory, Redirect } from 'react-router-dom'
import { message } from 'antd'
import moment from 'moment'
import get from 'lodash/get'
import size from 'lodash/size'
import map from 'lodash/map'

import { Loading } from 'Components'
import FormView from './Form.view'
import { manageProformalInvoiceSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  order_number: '',
  date: moment(),
  customer_id: undefined,
  production_requirement: '',
  customer: { id: '', code: '', name: '' },
  sale_order_items: [
    {
      completed: false,
      product_id: undefined,
      product: { id: undefined, code: '', name: '' },
      second_price: 0,
      specification: '',
      box_quantity: 1,
      quantity_in_box: 0,
      box_size: 0,
      note: '',
      total_quantity: 0,
      total_size: 0,
      old_fob: 0,
      new_fob: 0,
      using_new_prices: false,
      use_new_prices: false,
      net_weight: 0,
      gross_weight: 0,
      total: 0,
    },
  ],
  status: 'producing',
  usd_to_vnd_rate: 0,
  sticker_fees: 0,
  container_type_1_amount: 0,
  container_type_2_amount: 0,
  container_type_3_amount: 0,

  produce_date: moment().format('YYYY-MM-DD'),
  complete_date: moment().format('YYYY-MM-DD'),
  export_date: moment().format('YYYY-MM-DD'),
}

export default React.memo((props) => {
  const id = props.match?.params.id

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const [fetchEditData, setFetchEditData] = useState({ dataFetch: {}, isFetching: true })
  const [statusSaleOrder, setStatusSaleOrder] = useState('pending_pi')
  const [documents, setDocuments] = useState([])
  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})

  const formattedDocumentsParam = map(documents, (document) => ({
    file_name: get(document, 'originFileObj.name'),
    aws_object_key: get(document, 'originFileObj.object_key'),
  }))

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)
    const url = `/sale_orders/${id}/edit/`

    const formatParams = {
      ...values,
      date: values.date.format('YYYY-MM-DD'),
      produce_date: values.produce_date,
      complete_date: values.complete_date,
      export_date: values.export_date,
      documents: formattedDocumentsParam,
    }

    try {
      const resp = await axios.put(url, formatParams)
      setSubmitting(false)
      history.push(`/sale-orders/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/sale_orders/${id}/edit`
      setFetchEditData((prev) => ({ ...prev, isFetching: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setStatusSaleOrder(resp.data.status)
          computeData(resp.data)
          const notSetFirstTimeFetchedValuesYet = size(firstTimeFetchedValues) === 0
          const formattedDocuments = map(resp.data.documents, (document) => ({
            uid: `uid-${document.aws_object_key}`,
            originFileObj: {
              url: document.url,
              name: document.file_name,
              object_key: document.aws_object_key,
            },
          }))
          setDocuments(formattedDocuments)
          if (notSetFirstTimeFetchedValuesYet) {
            setFirstTimeFetchedValues({
              ...resp.data,
              date: moment(resp.data.date, 'YYYY-MM-DD'),
            })
          }
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setFetchEditData((prev) => ({ ...prev, isFetching: false }))
        }
      }
    }
    if (id) {
      fetchData()
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const computeData = (data) => {
    const exchange_rate = Number(data.usd_to_vnd_rate)
    const stickerFees = Number(data.sticker_fees)
    const saleOrderItems = data.sale_order_items.map((item) => {
      return {
        ...item,
        old_fob: Number(item.old_fob),
        manual_fob:
          Number(item.new_fob) ||
          Number(
            ((item.second_price * (1 + item.managament_cost / 100)) / exchange_rate).toFixed(2)
          ),
        new_fob: Number(item.new_fob) || Number(item.old_fob),
      }
    })
    const formattedData = {
      ...data,
      date: moment(data.date, 'YYYY-MM-DD'),
      sale_order_items: saleOrderItems,
      usd_to_vnd_rate: Number(data.usd_to_vnd_rate),
      sticker_fees: stickerFees,
    }
    setFetchEditData({ dataFetch: formattedData, isFetching: false })
  }

  if (fetchEditData.isFetching) return <Loading loading={fetchEditData.isFetching} />
  return statusSaleOrder !== 'pending_pi' && statusSaleOrder !== 'approved_pi' ? (
    <Redirect to={`/sale-orders/${id}/view`} />
  ) : (
    <div id="proformal_invoice_page">
      <div className="proformal-invoice-form">
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          children={(formProps) => (
            <FormView
              {...formProps}
              serverError={serverError}
              editId={id}
              isSubmitting={isSubmitting}
              setSubmitting={setSubmitting}
              documents={documents}
              setDocuments={setDocuments}
              {...fetchEditData}
              initialValues={initialValues}
              firstTimeFetchedValues={firstTimeFetchedValues}
            />
          )}
          validationSchema={manageProformalInvoiceSchema}
        />
      </div>
    </div>
  )
})
