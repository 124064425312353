import React, { useEffect } from 'react'
import { ErrorMessage, Field } from 'formik'
import { Input, Typography } from 'antd'

import { useDebounce } from 'Hooks'
import { CustomError } from './OrderItems'
import axios from 'Utils/axiosRexyAdmin'
// import noData from "./noData";

const { Title } = Typography
// const { Option } = Select;

export default React.memo((props) => {
  const {
    handleChange,
    values,
    loadingCondition,
    // setFieldValue,
    searchTerm,
    setSearchTerm,
    // onSearch,
  } = props

  const debouncedSearchTerm = useDebounce(searchTerm.keyword, 500)

  useEffect(() => {
    let didCancel = false

    const fetchStaff = async () => {
      const url = '/products/new/'
      try {
        const resp = await axios.get(url, {
          params: {
            keyword: debouncedSearchTerm,
            per_page: 20,
          },
        })

        if (!didCancel) {
          setSearchTerm((prev) => ({
            ...prev,
            result: resp.data.customers,
            searching: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setSearchTerm((prev) => ({ ...prev, searching: false }))
        }
      }
    }

    if (debouncedSearchTerm.length && searchTerm.keyword === debouncedSearchTerm) {
      fetchStaff()
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, searchTerm.keyword])

  // const onResultSelect = (val) => {
  //   setFieldValue("customer_id", val);
  // };

  // const searchDropDownCondition = searchTerm.searching ? (
  //   <div
  //     style={{
  //       display: "flex",
  //       flex: 1,
  //       justifyContent: "center",
  //       alignItems: "center",
  //       padding: 5,
  //     }}
  //   >
  //     <Spin />
  //   </div>
  // ) : searchTerm.keyword.length && !searchTerm.result.length ? (
  //   noData()
  // ) : null;

  return (
    <div className="general-information">
      <Title className="part-title">1. Thông tin chung</Title>
      <div className="part-field-wrapper">
        <div className="form-item">
          <Title className="field-title">
            Tên sản phẩm <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={Input}
            name="name"
            placeholder="Nhập tên sản phẩm"
            disabled={loadingCondition}
            value={values.name}
            onChange={handleChange}
          />
          <ErrorMessage component={(props) => <CustomError {...props} />} name="name" />
        </div>

        <div className="form-item">
          <Title className="field-title">
            Tên tiếng Anh (Proforma Invoice) <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={Input}
            name="name_on_proforma_invoice"
            placeholder="Nhập tên sản phẩm"
            disabled={loadingCondition}
            value={values.name_on_proforma_invoice}
            onChange={handleChange}
          />
          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name="name_on_proforma_invoice"
          />
        </div>

        <div className="form-item">
          <Title className="field-title">
            Datafood Code <span className="compulsory-field-symbol">*</span>
          </Title>
          <Field
            as={Input}
            name="code"
            placeholder="Nhập Datafood Code"
            disabled={loadingCondition}
            value={values.code}
            onChange={handleChange}
          />
          <ErrorMessage component={(props) => <CustomError {...props} />} name="code" />
        </div>

        <div className="form-item">
          <Title className="field-title">Customer Code</Title>

          <Field
            as={Input}
            name="customer_code"
            placeholder="Nhập Customer Code"
            disabled={loadingCondition}
            value={values.customer_code}
            onChange={handleChange}
          />
          <ErrorMessage component={(props) => <CustomError {...props} />} name="customer_code" />
          {/* <Field name="customer_id" disabled={loadingCondition}>
            {(props) => {
              return (
                <Select
                  name="customer_id"
                  className="customer-search-field"
                  showArrow={false}
                  showSearch={true}
                  notFoundContent={searchDropDownCondition}
                  placeholder="Nhập mã hoặc tên khách hàng để tìm kiếm"
                  onSearch={onSearch}
                  value={values.customer_id}
                  onChange={onResultSelect}
                  optionFilterProp="children"
                  loading={searchTerm.searching}
                  disabled={loadingCondition}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {searchTerm.result.map((item) => (
                    <Option
                      key={item.id}
                      value={item.id}
                    >{`${item.code}: ${item.name}`}</Option>
                  ))}
                </Select>
              );
            }}
          </Field>
          <ErrorMessage
            component={(props) => <CustomError {...props} />}
            name="customer_id"
          /> */}
        </div>
      </div>
    </div>
  )
})
