import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useStoreState } from 'easy-peasy'
import { Typography, Col, Button, DatePicker, Alert } from 'antd'
import { Form, ErrorMessage, Field } from 'formik'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import map from 'lodash/map'

import { OtherDocumentsTable, BlockNavigation } from 'Components'
import { preventEnterSubmit, roundUpDecimal } from 'Utils'

import CustomUploadDocument from '../CustomUploadDocument'
import ProducingItem from './ProducingItem.Component'

const { Title } = Typography

const format = 'DD-MM-YYYY'

const CustomError = React.memo((props) => {
  return <Alert message={props.children} type="error" className="field-error" showIcon />
})

export default React.memo((props) => {
  const {
    handleSubmit,
    setSubmitting,
    values,
    setValues,
    dataFetch,
    isFetching,
    editId,
    totalCubic,
    presentCubic,
    isSubmitting,
    setFieldValue,
    documents,
    setDocuments,
    initialValues,
    firstTimeFetchedValues,
  } = props

  const history = useHistory()
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  const [focusingRow, setFocus] = useState(undefined)

  useEffect(() => {
    if (editId && dataFetch && dataFetch.documents) {
      const formattedDocuments = map(dataFetch.documents, (document) => ({
        uid: `uid-${document.aws_object_key}`,
        originFileObj: {
          url: document.url,
          name: document.file_name,
          object_key: document.aws_object_key,
        },
      }))
      setDocuments(formattedDocuments)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetch, editId])

  useEffect(() => {
    let didCancel = false
    if (editId && !didCancel && Object.keys(dataFetch).length) {
      setValues(dataFetch)
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, dataFetch])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/sale-orders`)
  }

  const generateSuggestion = () => {
    if (!totalCubic || !presentCubic) {
      return ''
    } else if (totalCubic > presentCubic) {
      return (
        <span style={{ color: 'green' }}>
          {`Thiếu ${(totalCubic - presentCubic).toFixed(2)} m3`}
        </span>
      )
    } else if (totalCubic < presentCubic) {
      return (
        <span style={{ color: 'red' }}>{`Thừa ${(presentCubic - totalCubic).toFixed(2)} m3`}</span>
      )
    } else return ''
  }

  // function disabledDate(current) {
  //   return current && current < moment().startOf('day')
  // }

  const loadingCondition = isSubmitting || isFetching

  return (
    <Form
      id="manage_sale_orders_form"
      className="form-wrapper"
      onSubmit={handleSubmit}
      onKeyDown={preventEnterSubmit}
    >
      <BlockNavigation
        values={values}
        initialValues={initialValues}
        path={'edit'}
        firstTimeFetchedValues={firstTimeFetchedValues}
      />
      <div id="view_sale_order_drafting_pending" className="table-container">
        <div className="inner-view">
          <div className="header-view">
            <Title className="page-title">Thông tin đơn hàng</Title>
          </div>

          <div className="view-wrapper">
            <div className="form-item">
              <Title className="field-title">Order No:</Title>
              <div className="content">{values.order_number}</div>
            </div>

            <div className="form-item">
              <Title className="field-title">Ngày:</Title>
              <div className="content">{moment(values.date, 'YYYY-MM-DD').format(format)}</div>
            </div>

            <div className="form-item">
              <Title className="field-title">Khách hàng:</Title>
              <div className="content">{values.customer.name}</div>
            </div>

            <div className="total-wrapper">
              <div className="requirement">
                <Title className="requirement-title">Điều kiện sản xuất lô hàng:</Title>
                <span className="requirement-content" style={{ wordBreak: 'break-word' }}>
                  {values.production_requirement ? values.production_requirement : '-'}
                </span>
              </div>
            </div>

            <div className="order-table">
              <Title className="order-table-title">Thông tin sản phẩm:</Title>
              <Col className="order-table-content">
                <div className="inner-content">
                  <div className="scroll-wrap" style={{ minWidth: isSuperAdmin ? 2000 : 1600 }}>
                    <div className="row-wrapper">
                      <div className="row-item thread-row" style={{ maxWidth: 60 }}>
                        STT
                      </div>
                      <div className="row-item thread-row">Tên sản phẩm</div>
                      <div style={{ maxWidth: 160 }} className="row-item thread-row">
                        Packing
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 160 }}>
                        Giá mua sản phẩm chưa thành phẩm(Kg)
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 160 }}>
                        Giá mua sản phẩm thành phẩm(Thùng)
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 140 }}>
                        Số lượng (thùng)
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 140 }}>
                        Số lượng(đơn vị)
                      </div>
                      <div className="row-item thread-row" style={{ maxWidth: 120 }}>
                        Cubic (m3)
                      </div>
                      <div style={{ maxWidth: 140 }} className="row-item thread-row">
                        Ghi chú mặt hàng
                      </div>
                      {isSuperAdmin ? (
                        <>
                          <div style={{ maxWidth: 120 }} className="row-item thread-row">
                            Giá FOB lô trước (USD)
                          </div>
                          <div style={{ maxWidth: 120 }} className="row-item thread-row">
                            Duyệt giá FOB (USD)
                          </div>
                          <div style={{ maxWidth: 120 }} className="row-item thread-row">
                            Thành tiền (USD)
                          </div>
                        </>
                      ) : null}
                      <div className="row-item thread-row" style={{ maxWidth: 190 }}>
                        Số lượng đã sản xuất
                      </div>
                      <div
                        className="row-item thread-row"
                        style={{ alignItems: 'center', maxWidth: 100 }}
                      >
                        Đã hoàn thành
                      </div>
                    </div>
                    <div className="scroll-vertical-table">
                      {values.sale_order_items.map((item, index) => {
                        if (isFetching || !item.id) return null

                        return (
                          <ProducingItem
                            key={index}
                            loadingCondition={loadingCondition}
                            isSuperAdmin={isSuperAdmin}
                            focusingRow={focusingRow}
                            index={index}
                            item={item}
                            values={values}
                            dataFetch={dataFetch}
                            setFocus={setFocus}
                            setFieldValue={setFieldValue}
                          />
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            </div>

            <div className="form-item" style={{ flexDirection: 'column' }}>
              <div className="container-types">
                <Title className="order-table-title">Đóng hàng vào container</Title>
                <Col className="order-table-content">
                  <div className="inner-content">
                    <div className="scroll-wrap">
                      <div className="row-wrapper">
                        <div className="row-item thread-row">Loại container</div>
                        <div className="row-item thread-row">Cubic (m3)</div>
                        <div className="row-item thread-row">Số lượng</div>
                      </div>

                      <div className="row-wrapper">
                        <div className="row-item">{`(1) Container 20'DC`}</div>
                        <div className="row-item">28.50 m3</div>
                        <div className="row-item">{values.container_type_1_amount}</div>
                      </div>

                      <div className="row-wrapper">
                        <div className="row-item">{`(2) Container 40'DC`}</div>
                        <div className="row-item">58.50 m3</div>
                        <div className="row-item">{values.container_type_2_amount}</div>
                      </div>

                      <div className="row-wrapper">
                        <div className="row-item">{`(3) Container 40'HC`}</div>
                        <div className="row-item">66.50 m3</div>
                        <div className="row-item">{values.container_type_3_amount}</div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>

            <div className="form-item container-information">
              <div className="item">
                <Title className="field-title">Tổng số khối:</Title>
                <span className="content">{totalCubic ? `${totalCubic} m3` : '- m3'}</span>
              </div>

              <div className="item">
                <Title className="field-title">Hiện tại:</Title>
                <span className="content">
                  {presentCubic ? `${roundUpDecimal(presentCubic)} m3` : '- m3'}
                </span>
              </div>

              <div className="item">
                <Title className="field-title">Gợi ý:</Title>
                <span className="content">{generateSuggestion()}</span>
              </div>
            </div>

            <div className="view-form-item" style={{ maxWidth: 400 }}>
              <Title className="field-title">
                Ngày dự kiến sản xuất <span className="compulsory-field-symbol">&nbsp;*</span>
              </Title>
              <Field name="date" disabled={loadingCondition}>
                {() => (
                  <DatePicker
                    inputReadOnly
                    format={'DD-MM-YYYY'}
                    value={moment(values.produce_date, 'YYYY-MM-DD')}
                    onChange={(val) => setFieldValue('produce_date', val)}
                    style={{ width: '100%' }}
                    placeholder="Chọn ngày"
                    disabled={loadingCondition}
                    allowClear={false}
                    // disabledDate={disabledDate}
                  />
                )}
              </Field>
              <ErrorMessage component={(props) => <CustomError {...props} />} name="produce_date" />
            </div>

            <div className="view-form-item" style={{ maxWidth: 400 }}>
              <Title className="field-title">
                Ngày dự kiến hoàn thành <span className="compulsory-field-symbol">&nbsp;*</span>
              </Title>
              <Field name="date" disabled={loadingCondition}>
                {() => (
                  <DatePicker
                    inputReadOnly
                    format={'DD-MM-YYYY'}
                    value={moment(values.complete_date, 'YYYY-MM-DD')}
                    onChange={(val) => setFieldValue('complete_date', val)}
                    style={{ width: '100%' }}
                    placeholder="Chọn ngày"
                    disabled={loadingCondition}
                    allowClear={false}
                    // disabledDate={disabledDate}
                  />
                )}
              </Field>
              <ErrorMessage
                component={(props) => <CustomError {...props} />}
                name="complete_date"
              />
            </div>

            <div className="view-form-item" style={{ maxWidth: 400 }}>
              <Title className="field-title">
                Ngày dự kiến xuất hàng <span className="compulsory-field-symbol">&nbsp;*</span>
              </Title>
              <Field name="date" disabled={loadingCondition}>
                {() => (
                  <DatePicker
                    inputReadOnly
                    format={'DD-MM-YYYY'}
                    value={moment(values.export_date, 'YYYY-MM-DD')}
                    onChange={(val) => {
                      return setFieldValue('export_date', val)
                    }}
                    style={{ width: '100%' }}
                    placeholder="Chọn ngày"
                    disabled={loadingCondition}
                    allowClear={false}
                    // disabledDate={disabledDate}
                  />
                )}
              </Field>
              <ErrorMessage component={(props) => <CustomError {...props} />} name="export_date" />
            </div>

            <div className="other-documents">
              <OtherDocumentsTable
                documents={documents}
                setDocuments={setDocuments}
                action="edit"
              />
              <CustomUploadDocument
                documents={documents}
                setDocuments={setDocuments}
                setSubmitting={setSubmitting}
              />
            </div>

            <div className="producing-button-container">
              <Button
                icon={<RollbackOutlined />}
                type="primary"
                disabled={loadingCondition}
                className="cancel-button"
                onClick={handleBack}
              >
                Trở lại
              </Button>

              <Button
                icon={<FormOutlined />}
                type="primary"
                htmlType="submit"
                disabled={loadingCondition}
                className="process-button"
              >
                Cập nhật
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
})
