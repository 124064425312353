import React from 'react'
import { ErrorMessage } from 'formik'
import { Typography } from 'antd'
import { CustomError, OrderItems } from './OrderItems'

const { Title } = Typography

export default React.memo((props) => {
  const {
    loadingCondition,
    values,
    setFieldValue,
    editId,
    errors,
    path,
    // isSubmitting
  } = props
  // const typeMaterial = values.materials.filter(
  //   (material) => material.material_type === 'material'
  // )
  return (
    <div className="product-material">
      <Title className="part-title">5. Thông tin vật liệu (tạo nên 1 thùng thành phẩm)</Title>
      <div className="part-field-wrapper">
        {typeof errors.materials === 'string' && (
          <div className="error-material">
            <ErrorMessage component={(props) => <CustomError {...props} />} name="materials" />
          </div>
        )}
        <div className="form-item">
          <OrderItems
            name="materials"
            values={values}
            path={path}
            loadingCondition={loadingCondition}
            setFieldValue={setFieldValue}
            editId={editId}
          />

          <ErrorMessage component={(props) => <CustomError {...props} />} name="roles" />
        </div>
      </div>
    </div>
  )
})
