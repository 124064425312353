import React from 'react'
import { Typography, Alert, Select, DatePicker } from 'antd'
import moment from 'moment'
import { removeVietnameseTones } from 'Utils'

const { Title } = Typography
const { Option } = Select

export default React.memo((props) => {
  const { serverError, params, loading, staffList, doFetch } = props

  const onStaffSelect = (val) => doFetch((prev) => ({ ...prev, staff_id: val }))

  const onDateChange = (fieldName, val) => {
    if (fieldName === 'start_date' && val > moment(params.end_date, 'YYYY-MM-DD')) {
      doFetch((prev) => ({
        ...prev,
        start_date: val.format('YYYY-MM-DD'),
        end_date: val.format('YYYY-MM-DD'),
      }))
    } else {
      doFetch((prev) => ({ ...prev, [fieldName]: val.format('YYYY-MM-DD') }))
    }
  }

  // function disabledDate(current) {
  //   return current && current < moment(params.start_date, 'YYYY-MM-DD').startOf('day')
  // }

  return (
    <div id="time_attendence_form">
      <div className="time-attendence-form">
        <div id="manage_time_attendence_form" className="form-wrapper">
          <Title className="page-sub-title">Thông tin bảng lương</Title>
          {serverError && (
            <Alert message={serverError} type="error" className="server-error" showIcon />
          )}
          <div className="general-information">
            <div className="part-field-wrapper">
              <div className="form-item">
                <Title className="field-title">
                  Nhân viên <span className="compulsory-field-symbol">*</span>
                </Title>

                <Select
                  style={{ width: '100%' }}
                  showSearch
                  placeholder="Tìm kiếm nhân viên"
                  value={params.staff_id}
                  onChange={onStaffSelect}
                  optionFilterProp="children"
                  disabled={loading}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(input, option) => {
                    const dataInput = removeVietnameseTones(input)
                    const dataOption = removeVietnameseTones(option.children)
                    return dataOption.toLowerCase().indexOf(dataInput.toLowerCase()) >= 0
                  }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {staffList.result.map((item) => (
                    <Option
                      key={item.id}
                      value={item.id}
                      attr={item}
                    >{`${item.code}: ${item.name}`}</Option>
                  ))}
                </Select>
              </div>

              <div className="form-item">
                <Title className="field-title">
                  Từ ngày <span className="compulsory-field-symbol">&nbsp;*</span>
                </Title>
                <DatePicker
                  // inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={moment(params.start_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('start_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loading}
                  allowClear={false}
                />
              </div>

              <div className="form-item">
                <Title className="field-title">
                  Đến ngày <span className="compulsory-field-symbol">&nbsp;*</span>
                </Title>
                <DatePicker
                  // inputReadOnly
                  format={'DD-MM-YYYY'}
                  value={moment(params.end_date, 'YYYY-MM-DD')}
                  onChange={(val) => onDateChange('end_date', val)}
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày"
                  disabled={loading}
                  allowClear={false}
                  // disabledDate={disabledDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
