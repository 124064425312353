import * as yup from 'yup'

const requiredField = 'Trường này không được để trống'
const typeError = 'Vui lòng nhập số'

const manageSettingsSchema = yup.object().shape({
  usd_to_vnd_rate: yup.number().required(requiredField).typeError(typeError),
  sticker_fees: yup.number().required(requiredField).typeError(typeError),
})

export default manageSettingsSchema
