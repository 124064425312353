import React from 'react'
import { Typography } from 'antd'

import MaterialForm from '../Form'

const { Title } = Typography

export default (props) => {
  const id = props.match?.params.id

  return (
    <div id="new_material_page">
      <Title className="page-title">Tạo nguyên vật liệu mới</Title>

      <MaterialForm path={'new'} editId={id} duplicate={true} />
    </div>
  )
}
