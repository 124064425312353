import React from 'react'
import { Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

const SlidesModal = (props) => {
  const { visible, onCancel, children, ...rest } = props
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onCancel}
      style={{ top: 50, maxWidth: 900, maxHeight: 800 }}
      bodyStyle={{ padding: 5 }}
      width={'90%'}
      closeIcon={<CloseCircleOutlined style={{ color: 'red', fontSize: 20 }} />}
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default SlidesModal
