import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import get from 'lodash/get'
import map from 'lodash/map'

import FormView from './Form.view'
import { manageSaleOrderSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'
import EditMaterialPriceModal from './EditMaterialPriceModal'

const initialValues = {
  order_number: '',
  date: moment(),
  customer_id: undefined,
  production_requirement: '',
  customer: { id: '', code: '', name: '' },
  sale_order_items: [
    {
      completed: false,
      product_id: undefined,
      product: { id: undefined, code: '', name: '' },
      second_price: 0,
      specification: '',
      first_price: 0,
      product_type: '',
      box_quantity: 1,
      quantity_in_box: 0,
      box_size: 0,
      note: '',
      total_quantity: 0,
      total_size: 0,
      old_fob: 0,
      new_fob: 0,
      use_new_prices: false,
      net_weight: 0,
      gross_weight: 0,
      total: 0,
      // apply_new_product_price: false,
    },
  ],
  status: 'drafting',
  usd_to_vnd_rate: 0,
  sticker_fees: 0,
  documents: [],
  container_type_1_amount: 0,
  container_type_2_amount: 0,
  container_type_3_amount: 0,

  produce_date: moment().format('YYYY-MM-DD'),
  complete_date: moment().format('YYYY-MM-DD'),
  export_date: moment().format('YYYY-MM-DD'),
}

const modalInitialValues = {
  sale_order_id: undefined,
  sale_order_materials: [
    {
      material_id: undefined,
      material_name: '',
      price: undefined,
      use_new_price: false,
    },
  ],
}

export default React.memo((props) => {
  const { path, editId, dataFetch, isFetching, firstTimeFetchedValues } = props
  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [editProductMaterial, setEditProductMaterial] = useState({
    data: modalInitialValues,
    loading: true,
    visible: false,
  })

  const [documents, setDocuments] = useState([])

  useEffect(() => {
    if (editId && dataFetch && dataFetch.documents) {
      const formattedDocuments = map(dataFetch.documents, (document) => ({
        uid: `uid-${document.aws_object_key}`,
        originFileObj: {
          url: document.url,
          name: document.file_name,
          object_key: document.aws_object_key,
        },
      }))
      setDocuments(formattedDocuments)
    }
  }, [dataFetch, editId])

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)

    const formattedDocumentsParam = map(documents, (document) => ({
      file_name: get(document, 'originFileObj.name'),
      aws_object_key: get(document, 'originFileObj.object_key'),
    }))
    const formatSaleOrderItems = values.sale_order_items.map((item) => {
      let secondPrice = 0
      if (item.product_type === 'semifinish') {
        secondPrice = item.first_price + item.production_cost
      } else {
        secondPrice = item.second_price
      }
      return { ...item, second_price: secondPrice }
    })
    const formatParams = {
      ...values,
      sale_order_items: formatSaleOrderItems,
      date: values.date.format('YYYY-MM-DD'),
      produce_date: values.produce_date,
      complete_date: values.complete_date,
      export_date: values.export_date,
      documents: formattedDocumentsParam,
    }

    if (path === 'new') {
      createPurchaseOrder(formatParams)
    } else if (path === 'edit' || path === 'editProducing') {
      updatePurchaseOrder(formatParams)
    }
  }

  const createPurchaseOrder = async (params) => {
    const url = '/sale_orders/'
    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/sale-orders/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updatePurchaseOrder = async (params) => {
    const url = `/sale_orders/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setSubmitting(false)
      history.push(`/sale-orders/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="sale-order-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            dataFetch={dataFetch}
            isFetching={isFetching}
            isSubmitting={isSubmitting}
            setSubmitting={setSubmitting}
            editProductMaterial={editProductMaterial}
            setEditProductMaterial={setEditProductMaterial}
            documents={documents}
            setDocuments={setDocuments}
            initialValues={initialValues}
            firstTimeFetchedValues={firstTimeFetchedValues}
          />
        )}
        validationSchema={manageSaleOrderSchema}
      />

      <EditMaterialPriceModal
        {...editProductMaterial}
        setEditProductMaterial={setEditProductMaterial}
      />
    </div>
  )
})
