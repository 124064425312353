import React from 'react'

import { CheckPermission } from 'Components'
import List from './List'
import New from './New'
import View from './View'
import Edit from './Edit'

import './index.scss'

const ListStaff = (props) => <CheckPermission component={List} {...props} />

const ViewStaff = (props) => <CheckPermission component={View} {...props} />

const NewStaff = (props) => <CheckPermission component={New} {...props} />

const EditStaff = (props) => <CheckPermission component={Edit} {...props} />

export { ListStaff, ViewStaff, NewStaff, EditStaff }
