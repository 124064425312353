import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import FormView from './Form.view'
import { manageStaffsSchema } from 'Validation'
import axios from 'Utils/axiosRexyAdmin'

const initialValues = {
  code: '',
  name: '',
  code_in_time_attendance_machine: '',
  job_title: undefined,
  staff_type: undefined,
  salary_type: undefined,
  start_date: moment(),
  salary: undefined,
  note: '',
}

export default React.memo((props) => {
  const { path, editId } = props

  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = async (values) => {
    setServerError(null)
    setSubmitting(true)

    const formattedParams = {
      ...values,
      code_in_time_attendance_machine: values.code_in_time_attendance_machine
        ? values.code_in_time_attendance_machine
        : '',
      job_title: values.job_title ? values.job_title : null,
    }

    if (path === 'new') {
      createStaff(formattedParams)
    } else if (path === 'edit') {
      updateStaff(formattedParams)
    }
  }

  const createStaff = async (params) => {
    const url = '/staffs/'
    try {
      const resp = await axios.post(url, { ...params })
      setSubmitting(false)
      history.push(`/staffs/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  const updateStaff = async (params) => {
    const url = `/staffs/${editId}/edit/`

    try {
      const resp = await axios.put(url, { ...params })
      setSubmitting(false)
      history.push(`/staffs/${resp.data.id}/view`)
    } catch (error) {
      setServerError(error.response?.data.errors?.message)
      setSubmitting(false)
    }
  }

  return (
    <div className="staff-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        children={(props) => (
          <FormView
            {...props}
            serverError={serverError}
            editId={editId}
            path={path}
            isSubmitting={isSubmitting}
          />
        )}
        validationSchema={manageStaffsSchema}
      />
    </div>
  )
})
