import { useState, useEffect } from 'react'
import axiosExpand from 'Utils/axiosExpand'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { message } from 'antd'

import { convertObjectToQueryString, convertQueryStringToObject } from 'Utils'

const format = 'YYYY-MM-DD'

const useDebounceSearch = (value, delay, firstRender) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    if (firstRender) return setDebouncedValue(value)
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, value])
  return debouncedValue
}

const useDataApi = (urlInformation, filterKey) => {
  const [state, setState] = useState({
    data: [],
    loading: false,
    errors: null,
  })
  const [pagination, setPagination] = useState({
    material_id: undefined,
    material_code: undefined,
    start_date: moment().format(format),
    end_date: moment().format(format),
    current: 1,
    pageSize: 10,
    order_by: '',
    order: '',
  })
  const [params, setParams] = useState({
    material_id: undefined,
    material_code: undefined,
    start_date: moment().format(format),
    end_date: moment().format(format),
    page: 1,
    per_page: 10,
    order_by: '',
    order: '',
  })
  const [urlInfo, setUrlInfo] = useState(urlInformation)
  const [searchTerm, setSearchTerm] = useState('')
  const [initSearchValue, setInitSearchValue] = useState('')
  const [firstRender, setFirstRender] = useState(true)

  const history = useHistory()
  const location = useLocation()

  const parseFilter = (value) => {
    if (value) {
      if (Array.isArray(value)) return value
      return value.split(',')
    }
    return null
  }

  const computeFilterKey = (value) => {
    if (value === 'null') return null
    return parseFilter(value)
  }

  useEffect(() => {
    let didCancel

    let search = location.search.substring(1)
    let formattedParams = {
      start_date: moment().format(format),
      end_date: moment().format(format),
    }

    if (search) {
      let convetedString = convertQueryStringToObject(search)

      if (filterKey && convetedString[filterKey]) {
        const computedValue = computeFilterKey(convetedString[filterKey])
        convetedString = {
          ...convetedString,
          [filterKey]: computedValue,
        }
      }

      formattedParams = {
        ...convetedString,
        material_id:
          convetedString.material_id === 'undefined' || convetedString.material_id === undefined
            ? undefined
            : Number(convetedString.material_id),
        page: convetedString.page ? Number(convetedString.page) : 1,
        per_page: convetedString.per_page ? Number(convetedString.per_page) : 10,
        current: convetedString.page ? Number(convetedString.page) : 1,
        pageSize: convetedString.per_page ? Number(convetedString.per_page) : 10,
      }
      setSearchTerm(formattedParams.keyword ? formattedParams.keyword : '')
      setInitSearchValue(formattedParams.keyword ? formattedParams.keyword : '')

      setParams((prev) => ({
        ...prev,
        ...formattedParams,
        page: formattedParams.page,
        per_page: formattedParams.per_page,
        order_by: formattedParams.order_by,
        order: formattedParams.order,
      }))

      setPagination((prev) => ({
        ...prev,
        ...formattedParams,
        current: formattedParams.page,
        pageSize: formattedParams.per_page,
        order_by: formattedParams.order_by,
        order: formattedParams.order,
      }))
    }
    const fetchData = async () => {
      setState((prev) => ({ ...prev, loading: true, errors: null }))
      try {
        const result = await axiosExpand.get(urlInfo.url, {
          params: {
            ...formattedParams,
          },
        })
        if (!didCancel) {
          if (urlInfo.key) {
            setState((prev) => ({
              ...prev,
              data: result.data[urlInfo.key],
              loading: false,
            }))
          } else {
            setState((prev) => ({
              ...prev,
              data: result.data,
              loading: false,
            }))
          }

          setFirstRender(false)
          if (result.data.pagination) {
            setPagination((prev) => ({
              ...prev,
              ...params,
              current: result.data.pagination.page,
              pageSize: result.data.pagination.per_page,
              order: formattedParams.order,
              order_by: formattedParams.order_by,
              total: result.data.pagination.total_count,
            }))

            const queryString = {
              ...formattedParams,
              ...result.data.pagination,
            }

            const converted = convertObjectToQueryString(queryString)

            history.push({
              pathname: location.pathname,
              search: converted,
            })
          }
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors?.message,
          }))
          setFirstRender(false)
        }
      }
    }

    if (firstRender) fetchData()

    return () => {
      didCancel = true
      setFirstRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedSearchTerm = useDebounceSearch(searchTerm, 500, firstRender)

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      setState((prev) => ({ ...prev, loading: true, errors: null }))
      if (initSearchValue !== debouncedSearchTerm) params.page = 1
      try {
        const result = await axiosExpand.get(urlInfo.url, {
          params: {
            ...params,
            page: params.page,
            keyword: debouncedSearchTerm,
          },
        })

        if (!didCancel) {
          if (urlInfo.key) {
            setState((prev) => ({
              ...prev,
              data: result.data[urlInfo.key],
              loading: false,
            }))
          } else {
            setState((prev) => ({
              ...prev,
              data: result.data,
              loading: false,
            }))
          }

          setInitSearchValue(debouncedSearchTerm)
          if (result.data.pagination) {
            setPagination((prev) => ({
              ...prev,
              current: result.data.pagination.page,
              pageSize: result.data.pagination.per_page,
              order: params.order,
              order_by: params.order_by,
              total: result.data.pagination.total_count,
            }))

            const queryString = {
              ...params,
              ...result.data.pagination,
              keyword: debouncedSearchTerm,
            }

            const converted = convertObjectToQueryString(queryString)

            history.push({
              pathname: location.pathname,
              search: converted,
            })
          }
        }
      } catch (error) {
        if (!didCancel) {
          message.error(error.response?.data.errors?.message)
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors?.message,
          }))
        }
      }
    }

    if (!firstRender) fetchData()

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, debouncedSearchTerm, urlInfo.url, urlInfo.key])

  return {
    ...state,
    params,
    searchTerm,
    firstRender,
    initSearchValue,
    pagination,
    setPagination,
    doFetch: setParams,
    setSearchTerm,
    setUrlInfo,
  }
}
export default useDataApi
