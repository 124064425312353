import { persist } from 'easy-peasy'

import auth from './auth'
import roles from './roles'
import purchaseOrder from './purchaseOrder'
import menuTabs from './menuTabs'

export default {
  auth: persist(auth, {
    whitelist: ['email', 'token', 'isLoggedIn'],
    storage: 'localStorage',
  }),
  roles,
  purchaseOrder,
  menuTabs,
}
