import { FormOutlined, PlusOutlined, RollbackOutlined } from '@ant-design/icons'
import { Alert, Button } from 'antd'
import { BlockNavigation } from 'Components'
import { Form } from 'formik'
import size from 'lodash/size'

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useScrollToErrors, preventEnterSubmit } from 'Utils'
import axios from 'Utils/axiosRexyAdmin'
import GeneralInformation from './FormPart1'
import ProductDetails from './FormPart2'
import ProductQuality from './FormPart3'
import ProductPrices from './FormPart4'
import MaterialDetails from './FormPart5'

export default React.memo((props) => {
  const {
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    serverError,
    editId,
    path,
    errors,
    touched,
    duplicate,
    initialValues,
  } = props

  const history = useHistory()
  const [firstTimeFetchedValues, setFirstTimeFetchedValues] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const [searchTerm, setSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })

  const [supplierSearchTerm, setSupplierSearchTerm] = useState({
    keyword: '',
    result: [],
    searching: false,
  })

  const [previewProductImage, setPreviewProductImage] = useState({
    files: [],
    visible: false,
    currentIndex: 0,
  })

  const [uploading, setUploading] = useState(false)

  const { setShouldScroll } = useScrollToErrors(errors, serverError, touched)
  // const [visibleMaterialDetails, setVisibleMaterialDetails] = useState(true)
  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/products/${editId}/edit`
      setIsFetching(true)
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          let codes_obj = duplicate
            ? {
                code: '',
                customer_code: '',
                package_barcode: '',
                box_barcode: '',
                hs_code: '',
              }
            : {}

          let formattedImages = []
          if (resp.data.addtional_hashes.length) {
            formattedImages = resp.data.addtional_hashes.map((image, index) => ({
              uid: `uid-${index}`,
              name: 'image.png',
              status: 'done',
              url: image.image_link,
              original: image.image_link,
              thumbnail: image.image_link,
              thumbUrl: image.image_link,
              originFileObj: { aws_object_key: image.aws_object_key },
            }))
            // setProductImages(formattedImages)
          }

          let supplierIds = []
          if (resp.data.suppliers.length) {
            supplierIds = resp.data.suppliers.map((supplier) => supplier.supplier_id)

            const supplierOptions = resp.data.suppliers.map((supplier) => ({
              ...supplier,
              id: supplier.supplier_id,
            }))

            setSupplierSearchTerm((prev) => ({
              ...prev,
              result: supplierOptions,
            }))
          }
          const typeMaterial = resp.data.materials.filter(
            (material) => material.material_type === 'material'
          )
          setValues({
            ...values,
            ...resp.data,
            ...codes_obj,
            suppliers: supplierIds,
            aws_object_keys: formattedImages,
            typeMaterial: typeMaterial.length > 0 ? 'material' : 'packing',
          })

          setPreviewProductImage((prev) => ({ ...prev, files: formattedImages }))

          resp.data.customer_id = duplicate ? undefined : resp.data.customer_id
          resp.data.customer = duplicate ? {} : resp.data.customer

          const notSetFirstTimeFetchedValuesYet = size(firstTimeFetchedValues) === 0

          if (notSetFirstTimeFetchedValuesYet) {
            setFirstTimeFetchedValues({
              ...values,
              ...resp.data,
              ...codes_obj,
              suppliers: supplierIds,
            })
          }

          setIsFetching(false)
        }
      } catch (error) {
        if (!didCancel) {
          setIsFetching(false)
        }
      }
    }
    if (editId) {
      fetchData()
    }

    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, setValues])

  const handleOK = () => {
    setShouldScroll(true)
    // setFieldValue('aws_object_keys', productImages)
  }
  // const getProductType = (value) => {
  //   if (value === 'semifinish') {
  //     setVisibleMaterialDetails(true)
  //   } else {
  //     setVisibleMaterialDetails(false)
  //   }
  // }
  const loadingCondition = isSubmitting || isFetching

  return (
    <>
      <Form
        id="manage_products_form"
        className="form-wrapper"
        onSubmit={handleSubmit}
        style={{ width: '100%' }}
        onKeyDown={preventEnterSubmit}
      >
        <BlockNavigation
          values={values}
          initialValues={initialValues}
          path={path}
          firstTimeFetchedValues={firstTimeFetchedValues}
        />
        {serverError && (
          <Alert message={serverError} type="error" className="server-error" showIcon />
        )}

        <GeneralInformation
          {...props}
          loadingCondition={loadingCondition}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          // onSearch={onSearch}
        />

        <ProductDetails
          {...props}
          loadingCondition={loadingCondition}
          // productImages={productImages}
          // setProductImages={setProductImages}
          previewProductImage={previewProductImage}
          setPreviewProductImage={setPreviewProductImage}
          supplierSearchTerm={supplierSearchTerm}
          setSupplierSearchTerm={setSupplierSearchTerm}
          uploading={uploading}
          setUploading={setUploading}
        />

        <ProductQuality {...props} loadingCondition={loadingCondition} />

        <ProductPrices {...props} loadingCondition={loadingCondition} />
        {values.product_type === 'semifinish' && (
          <MaterialDetails {...props} loadingCondition={loadingCondition} editId={editId} />
        )}

        <div className="form-button-contain">
          <Button
            loading={loadingCondition}
            disabled={uploading}
            type="primary"
            className="cancel-button"
            icon={<RollbackOutlined />}
            onClick={() =>
              !!history.goBack && typeof history.goBack === 'function'
                ? history.goBack()
                : history.push('/products')
            }
          >
            Trở lại
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            icon={path === 'new' ? <PlusOutlined /> : <FormOutlined />}
            loading={loadingCondition}
            disabled={uploading}
            onClick={handleOK}
          >
            {path === 'new' ? 'Tạo sản phẩm' : 'Cập nhật'}
          </Button>
        </div>
      </Form>
    </>
  )
})
