import React from 'react'

import { CheckPermission } from 'Components'
import View from './View'

import './index.scss'

const allowRoles = ['R', 'OM']

const ViewStockReport = (props) => (
  <CheckPermission rolesPermission={allowRoles} component={View} {...props} />
)

export { ViewStockReport }
