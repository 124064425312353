import React from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import form_logo from 'Assets/images/logo3.svg'
import email_icon from 'Assets/images/email_icon.png'

const { Title } = Typography
const { Password } = Input

const LoginView = React.memo((props) => {
  const { handleChange, values, isSubmitting, isValidating, handleSubmit, serverError } = props

  const CustomError = (props) => {
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }

  const EmailIcon = (props) => {
    return <img {...props} src={email_icon} alt="email icon" width={16} height={14} />
  }

  const loadingCondition = isSubmitting || isValidating

  return (
    <Form id="login_step_one_form" className="form-wrapper" onSubmit={handleSubmit}>
      <div className="form-logo">
        <img src={form_logo} alt="form logo" />
      </div>

      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">Email</Title>
        <Field
          as={Input}
          name="email"
          placeholder="Nhập email"
          className="email-field"
          size="large"
          prefix={<EmailIcon style={{ marginRight: 5 }} />}
          disabled={loadingCondition}
          value={values.email}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="email" />
      </div>

      <div className="form-item">
        <Title className="field-title">Mật khẩu</Title>
        <Field
          as={Password}
          name="password"
          placeholder="Nhập mật khẩu"
          className="password-field"
          size="large"
          prefix={<LockOutlined style={{ marginRight: 5 }} />}
          value={values.password}
          onChange={handleChange}
          disabled={loadingCondition}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="password" />
        <div className="forgot-password-link">
          <Link to="/forgot-password">Quên mật khẩu?</Link>
        </div>
      </div>

      <Button type="primary" htmlType="submit" className="submit-button" loading={loadingCondition}>
        Đăng nhập
      </Button>
    </Form>
  )
})

export default LoginView
