import React, { useState, useEffect } from 'react'
import { Table, Input, Button, Select, message } from 'antd'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'
import styled from 'styled-components'
import _ from 'lodash'

import axios from 'Utils/axiosRexyAdmin'
import { useDataApi } from 'Hooks'
import { pageSizeConfig } from 'Utils'
import DeleteModal from './DeleteModal'

const DeleteButton = styled(Button)`
  color: ${(props) => props.$buttonColor};
  &:hover {
    color: ${(props) => props.$buttonColor};
  }
`

const { Option } = Select

export default React.memo(() => {
  const history = useHistory()

  const [clickedRow, setClickedRow] = useState({
    id: undefined,
    status: '',
    loading: false,
  })

  const [listStatus, setListStatus] = useState({ data: [], loading: false })

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    order_number: '',
    visible: false,
  })

  const { data, loading, pagination, doFetch, setSearchTerm } = useDataApi({
    url: '/notifications/',
    key: 'records',
  })

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const notificationsState = useStoreState((state) => state.menuTabs['notifications'])

  useEffect(() => {
    let didCancel
    if (notificationsState && !didCancel) {
      doFetch((prev) => ({ ...prev, page: 1 }))
      shouldRefresh({ key: 'notifications', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsState])

  useEffect(() => {
    let didCancel = false
    const fetchStatus = async () => {
      const url = '/notifications/new/'

      setListStatus((prev) => ({ ...prev, loading: true }))

      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setListStatus({ data: resp.data.statuses, loading: false })
        }
      } catch (error) {
        if (!didCancel) {
          setListStatus((prev) => ({ ...prev, loading: false }))
          message.error(error.response?.data.errors?.message)
        }
      }
    }

    fetchStatus()

    return () => (didCancel = true)
  }, [])

  const columns = [
    {
      title: 'Thông báo',
      dataIndex: 'content',
      sortOrder: pagination.order_by === 'content' ? `${pagination.order}` : null,
      width: 300,
      sorter: true,
    },
    {
      title: 'Order No',
      dataIndex: 'sale_order?.order_number',
      sortOrder: pagination.order_by === 'sale_order?.order_number' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      render: (order, row) => {
        return (
          <div className="sale-order-link" onClick={() => handleOrderClick(row)}>
            {row.sale_order?.order_number}
          </div>
        )
      },
    },
    {
      title: 'Export No',
      dataIndex: 'commodity_export?.export_number',
      sortOrder:
        pagination.order_by === 'commodity_export?.export_number' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      render: (order, row) => {
        return (
          <div className="sale-order-link" onClick={() => handleExportClick(row)}>
            {row.commodity_export?.export_number}
          </div>
        )
      },
    },
    {
      title: 'Purchase Order No',
      dataIndex: 'purchase_order?.order_number',
      sortOrder:
        pagination.order_by === 'purchase_order?.order_number' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
      render: (order, row) => {
        return (
          <div className="sale-order-link" onClick={() => handlePurchaseOrderClick(row)}>
            {row.purchase_order?.order_number}
          </div>
        )
      },
    },
    {
      title: 'Nước xuất khẩu',
      dataIndex: 'customer_country',
      sortOrder: pagination.order_by === 'customer_country' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
    },
    {
      title: 'Dateline',
      dataIndex: 'dateline',
      sortOrder: pagination.order_by === 'dateline' ? `${pagination.order}` : null,
      width: 140,
      sorter: true,
      render: (dateline, row) => {
        // const color =
        //   row.status === 'completed'
        //     ? '#097b9e'
        //     : moment(dateline, 'YYYY-MM-DD').isSameOrBefore(moment().startOf('day'))
        //     ? '#ec2020'
        //     : '#148d1a'
        return (
          // <div style={{ color: color }}>{moment(dateline, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
          <div>{moment(dateline, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
        )
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      sortOrder: pagination.order_by === 'status' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
      render: (status, row) => renderStatusOrder(status, row),
    },
    {
      title: 'Hoàn thành',
      width: 220,
      render: (status, row) => renderCompletedColumn(status, row),
    },
    {
      key: 'x',
      width: 100,
      render: (record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    const completed = record.status === 'completed'

    const buttonColor = `${completed ? '#ec2020' : 'rgb(0, 0, 0, 0.25)'}`

    return (
      <div className="table-actions">
        <DeleteButton
          className="action-item"
          type="link"
          onClick={() => handleDelete(record)}
          disabled={!completed}
          $buttonColor={buttonColor}
        >
          Xoá
        </DeleteButton>
      </div>
    )
  }

  const renderStatusOrder = (status, row) => {
    const activeLoading = clickedRow.loading && row.id === clickedRow.id

    return (
      <Select
        onChange={(status) => onChangeStatus(status, row)}
        value={status}
        style={{ width: '100%' }}
        disabled={activeLoading}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ maxWidth: 650 }}
      >
        {renderStatusOptions(status)}
      </Select>
    )
  }

  const renderCompletedColumn = (record) => {
    const completed = record.status === 'completed'

    return (
      <div>
        {completed ? (
          <>
            <div>{_.get(record, 'completed_by.name')}</div>
            <div>{moment(_.get(record, 'updated_at')).format('YYYY-MM-DD hh:mm:ss')}</div>
          </>
        ) : (
          <>
            <div>-</div>
          </>
        )}
      </div>
    )
  }

  const renderStatusOptions = (presentStatus) => {
    const statusIndex = listStatus.data.findIndex((x) => x.value === presentStatus)
    const newListStatus = listStatus.data.filter(
      (_, index) => index === statusIndex || index === statusIndex + 1
    )
    return newListStatus.map((status) => (
      <Option key={status.value} value={status.value}>
        {status.name}
      </Option>
    ))
  }

  const handleDelete = (record) => {
    setDeleteModal({ id: record.id, order_number: record.order_number, visible: true })
  }

  const handleOrderClick = (row) => {
    history.push(`/sale-orders/${row.sale_order.id}/view`)
  }

  const handleExportClick = (row) => {
    history.push(`/commodity-exports/${row.commodity_export.id}/view`)
  }

  const handlePurchaseOrderClick = (row) => {
    history.push(`/purchase-orders/${row.purchase_order.id}/view`)
  }

  const onChangeStatus = async (status, row) => {
    setClickedRow({ id: row.id, loading: true, status })
    try {
      await axios.put(`/notifications/${row.id}/edit/`, {
        status,
      })
      doFetch({})
      setClickedRow({ id: undefined, loading: false })
    } catch (error) {
      setClickedRow({ id: undefined, loading: false })
      doFetch({})
      message.error(error.response?.data.errors?.message)
    }
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    )
  }

  const dataSource = data.map((transaction, index) => {
    return {
      ...transaction,
      'sale_order.order_number': transaction.sale_order?.order_number,
      // 'purchase_order.order_number': transaction.purchase_order.order_number,
    }
  })

  const getRowType = (record) => {
    const date = _.get(record, 'dateline')
    let daysLeft = moment(date, 'YYYY-MM-DD hh:mm:ss')
      .startOf('day')
      .diff(moment().startOf('day'), 'days')

    if (daysLeft <= 2) return 'row-type-1'

    if (daysLeft <= 5) return 'row-type-2'

    return 'row-type-3'
  }

  const loadingCondition = loading || listStatus.loading

  return (
    <div id="manage_notification_table">
      <Table
        title={renderTitle}
        // className="manage-notification-table"
        // rowClassName='light-row'
        rowClassName={(record) => getRowType(record)}
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loadingCondition}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1450, y: 720 }}
        bordered
      />
      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
})
