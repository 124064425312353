import React, { useState, useEffect } from 'react'
import { Typography, Card, Col, Row, Button } from 'antd'
import { RollbackOutlined, FormOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import axios from 'Utils/axiosRexyAdmin'
import { getFieldName, formatNumber } from 'Utils'

const { Title } = Typography
const format = 'YYYY-MM-DD'

const INITIAL_PROFILE = {
  code: '',
  name: '',
  job_title: '',
  staff_type: '',
  start_date: moment().format(format),
  salary: '',
  note: '',
}

const STAFF_TYPE = [
  { name: 'Nhân viên văn phòng', value: 'office' },
  { name: 'Nhân viên xưởng', value: 'factory' },
]

export default React.memo((props) => {
  const history = useHistory()
  const viewId = props.match?.params.id

  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  const [jobTitleList, setJobTitleList] = useState([])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/staffs/${viewId}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setState((prev) => ({ ...prev, data: { ...prev.data, ...resp.data }, loading: false }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [viewId])

  useEffect(() => {
    let didCancel = false

    const fetchJobTitles = async () => {
      const url = '/staffs/new/'
      const resp = await axios.get(url)
      setJobTitleList(resp.data.job_titles)
    }

    if (!didCancel) {
      fetchJobTitles()
    }
    return () => (didCancel = true)
  }, [])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/staffs`)
  }

  const handleEdit = () => {
    history.push(`/staffs/${viewId}/edit`)
  }

  return (
    <div id="view_staff" className="table-container">
      <Title className="page-title">Thông tin nhân viên</Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">Thông tin chung</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên nhân viên
                </Col>
                <Col className="content-wrapper" span={16}>
                  {`${state.data.name}`}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã nhân viên
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã nhân viên trên máy chấm công
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.code_in_time_attendance_machine}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Phân loại nhân viên
                </Col>
                <Col className="content-wrapper" span={16}>
                  {getFieldName(state.data.staff_type, STAFF_TYPE)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Vị trí công tác
                </Col>
                <Col className="content-wrapper" span={16}>
                  {getFieldName(state.data.job_title, jobTitleList)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mức lương
                </Col>
                <Col className="content-wrapper" span={16}>
                  {`${formatNumber(Number(state.data.salary))} VND${
                    state.data.staff_type === 'factory' ? ' / Ngày' : ' / Tháng'
                  }`}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ngày bắt đầu
                </Col>
                <Col className="content-wrapper" span={16}>
                  {moment(state.data.start_date, format).format('DD-MM-YYYY')}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ghi chú
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>

          <Button
            icon={<FormOutlined />}
            type="primary"
            loading={state.loading}
            className="process-button"
            onClick={handleEdit}
          >
            Chỉnh sửa
          </Button>
        </div>
      </div>
    </div>
  )
})
