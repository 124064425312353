import React, { useState, useEffect } from 'react'
import { Table, Input, Button, Select, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useStoreState, useStoreActions } from 'easy-peasy'

import axios from 'Utils/axiosRexyAdmin'
import axiosExpand from 'Utils/axiosExpand'
import { useDataApi } from 'Hooks'
import { formatNumber, pageSizeConfig } from 'Utils'
// import DeleteModal from './DeleteModal'
import SubmittedToInStockModel from './SubmittedToInStockModel'
import DeleteModal from './DeleteModal'

const { Option } = Select

export default React.memo(() => {
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState({
    id: undefined,
    name: '',
    visible: false,
  })

  const [clickedRow, setClickedRow] = useState({
    id: undefined,
    status: '',
    loading: false,
    order_number: '',
  })

  const [listStatus, setListStatus] = useState({ data: [], loading: false })
  const [submittedToInStockModel, setSubmittedToInStockModel] = useState({
    id: undefined,
    status: '',
    visible: false,
  })

  const { params, data, loading, pagination, doFetch, setSearchTerm } = useDataApi(
    {
      url: '/purchase_orders/',
      key: 'records',
    },
    'order_type'
  )

  const dataSource = data.map((order, index) => ({
    ...order,
    'supplier.code': `${order.supplier.code} : ${order.supplier.name}`,
    order_type: order.order_type,
  }))

  const shouldRefresh = useStoreActions((actions) => actions.menuTabs.shouldRefresh)
  const purchaseOrders = useStoreState((state) => state.menuTabs['purchase-orders'])

  useEffect(() => {
    let didCancel
    if (purchaseOrders && !didCancel) {
      doFetch({ order_by: '', order: '' })
      shouldRefresh({ key: 'purchase-orders', status: false })
    }
    return () => (didCancel = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrders])

  useEffect(() => {
    let didCancel = false
    const fetchStatus = async () => {
      const url = '/purchase_orders/new/'

      setListStatus((prev) => ({ ...prev, loading: true }))

      try {
        const resp = await axios.get(url)

        if (!didCancel) {
          setListStatus({ data: resp.data.status, loading: false })
        }
      } catch (error) {
        if (!didCancel) {
          setListStatus((prev) => ({ ...prev, loading: false }))
          message.error(error.response?.data.errors?.message)
        }
      }
    }

    fetchStatus()

    return () => (didCancel = true)
  }, [])

  const renderFiltered = (value) => {
    if (value) {
      if (Array.isArray(value)) return value
      return value.split(',')
    }
    return null
  }

  const columns = [
    {
      title: 'Loại đơn đặt hàng',
      dataIndex: 'order_type',
      sortOrder: pagination.order_by === 'order_type' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
      filters: [
        {
          text: 'Nguyên vật liệu',
          value: 'material',
        },
        {
          text: 'Tem',
          value: 'label',
        },
        {
          text: 'Bao bì',
          value: 'packing',
        },
        {
          text: 'Thành phẩm',
          value: 'finish_product',
        },
      ],
      render: (type) =>
        type === 'material'
          ? 'Nguyên vật liệu'
          : type === 'packing'
          ? 'Bao bì'
          : type === 'label'
          ? 'Tem'
          : type === 'finish_product'
          ? 'Thành phẩm'
          : '',
      onFilter: (value, record) => record.order_type === value,
      filteredValue: renderFiltered(params.order_type),
    },
    {
      title: 'Mã đơn đặt hàng',
      dataIndex: 'order_number',
      sortOrder: pagination.order_by === 'order_number' ? `${pagination.order}` : null,
      width: 170,
      sorter: true,
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'supplier.code',
      sortOrder: pagination.order_by === 'supplier.code' ? `${pagination.order}` : null,
      width: 200,
      sorter: true,
    },
    {
      title: 'Tổng đơn đặt hàng (VND)',
      dataIndex: 'total',
      sortOrder: pagination.order_by === 'total' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
      render: (record) => formatNumber(Number(record)),
    },
    {
      title: 'Ngày đơn đặt hàng',
      dataIndex: 'date',
      sortOrder: pagination.order_by === 'date' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
      render: (date) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      sortOrder: pagination.order_by === 'status' ? `${pagination.order}` : null,
      width: 180,
      sorter: true,
      render: (status, row) => renderStatusOrder(status, row),
    },
    {
      key: 'x',
      width: 160,
      // fixed: 'right',
      render: (record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        {/* <Button className="action-item view" type="link" onClick={() => handleView(record)}>
          Xem
        </Button> */}
        {record.status !== 'pending' ? <div style={{width: '66px'}}></div> : (
          <Button className="action-item update" type="link" onClick={(e) => handleEdit(record, e)}>
            Sửa
          </Button>
        )}

        {record.status === 'pending' || record.status === 'submitted' ? 
          <Button
            className="action-item delete"
            type="link"
            danger
            onClick={(e) => handleDelete(record, e)}
          >
            Xoá
          </Button> 
          : null
        }

      </div>
    )
  }

  const renderStatusOrder = (status, row) => {
    const activeLoading = clickedRow.loading && row.id === clickedRow.id

    return (
      <Select
        onChange={(status, e) => onChangeStatus(status, row)}
        value={status}
        style={{ width: '80%' }}
        disabled={activeLoading}
        onClick={(event) => event.stopPropagation()}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ maxWidth: 650 }}
      >
        {renderStatusOptions(status)}
      </Select>
    )
  }

  const renderStatusOptions = (presentStatus) => {
    const statusIndex = listStatus.data.findIndex((x) => x.value === presentStatus)
    const newListStatus = listStatus.data.filter(
      (_, index) => index === statusIndex || index === statusIndex + 1
    )
    return newListStatus.map((status) => (
      <Option key={status.value} value={status.value}>
        {status.name}
      </Option>
    ))
  }

  const handCreate = () => {
    history.push('/purchase-orders/new')
  }

  const handleView = (record) => {
    history.push(`/purchase-orders/${record.id}/view`)
  }

  const handleEdit = (record, e) => {
    e.stopPropagation()
    history.push(`/purchase-orders/${record.id}/edit`)
  }

  const handleDelete = (record, event) => {
    event.stopPropagation()
    setDeleteModal({ id: record.id, order_number: record.order_number, visible: true })
  }

  const onChangeStatus = async (status, row) => {
    if (status === 'in_stock') {
      // +++++
      setSubmittedToInStockModel({
        id: row.id,
        status: status,
        visible: true,
        order_number: row.order_number,
      })
    } else {
      setClickedRow({ id: row.id, loading: true, status })

      try {
        await axiosExpand.put(`/purchase_orders/update-status/`, {
          id: row.id,
          status,
        })

        // if (
        //   (row.order_type === 'material' || row.order_type === 'packing') &&
        //   status === 'in_stock'
        // ) {
        //   window.open(`/manage-stocks/new/${row.id}`)
        // }
        doFetch((prev) => ({ ...prev }))
        setClickedRow({ id: undefined, loading: false })
      } catch (error) {
        setClickedRow({ id: undefined, loading: false })
        doFetch((prev) => ({ ...prev }))
        message.error(error.response?.data.errors?.message)
      }
    }
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field && sorter.order ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
    })
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Tìm kiếm..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="create-button"
          type="primary"
          icon={<PlusOutlined className="create-icon" />}
          onClick={handCreate}
        >
          Tạo đơn đặt hàng
        </Button>
      </div>
    )
  }

  const loadingCondition = loading || listStatus.loading

  return (
    <div id="manage_purchase_order_table">
      <Table
        title={renderTitle}
        bordered
        className="users-table"
        dataSource={dataSource}
        columns={columns}
        pagination={{ ...pagination, ...pageSizeConfig }}
        loading={loadingCondition}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1350, y: 680 }}
        onRow={(record) => ({
          onClick: () => handleView(record),
        })}
      />
      <SubmittedToInStockModel
        {...submittedToInStockModel}
        setSubmittedToInStockModel={setSubmittedToInStockModel}
        doFetch={doFetch}
      />
      <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} />
    </div>
  )
})
