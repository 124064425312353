import React, { useState, useEffect } from 'react'
import axiosExpand from 'Utils/axiosExpand'
import { Typography, Card, Col, Row, Button, message } from 'antd'
import {
  RollbackOutlined,
  FormOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import { formatNumber, numToWord } from 'Utils'
import axios from 'Utils/axiosRexyAdmin'
import moment from 'moment'

const { Title } = Typography

const INITIAL_PROFILE = {
  code: '',
  bank_branch: '',
  bank_number: '',
  bank_account_name: '',
  bank_name: '',
  note: '',
  supplier_id: undefined,
  amount: undefined,
  payment_method: undefined,
  supplier: {
    id: 0,
    code: '',
    name: '',
  },
  purchase_order: {
    id: 0,
    order_number: '',
  },
  purchase_order_invoice: {},
}

export default React.memo((props) => {
  const history = useHistory()
  const id = props.match?.params.id
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)
  const [state, setState] = useState({
    data: INITIAL_PROFILE,
    loading: false,
    errors: null,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const url = `/standing_orders/${id}/edit`
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const resp = await axios.get(url)
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            data: {
              ...resp.data,
            },
            loading: false,
          }))
        }
      } catch (error) {
        if (!didCancel) {
          setState((prev) => ({
            ...prev,
            loading: false,
            errors: error.response?.data.errors?.message,
          }))
        }
      }
    }

    fetchData()

    return () => (didCancel = true)
  }, [id])

  const handleBack = () => {
    !!history.goBack && typeof history.goBack === 'function'
      ? history.goBack()
      : history.push(`/standing-orders`)
  }

  const handleEdit = () => {
    history.push(`/standing-orders/${id}/edit`)
  }

  const handleDownloadStandingOrder = async () => {
    try {
      const FileDownload = require('js-file-download')
      const result = await axiosExpand.get(`/standing_orders/${id}/export/standing-order/`, {
        responseType: 'arraybuffer',
      })
      FileDownload(result.data, `${state.data.code}-uy-nhiem-chi.xlsx`)
    } catch (error) {
      // message.error(error.response?.data.errors?.message)
      message.error('Đã có lỗi xảy ra, xin vui lòng tải lại trang và thử lại.')
    }
  }

  return (
    <div id="view_standing_order" className="table-container">
      <Title className="page-title">Thông tin uỷ nhiệm chi</Title>

      <div className="body-container">
        <div className="general-information">
          <Title className="part-title">Thông tin chung</Title>
          <div className="part-field-wrapper">
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã uỷ nhiệm chi
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Ngày
                </Col>
                <Col className="content-wrapper" span={16}>
                  {moment(state.data.date).format('DD-MM-YYYY')}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.supplier.code}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Tên nhà cung cấp
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.supplier.name}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Mã đơn đặt hàng NCC
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.purchase_order.order_number}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số hoá đơn TT công nợ NCC
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.purchase_order_invoice?.invoice_number}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số tiền bằng số (VND)
                </Col>
                <Col className="content-wrapper" span={16}>
                  {formatNumber(state.data.amount)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Hình thức thanh toán
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.readable_payment_method}
                </Col>
              </Row>
            </Card.Grid>
            {state.data.payment_method === 'atm' && (
              <>
                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Tên ngân hàng thụ hưởng
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.bank_name}
                    </Col>
                  </Row>
                </Card.Grid>

                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Chi nhánh ngân hàng
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.bank_branch}
                    </Col>
                  </Row>
                </Card.Grid>

                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Số tài khoản
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.bank_number}
                    </Col>
                  </Row>
                </Card.Grid>

                <Card.Grid hoverable={false} className="view-item">
                  <Row className="row">
                    <Col className="field-title-wrapper text-style" span={8}>
                      Đơn vị thụ hưởng
                    </Col>
                    <Col className="content-wrapper" span={16}>
                      {state.data.bank_account_name}
                    </Col>
                  </Row>
                </Card.Grid>
              </>
            )}

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Số tiền bằng chữ
                </Col>
                <Col className="content-wrapper" span={16}>
                  {numToWord(state.data.amount)}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Đã duyệt
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.approved ? (
                    <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} />
                  ) : (
                    <CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />
                  )}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  Nội dung
                </Col>
                <Col className="content-wrapper" span={16}>
                  {state.data.note}
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid hoverable={false} className="view-item">
              <Row className="row">
                <Col className="field-title-wrapper text-style" span={8}>
                  File uỷ nhiệm chi
                </Col>
                <Col className="content-wrapper" span={16}>
                  <span className="downloadable-button" onClick={handleDownloadStandingOrder}>
                    Tải về
                  </span>
                </Col>
              </Row>
            </Card.Grid>
          </div>
        </div>
        <div className="button-container">
          <Button
            icon={<RollbackOutlined />}
            type="primary"
            loading={state.loading}
            className="cancel-button"
            onClick={handleBack}
          >
            Trở lại
          </Button>
          {state.data.approved === false && isSuperAdmin === false && (
            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={state.loading}
              className="process-button"
              onClick={handleEdit}
            >
              Chỉnh sửa
            </Button>
          )}

          {isSuperAdmin === true && (
            <Button
              icon={<FormOutlined />}
              type="primary"
              loading={state.loading}
              className="process-button"
              onClick={handleEdit}
            >
              Chỉnh sửa
            </Button>
          )}
        </div>
      </div>
    </div>
  )
})
